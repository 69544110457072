<template>
  <div class="trustpilot-container">
    <div v-if="location === 'menu'" class="trustpilot-widget widget-menu" :data-locale="widgetLocale"
      data-template-id="5419b732fbfb950b10de65e5" data-businessunit-id="59a94d4c0000ff0005aa2d04"
      data-style-height="24px" data-style-width="100%" data-theme="light">
      <a href="https://nl.trustpilot.com/review/snijlab.nl" target="_blank" rel="noopener">{{ $t('check-us-on-trustpilot') }}</a>
    </div>
    <div v-if="location === 'footer'" class="trustpilot-widget widget-footer mt-3" :data-locale="widgetLocale"
      data-template-id="53aa8807dec7e10d38f59f32" data-businessunit-id="59a94d4c0000ff0005aa2d04"
      data-style-height="150px" data-style-width="100%" data-theme="light">
      <a href="https://nl.trustpilot.com/review/snijlab.nl" target="_blank" rel="noopener">{{ $t('check-us-on-trustpilot') }}</a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    location: {
      type: String,
      required: false,
      default: 'menu',
    },
  },
  data() {
    return {
      scriptLoaded: false,
      widgetLocale: this.$i18n.locale === 'nl' ? 'nl-NL' : 'en-EN',
    };
  },

  mounted() {
    // const trustpilotScript = document.createElement('script');
    // trustpilotScript.setAttribute('src', 'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js');
    // document.head.appendChild(trustpilotScript);

    // eslint-disable-next-line max-len
    let trustpilotScript = document.querySelector('script[src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"]');
    if (!trustpilotScript) {
      trustpilotScript = document.createElement('script');
      trustpilotScript.addEventListener('load', () => {
        this.scriptLoaded = true;
      });
      trustpilotScript.setAttribute('src', 'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js');
      document.head.appendChild(trustpilotScript);
    } else {
      this.scriptLoaded = true;
    }
  },
};
</script>

<style lang="scss">
  .trustpilot-container {
    margin: auto;
    width: 100%;

    .widget-menu {
      iframe {
        margin-top: 0.5rem !important
      }
    }
  }
</style>
