<template>
  <div v-if="showSubmenu" class="sub-menu"
    :class="{'and-message-bar': showingMessageBar}">
    <div v-show="loading" class="progress">
      <div class="progress-bar bg-snijlab progress-bar-indeterminate"
        role="progressbar"/>
    </div>
    <b-nav v-if="submenuType === 'cart'" tabs>
      <div class="btn-container btn-cart">
        <b-button v-if="submenuSelected === 'review'"
          :to="{name: 'order-cart', params: routeParams}"
          size="sm"
          class="left btn btn-light">
            <i class="material-icons compact">arrow_back_ios</i> {{$t('submenu.shopping_cart')}}
        </b-button>
      </div>

      <div
        v-if="submenuSelected !== 'drawingProducts'"
        class="btn-container-center">
        <b-nav-item
          :to="{name: 'dashboard', params: routeParams}"
          :active="submenuSelected === 'dashboard'">
          {{$t('submenu.centermenu.dashboard')}}</b-nav-item>

        <b-nav-item
          :to="{name: 'order-cart', params: routeParams}"
          :disabled="status !== 'DRAFT' && !this.$store.getters.isAdmin"
          :class="{'btn-snijlab' : status !== 'DRAFT' && this.$store.getters.isAdmin}"
          :active="submenuSelected === 'cart'">
          <span class="num">1.</span> {{$t('submenu.centermenu.shopping_cart')}}</b-nav-item>

        <b-nav-item
          :to="{name: 'order-review', params: routeParams}"
          :disabled="status !== 'DRAFT' && !this.$store.getters.isAdmin"
          :class="{'btn-snijlab' : status !== 'DRAFT' && this.$store.getters.isAdmin}"
          :active="submenuSelected === 'review'">
          <span class="num">2.</span> {{$t('submenu.centermenu.order_overview')}}</b-nav-item>

        <b-nav-item
          :disabled="status !== 'SUBMITTED'"
          :to="{name: 'order-payment', params: routeParams}"
          :active="submenuSelected === 'payment'">
          <span class="num">3.</span> {{$t('submenu.centermenu.payment')}}</b-nav-item>
      </div>

      <div class="btn-container btn-container-right">
        <b-button v-if="submenuSelected === 'cart'"
          :to="{name: 'order-review', params: routeParams}"
          size="sm"
          :class="{'btn-success':isValidCart, 'btn-light':!isValidCart}">
          {{$t('submenu.complete_order')}} <i class="material-icons compact">arrow_forward_ios</i>
        </b-button>
        <div v-if="submenuSelected === 'review'"/>
      </div>
    </b-nav>

    <b-nav v-else-if="submenuType === 'my-settings'"
      tabs>
      <div class="btn-container btn-container-right btn-container-right-spacer" />
      <div class="btn-container-center">
        <h1
          v-if="submenuSelected === 'settings'"
          :to="{name:'user-settings-me', params: routeParams}"
          :active="submenuSelected === 'settings'"><i class="material-icons compact">person_outline</i>
          <template v-if="!this.$store.getters.isAdmin"> {{$t('submenu.title.my_details')}}</template>
          <template v-else> {{$t('admin.details_of')}} {{this.$store.getters.profileFullName}}</template>
        </h1>

        <h1
          v-if="submenuSelected === 'drawingProducts'"><i class="material-icons compact">folder_open</i>
          <template v-if="!this.$store.getters.isAdmin"> {{$t('submenu.title.previously_ordered_designs')}}</template>
          <template v-else> {{$t('admin.previously_ordered_designs_of')}} {{this.$store.getters.profileFullName}}</template>
        </h1>

      <h1
        v-if="submenuSelected === 'order-list'"><i class="material-icons compact">history</i>
        <template v-if="!this.$store.getters.isAdmin"> {{$t('submenu.title.orders')}}</template>
        <template v-else> {{$t('admin.orders_of')}} {{this.$store.getters.profileFullName}}</template>
      </h1>

      <h1 v-if="submenuSelected === 'drafts-list'"
        :to="{name:'drafts-list-me', params: routeParams}"
        :active="submenuSelected === 'drafts-list'">
          <i class="material-icons compact">list_alt</i>
          <template v-if="!this.$store.getters.isAdmin"> {{$t('submenu.title.concept_orders')}}</template>
          <template v-else> {{$t('admin.concept_order_of')}} {{this.$store.getters.profileFullName}}</template>
        </h1>
      </div>

      <div class="btn-container btn-container-right">
        <b-button
          size="sm"
          :to="{name: 'order-cart', params: routeParams}"
          :class="{'btn-success': true }">
            <i class="material-icons">shopping_cart</i> {{$t('submenu.shopping_cart')}}
          </b-button>
      </div>
    </b-nav>
  </div>
</template>
<script>

export default {
  props: {
    orderId: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    showSubmenu: {
      type: Boolean,
      default: false,
    },
    showingMessageBar: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    routeParams() {
      return {
        lang: this.$i18n.locale,
        orderId: this.orderId,
      };
    },
    status() {
      return this.$store.state.cart.status;
    },
    submenuType() {
      if (this.$route.meta.submenuType) {
        return this.$route.meta.submenuType;
      }
      return '';
    },
    submenuSelected() {
      if (this.$route.meta.submenuSelected) {
        return this.$route.meta.submenuSelected;
      }
      return '';
    },
    cartEmpty() {
      return Object.keys(this.$store.state.cart.orderDrawings).length === 0;
    },
    orderIsSubmitted() {
      return this.$store.state.cart === 'SUBMITTED';
    },
    orderIsDraft() {
      return this.$store.state.cart === 'DRAFT';
    },
    isValidOrder() {
      return !(this.data.orderErrors && Object.keys(this.data.orderErrors).length > 0)
        || (this.data.addressWarnigns && Object.keys(this.data.addressWarnings).length > 0)
        || (this.data.addressErrors && Object.keys(this.data.addressErrors).length > 0);
    },
    isValidCart() {
      // returns true if all configuration in the cart is done
      return this.$store.state.cart.orderErrors !== undefined
      && Object.keys(this.$store.state.cart.orderErrors).length === 0 && !this.cartEmpty;
    },
  },
};
</script>
<style lang="scss">
  .sub-menu {
    @media (min-width: 768px) {
      &.and-message-bar {
        padding-top: 50px;
      }
    }

    .btn-container-center {
      display: flex;
      flex-direction: row;
      align-content: center;

      h1 {
        padding-top: 0.75rem;
        font-size: 0.875rem;
        font-weight: 400;
        vertical-align: middle;
      }
    }

    .btn-container {
      margin-top: 2px;
      width: 210px
    }
    .progress-bar-indeterminate.bg-snijlab {
        border-bottom-color: #fff5a3;
    }
    .progress {
        margin-bottom: -5px;
    }
    .nav {
      display: flex;
      justify-content: space-between;
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .nav .nav-link {
      cursor: pointer;
      text-transform: none;
    }

    .btn-container {
      display: flex;
      justify-content: flex-start;
      padding-bottom: 0.2rem;
    }
    .btn-container.btn-container-right {
      justify-content: flex-end;
    }

    .btn {
      margin: 5pt 0;
    }

    .nav-item {
      display: flex;
    }

    @media (max-width: 1092px) {
      .btn {
        margin: 3pt 0;
      }
    }

    @media (max-width: 1084px) {

      .nav .btn-container .btn {
        font-size: 0.75rem;
        padding: 0.5rem;
      }

      .btn-container {
        width: 185px;

      }

      .nav .nav-link {
        min-height: 2.25rem;
        padding: 0.75rem;
        font-size: 0.75rem;
      }

      .btn-container.btn-container-right .btn, .btn-container.btn-container-left .btn {
        right: inherit;
        position: relative;
      }

      .btn-container {
        margin-top: 2px;
      }

      .btn-container-center h1 {
        padding-top: 0.70rem;
        font-size: 0.75rem;
        font-weight: 400;
      }

      .btn-container-left {
        justify-content: flex-start;
      }
    }

    @media (max-width: 878px) {
      .nav .btn-container {
        margin-left: 1rem;
        margin-right: 1rem;
      }

      .nav {
        padding-left: 0;
        padding-right: 0;
      }

      .nav-item > .nav-link {
        display: none;
      }

      .nav-item > .nav-link.active {
        display: block;
      }
    }

    @media (max-width: 576px) {

      .btn-container {
        width: auto;
      }

      .num {
        display: none;
      }

      .btn-container .btn i.material-icons {
        display: none;
      }

      .nav.nav-tabs {
        justify-content: space-around;

        .btn-container-right-spacer {
          display: none;
        }
        .btn-container-center {
          align-self: flex-start;
        }
        .btn-container-right {
          align-self: flex-end;
          margin: 0;
        }

        .btn-container.btn-cart {
          margin: 0;
        }
      }
    }
  }

</style>
