<template>
  <div class="dashboard-recent-uploads-block bg-light-4
  21 border border-light rounded p-2">
    <h2>{{$t('dashboard.previous_uploads')}}</h2>
    <div class="recent-upload-container">
      <table class="table table-condensed mb-0 w-100">
        <tbody>
          <recent-upload-item v-for="(dcId) in drawingConfigurationsSorted.slice(0, 3)" :key="dcId" :id="dcId" />
        </tbody>
      </table>
    </div>
    <router-link v-if="drawingConfigurationsSorted.length > 3" class="btn btn-link mt-2"
      :to="{name: 'products-list-me', params: {lang: $i18n.locale}}">
      <i class="material-icons">history</i> {{$t('dashboard.all_your_uploads')}}
    </router-link>
  </div>

</template>

<script>
// import _has from 'lodash/has';
// import LoadingButton from 'src/components/tools/LoadingButton';
import RecentUploadItem from 'src/components/dashboard/RecentUploadItem';

export default {
  components: {
    // LoadingButton,
    RecentUploadItem,
  },
  props: {
    drawingConfigurationsSorted: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      // addToOrderLoading: false,
    };
  },
  computed: {
    // isInCart() {
    //   return _has(this.drawingConfiguration.draftOrders, this.$store.state.cart.orderId);
    // },
    // canAddToCart() {
    //   return this.drawingConfiguration.approvable && !this.hasUndeliverableMaterial;
    // },
    // hasError() {
    //   return this.drawingConfiguration.feedback.some((f) => f.type === 'ERROR');
    // },
    // hasUndeliverableMaterial() {
    //   return this.drawingConfiguration.locked && (this.hasMaterial && !this.materialIsPublished);
    // },
    // materialIsPublished() {
    //   if (this.material) {
    //     return this.material.is_published;
    //   }
    //   return false;
    // },
    // hasMaterial() {
    //   return this.drawingConfiguration.material_id in this.materials
    //   && parseInt(this.drawingConfiguration.material_id, 10) !== 1;
    // },
    // materials() {
    //   return this.$store.state.materials;
    // },
    // material() {
    //   if (this.materials[this.drawingConfiguration.material_id]) {
    //     return this.materials[this.drawingConfiguration.material_id];
    //   }
    //   return null;
    // },
    // addToCartVariant() {
    //   if (this.canAddToCart && this.hasMaterial) {
    //     return 'primary';
    //   }
    //   return 'empty';
    // },
    // drawingConfiguration() {
    //   return this.$store.state.dc.drawingConfiguration[parseInt(this.id, 10)];
    // },
    // height() {
    //   if (!_has(this.drawingConfiguration, 'Drawing.dimensions.height')) {
    //     return '';
    //   }
    //   return parseFloat(this.drawingConfiguration.Drawing.dimensions.height)
    //     .toFixed(0);
    // },
    // width() {
    //   if (!_has(this.drawingConfiguration, 'Drawing.dimensions.width')) {
    //     return '';
    //   }
    //   return parseFloat(this.drawingConfiguration.Drawing.dimensions.width)
    //     .toFixed(0);
    // },
    // hasSize() {
    //   return this.width !== '' && this.width !== '0' && this.height !== '' && this.height !== '0';
    // },
    // pngFile() {
    //   return `${this.$config.apiUrl}/drawing/pngsmall/${this.drawingConfiguration.drawing_id}`;
    // },
    // createdDate() {
    //   return new Date(this.drawingConfiguration.created_at).toLocaleDateString(this.$i18n.locale, {
    //     year: 'numeric', month: '2-digit', day: '2-digit',
    //   });
    // },
  },
  methods: {
    formatAcceptedDate(date) {
      const options = {
        // weekday: 'short',
        // year: 'numeric',
        // month: 'short',
        // day: 'numeric',
      };
      const event = new Date(date);
      return event.toLocaleDateString(this.$i18n.locale, options);
    },
    statusLabel(status) {
      switch (status) {
        case 'DRAFT':
          return this.$t('status_labels.draft');
        case 'SUBMITTED':
          return this.$t('status_labels.payment_pending');
        case 'ACCEPTED':
          return this.$t('status_labels.in_production');
        case 'IN_PROCESS':
          return this.$t('status_labels.in_production');
        case 'PROCESSED':
          return this.$t('status_labels.done');
        case 'SHIPPED':
          return this.$t('status_labels.shipped');
        case 'CANCELLED':
          return this.$t('status_labels.cancelled');
        case 'EXPIRED':
          return this.$t('status_labels.expired');
        default:
          return status;
      }
    },
    statusValue(status) {
      switch (status) {
        case 'DRAFT':
          return 0.0;
        case 'SUBMITTED':
          return 0.25;
        case 'ACCEPTED':
          return 0.5;
        case 'IN_PROCESS':
          return 0.75;
        case 'PROCESSED':
          return 0.9;
        case 'SHIPPED':
          return 1.0;
        case 'CANCELLED':
          return 0.0;
        case 'EXPIRED':
          return 0.0;
        default:
          return 0.0;
      }
    },
    statusVariant(status) {
      switch (status) {
        case 'DRAFT':
          return 'secondary';
        case 'SUBMITTED':
          return 'info';
        case 'ACCEPTED':
          return 'primary';
        case 'IN_PROCESS':
          return 'primary';
        case 'PROCESSED':
          return 'primary';
        case 'SHIPPED':
          return 'success';
        case 'CANCELLED':
          return 'danger';
        case 'EXPIRED':
          return 'danger';
        default:
          return 'secondary';
      }
    },
    invoiceLink(order) {
      return `${this.$config.apiUrl}/document/invoice/${order.invoiceId}`;
    },

    // addToOrder() {
    //   this.addToOrderLoading = true;
    //   this.$store.dispatch('addDrawingConfiguration', {
    //     dcId: this.id,
    //     orderId: this.orderId,
    //   })
    //     .finally(() => {
    //       // this.$eventBus.$emit('gtm.add_to_cart', [this.id]);
    //       this.addToOrderLoading = false;
    //     });
    // },
    // showSelectMaterial() {
    //   this.$emit('showSelectMaterial', this);
    //   return false;
    // },
    // openProductEditor() {
    //   this.$router.push({
    //     name: 'products-list-me-editor',
    //     params: { productId: this.id },
    //   });
    // },
  },
};
</script>

<style lang="scss">
  .dashboard-recent-uploads-block {
    .recent-upload-container {
      table {
        th, td {
          padding: .2rem !important;
          height: auto !important;

          .shipping-method {
            font-size: 0.6rem;
          }
        }
        .recent-upload-item {
          .img-container {
            max-width: 100px;
          }
        }
      }
    }
  }
</style>
