/* eslint no-param-reassign: 0 */
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import _orderBy from 'lodash/orderBy';
import config from 'src/config';
import cart from 'src/store/modules/cart.store';
import dc from 'src/store/modules/drawingConfigurations.store';
import EventBus from 'src/event-bus';
import i18n from '@/i18n';

Vue.use(Vuex);

// this store is for a cart..
const store = new Vuex.Store({
  strict: false,
  modules: {
    cart,
    dc,
  },
  state: {
    drawingProducts: {},
    drawingProductGroups: {},
    translations: {},
    materials: {},
    materialAdvice: {},
    materialFamilies: {},
    materialCategories: {},
    materialLabels: {},
    products: {},
    templates: {},
    priorities: {},
    selectorMaterials: [],
    orders: {},
    user: null,
    admin_user: null,
    masqUserId: null,
    lang: null,
    profile: {},
    profileErrors: {},
    settings: {
      showVat: true,
    },
    messageBar: '',
    showMessageBar: false,
    pages: {},
    popups: {},
    errors: {},
    loaded: {
      user: false,
      settings: false,
      profile: false,
      drawingProducts: false,
      drawingProductGroups: false,
      countries: false,
      translations: false,
      materials: false,
      materialsAdvice: false,
      materialFamilies: false,
      drawingConfigurations: false,
      productGroups: false,
      errors: false,
      popups: false,
      pages: false,
      cart: false,
      templates: false,
    },
    lock: {},
    countries: {},
  },
  getters: {
    prioritiesOrdered(state) {
      return _orderBy(state.cart.priorities, ['ordinal'], ['asc']);
    },
    loaded: (state) => (sections) => sections.every((sec) => sec in state.loaded && state.loaded[sec]),

    // quick access to info for admin part
    isAdmin: (state) => !!state.admin_user,
    lang: (state) => state.lang,
    profileFullName: (state) => {
      let fullName = '';
      if (state.profile.first_name) fullName = state.profile.first_name;
      if (state.profile.last_name) {
        if (fullName !== '') fullName += ' ';
        fullName += state.profile.last_name;
      }
      return fullName;
    },
  },
  actions: {
    /**
     * Triggered when an 401 error is received
     * @param context
     */
    signedOut(context) {
      if (context.state.loaded.user === true) {
        window.location.refresh();
      }
    },
    setManualStatus(context, value) {
      context.commit('SET_STATUS', value);
    },
    createNewOrder(context) {
      return axios.get(
        `${config.apiUrl}/order/create`,
        { withCredentials: true },
      ).then((response) => {
        context.commit('UPDATE_CART', response.data.result);
        context.commit('SET_CURRENT_ORDER', { orderId: response.data.result.orderId });
      }).catch((err) => {
        EventBus.error(err);
      });
    },
    deleteDraftOrder(context, orderId) {
      const formData = new FormData();
      formData.append('order_id', orderId);
      return axios.post(
        `${config.apiUrl}/order/deleteDraftOrder`,
        formData,
        { withCredentials: true },
      ).then(() => {
        context.commit('DELETE_DRAFT', orderId);
        EventBus.success('Conceptorder verwijderd');
      }).catch((err) => {
        EventBus.error(err);
      });
    },
    loadUserOrders(context) {
      return axios.get(
        `${config.apiUrl}/user/orders`,
        { withCredentials: true },
      ).then((response) => {
        context.commit('SET_USER_ORDERS', { orders: response.data.result });
      }).catch((err) => {
        EventBus.error(err);
      });
    },
    loadMaterials(context) {
      if (context.state.lock.LOADING_MATERIALS) {
        return null;
      }
      context.commit('LOCK', { name: 'LOADING_MATERIALS', value: true });
      if (Object.keys(context.state.materials).length !== 0) {
        return null;
      }
      return axios.get(`${config.apiUrl}/material/getMaterials`, {
        withCredentials: true,
      }).then((response) => {
        context.commit('SET_MATERIAL_FAMILIES', response.data.result);
        context.commit('SET_MATERIALS', response.data.result);
        context.commit('SET_MATERIAL_ADVICE', response.data.result);
        context.commit('SET_DRAWING_PRODUCT_GROUPS', response.data.result);
        context.commit('SET_DRAWING_PRODUCTS', response.data.result);
        context.commit('SET_PRODUCTS', response.data.result);
      }, (err) => {
        throw err;
      }).finally(() => {
        context.commit('UNLOCK', { name: 'LOADING_MATERIALS' });
      });
    },
    loadTemplates(context) {
      if (context.state.lock.LOADING_TEMPLATES) {
        return null;
      }
      context.commit('LOCK', { name: 'LOADING_TEMPLATES', value: true });
      if (Object.keys(context.state.templates).length !== 0) {
        return null;
      }
      return axios.get(`${config.apiUrl}/cms/getTemplates`, {
        withCredentials: true,
      }).then((response) => {
        context.commit('SET_TEMPLATES', response.data.result);
      }, (err) => {
        throw err;
      }).finally(() => {
        context.commit('UNLOCK', { name: 'LOADING_TEMPLATES' });
      });
    },
    // prevent double loading..
    loadSettings(context) {
      if (context.state.lock.LOADING_SETTINGS) {
        return null;
      }
      context.commit('LOCK', { name: 'LOADING_SETTINGS', value: true });
      if (Object.keys(context.state.materials).length !== 0) {
        return null;
      }
      return axios.get(`${config.apiUrl}/settings/bootstrap`, {
        withCredentials: true,
      }).then((response) => {
        context.commit('SET_TRANSLATIONS', response.data.result);
        context.commit('SET_COUNTRIES', response.data.result);
        context.commit('SET_PRIORITIES', response.data.result);
        context.commit('SET_DRAWING_ERRORS', response.data.result);
        context.commit('SET_POPUPS', response.data.result);
        context.commit('SET_PAGES', response.data.result);
        context.commit('SET_MESSAGE_BAR', response.data.result);
        context.commit('UNLOCK', { name: 'LOADING_SETTINGS' });
      }, (err) => {
        context.commit('UNLOCK', { name: 'LOADING_SETTINGS' });
        throw err;
      });
    },
    loadUser({ commit }, user) {
      commit('SET_LANG', user.settings.lang);
      commit('SET_USER', user);
    },
    setShowVat({ commit }, showVat) {
      commit('SET_SHOW_VAT', showVat);
    },
    setAllowAdvancedProducts({ commit }, allowAdvancedProducts) {
      commit('SET_ALLOW_ADVANCED_PRODUCTS', allowAdvancedProducts);
    },
    setShowMessageBar({ commit }, value) {
      commit('SET_SHOW_MESSAGE_BAR', value);
    },
    // eslint-disable-next-line no-unused-vars
    updateLang({ commit }, value) {
      if (!value) {
        return;
      }
      i18n.locale = value;
      commit('SET_LANG', value);
      config.apiUrl = `${config.baseApiUrl}/${value}`;
    },
    setLoaded({ commit }, type, isLoaded) {
      commit('SET_LOADED', { type, isLoaded });
    },
    updateProfileField(context, { field, value }) {
      const formData = new FormData();
      if (field instanceof Array && value instanceof Array) {
        for (let i = 0; i < Math.min(field.length, value.length); i += 1) {
          formData.append('field[]', field[i]);
          formData.append('value[]', value[i]);
        }
      } else {
        formData.append('field[]', field);
        formData.append('value[]', value);
      }
      if (field.length === 0) {
        return null;
      }
      return axios.post(
        `${config.apiUrl}/user/updateField`,
        formData,
        { withCredentials: true },
      )
        .then((response) => {
          if (response.data.error !== null) {
            context.commit('UPDATE_PROFILE_ERRORS', response.data.error);
          } else {
            context.commit('REMOVE_PROFILE_ERRORS', field);
            context.commit('UPDATE_PROFILE', response.data.result);
          }
        }).catch((err) => {
          if (err.response.data.error !== null) {
            context.commit('UPDATE_PROFILE_ERRORS', err.response.data.error);
          } else {
            EventBus.error(err);
          }
        });
    },
    updateVat(context, value) {
      const formData = new FormData();
      formData.append('value', value);
      return axios.post(
        `${config.apiUrl}/user/updateVat`,
        formData,
        { withCredentials: true },
      ).then(() => {
        context.commit('UPDATE_VAT', value);
      }).catch((err) => {
        EventBus.error(err);
      });
    },
    updateAllowAdvancedProducts(context, value) {
      const formData = new FormData();
      formData.append('value', value);
      return axios.post(
        `${config.apiUrl}/user/updateAllowAdvancedProducts`,
        formData,
        { withCredentials: true },
      ).then(() => {
        context.commit('UPDATE_ALLOW_ADVANCED_PRODUCTS', value);
      }).catch((err) => {
        EventBus.error(err);
      });
    },
  },
  mutations: {
    LOCK(state, { name, value }) {
      Vue.set(state.lock, name, value);
    },
    UNLOCK(state, { name }) {
      Vue.delete(state.lock, name);
    },
    SET_CURRENT_ORDER(state, orderId) {
      Vue.set(state.settings, 'order_id', orderId);
    },
    SET_USER: (state, {
      // eslint-disable-next-line camelcase
      user, profile, settings, admin_user,
    }) => {
      Vue.set(state, 'user', user);
      Vue.set(state, 'profile', profile);
      // eslint-disable-next-line camelcase
      if (admin_user) {
        Vue.set(state, 'admin_user', admin_user);
      }
      Vue.set(state, 'settings', settings);
      Vue.set(state.loaded, 'user', true);
    },
    SET_STATUS: (state, value) => {
      Vue.set(state.settings, 'status', !!value);
    },
    SET_MASQ_USER_ID: (state, value) => {
      Vue.set(state, 'masqUserId', value);
    },
    SET_PROFILE: (state, profile) => {
      Vue.set(state, 'profile', profile);
    },
    SET_LOADED: (state, { type, isLoaded }) => {
      Vue.set(state.loaded, type, isLoaded);
    },
    SET_TRANSLATIONS: (state, { translation }) => {
      Vue.set(state, 'translations', translation);
      Vue.set(state.loaded, 'translations', true);
    },
    SET_DRAWING_ERRORS: (state, { errors }) => {
      Vue.set(state, 'errors', errors);
      Vue.set(state.loaded, 'errors', true);
    },
    // eslint-disable-next-line camelcase
    SET_MESSAGE_BAR: (state, { message_bar }) => {
      Vue.set(state, 'messageBar', message_bar);
      // eslint-disable-next-line camelcase
      if (message_bar !== '') {
        Vue.set(state, 'showMessageBar', true);
      }
    },
    SET_POPUPS: (state, { popups }) => {
      Vue.set(state, 'popups', popups);
      Vue.set(state.loaded, 'popups', true);
    },
    SET_PAGES: (state, { pages }) => {
      Vue.set(state, 'pages', pages);
      Vue.set(state.loaded, 'pages', true);
    },
    SET_PRIORITIES: (state, { priorities }) => {
      Vue.set(state, 'priorities', priorities);
      Vue.set(state.loaded, 'translations', true);
    },
    SET_COUNTRIES: (state, { countries }) => {
      Vue.set(state, 'countries', countries);
      Vue.set(state.loaded, 'countries', true);
    },
    SET_SHOW_VAT: (state, showVat) => {
      Vue.set(state.settings, 'showVat', showVat);
    },
    SET_ALLOW_ADVANCED_PRODUCTS: (state, allowAdvancedProducts) => {
      Vue.set(state.settings, 'allowAdvancedProducts', allowAdvancedProducts);
    },
    SET_SHOW_MESSAGE_BAR: (state, value) => {
      Vue.set(state, 'showMessageBar', value);
    },
    SET_LANG: (state, value) => {
      Vue.set(state, 'lang', value);
    },
    SET_MATERIALS: (state, { materials }) => {
      const materialCategories = [];
      const materialLabels = [];

      for (let i = 0; i < materials.length; i += 1) {
        const material = materials[i];

        material.ordinal = state.materialFamilies[material.family_id].ordinal;
        material.color_bucket = material.color_bucket.toLowerCase();
        let hasSelfSuppliedCat = false;
        material.tags.forEach((tag) => {
          if (tag.startsWith('_cat_')) {
            const tagStripped = tag.replace('_cat_', '');
            if (materialCategories.indexOf(tagStripped) === -1) {
              materialCategories.push(tagStripped);
            }
            hasSelfSuppliedCat = tag.endsWith('_self_supplied');
          } else if (tag.startsWith('_label_')) {
            const tagStripped = tag.replace('_label_', '');
            if (materialLabels.indexOf(tagStripped) === -1) {
              materialLabels.push(tagStripped);
            }
          }
        });

        if (material.family_name.startsWith('Acryl')) {
          const nameSplit = material.family_name.split(' ');
          const acrylicName = nameSplit[0];
          nameSplit.splice(0, 1);
          const acrylicSpecification = nameSplit.join(' ');

          material.pretty_name_spaced = [
            acrylicName,
            !hasSelfSuppliedCat ? `${parseFloat(material.thickness, 10)} mm` : '',
            acrylicSpecification,
            '<br>',
            material.surface !== '' ? material.surface : '',
            material.name_specification,
          ].join(' ');
        } else {
          material.pretty_name_spaced = [
            material.family_name,
            !hasSelfSuppliedCat ? `${parseFloat(material.thickness, 10)} mm` : '',
            '<br>',
            material.surface !== '' ? `${material.surface}` : '',
            material.name_specification,
          ].join(' ');
        }
        material.pretty_name = material.pretty_name_spaced.replace('<br>', '').replace(/\s+/g, ' ').trim();
        material.pretty_name_spaced = material.pretty_name_spaced.replace(/\s+/g, ' ').trim();

        if (!material.transparency) {
          material.transparency = 'opaque';
        }
        Vue.set(state.materials, material.id, material);
      }
      materialCategories.sort();
      Vue.set(state, 'materialCategories', materialCategories);
      Vue.set(state, 'materialLabels', materialLabels);
      Vue.set(state.loaded, 'materials', true);
    },
    // eslint-disable-next-line camelcase
    SET_MATERIAL_ADVICE: (state, { material_advice }) => {
      for (let i = 0; i < material_advice.length; i += 1) {
        const advice = material_advice[i];
        Vue.set(state.materialAdvice, advice.id, advice);
      }
      Vue.set(state.loaded, 'material_advice', true);
    },
    SET_MATERIAL_FAMILIES: (state, { families }) => {
      for (let i = 0; i < families.length; i += 1) {
        const family = families[i];
        if (family.name !== 'unused') {
          Vue.set(state.materialFamilies, family.id, family);
        }
      }
      Vue.set(state.loaded, 'materialFamilies', true);
    },
    SET_TEMPLATES: (state, { templates }) => {
      for (let i = 0; i < templates.length; i += 1) {
        const template = templates[i];
        Vue.set(state.templates, template.id, template);
      }
      Vue.set(state.loaded, 'templates', true);
    },
    // eslint-disable-next-line camelcase
    SET_DRAWING_PRODUCT_GROUPS: (state, { drawing_product_groups }) => {
      Object.values(drawing_product_groups).forEach((obj) => {
        if (i18n.locale in obj.Translation) {
          Vue.set(state.drawingProductGroups, obj.keyword, {
            name: obj.Translation[i18n.locale].name,
            id: obj.id,
            keyword: obj.keyword,
            DrawingProducts: obj.DrawingProducts,
          });
        }
      });
      Vue.set(state.loaded, 'drawingProductGroups', true);
    },
    SET_USER_ORDERS: (state, { orders }) => {
      for (let i = 0; i < orders.length; i += 1) {
        const order = orders[i];
        Vue.set(state.orders, parseInt(order.orderId, 10), order);
      }
      Vue.set(state.loaded, 'orders', true);
    },
    // eslint-disable-next-line camelcase
    SET_DRAWING_PRODUCTS: (state, { drawing_products }) => {
      // eslint-disable-next-line arrow-body-style
      for (let i = 0; i < drawing_products.length; i += 1) {
        const product = drawing_products[i];
        if (i18n.locale in product.Translation) {
          const obj = {
            name: product.Translation[i18n.locale].name,
            description: product.Translation[i18n.locale].description,
            url: product.Translation[i18n.locale].description,
            group_id: product.group_id,
            group: Object.values(state.drawingProductGroups).find((gr) => gr.id === product.group_id),
            id: product.id,
          };
          Vue.set(state.drawingProducts, product.id, obj);
        }
      }
      Vue.set(state.loaded, 'drawingProducts', true);
    },
    SET_PRODUCTS: (state, { products }) => {
      // eslint-disable-next-line arrow-body-style
      for (let i = 0; i < products.length; i += 1) {
        const product = products[i];
        if (i18n.locale in product.Translation) {
          product.name = product.Translation[i18n.locale].name;
          product.body = product.Translation[i18n.locale].body;
        }
        product.id = parseInt(product.id, 10);
        product.price = parseFloat(product.price);
        product.qty_available = parseInt(product.qty_available, 10);
        Vue.set(state.products, product.id, product);
      }
      Vue.set(state.loaded, 'products', true);
    },
    UPDATE_PROFILE_ERRORS: (state, errorObj) => {
      Object.keys(errorObj).forEach((field) => Vue.set(state.profileErrors, field, errorObj[field]));
    },
    REMOVE_PROFILE_ERRORS: (state, field) => {
      Vue.set(state.profileErrors, field, null);
    },
    UPDATE_PROFILE: (state, profile) => {
      Vue.set(state, 'profile', profile);
    },
    UPDATE_VAT: (state, value) => {
      Vue.set(state.settings, 'showVat', value);
    },
    UPDATE_ALLOW_ADVANCED_PRODUCTS: (state, value) => {
      Vue.set(state.settings, 'allowAdvancedProducts', value);
    },
    DELETE_DRAFT: (state, orderId) => {
      Vue.delete(state.orders, orderId);
    },
  },
});

export default store;
