<template>
  <div v-if="ready" class="drawing-configuration-list">
    <router-view/>

    <div class="card shadow-none dc-list">

      <div class="card-header dc-list-filter-container">
        <div class="dc-list-filter-select-all pl-2">
          <b-form-checkbox
            ref="cbSelectAll"
            v-model="selectAllChecked"
            @change="(val) => setAllSelected(val)" />
        </div>

        <div class="dc-list-filter-search p-0">
          <form-widget-input
            v-model="filterText"
            type="text"
            iconFront="search"
            size="sm"
            placeholder="Filter…"
            row-class="" />
        </div>

        <div v-if="$store.getters.isAdmin" class="dc-list-filter-buttons pt-2 pl-2">{{$t('drawing_configuration.show')}}:
          <chip-toggle class="bg-snijlab" :value="filter.all" @click="filterBy('all')">
            {{$t('drawing_configuration.all')}}</chip-toggle>
          <chip-toggle class="bg-snijlab" :value="filter.locked" @click="filterBy('locked')" icon="lock">
            {{$t('drawing_configuration.ordered')}}</chip-toggle>
          <chip-toggle class="bg-snijlab" :value="filter.draft" @click="filterBy('draft')" icon="edit">
            {{$t('drawing_configuration.not_ordered')}}</chip-toggle>
          <chip-toggle class="bg-snijlab" :value="filter.error" @click="filterBy('error')" icon="block">
            {{$t('drawing_configuration.cant_be_ordered')}}</chip-toggle>
        </div>

        <div v-else-if="$store.state.user.allow_advanced_products" class="dc-list-filter-buttons pt-2 pl-2">
          {{$t('drawing_configuration.show')}}:
          <chip-toggle :value="filter.all" @click="filterBy('all')">
            {{$t('drawing_configuration.all')}}</chip-toggle>
          <chip-toggle :value="filter.locked" @click="filterBy('locked')">
            {{$t('drawing_configuration.ordered')}}</chip-toggle>
          <chip-toggle :value="filter.draft" @click="filterBy('draft')">
            {{$t('drawing_configuration.not_ordered')}}</chip-toggle>
        </div>

        <div class="dc-list-group-actions">
          <drawing-configuration-batch-actions
            @loading="onBatchLoading"
            @deselectAll="setAllSelected(false)"
            :selected="Object.keys(selected)"
            :order-id="orderId" />
        </div>
      </div>
      <div>
        <div class="dc-list-container"
          v-if="visible"
          ref="drawingList">

          <drawing-configuration-list-item
            v-for="(key) in drawingConfigurationsSorted"
            ref="dc"
            :id="parseInt(key)"
            :key="key"
            :selected="isSelected(parseInt(key)) && !isFiltered(parseInt(key))"
            :filtered="isFiltered(parseInt(key)) || waitingForFirstFilterUpdate"
            :show-vat="showVat"
            :priority="priority"
            :order-id="orderId"
            :disabled="isBatchLoading"
            @selected="(val) => setSelected(parseInt(key), val)"
            @addToOrder="addToOrder"
            @showSelectMaterial="selectMaterial" />

          <div v-if="drawingConfigurationsSorted.filter(idx => !isFiltered(idx)).length === 0
            && drawingConfigurations.length !== 0"
            class="card shadow-1 mt-2">
            <div class="card-body p-2 text-larger">
              <p v-if="filterText !== ''">
                {{$t('drawing_configuration.there_are_no_designs_matching_the_filter')}}
                 "<strong>{{ filterText }}</strong>".<br />
                <span class="btn btn-light btn-xs mt-2" @click="clearFilter">{{$t('drawing_configuration.remove_filter')}}</span>
              </p>
              <p v-else>
                {{$t('drawing_configuration.you_havent_ordered_any_designs')}}<br />
                {{$t('drawing_configuration.go_to_your_cart_to_order')}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _orderBy from 'lodash/orderBy';
import Mark from 'mark.js';
import DrawingConfigurationListItem from 'src/components/drawingConfiguration/DrawingConfigurationListItem';
import FormWidgetInput from 'src/components/form/FormWidgetInput';
import ChipToggle from 'src/components/drawingConfiguration/ChipToggle';
import DrawingConfigurationBatchActions from 'src/components/drawingConfiguration/DrawingConfigurationBatchActions';
/** TODO: GET RID OF ALL REFS */
export default {
  components: {
    DrawingConfigurationBatchActions,
    ChipToggle,
    FormWidgetInput,
    DrawingConfigurationListItem,
  },
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    let query = `${this.$route.query.search}`;
    if (this.$route.query.search === undefined) {
      query = '';
    }
    return {
      filter: {
        all: true,
        draft: false,
        locked: false,
        error: false,
      },
      filtered: {},
      waitingForFirstFilterUpdate: true,
      selected: {},
      isBatchLoading: false,
      showDropdown: false,
      filterText: query,
      selectAllChecked: false,
    };
  },
  watch: {
    filterText(value) {
      this.updateFilters();
      this.$router.replace({
        name: 'products-list-me',
        params: { lang: this.$i18n.locale },
        query: { search: value },
      });
    },
    drawingConfigurations() {
      setTimeout(this.updateFilters, 100);
    },
  },
  computed: {
    priority() {
      return this.$store.state.cart.priorityId;
    },
    orderId() {
      const currentId = parseInt(this.$route.params.orderId, 10);
      if (!currentId) {
        return parseInt(this.$store.state.settings.order_id, 10);
      }
      return currentId;
    },
    showVat() {
      return this.$store.state.settings.showVat;
    },
    drawingProducts() {
      return this.$store.state.drawingProducts;
    },
    config() {
      return this.$config;
    },
    drawingConfigurations() {
      return this.$store.state.dc.drawingConfiguration;
    },
    drawingConfigurationsSorted() {
      return _orderBy(this.$store.state.dc.drawingConfiguration, ['id'], ['desc'])
        .map((item) => item.id);
    },
    ready() {
      return this.$store.getters.loaded([
        'drawingProducts',
        'materials',
        'translations',
        'user',
        'drawingConfigurations',
        'errors',
      ]);
    },
  },
  mounted() {
    this.updateFilters();
  },
  // Initial data..
  methods: {
    clearFilter() {
      this.filterText = '';
      this.filterBy('locked');
    },
    setFiltered(id, isTrue) {
      if (!isTrue) {
        this.$delete(this.filtered, parseInt(id, 10));
        this.$delete(this.selected, parseInt(id, 10));
      } else {
        this.$set(this.filtered, parseInt(id, 10), true);
      }
    },
    setAllFiltered(value) {
      if (!value) {
        Object.keys(this.filtered).forEach((val) => {
          this.setFiltered(val, false);
        });
      } else {
        Object.keys(this.drawingConfigurations).forEach((id) => {
          this.setFiltered(id, true);
        });
      }
    },
    isFiltered(key) {
      return key in this.filtered;
    },
    setSelected(id, isTrue) {
      if (!isTrue) {
        this.$delete(this.selected, parseInt(id, 10));
      } else if (!this.isFiltered(id)) {
        this.$set(this.selected, parseInt(id, 10), true);
      }
    },
    setAllSelected(value) {
      if (!value) {
        Object.keys(this.selected).forEach((val) => {
          this.setSelected(val, false);
        });
        this.selectAllChecked = false;
      } else {
        Object.keys(this.drawingConfigurations).forEach((id) => {
          this.setSelected(id, true);
        });
      }
    },
    isSelected(key) {
      return key in this.selected && !this.isFiltered(key);
    },
    onBatchLoading(value) {
      this.isBatchLoading = value;
    },
    showCart() {
      this.$emit('showCart');
    },

    selectMaterial(event) {
      this.$router.push({
        name: `${this.$route.name}-editor`,
        params: {
          orderId: this.$route.params.orderId,
          lang: this.$route.params.lang,
          productId: event.id,
        },
      });
    },
    addToOrder(component) {
      this.$store.dispatch('addDrawingConfiguration', {
        dcId: component.id,
        orderId: this.orderId,
      });
    },
    isFilteredRef(prodListItem, keywords) {
      const innerText = prodListItem.$el.textContent.toUpperCase().trim();
      const containsKeyword = keywords.every((word) => innerText.indexOf(word) !== -1);
      const error = !prodListItem.canAddToCart;
      const locked = prodListItem.drawingConfiguration.locked && !error;
      const draft = !locked && !error;
      const show = this.filter.all === true
        || (locked && this.filter.locked)
        || (error && this.filter.error)
        || (draft && this.filter.draft);
      return (keywords.length === 0 || containsKeyword) && show;
    },
    // TODO: This should become responsive
    updateFilters() {
      if (!this.$refs.dc) {
        return;
      }
      this.setAllFiltered(false);
      const self = this;
      const keywords = (self.filterText.trim()
        .replace(/ +(?= )/g, '')
        .toUpperCase()).split(' ')
        .filter((word) => word.trim().length !== 0);
      this.$refs.dc
        .filter((dcItem) => !this.isFilteredRef(dcItem, keywords))
        .forEach((dcItem) => this.setFiltered(dcItem.id, true));
      // Mark all text.
      const options = {
        element: 'span',
        className: 'highlight',
      };

      const nonfilteredList = this.$refs.dc.filter((dcItem) => this.isFilteredRef(dcItem, keywords)).map((dcItem) => dcItem.id);
      this.$store.dispatch('updateFilter', { productIds: nonfilteredList });
      if (nonfilteredList.length > 0) {
        const instance = new Mark(self.$refs.drawingList);
        instance.unmark(options);
        instance.mark(keywords, options);
      }
      this.waitingForFirstFilterUpdate = false;
    },
    filterBy(column) {
      this.filter.all = false;
      this.filter.error = false;
      this.filter.locked = false;
      this.filter.draft = false;
      this.filter[column] = true;
      this.updateFilters();
    },
  },
};
</script>

<style lang="scss">
  .drawing-configuration-list {
    .dc-list {
      margin: 0 1rem 1rem;
    }

    .text-larger {
      font-size: 0.9rem;
    }

    .dc-list-filter-container {
      display: grid;
      background-color: #fff;
      grid-template-columns: 65px 1fr 1fr;
      padding: 10px 10px 10px 0;
    }

    // .dc-list-group-actions {
    //   display: flex;
    // }

    .dc-list-filter-select-all {
      grid-column: 1/2;
      grid-row: 1/2;
      display: block;
      padding-top: 15px;
    }

    .dc-list-filter-container .dc-list-filter-search {
      grid-column: 2/3;
      grid-row: 1/2;
    }

    .dc-list-group-actions {
      grid-column: 3/4;
      grid-row: 1/2;
      display: flex;
      justify-content: flex-end;
    }

    .dc-list-filter-buttons {
      grid-column: 2/4;
      grid-row: 2/3;
    }

    .chip-checked.bg-snijlab {
      background-color: darken(#ffe400, 10%) !important;
    }

    span.highlight {
      background-color: #daef47;
      color: black;
    }

    span.normal {
      color: #434a54;
    }

    .product-list {
      list-style: square inside;
      padding-left: 0;
      color: #c2c4c5;
    }

    .infospan {
      float: right;
      margin-right: 6px;
      margin-top: -20px;
      position: relative;
      z-index: 2;
      color: #ccc;
    }

    @media (max-width: 768px) {
      .dc-list-filter-container {
        grid-template-columns: 65px 1fr 1fr;
      }
      .dc-list-group-actions {
        grid-column: 3/4;
        grid-row: 1/2;
        // justify-content: end;
        /*padding-left: 1rem;*/
        // padding-top: 0.5rem;
      }
    }

    @media (max-width: 576px)
    {
      .dc-list-filter-buttons {
        grid-column: 1/4;
      }

      .dc-list-filter-select-all {
        display: none;
      }

      .dc-list-filter-container .dc-list-filter-search {
        grid-column: 1/4;
      }

      .dc-list-group-actions {
        display: none;
      }
    }

    @media (min-width: 576px) and (max-width: 992px)  {
      .dc-list-filter-buttons {
        .chip {
          font-size: 0.6rem;
          padding-right: 0.5rem;
          padding-left: 0.5rem;
        }
      }
    }

    @media (min-width: 992px) {
      .dc-list-filter-container {
        position: sticky;
        top: 130px;
        z-index: 6;
        box-shadow: 0 5px 12px -5px rgba(163, 161, 163, 1);
      }
    }

    .btn-outline-light {
      box-shadow: none !important;
    }
  }
</style>
