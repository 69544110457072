<template>
  <form class="forgot-password-save-new"
    :class="{'form-dark' : useDark}"
    @submit.prevent="submit" >

    <div class="input-row-full">
      <h2>{{ $t('forgot_password.change_password') }}</h2>
      <p>{{ $t('forgot_password.enter_a_new_password') }}</p>
    </div>

    <form-widget-input
      v-model="$v.password.$model"
      :error="error('password')"
      type="password"
      :placeholder="$t('forgot_password.new_password')"
      autocomplete="new-password" />

    <form-widget-input
      v-model="$v.repeatPassword.$model"
      type="password"
      :error="error('repeatPassword')"
      :placeholder="$t('forgot_password.new_password_again')"
      autocomplete="new-password" />

    <div v-if="serverErrors.main" class="text-danger input-row-full">
      {{$t('loginpanel.the_entered_security_code_is_invalid')}}
    </div>
    <div class="input-row-full">
      <loading-button
        :loading="loading"
        type="submit"
        variant="primary"
        class="btn-light btn-block mt-3 login-btn"
        @click="submit">{{$t('loginpanel.save_password')}}
      </loading-button>
    </div>

  </form>
</template>
<script>
import axios from 'axios';
import { validationMixin } from 'vuelidate';
import {
  required, minLength, sameAs,
} from 'vuelidate/lib/validators';
import FormWidgetInput from 'src/components/form/FormWidgetInput';
import LoadingButton from 'src/components/tools/LoadingButton';

export default {
  components: {
    LoadingButton,
    FormWidgetInput,
  },
  props: {
    code: {
      type: String,
      required: true,
    },
    confirmationCode: {
      type: String,
      required: true,
    },
    useDark: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  mixins: [validationMixin],
  validations() {
    return {
      password: {
        required,
        minLength: minLength(8),
      },
      repeatPassword: {
        sameAsPassword: sameAs('password'),
      },
    };
  },
  data() {
    return {
      loading: false,
      current: '',
      password: '',
      repeatPassword: '',
      serverErrors: {
        main: null,
      },
    };
  },
  computed: {
    errors() {
      return {
        password: this.$t('loginpanel.your_password_has_to_be_at_least_8_characters_long'),
        repeatPassword: this.$t('loginpanel.the_password_fields_dont_match'),
      };
    },
  },
  methods: {
    error(name) {
      if (this.$v[name].$error) {
        return this.errors[name];
      }
      if (name in this.serverErrors && this.serverErrors[name] != null && this.serverErrors[name].length > 0) {
        return this.serverErrors[name];
      }
      return '';
    },
    submit() {
      if (this.loading) {
        return;
      }
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.serverErrors = {};
      const formData = new FormData();
      formData.append('password', this.password);
      formData.append('confirmation', this.confirmationCode);
      formData.append('code', this.code);
      this.loading = true;

      axios
        .post(`${this.$config.apiUrl}/user/forgotPasswordSave`,
          formData,
          { withCredentials: true })
        .then((response) => {
          this.hasError = false;
          this.$eventBus.$emit('snlb.success', this.$t('loginpanel.your_password_has_been_changed_youve_been_logged_in'));
          this.$v.password.$model = null;
          this.password = null;
          this.$v.repeatPassword.$model = null;
          this.repeatPassword = null;
          this.$v.$reset();
          this.$eventBus.$emit('newLogin', response.data.result);
        }).catch((error) => {
          if (error.response.data.error === 'INCORRECT_FORGOT_PASSWORD_CODE') {
            this.serverErrors.main = this.$t('loginpanel.code_is_invalid_or_older_than_30_minutes');
            return;
          }
          this.$eventBus.$emit('snlb.error', error);
        }).finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss">
  .forgot-password-save-new {
    .invalid-feedback {
      background-color: #fff;
      font-weight: bold;
      padding: 4px;
    }
  }
</style>
