<template>
  <div class="dc-editor-feedback form-group">
    <drawing-configuration-reference-widget
      ref="referenceWidget"
      :dc_id="dcId"
      :value="storeData.name"
      :locked="storeData.locked"/>

    <div class="mt-3">
      {{$t('drawing_configuration.size')}}:<br>
      <div class="drawing-size mb-2">{{ width }} mm x {{ height }} mm</div>

      <div class="pointer"
        @click.prevent.stop="showHelp('editor.processes')">
        {{$t('drawing_configuration.processes')}}:
        <i class="material-icons compact pointer info-icon" >info</i>
        <ul class="pl-3">
          <li v-if="hasCutting"><span class="text-normal">{{$t('drawing_configuration.cutting')}}</span></li>
          <li v-if="hasVectorEngrave"><span class="text-normal">{{$t('drawing_configuration.line_engraving')}}</span></li>
          <li v-if="hasRasterEngrave"><span class="text-normal">{{$t('drawing_configuration.area_engraving')}}</span></li>

          <li v-if="!hasCutting" class="text-muted"><span class="text-normal">
            {{$t('drawing_configuration.no_cutting')}}</span></li>
          <li v-if="!hasVectorEngrave" class="text-muted"><span class="text-normal">
            {{$t('drawing_configuration.no_line_engraving')}}</span></li>
          <li v-if="!hasRasterEngrave" class="text-muted"><span class="text-normal">
            {{$t('drawing_configuration.no_area_engraving')}}</span></li>
        </ul>
      </div>
    </div>

    <div v-if="feedback.length > 0">
      <span>{{$t('drawing_configuration.drawing_check')}}:</span>
      <ul class="pl-3">
        <template v-for="(item, key) in feedback">
        <li v-if="item.type !== 'ERROR'"
          :id="'feedback-'+item.id"
          :key="key"
          class="text-warning pointer"
          @click="showHelp(item.message)">
          {{ getError(item.message) }}&nbsp;<i class="material-icons compact info-icon">info</i>
        </li>
        </template>
        <template v-for="(item, key) in feedback">
          <li
            v-if="item.type === 'ERROR'"
            :id="'feedback-'+item.id"
            :key="key"
            class="text-danger pointer"
            @click="showHelp(item.message)">
            {{ getError(item.message) }}&nbsp;<i class="material-icons compact info-icon">info</i>
          </li>
        </template>
      </ul>

      <div v-if="!drawingCheck && !hasError"
        class="alert bg-success p-2">
        <drawing-check :dc-id="id"/>
      </div>
      <drawing-check v-else :dc-id="id"/>

      <div v-if="!hasError && !approved">
        <p>
          <strong>{{$t('drawing_configuration.questions')}}</strong> {{$t('drawing_configuration.send_an_email_to')}}
           <a href="mailto:info@snijlab.nl">info@snijlab.nl</a><br>
        {{$t('drawing_configuration.or_call')}} <a href="tel:0031103070949">+31 (0)10-3070949</a>
         {{$t('drawing_configuration.provide_order_number')}}
        <strong>{{ $config.drawingConfigurationPrefix }}{{ id }}</strong>
        </p>
      </div>
    </div>

    <div
      v-if="hasError"
      class="alert bg-warning-light">
      <p>
        <strong>{{$t('drawing_configuration.this_drawing_cant_be_produced')}}</strong><br><br>
        {{$t('drawing_configuration.fix_above_mentioned_problems')}}<br ><br >
        <strong>{{$t('editor.need_help')}}</strong> {{$t('editor.mail_to')}} <a href="mailto:info@snijlab.nl">info@snijlab.nl</a>
        {{$t('editor.or_call')}} <a href="tel:0031103070949">+31 (0)10-3070949</a> {{$t('editor.and_provide_drawing_number')}}
        <strong>{{ $config.drawingConfigurationPrefix }}{{ id }}.</strong></p>
    </div>
  </div>
</template>
<script>
import DrawingCheck from 'src/components/dcEditor/DrawingCheck';
import DrawingConfigurationReferenceWidget from 'src/components/drawingConfiguration/DrawingConfigurationReferenceWidget';

export default {
  components: {
    DrawingCheck,
    DrawingConfigurationReferenceWidget,
  },
  props: {
    hasCutting: {
      type: Boolean,
      default: false,
    },
    hasVectorEngrave: {
      type: Boolean,
      default: false,
    },
    hasRasterEngrave: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      required: true,
    },
    feedback: {
      type: Array,
      required: true,
    },
    showFeedback: {
      type: Boolean,
      required: true,
    },
    drawingCheck: {
      type: Boolean,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
  },
  computed: {
    dcId() {
      return this.id;
    },
    helpText() {
      return this.$store.state.errors;
    },
    storeData() {
      return this.$store.state.dc.drawingConfiguration[this.id];
    },
    errors() {
      return this.$store.state.errors;
    },
    approved() {
      return this.storeData.approved;
    },
  },
  methods: {
    showHelp(type) {
      this.$eventBus.$emit('snlb.showHelp', type);
    },
    getError(message) {
      if (message.toLowerCase() in this.errors) {
        return this.errors[message.toLowerCase()].editorName;
      }
      return message;
    },
  },
};
</script>
<style lang="scss">
  .dc-editor-feedback {

    .input-row-full {
      padding: 0;

      input {
        font-size: 0.7rem;
      }
    }

    .custom-select option {
        font-size: 15px;
        font-weight: 300;
    }

    .drawing-size {
      font-size: 1.0rem;
    }
  }
</style>
