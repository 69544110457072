<template>
  <div class="error-center">
    <logo-animated />
    <h1 class="error-center-h1">404</h1>
    <h2 class="error-center-h2">{{$t('page_404.this_page_does_not_exist')}}</h2>
    <p class="error-text-extra">
      {{$t('page_404.think_this_was_an_error')}} <a href="mailto:info@snijlab.nl">info@snijlab.nl</a>
    </p>
    <!-- <div>
      <router-link to="/" class="btn btn-snijlab mb-4">{{$t('page_404.back_to_home')}}</router-link>
    </div> -->
  </div>
</template>

<script>
import LogoAnimated from 'src/components/errors/LogoAnimated';

export default {
  components: { LogoAnimated },
};
</script>

<style langs="scss">
  @import '../../assets/scss/_error.scss';
</style>
