<template>
  <form @submit.prevent="submit">
    <div class="change-password-form">

      <form-widget-input
        v-model="$v.current.$model"
        :error="error('current')"
        type="password"
        :placeholder="$t('user_settings.current_password')"
        autocomplete="current-password" />

      <form-widget-input
        v-model="$v.password.$model"
        :error="error('password')"
        type="password"
        :placeholder="$t('user_settings.new_password')"
        autocomplete="new-password" />

      <form-widget-input
        v-model="$v.repeatPassword.$model"
        type="password"
        :error="error('repeatPassword')"
        :placeholder="$t('user_settings.repeat_password')"
        autocomplete="new-password" />

      <loading-button
        :loading="loading"
        type="submit"
        variant="primary"
        class="btn btn-primary btn-xs m-2"
        @click="submit">{{$t('user_settings.save_new_password')}}
      </loading-button>
    </div>
  </form>
</template>

<script>
import axios from 'axios';
import _has from 'lodash/has';
import { validationMixin } from 'vuelidate';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
import FormWidgetInput from 'src/components/form/FormWidgetInput';
import LoadingButton from 'src/components/tools/LoadingButton';

export default {
  components: {
    LoadingButton,
    FormWidgetInput,
  },
  mixins: [validationMixin],
  validations() {
    return {
      current: {
        required,
      },
      password: {
        required,
        minLength: minLength(8),
      },
      repeatPassword: {
        sameAsPassword: sameAs('password'),
      },
    };
  },
  data() {
    return {
      loading: false,
      current: '',
      password: '',
      repeatPassword: '',
      serverErrors: {
      },
    };
  },
  computed: {
    errors() {
      return {
        password: this.$i18n.t('user_settings.password_needs_to_be_8_characters'),
        repeatPassword: this.$i18n.t('user_settings.passwords_dont_match'),
        current: this.$i18n.t('user_settings.enter_current_password'),
      };
    },
  },
  methods: {
    error(name) {
      if (this.$v[name].$error) {
        return this.errors[name];
      }
      if (name in this.serverErrors && this.serverErrors[name] != null && this.serverErrors[name].length > 0) {
        return this.serverErrors[name];
      }
      return '';
    },
    submit() {
      if (this.loading) {
        return;
      }
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.serverErrors = {};
      const formData = new FormData();
      formData.append('user[current]', this.current);
      formData.append('user[password]', this.password);
      this.loading = true;

      axios
        .post(`${this.$config.apiUrl}/user/updatePassword`,
          formData,
          { withCredentials: true })
        .then(() => {
          this.hasError = false;
          this.$eventBus.$emit('snlb.success', 'Wachtwoord is gewijzigd');
          this.$v.password.$model = null;
          this.password = null;
          this.$v.repeatPassword.$model = null;
          this.repeatPassword = null;
          this.$v.current.$model = null;
          this.current = null;
          this.$v.$reset();
        }).catch((error) => {
          if (!(_has(error, 'response.data.error.global[0]'))) {
            this.$eventBus.$emit('snlb.error', `UNEXPECTED ERROR: ${error}`);
          } else if (error.response.data.error.global[0] === 'INVALID_PASSWORD') {
            this.serverErrors.current = 'Wachtwoord is incorrect';
          } else {
            this.$eventBus.$emit('snlb.error', `UNEXPECTED ERROR: ${error}`);
          }
        }).finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss">
</style>
