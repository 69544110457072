<template>
  <div>
    <div :class="rowClasses">
      <b-form-group>
        <span slot="label">{{ label }} <async-queue
          :watch-value="valuePublic"
          :delay="delay"
          @process="update"/>
        </span>

        <b-form-radio-group
          ref="input"
          :options="options"
          :id="id"
          :checked="valuePublic"
          :disabled="disabled"
          :readonly="readonly"
          :size="size"
          :state="state"
          :aria-invalid="hasError"
          :autofocus="autofocus"
          name="radio-inline"
          @input="onInput"
          @change="onInput" />
      </b-form-group>

      <form-help-widget
        :error="error"
        :warning="warning"
        :help="help" />
    </div>
  </div>
</template>

<script>
import FormWidgetBase from 'src/components/form/FormWidgetBase';
import AsyncQueue from 'src/components/tools/AsyncQueue';
import VolatileField from 'src/lib/Volatile';

export default {
  extends: FormWidgetBase,
  mixins: [VolatileField('value')],
  props: {
    value: {
      default: false,
      type: Boolean,
    },
    inputValue: {
      default: '',
    },
    inputName: {
      default: '',
      type: String,
    },
    delay: {
      default: 100,
      type: Number,
    },
    trueLabel: {
      type: String,
      default: 'yes',
    },
    falseLabel: {
      type: String,
      default: 'no',
    },
  },
  components: {
    AsyncQueue,
  },
  computed: {
    hasError() {
      return Boolean(this.error);
    },
    options() {
      return [
        {
          text: this.falseLabel,
          value: true,
        },
        {
          text: this.trueLabel,
          value: true,
        },
      ];
    },
  },
  watch: {
    inputValue(newValue) {
      this.valueTaintedValue = newValue;
    },
  },
  methods: {
    onInput(value) {
      this.valueTaintedValue = value;
    },
    update(promise, value) {
      this.$emit('update', { promise, value, inputName: this.inputName });
    },
  },
};
</script>
