<template>
    <div class="contentful-jumbotron" :class="{'isLastElement': isLastElement}">
      <div class="jumbotron jumbotron-fluid w-100">
      <div v-if="data.backgroundImage" class="bg-img-container">
        <img :src="`${data.backgroundImage.fields.file.url}?w=1800`" alt="" />
      </div>
      <div class="center-container">
        <div class="title-container">
          <h1 v-if="h1Header" class="display-4">{{data.title}}</h1>
          <h2 v-else class="display-4">{{data.title}}</h2>
        </div>
        <div class="text-container">
          <div v-html="htmlString(data.text)" />
          <!-- <p v-if="data.text"><rich-text-renderer :document="data.text"/></p> -->
          <div class="btn-container">
            <a v-if="data.ctaPrimaryTitle && data.ctaPrimaryLink && data.ctaPrimaryLink.endsWith('/dashboard')"
                class="btn btn-success mr-2 shadow-none mb-2"
                :href="$router.resolve({ 'name': 'dashboard' }).href">{{data.ctaPrimaryTitle}}</a>
            <router-link v-else-if="data.ctaPrimaryTitle && data.ctaPrimaryLink" class="btn btn-success mr-2"
              :to="{path: strippedLink(data.ctaPrimaryLink)}">{{data.ctaPrimaryTitle}}</router-link>

              <router-link v-if="data.ctaSecondaryTitle && data.ctaSecondaryLink" class="btn btn-outline-snijlab shadow-none"
              :to="{path: strippedLink(data.ctaSecondaryLink)}">{{data.ctaSecondaryTitle}}</router-link>
          </div>

        </div>
        <img v-if="data.image" class="img-fluid" :src="data.image.fields.file.url" :alt="data.image.fields.description" />
      </div>
    </div>
    </div>

</template>

<script>
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

export default {
  components: { },
  props: {
    h1Header: {
      type: Boolean,
      required: false,
      default: false,
    },
    data: {
      type: Object,
      required: true,
    },
    isLastElement: {
      type: Boolean,
      default: false,
    },
    bgColor: {
      type: String,
      default: '#424242',
    },
    removeBeforeDestroy: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    cssBackground() {
      if (this.data.backgroundImage) {
        return `background-image: url("${this.data.backgroundImage.fields.file.url}?w=1800");`;
      }
      return `background-color: ${this.bgColor};`;
    },
    strippedLink(url) {
      const strippedUrl = url.split('snijlab.nl');
      if (strippedUrl.length > 1) {
        return strippedUrl[1];
      }
      return url;
    },
    htmlString(text) {
      return documentToHtmlString(text);
    },
  },
  beforeDestroy() {
    if (this.removeBeforeDestroy) {
      this.$root.$el.parentNode.removeChild(this.$root.$el);
    }
  },
};
</script>

<style lang="scss">
  .contentful-jumbotron {
    margin-bottom: 3rem;
    position: relative;

    h1, h2 {
      font-weight: 700;
    }

    &.isLastElement {
      margin-bottom: 0;
    }

    .jumbotron {
      padding: 0;
      color: white;
      // background-size: cover;

      .bg-img-container {
        position: absolute;
        z-index: 7;
        top: 0;
        left: 0;
        overflow: hidden;
        width: 100%;
        height: 100%;
        background-color: rgb(15, 15, 15);

        img {
          opacity: .25;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .center-container {
        position: relative;
        z-index: 8;
        max-width: 1000px;
        margin: auto;
        display: grid;
        grid-template-columns: auto 550px;
        // padding: 0 1rem 5rem 0;
        padding: 1rem 1rem 2rem 1rem;

        .title-container {
          padding-top: 4rem;
          grid-column: 1/3;
          grid-row: 1;
          align-self: start;
          padding-top: 4rem;
        }

        .text-container {
          grid-column: 1;
          grid-row: 2;
          align-self: start;
        }

        img {
          align-self: start;
          grid-column: 2;
          grid-row: 2;
          max-width: 550px;
        }
      }

      @media(max-width: 991px) {
        margin-bottom: 2rem;

        h2 {
          font-size: 1.8rem;
          font-weight: 700;
        }

        .center-container {
          grid-template-columns: 100%;
          grid-template-rows: auto 1fr;
          padding-top: 4rem;
          padding-bottom: 2rem;

          .title-container {
            grid-column: 1;
            grid-row: 1;
            padding: 1rem 1rem;
          }

          .text-container {
            font-size: .8rem;
            grid-column: 1;
            grid-row: 3;
            padding: 0rem 1rem;

            .btn {
              font-size: .8rem;
            }

          }
          img {
            grid-column: 1;
            grid-row: 2;
            width: 100%;
            padding: 0 1rem;
          }
        }
      }
    }
  }
</style>
