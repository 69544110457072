<template>
  <div class="cart-redirect">
    <indeterminate-progress
      :radius="100"
      :stroke-width="10"
      :offset="10"
    />
  </div>
</template>

<script>
import IndeterminateProgress from 'src/components/tools/IndeterminateProgress';

export default {
  components: { IndeterminateProgress },
  computed: {
    ready() {
      return this.$store.getters.loaded([
        'cart',
      ]);
    },
  },
  watch: {
    ready(isReady) {
      if (isReady) {
        this.redirect();
      }
    },
  },
  methods: {
    redirect() {
      // eslint-disable-next-line prefer-destructuring
      const orderId = this.$store.state.cart.orderId;
      if (this.$store.state.cart.status === 'DRAFT') {
        this.$router.push({
          name: 'order-cart',
          params: {
            lang: this.$i18n.locale,
            orderId,
          },
        });
      } else if (this.$store.state.cart.status === 'SUBMITTED') {
        this.$router.push({
          name: 'order-payment',
          params: {
            lang: this.$i18n.locale,
            orderId,
          },
        });
      } else {
        this.$router.push({
          name: 'order-confirmation',
          params: {
            lang: this.$i18n.locale,
            orderId,
          },
        });
      }
    },
  },
  mounted() {
    if (this.ready) {
      this.redirect();
    }
  },
};
</script>

<style>
  .cart-redirect {
    display: flex;
    margin-top: 100px;
    justify-content: center;
  }
</style>
