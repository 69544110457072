<template>
  <div class="cart-review-view mt-2">
    <div class="row">
      <div class="col-12">
        <order-status-warning
          v-if="ready"
          :can-be-paid="canBePaid"
          :is-locked="isLocked"
          @start-new-order="createNewOrder"
          @goToPayment="goToPayment" />
      </div>
    </div>

    <div class="row mb-3" v-if="ready">
      <div class="col-md-6 col-xl-4">
        <div class="summary-card" v-if="cart">
          <div class="card shadow-1">
            <div class="card-block card-block-title pl-2 py-1 bg-light">
              <div class="card-title">
                Order L{{ orderId }}
              </div>
            </div>
            <div class="card-body border-top">
              <div v-if="isValidOrder" class="bg-dark p-2">
                <ul class="deliver-summary-list p-0 mb-0">
                  <template v-if="cart.shippingMethod=== 'PICKUP'">
                    <li><i class="material-icons compact">place</i> Je hebt gekozen om de order op te halen</li>
                    <li><i class="material-icons compact">insert_invitation</i> Je order is gereed op
                      <strong>{{ $tools.date.getRelativeDay(estimatedDelivery, $i18n.locale) }} vanaf 16:00</strong>
                      als je betaalt vóór
                      <strong>{{ $tools.date.getRelativeDay(orderBeforeTime, $i18n.locale) }}
                        {{ $tools.date.getTime(orderBeforeTime, $i18n.locale)}}</strong>.
                    </li>
                  </template>
                  <template v-else>
                    <li v-if="!onlyProductsInCart">
                      <i class="material-icons compact">insert_invitation</i>
                      {{$t('order_review.your_order_will_be_delivered')}}
                      <strong>{{ $tools.date.getRelativeDay(estimatedDelivery, $i18n.locale) }}</strong>
                       {{$t('order_review.if_paid_before')}} <strong>
                       {{ $tools.date.getRelativeDay(orderBeforeTime, $i18n.locale) }}
                       {{ $tools.date.getTime(orderBeforeTime, $i18n.locale)}}</strong>.
                    </li>
                    <li v-else>
                      <i class="material-icons compact">insert_invitation</i>
                      {{$t('order_review.your_order_will_be_delivered_products_only')}}
                      <strong>{{ $tools.date.getRelativeDay(estimatedDelivery, $i18n.locale) }}</strong>
                       {{$t('order_review.if_paid_before_products_only')}} <strong>
                       {{ $tools.date.getRelativeDay(orderBeforeTime, $i18n.locale) }}
                       {{ $tools.date.getTime(orderBeforeTime, $i18n.locale)}}</strong>.
                    </li>
                  </template>
                </ul>
                <div v-if="!onlyProductsInCart" class="mt-2">
                  {{$t('cart_review.want_a_different_delivery_date_or_method')}}
                </div>
              </div>

              <cart-reference-widget
                :order_id="orderId"
                :use_label="true"
                :max-length="50"
                class="mt-2 mx-2" />

              <cart-phone-number-widget
                :order_id="orderId"
                :use_label="true" />

              <cart-comment-widget
                :order_id="orderId" />

              <!-- <cart-dropshipping-widget
                v-if="shippingMethod === 'SHIPPING'"
                :order_id="orderId">
              </cart-dropshipping-widget> -->

              <div class="px-2 mt-3 pb-2">
                <cart-review-table
                  :show-prices="true"
                  :show-vat="showVat" />
              </div>
            </div>
          </div>
        </div>
        </div>

        <div class="col-md-6 col-xl-4 mt-3 mt-md-0">
          <address-form
            store-property="cart.address"
            store-error-property="cart.addressErrors"
            :order-id="orderId"
            :shipping-method="shippingMethod"
            :has-dropshipping="hasDropshipping"
            @updateFields="updateFormFields" />
        </div>
      <div class="col-md-6 offset-md-6 col-xl-4 offset-xl-0">
        <div class="payment-card">
          <div v-if="showVatInformation && cart.address.is_business"
            class="card shadow-1 mb-3">
            <div class="card-block">
              <div class="card-title pl-2 py-1 bg-light">
                {{$t('order_review.european_vat_regulations')}}
              </div>
            </div>

            <div class="card-body border-top icp-details p-2">
              <div>
                <div class="icp-conclusion">
                  <div v-if="cart.vat.vatType === 'ICP'">
                    <i class="material-icons compact text-success">check</i>
                    {{$t('order_review.this_order_is_applicable_for_reversed_vat_charges')}}<br/>
                  </div>

                  <div v-else>
                    <i class="material-icons compact text-warning">error</i>
                    {{$t('order_review.this_order_is_not_applicable_for_reversed_vat_charges')}}<br>
                  </div>
                </div>

                <div v-if="showIcpDetails">
                  <div class="vat-detail">{{$t('order_review.snijlab_can_reverse_charge_vat')}}</div>
                  <br>
                  <b-form-checkbox
                    :disabled="true"
                    :checked="hasShippingToEu && shippingMethod === 'SHIPPING'"
                    class="no-pointer"> {{$t('order_review.reversed_vat.order_is_shipped_outside_netherlands')}}
                  </b-form-checkbox>

                  <b-form-checkbox
                    :disabled="true"
                    :checked="cart.address.invoice_country === cart.address.delivery_country
                    && shippingMethod === 'SHIPPING'"
                    class="no-pointer"> {{$t('order_review.reversed_vat.shipping_country_is_invoice_country')}}
                  </b-form-checkbox>

                  <b-form-checkbox
                    :disabled="true"
                    :checked="cart.vat.validatedVatNumber"
                    class="no-pointer">{{$t('order_review.reversed_vat.valid_eu_vat_number_given')}}
                  </b-form-checkbox>

                  <b-form-checkbox
                    :disabled="true"
                    :checked="cart.vat.vatCountry === cart.address.invoice_country"
                    class="no-pointer">{{$t('order_review.reversed_vat.invoice_company_should_match_vat_number')}}<br>
                  </b-form-checkbox>

                  <pre class="icp-vies-details" v-if="cart.vat.validationType === 'VIES'">{{ cart.vat.vatResponse }}</pre>
                </div>

                <div class="text-right">
                  <a href="#"
                    @click="toggleIcpDetails">
                    <template v-if="showIcpDetails">{{$t('order_review.reversed_vat.hide_details')}}</template>
                    <template v-else>{{$t('order_review.reversed_vat.show_details')}}</template>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="card shadow-1">
            <div class="card-block card-block-title pl-2 py-1 bg-light">
              <div class="card-title">{{$t('order_review.payment_method')}}</div>
            </div>
            <payment-list v-model="paymentMethod"/>
          </div>
        </div>

        <div class="card shadow-1 mt-3">
          <div class="card-block card-block-title pl-2 py-1 bg-light">
            <div class="card-title">{{$t('order_review.order_and_pay')}}</div>
          </div>
          <cart-review-error-component
            v-if="!isValidOrder"
            ref="errors"
            class="p-2"
            :cart="cart"
            :is-valid-order="isValidOrder"
            :show-errors="showErrors"
            :payment-method="paymentMethod"
            @show-form-errors="showErrors" />

          <template v-else>
            <div class="p-2">
              <b-form-checkbox v-model="saveAsDefault">{{$t('order_review.store_address_as_default')}}</b-form-checkbox>

                <div class="pt-2" :class="{ 'alert-danger' : showConfirmError && !agreedToTC}">
                  <div class="py-1">
                    <b-form-checkbox v-model="agreedToTC">
                      {{$t('order_review.i_accept_the')}}
                      <router-link :to="{ name: 'terms-page'}" target="_blank">
                      {{$t('order_review.terms_of_service')}} </router-link>
                    </b-form-checkbox>
                  </div>
                </div>

              <div class="mt-3" v-if="isValidOrder">
                {{$t('order_review.by_continuing_you_place_an_order_with_payment_obligation')}}
              </div>
            </div>
          </template>

          <div class="px-2 pb-2">
            <loading-button
              id="btn-confirm-order"
              size="sm"
              class="px-2 mr-2 mb-1"
              :loading="payButtonLoading"
              :class="{'btn-success' : isValidOrder, 'btn-light': !isValidOrder}"
              :disabled="!isValidOrder"
              stroke-color="#fff"
              :btn-text="$t('order_review.place_order_and_pay_button')"
              @click="payOrder"><i class="material-icons">check</i> {{$t('order_review.place_order_and_pay_button')}}
            </loading-button>

            <b-button
              :disabled="!isValidOrder || !isDraft"
              size="sm"
              class="px-2 mb-1"
              :class="{'btn-info' : isValidOrder, 'btn-light': !isValidOrder}"
              @click="downloadQuote">
                <i class="material-icons">insert_drive_file</i> {{$t('order_review.download_quote')}}
            </b-button>

          </div>
        </div>

        <div v-if="$store.getters.isAdmin" class="card shadow-1 mt-3">
          <div class="card-block card-block-title pl-2 py-1 bg-light bg-snijlab">
            <div class="card-title">Admin</div>
          </div>
          <div class="card-body p-2">
            <email-template-widget /><br>

            <h5 class="border-top">{{$t('admin.current_status')}}: <span class="badge badge-light">{{getStatus}}</span></h5>
            <loading-button  v-if="isValidOrder && (isDraft || canBePaid)"
              @click="acceptWithEmailAsAdmin"
              size="xs"
              class="btn-snijlab"
              :loading="updatingAccepted">
              <i class="material-icons compact">mail</i> {{$t('admin.accept_and_send_invoice')}}
            </loading-button>

            <a :href="adminLink" target="_blank" class="btn btn-xs btn-snijlab mt-2">
              <i class="material-icons compact">visibility</i> {{$t('admin.view_in_admin')}}
            </a>
          </div>
        </div>
      </div>
  </div>
  </div>
</template>
<script>
import _get from 'lodash/get';
import axios from 'axios';
import Vue from 'vue';
import CartReviewTable from 'src/components/cart/review/CartReviewTable';
import CartCommentWidget from 'src/components/cart/review/CartCommentWidget';
import CartReferenceWidget from 'src/components/cart/edit/CartReferenceWidget';
import AddressForm from 'src/components/cart/form/AddressForm';
import CartReviewErrorComponent from 'src/components/cart/review/CartReviewErrorComponent';
import OrderStatusWarning from 'src/components/cart/edit/OrderStatusWarning';
import PaymentList from 'src/components/cart/review/PaymentList';
import CartPhoneNumberWidget from 'src/components/cart/edit/CartPhoneNumberWidget';
import EmailTemplateWidget from 'src/components/admin/EmailTemplateWidget';
import LoadingButton from 'src/components/tools/LoadingButton';

export default {
  components: {
    LoadingButton,
    CartPhoneNumberWidget,
    OrderStatusWarning,
    CartReviewErrorComponent,
    CartReferenceWidget,
    CartCommentWidget,
    AddressForm,
    PaymentList,
    CartReviewTable,
    EmailTemplateWidget,
  },
  data() {
    return {
      loading: true,
      showIcpDetails: false,
      shippingUpdating: false,
      saveAsDefault: true,
      agreedToTC: false,
      paymentMethod: 'mollie-ideal',
      showConfirmError: false,
      updatingPriority: false,
      showEmailOptionsField: false,
      showEmailVariables: false,
      payOrderBtnPressed: false,
      emailTemplates: [],
      updatingAccepted: false,
      payButtonLoading: false,
    };
  },
  computed: {
    ready() {
      // TODO: load multiple carts..
      return this.$store.getters.loaded([
        'drawingProducts',
        'materials',
        'translations',
        'user',
        'drawingConfigurations',
        'cart',
      ]) && this.hasCorrectOrder;
    },
    hasCorrectOrder() {
      if (!this.$store.getters.loaded(['cart'])) {
        return true;
      }
      return this.$store.state.cart.orderId === this.orderId;
    },
    adminLink() {
      return `${this.$config.baseUrl}/admin.php/nl/order/${this.orderId}/edit`;
    },
    isLocked() {
      return this.$store.state.cart.status !== 'DRAFT';
    },
    isDraft() {
      return this.$store.state.cart.status === 'DRAFT';
    },
    canBePaid() {
      return this.$store.state.cart.status === 'SUBMITTED';
    },
    hasDropshipping() {
      return this.$store.state.cart.hasDropshipping;
    },
    getStatus() {
      return this.$store.state.cart.status;
    },
    costTable() {
      return this.$store.state.cart.costTable;
    },
    hasSubtotal() {
      return this.costTable && this.costTable.total.amount !== 0;
    },
    orderId() {
      // todo: redirect?
      const currentId = parseInt(this.$route.params.orderId, 10);
      if (!currentId) {
        return parseInt(this.$store.state.settings.order_id, 10);
      }
      return currentId;
    },
    canConfirmOrder() {
      return this.agreedToTC && this.isValidOrder;
    },
    isValidOrder() {
      return !this.hasErrors && this.paymentMethod !== 'invalid' && this.hasSubtotal;
    },
    cartHasPhonenumber() {
      if (!('phone_number' in this.cart.address)) {
        return false;
      }
      if (this.cart.address.phone_number === '' || this.cart.address.phone_number.length < 8) {
        return false;
      }
      return true;
    },
    hasErrors() {
      return this.cart.phoneNumberError || this.cart.phoneNumberWarning
        || (this.cart.orderErrors && Object.keys(this.cart.orderErrors).length > 0)
        || (this.cart.addressWarnings && Object.keys(this.cart.addressWarnings).length > 0)
        || (this.cart.addressErrors && Object.keys(this.cart.addressErrors).length > 0);
    },
    orderBeforeTime() {
      if (this.priority) {
        return this.priority.orderBeforeTime;
      }
      return '';
    },
    estimatedDelivery() {
      if (this.priority) {
        return this.priority.estimatedDelivery;
      }
      return '';
    },
    canConfirm() {
      return this.agreedToTC
          && this.cart.orderErrors.length === 0;
    },
    cartErrors() {
      return this.$store.state.cart.orderErrors.filter((err) => err.startsWith('ORDER_DRAWING_CONFIGURATION'));
    },
    shippingMethod() {
      return this.$store.state.cart.shippingMethod;
    },
    cart() {
      return this.$store.state.cart;
    },
    config() {
      return this.$config;
    },
    orderDrawings() {
      return this.$store.state.cart.orderDrawings;
    },
    showDelivery() {
      return this.$store.state.cart.shippingMethod !== 'PICKUP';
    },
    alternativeDelivery: {
      get() {
        return !this.addressData.invoice_is_delivery_address;
      },
      set(value) {
        Vue.set(this.addressData, 'invoice_is_delivery_address', !value);
      },
    },
    hasAlternativeDelivery() {
      return this.hasDelivery && this.alternativeDelivery;
    },
    showVat() {
      return this.$store.state.settings.showVat;
    },
    showVatInformation() {
      return this.euVatCountries.some((code) => code === this.cart.address.invoice_country);
    },
    hasShippingToEu() {
      return this.euVatCountries.some((code) => code === this.cart.address.delivery_country);
    },
    priority() {
      return _get(this.$store.state.cart.priorities, this.$store.state.cart.priorityId, '');
    },
    isValidICP() {
      const country = _get(this.cart.address, 'invoice_country');
      const result = this.euVatCountries.includes(country);
      return result && this.is_business;
    },
    euVatCountries() {
      return [
        'BE', 'BG', 'CZ', 'DK', 'DE', 'EE', 'IE', 'GR', 'ES', 'FR', 'HR', 'IT', 'CY', 'LV', 'LT', 'LU', 'HU',
        'MT', 'AT', 'PL', 'PT', 'RO', 'SI', 'SK', 'FI', 'SE', 'GB'];
    },
    countries() {
      return this.$store.state.countries;
    },
    showPostcodeInvoice() {
      return _get(this.addressData, 'invoice_country') === 'NL';
    },
    showPostcodeDelivery() {
      return _get(this.addressData, 'delivery_country') === 'NL';
    },
    onlyProductsInCart() {
      return Object.keys(this.$store.state.cart.orderDrawings).length === 0
      && Object.keys(this.$store.state.cart.orderProducts).length > 0;
    },
  },
  watch: {
    ready(value) {
      if (value) {
        try {
          this.$eventBus.$emit('gtm.reviewedCart', {
            orderId: this.$store.state.cart.orderId,
            totalPrice: this.getSubtotalDotNotation('total', false),
          });
        } catch (error) {
          //
        }
        this.redirectIfNeeded();
      }
    },
  },
  mounted() {
    this.redirectIfNeeded();
  },
  methods: {
    redirectIfNeeded() {
      if (this.$store.state.cart.status !== 'DRAFT' && !this.$store.getters.isAdmin) {
        this.$router.push({
          name: 'order-payment',
          params: {
            lang: this.$route.params.lang,
            orderId: this.orderId,
          },
        });
      }
    },
    createNewOrder() {
      this.$store.dispatch('createNewOrder')
        .then(() => {
          this.$router.push({
            name: 'order-cart',
            params: {
              lang: this.$route.params.lang,
              orderId: this.orderId,
            },
          });
        });
    },
    goToPayment() {
      this.$router.push({
        name: 'order-payment',
        params: {
          lang: this.$route.params.lang,
          orderId: this.orderId,
        },
      });
    },
    getSubtotalDotNotation(name, vat) {
      let total = this.costTable[name].amount;
      if (vat) {
        total += Object.values(this.costTable[name].vat).reduce((sum, value) => sum + value, 0);
      }
      return new Intl.NumberFormat('en', { minimumFractionDigits: 2 }).format(total / 100);
    },
    payOrder() {
      if (!this.isValidOrder || !this.agreedToTC) {
        this.showConfirmError = true;
        if (this.$refs.errors) {
          this.$refs.errors.highlight();
        }
        return null;
      }
      if (this.payOrderBtnPressed) {
        return null;
      }
      this.payButtonLoading = true;
      this.payOrderBtnPressed = true;

      this.$eventBus.$emit('odoo.event', { event: 'payment_started', msg: 'Payment started from cart' });
      this.$eventBus.$emit('gtm.startedPayment', {
        orderId: this.$store.state.cart.orderId,
        totalPrice: this.getSubtotalDotNotation('total', false),
      });

      const formData = new FormData();
      formData.append('order_id', this.$store.state.cart.orderId);
      formData.append('payment_method', this.paymentMethod);
      formData.append('save_default', this.saveAsDefault);
      const ga = this.$tools.getCookie('_ga');
      if (ga !== '') {
        formData.append('ga', ga);
      }
      return axios.post(
        `${this.$config.apiUrl}/order/submit`,
        formData,
        { withCredentials: true },
      )
        .then((response) => {
          window.location.href = response.data.result.paymentUrl;
        })
        .catch((err) => {
          this.payOrderBtnPressed = false;
          this.payButtonLoading = false;
          this.$eventBus.error(err);
        });
    },
    downloadQuote() {
      if (this.isValidOrder) {
        window.open(`${this.$config.apiUrl}/document/quote/${this.$store.state.cart.orderId}?${Date.now()}`,
          '_blank');
      }
    },
    toggleIcpDetails() {
      this.showIcpDetails = !this.showIcpDetails;
    },
    onSave() {
      this.$store.dispatch('updateCartAddress', { address: this.addressData })
        .then(() => {
        });
    },
    onNext() {
      window.open(`${this.$config.prefix}cart/review`, '_self');
    },
    showErrors() {
      this.$store.dispatch('upgradeCartAddressWarningsToErrors');
    },
    updateFormFields(values, resolve, reject) {
      const outValues = { ...values };
      outValues.orderId = this.orderId;
      this.$store.dispatch('updateCartField', outValues)
        .then((value) => {
          resolve(value);
        })
        .catch((value) => {
          reject(value);
        });
    },
    updatePriority(id) {
      this.updatingPriority = true;
      this.$store.dispatch('updateOrderPriority', {
        priorityId: id,
        orderId: this.orderId,
      })
        .finally(() => {
          this.updatingPriority = false;
        });
    },
    acceptWithEmailAsAdmin() {
      this.updatingAccepted = true;
      this.$store.dispatch('adminAcceptedWithEmail', {
        orderId: this.orderId,
      })
        .finally(() => {
          this.updatingAccepted = false;
        });
    },
  },
};
</script>

<style lang="scss">
  .cart-review-view {
    .icp-details .custom-control-input:disabled ~ .custom-control-label {
      color: #000000;
    }

    .no-pointer {
      pointer-events: none;
    }

    .icp-conclusion {
      font-size: 0.875rem;
    }

    .icp-vies-details {
      padding-top: 0.5rem;
      padding-left: 2rem;
    }

    .vat-detail {
      padding-left: 2rem;
      padding-top: 1rem;
      padding-bottom: 0.5rem;
    }

    .card-body ul.bg-dark {
      margin-left: 0;
      padding: 1rem;
      list-style: none;
    }

    .deliver-summary-list {
      list-style-type: none;
    }

    .email-card {
      .email-buttons {
        display: flex;
        justify-content: space-between;
      }
      textarea {
        width: 100%;
        min-height: 500px;
      }
    }
  }

</style>
