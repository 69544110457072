<template>
  <div/>
</template>

<script>
export default {
  props: {
    userId: {
      type: Number,
      default: null,
      required: false,
    },
  },
  computed: {
    userLoaded() {
      return this.$store.state.loaded.user;
    },
    requestedUserId() {
      return this.$store.state.user.id;
    },
  },
  watch: {
    userLoaded(isLoaded) {
      if (!isLoaded) return;
      this.$store.dispatch('loadUserOrders', null);
    },
  },
  mounted() {
    const isLoaded = this.$store.state.loaded.user;
    if (isLoaded && !this.$store.getters.loaded(['orders'])) {
      this.$store.dispatch('loadUserOrders');
    }
  },
};
</script>
