<template>
  <div class="order-priority-list card shadow-1">
    <div class="card-block card-block-title p-0 pl-2 bg-light">
      <div class="card-title">{{$t('cart.delivery_preference')}}
        <loading-widget class="m-0" :loading="updating" :radius="4" />

        <span class="subtotal-title mr-2">{{$t('cart.subtotal')}}</span>
      </div>
    </div>
    <div class="card-body border-top list-group list-group-flush">
      <template v-for="(priority, id) in priorities">
        <order-priority-list-item
          v-show="(!usingOnlyProductsDelivery && priority.id !== 6) ||
            (usingOnlyProductsDelivery && priority.id === 6)"
          :key="id"
          :priority="priority"
          :deadlineTime="getDeadlineTime(priority)"
          :price="getPrice(priority.subtotalDeliverables)"
          :price-excl-vat="getPriceExclVat(priority.subtotalDeliverables)"
          :price-num="getPriceNum(priority.subtotalDeliverables)"
          :price-num-excl-vat="getPriceNumExclVat(priority.subtotalDeliverables)"
          :name="priority.translation[$i18n.locale].name"
          :shipping-time="priority.shippingTime"
          :shipping-method="shippingMethod"
          :order-before-time="priority.orderBeforeTime"
          :delivery-time="priority.deliveryDays"
          :selected="isSelected(priority.id)"
          @updatePriority="updatePriority" />
      </template>
    </div>
  </div>
</template>

<script>

import _orderBy from 'lodash/orderBy';

import OrderPriorityListItem from 'src/components/cart/priority/OrderPriorityListItem';
import LoadingWidget from 'src/components/tools/LoadingWidget';

export default {
  components: {
    LoadingWidget,
    OrderPriorityListItem,
  },
  props: {
    updating: {
      type: Boolean,
      required: true,
    },
    orderId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    priorities() {
      return _orderBy(this.$store.state.cart.priorities, ['ordinal'], ['desc']);
    },
    shippingMethod() {
      return this.$store.state.cart.shippingMethod;
    },
    usingOnlyProductsDelivery() {
      return this.$store.state.cart.priorityId === 6;
    },
  },
  methods: {
    getPrice(item) {
      return this.$config.formatPrice(item, true, false);
    },
    getPriceExclVat(item) {
      return this.$config.formatPrice(item, false, false);
    },
    getPriceNum(item) {
      if (this.$store.state.cart.hasDropshipping) {
        const dropShipCost = Math.round((1.21 * this.$config.dropshippingCosts));
        return this.$config.getPrice(item, true) - dropShipCost;
      }
      return this.$config.getPrice(item, true);
    },
    getPriceNumExclVat(item) {
      if (this.$store.state.cart.hasDropshipping) {
        const dropShipCost = Math.round((this.$config.dropshippingCosts));
        return this.$config.getPrice(item, false) - dropShipCost;
      }
      return this.$config.getPrice(item, false);
    },
    getDeadlineTime(priority) {
      return this.$tools.date.getTime(priority.orderBeforeTime, this.$i18n.locale);
    },
    isSelected(priorityId) {
      return this.$store.state.cart.priorityId === priorityId;
    },
    updatePriority(data) {
      this.$emit('updatePriority', data);
    },
  },
};
</script>

<style lang="scss">
  .order-priority-list .subtotal-title {
    float: right;
  }
</style>
