<template>
  <div v-if="loading"
    class="loading-widget">
    <indeterminate-progress
      :offset="radius"
      :radius="radius"
      :stroke-width="2"
      :strokeColor="strokeColor" />
  </div>
</template>

<script>
import IndeterminateProgress from 'src/components/tools/IndeterminateProgress';

export default {
  components: { IndeterminateProgress },
  props: {
    radius: {
      type: Number,
      default: 5,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    strokeColor: {
      type: String,
      default: '#000000',
    },
  },
};
</script>

<style>
.loading-widget {
  display: inline-block;
}
</style>
