<template>
  <div>
    <user-settings />
  </div>
</template>

<script>
import UserSettings from 'src/components/user/UserSettings';

export default {
  components: { UserSettings },
};
</script>
