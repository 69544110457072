<template>
  <div class="dc-editor-bottom-navigation">
    <div class="dc-nav-left">
      <b-button
        v-if="hasPrevious && isCart"
        :to="previousRouteParams"
        size="xs"
        variant="light"
        class="px-1 px-sm-2">
        <i class="material-icons">arrow_back_ios</i> {{$t('editor.previous_design')}}
      </b-button>
    </div>
    <div
    v-if="isCart"
    class="dc-nav-center">
      {{ currentIndex + 1 }} / {{ queueLength }} {{$t('editor.in_cart')}}
    </div>
    <div class="dc-nav-right">
      <b-button
        v-if="hasNext && isCart"
        :to="nextRouteParams"
        size="xs"
        class="px-1 px-sm-2"
        :class="{'btn-success': hasValidConfiguration, 'btn-light': !hasValidConfiguration}">
        {{$t('editor.next_design')}} <i class="material-icons">arrow_forward_ios</i>
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentIndex: {
      type: Number,
      required: true,
    },
    isCart: {
      type: Boolean,
      required: true,
    },
    hasValidConfiguration: {
      type: Boolean,
      required: true,
    },
    hasNext: {
      type: Boolean,
      required: true,
    },
    nextRouteParams: {
      type: Object,
      required: true,
    },
    hasPrevious: {
      type: Boolean,
      required: true,
    },
    previousRouteParams: {
      type: Object,
      required: true,
    },
    queueLength: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.dc-editor-bottom-navigation {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 767px) {
    .dc-nav-left,
    .dc-nav-right {
      flex: 1;
    }

    .dc-nav-center {
      order: 1;
      flex: 1 100%;
      margin-bottom: .5rem;
    }

    .dc-nav-left {
      order: 2;
      justify-content: flex-start;
    }

    .dc-nav-right {
      order: 3;
      justify-content: flex-end;
    }
  }

  .custom-select option {
    font-size: 15px;
    font-weight: 300;
  }

  .dc-nav-center {
    vertical-align: bottom;
    text-align: center;
    display: none;
  }

  .dc-nav-left,
  .dc-nav-right {
    position: relative;
    display: flex;
  }

  .dc-nav-center {
    vertical-align: bottom;
    width: 200px;
    text-align: center;
  }

  @media (min-width: 370px) {
    .dc-nav-center {
      display: block;
    }
  }
}
</style>
