import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';

// Common list
import NotFoundError from 'src/components/errors/NotFoundError';
import InternalError from 'src/components/errors/InternalError';
import NotAllowedError from 'src/components/errors/NotAllowed';
import Main from 'src/views/Main';

import OrderList from 'src/components/orders/OrderList';
import DraftList from 'src/components/orders/DraftList';
import UserSettingsView from 'src/views/user/UserSettingsView';
import CartList from 'src/components/cart/views/CartList';
import CartReviewView from 'src/components/cart/views/CartReviewView';
import DashboardView from 'src/views/DashboardView';

import DrawingConfigurationList from 'src/components/drawingConfiguration/DrawingConfigurationList';
import OrderPaymentView from 'src/components/cart/views/OrderPaymentView';
import ShowProductEditor from 'src/components/ShowProductEditor';
import OrderThanksView from 'src/components/cart/views/OrderThanksView';
import CartRedirect from 'src/components/cart/views/CartRedirect';

// import KnowledgebasePage from 'src/components/knowledgebase/KnowledgebasePage';
// import SinglePage from 'src/components/singlePage/SinglePage';
// import TemplatesGridView from 'src/components/templates/TemplatesGridView';
// import TemplateDetailView from 'src/components/templates/TemplateDetailView';
// import MaterialListGridView from 'src/components/material/MaterialListGridView';
// import MaterialDetailView from 'src/components/material/MaterialDetailView';

// import SearchView from 'src/components/search/SearchView';
// import LandingPageView from 'src/views/landingPage/LandingPage';
import Frontpage from 'src/views/Frontpage';
import AppContainer from 'src/views/AppContainer';
// import CreateUserAsAdminPage from 'src/views/admin/CreateUserAsAdminPage';

import ForgotPasswordPage from 'src/views/ForgotPasswordPage';

// import NewsPage from 'src/views/news/NewsPage';
// import UniversalContentful from 'src/views/UniversalContentful';

// import ContentfulMaterialPage from 'src/views/contentful/ContentfulMaterialPage';
// import BrochurePage from 'src/views/BrochurePage';
// import SampleKitPage from 'src/views/SampleKitPage';
// import LaserCuttingGuidePage from 'src/views/LaserCuttingGuidePage';
// import QuotePage from 'src/views/QuotePage';

// import CasesPage from 'src/views/cases/CasesPage';
// import CasePage from 'src/views/cases/CasePage';

Vue.use(VueRouter);
Vue.use(VueMeta);
const langRegex = '/:lang(nl|en)';
const routes = [
  {
    component: AppContainer,
    name: 'app',
    path: `${langRegex}/app`,
    meta: {
      authenticated: true,
    },
    children: [
      {
        path: `${langRegex}/dashboard`,
        name: 'dashboard',
        component: DashboardView,
        meta: {
          submenuType: 'cart',
          submenuSelected: 'dashboard',
          title: {
            nl: 'Dashboard',
            en: 'Dashboard',
          },
        },
      },
      {
        component: Main,
        path: 'orders/',
        children: [
          {
            path: 'cart',
            name: 'cart-redirect',
            component: CartRedirect,
            meta: {
              submenuType: 'cart',
              submenuSelected: 'cart',
              title: {
                nl: 'Winkelwagen',
                en: 'Cart',
              },
            },
          },
          {
            path: '',
            name: 'orders-list-me',
            component: OrderList,
            meta: {
              submenuType: 'my-settings',
              submenuSelected: 'order-list',
              title: {
                nl: 'Ordergeschiedenis',
                en: 'Order history',
              },
            },
          },
          {
            path: '(L)?:orderId/cart',
            name: 'order-cart',
            component: CartList,
            meta: {
              submenuType: 'cart',
              submenuSelected: 'cart',
              title: {
                nl: 'Winkelwagen',
                en: 'Cart',
              },
            },
            children: [
              {
                path: 'editor/:productId',
                name: 'order-cart-editor',
                component: ShowProductEditor,
                meta: {
                  submenuType: 'cart',
                  submenuSelected: 'cart',
                  title: {
                    nl: 'Product details',
                    en: 'Product details',
                  },
                },
              },
            ],
          },
          {
            path: '(L)?:orderId/review',
            name: 'order-review',
            component: CartReviewView,
            meta: {
              submenuType: 'cart',
              submenuSelected: 'review',
              title: {
                nl: 'Orderoverzicht',
                en: 'Order overview',
              },
            },
          },
          {
            path: '(L)?:orderId/payment',
            name: 'order-payment',
            component: OrderPaymentView,
            meta: {
              submenuType: 'cart',
              submenuSelected: 'payment',
              title: {
                nl: 'Betalen',
                en: 'Payment',
              },
            },
          },
          {
            path: '(L)?:orderId/confirmation',
            name: 'order-confirmation',
            component: OrderThanksView,
            meta: {
              submenuType: 'cart',
              submenuSelected: 'thanks',
              title: {
                nl: 'Orderbevestiging',
                en: 'Order confirmation',
              },
            },
          },
        ],
      },
      {
        path: `${langRegex}/concept-orders/`,
        name: 'drafts-list-me',
        component: DraftList,
        meta: {
          submenuType: 'my-settings',
          submenuSelected: 'drafts-list',
          title: {
            nl: 'Conceptorders',
            en: 'Concept orders',
          },
        },
      },
      {
        path: 'user/',
        component: Main,
        children: [
          {
            path: 'settings',
            name: 'user-settings-me',
            component: UserSettingsView,
            meta: {
              submenuType: 'my-settings',
              submenuSelected: 'settings',
              title: {
                nl: 'Mijn gegevens',
                en: 'My details',
              },
            },
          },
        ],
      },
      {
        path: 'products/',
        component: Main,
        children: [
          {
            path: '',
            name: 'products-list-me',
            component: DrawingConfigurationList,
            meta: {
              submenuType: 'my-settings',
              submenuSelected: 'drawingProducts',
              title: {
                nl: 'Mijn ontwerpen',
                en: 'My designs',
              },
            },
            children: [
              {
                path: 'editor/:productId',
                name: 'products-list-me-editor',
                component: ShowProductEditor,
                meta: {
                  submenuType: 'my-settings',
                  submenuSelected: 'drawingProducts',
                  title: {
                    nl: 'Product details',
                    en: 'Product details',
                  },
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: `${langRegex}/create-user/`,
    name: 'create-user-as-admin',
    component: () => import('src/views/admin/CreateUserAsAdminPage'),
    meta: {
      title: {
        nl: 'Maak account',
        en: 'Create account',
      },
      authenticated: true,
    },
  },
  {
    path: `${langRegex}/error/`,
    component: Main,
    meta: {
      authenticated: false,
    },
    children: [
      {
        path: '404',
        name: 'error-404',
        component: NotFoundError,
      },
      {
        path: '500',
        name: 'error-500',
        component: InternalError,
      },
      {
        path: '403',
        name: 'error-403',
        component: NotAllowedError,
      },
    ],
  },
  // {
  //   path: `${langRegex}/help/:route(.*)`,
  //   name: 'kb-page',
  //   component: KnowledgebasePage,
  //   meta: {
  //     title: {
  //       nl: 'Uitleg',
  //       en: 'Help',
  //     },
  //     authenticated: false,
  //   },
  // },
  // {
  //   path: `${langRegex}/contact`,
  //   name: 'contact-page',
  //   component: SinglePage,
  //   meta: {
  //     title: {
  //       nl: 'Contact',
  //       en: 'Contact',
  //     },
  //     authenticated: false,
  //     loadPage: 'contact',
  //   },
  // },
  // {
  //   path: `${langRegex}/news/:route?`,
  //   name: 'news-page',
  //   component: NewsPage,
  //   meta: {
  //     title: {
  //       nl: 'Nieuws',
  //       en: 'News',
  //     },
  //     authenticated: false,
  //   },
  // },
  // {
  //   path: `${langRegex}/cases`,
  //   name: 'cases-page',
  //   component: CasePage,
  //   meta: {
  //     title: {
  //       nl: 'Cases',
  //       en: 'Cases',
  //     },
  //     authenticated: false,
  //   },
  // },
  // {
  //   path: `${langRegex}/cases/:route`,
  //   name: 'case-page',
  //   component: CasePage,
  //   meta: {
  //     title: {
  //       nl: 'Case',
  //       en: 'Case',
  //     },
  //     authenticated: false,
  //   },
  // },
  // {
  //   path: `${langRegex}/page/:route`,
  //   name: 'universal-contentful-page',
  //   component: UniversalContentful,
  //   meta: {
  //     title: {
  //       nl: 'Universal',
  //       en: 'Universal',
  //     },
  //     authenticated: false,
  //     nowrapper: true,
  //   },
  // },
  // {
  //   path: `${langRegex}/terms-and-conditions`,
  //   name: 'terms-page',
  //   component: SinglePage,
  //   meta: {
  //     title: {
  //       nl: 'Algemene Voorwaarden',
  //       en: 'Terms of service',
  //     },
  //     authenticated: false,
  //     loadPage: 'terms-and-conditions',
  //   },
  // },
  // {
  //   path: `${langRegex}/privacy-policy`,
  //   name: 'privacy-page',
  //   component: SinglePage,
  //   meta: {
  //     title: {
  //       nl: 'Privacy Policy',
  //       en: 'Privacy Policy',
  //     },
  //     authenticated: false,
  //     loadPage: 'privacy-policy',
  //   },
  // },
  // {
  //   path: `${langRegex}/templates`,
  //   name: 'templates-page',
  //   component: TemplatesGridView,
  //   meta: {
  //     title: {
  //       nl: 'Templates',
  //       en: 'Templates',
  //     },
  //     authenticated: false,
  //   },
  // },
  // {
  //   path: `${langRegex}/templates/:templateId/:templateRoute`,
  //   name: 'template-detail',
  //   component: TemplateDetailView,
  //   meta: {
  //     title: {
  //       nl: 'Templates',
  //       en: 'Templates',
  //     },
  //     authenticated: false,
  //   },
  // },
  // {
  //   path: `${langRegex}/materials`,
  //   name: 'materials-view',
  //   component: MaterialListGridView,
  //   meta: {
  //     title: {
  //       nl: 'Materialen',
  //       en: 'Materials',
  //     },
  //     authenticated: false,
  //   },
  // },
  // {
  //   path: `${langRegex}/materials/:matbaseId(\\d+)`,
  //   name: 'material-detail',
  //   component: MaterialDetailView,
  //   meta: {
  //     title: {
  //       nl: 'Materialen',
  //       en: 'Materials',
  //     },
  //     authenticated: false,
  //   },
  // },
  // {
  //   path: `${langRegex}/materials/:contentfulSlug`,
  //   name: 'contentful-material-page',
  //   component: ContentfulMaterialPage,
  //   meta: {
  //     // title: {
  //     //   nl: 'Materialen',
  //     //   en: 'Materials',
  //     // },
  //     authenticated: false,
  //     nowrapper: true,
  //   },
  // },
  // {
  //   path: `${langRegex}/materials/:route`,
  //   name: 'contentful-material-page',
  //   component: UniversalContentful,
  //   meta: {
  //     title: {
  //       nl: 'Universal',
  //       en: 'Universal',
  //     },
  //     authenticated: false,
  //     nowrapper: true,
  //   },
  // },

  // {
  //   path: `${langRegex}/request-a-brochure`,
  //   name: 'brochure',
  //   component: BrochurePage,
  //   meta: {
  //     title: {
  //       nl: 'Brochure',
  //       en: 'Brochure',
  //     },
  //     authenticated: false,
  //     nowrapper: true,
  //     contentfulPageSlug: 'request-a-brochure',
  //   },
  // },
  // {
  //   path: `${langRegex}/request-a-quote`,
  //   name: 'quote-page',
  //   component: QuotePage,
  //   meta: {
  //     title: {
  //       nl: 'Offerte aanvragen',
  //       en: 'Request a quote',
  //     },
  //     authenticated: false,
  //     nowrapper: true,
  //     contentfulPageSlug: 'request-a-quote',
  //   },
  // },
  // {
  //   path: `${langRegex}/request-a-sample-kit`,
  //   name: 'sample-kit',
  //   component: SampleKitPage,
  //   meta: {
  //     title: {
  //       nl: 'Sample-kit',
  //       en: 'Sample-kit',
  //     },
  //     authenticated: false,
  //     nowrapper: true,
  //     contentfulPageSlug: 'request-a-sample-kit',
  //   },
  // },
  // {
  //   path: `${langRegex}/laser-cutting-guide`,
  //   alias: `${langRegex}/lasersnijden-guide/`,
  //   name: 'laser-cutting-guide',
  //   component: LaserCuttingGuidePage,
  //   meta: {
  //     title: {
  //       nl: 'Lasersnijden guide',
  //       en: 'Laser Cutting Guide',
  //     },
  //     authenticated: false,
  //     nowrapper: true,
  //     contentfulPageSlug: 'laser-cutting-guide',
  //   },
  // },
  // {
  //   path: `${langRegex}/lp/:landingPageRoute`,
  //   name: 'landing-page',
  //   component: LandingPageView,
  //   meta: {
  //     title: {
  //       nl: 'Snijlab',
  //       en: 'Snijlab',
  //     },
  //     authenticated: false,
  //   },
  // },
  // {
  //   path: `${langRegex}/search`,
  //   name: 'search-view',
  //   component: () => import('src/components/search/SearchView'),
  //   meta: {
  //     title: {
  //       nl: 'Zoeken',
  //       en: 'Search',
  //     },
  //     authenticated: false,
  //   },
  // },
  {
    path: `${langRegex}/forgot-password/:code/:confirmationCode`,
    name: 'forgot-password-page',
    component: ForgotPasswordPage,
    meta: {
      title: {
        nl: 'Wachtwoord vergeten',
        en: 'Forgot password',
      },
      authenticated: false,
    },
  },
  /* Backup paths */
  {
    path: `${langRegex}`,
    name: 'frontpage',
    component: Frontpage,
    meta: {
      title: {
        nl: 'Snijlab',
        en: 'Snijlab',
      },
      authenticated: false,
    },
  },
  {
    path: '/',
    redirect: '/nl',
  },
  {
    path: `${langRegex}/*`,
    component: NotFoundError,
    // redirect: (route) => `${route.lang}/error/404`,
  },
  {
    path: '*',
    component: NotFoundError,
  // redirect: '/nl/error/404',
  },
];

// scrollBehavior:
// - only available in html5 history mode
// - defaults to no scroll behavior
// - return false to prevent scroll
const scrollBehavior = (to, from, savedPosition) => {
  // prevent jumping to the top when changing material filters (which
  // will update the query params in the URL)
  if (to.name === 'materials-view' && from.name === 'materials-view') {
    return false;
  }

  if (savedPosition && to.path !== from.path) {
    return savedPosition;
  }
  if (to.hash) {
    return {
      selector: to.hash,
    };
  }

  return {
    x: 0,
    y: 0,
  };
};

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior,
});

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  if (nearestWithTitle) {
    const newTitle = nearestWithTitle.meta.title[to.params.lang];
    if (newTitle !== 'Snijlab') {
      document.title = `${newTitle} - Snijlab`;
    } else {
      document.title = newTitle;
    }
  } else {
    document.title = 'Snijlab';
  }
  next();
});

export default router;
