<template>
    <div class="drawing-configuration-price-button text-center">
      <div v-if="showCart">
        <span v-if="hasMaterial">
          <span class="single-price-striketrough text-muted">{{singleUnitPrice}}</span>
          {{ getPriceCart(priorityId, false) }}</span>
        <span v-else class="text-muted">&euro; -,--</span>
      </div>

      <template v-else-if="hasMaterial">
          <div v-if="!showNestedPrice"
            :id="btnId"
            class="text-left">
            &euro; {{ getPrice(priorityId, 0, false) }}
             <br>
            <small class="text-muted">
              &euro; {{ getPrice(priorityId, 0, true) }} {{$t('drawing_configuration.incl')}}
            </small>
          </div>

          <div v-else-if="showNestedPrice"
            :id="btnId">
            &euro;{{ getSortedPrice(false, -1, false) }} - &euro; {{ getSortedPrice(true, 0, false) }}
            <br>
            <small class="text-muted">
              &euro;{{ getSortedPrice(false, -1, true) }} - &euro;
              {{ getSortedPrice(true, 0, true) }} {{$t('drawing_configuration.incl')}}
            </small>
          </div>
        </template>

        <template v-else>
          <div class="text-muted">&euro; --,--</div>
        </template>
    </div>
</template>

<script>
import _get from 'lodash/get';

export default {
  props: {
    showNestedPrice: {
      type: Boolean,
      required: true,
    },
    showVat: {
      type: Boolean,
      required: true,
    },
    showCart: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      required: true,
    },
    priorityId: {
      type: Number,
      required: true,
    },
    useModal: {
      type: Boolean,
      default: true,
    },
    showInPlace: {
      type: Boolean,
      default: true,
    },
    materialId: {
      type: Number,
    },
  },
  data() {
    return {
      show: false,
      qtyIsValid: true,
    };
  },
  computed: {
    orderDrawing() {
      const drawings = Object.values(this.$store.state.cart.orderDrawings)
        .filter((od) => od.drawingConfigurationId === this.id);
      if (drawings.length > 0) {
        return drawings[0];
      }
      return false;
    },
    singleUnitPrice() {
      const singlePrice = parseFloat(this.$store.getters.priceByQty(this.id, this.priorityId, 0, false));
      const currentPrice = parseFloat(this.orderDrawing.costs.perPiece.amount) / 100.0;
      if (singlePrice !== currentPrice) {
        return this.$config.currencyFormat.format(this.$store.getters.priceByQty(this.id, this.priorityId, 0, false));
      }
      return '';
    },
    btnId() {
      //
      // eslint-disable-next-line no-underscore-dangle
      return `btn-${this._uid}`;
    },
    material() {
      if (this.materialId in this.$store.state.materials) {
        return this.$store.state.materials[this.materialId];
      }
      return false;
    },
    hasMaterial() {
      return this.material && parseInt(this.materialId, 10) !== 1;
    },
    availableProducts() {
      if (this.material) {
        return this.material.material.DrawingProducts.map((item) => this.$store.state.drawingProducts[parseInt(item.id, 10)]);
      }
      return [];
    },
    title() {
      if (_get(this.priority, `translation.${this.$i18n.locale}.name`)) {
        return `Prijsberekening<br>${this.priority.translation[this.$i18n.locale].name}`;
      }
      return 'Prijsberekening: leeg';
    },
  },
  methods: {
    // quantityIdx: 0 for 1 item, -1 for the highest nesting level.
    getPrice(priorityId, qtyIdx, useVat) {
      return this.$store.getters.priceByQty(this.id, priorityId, qtyIdx, useVat);
    },
    getSortedPrice(descending, qtyIdx, useVat) {
      let prices = Object.keys(this.$store.state.priorities)
        .map((priority) => this.$store.getters.priceByQty(this.id, priority, qtyIdx, useVat))
        .map((price) => parseFloat(price))
        .filter((price) => !Number.isNaN(price))
        .sort((a, b) => a - b);
      if (descending) {
        prices = prices.reverse();
      }
      if (prices[0] > 3.0) {
        return Math.round(prices[0]);
      }
      return this.$config.numberFormat.format(prices[0]);
    },
    getPriceCart(priorityId, useVat) {
      if (!this.orderDrawing) {
        return this.getPrice(priorityId, 0, useVat);
      }
      return this.$config.formatPrice(this.orderDrawing.costs.perPiece, useVat, true);
      // return this.$store.getters.priceByQty(this.id, priorityId, qtyIdx, useVat);
    },
  },
};
</script>

<style lang="scss">
  .drawing-configuration-price-button {
    flex: 3;
    font-size: 18px;

    .single-price-striketrough {
      text-decoration: line-through;
      color: rgb(117, 117, 117) !important;
    }
  }
</style>
