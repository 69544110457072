<template>
  <tr v-if="dc" class="cart-review-table-order-drawing-item product-row align-top">
    <td>
      <strong>X{{ dc.id }}</strong>
    </td>
    <td class="overflow-anywhere">
      {{ dc.name }}
    </td>
    <template v-if="!hasError && isApproved && hasMaterial && !hasUndeliverableMaterial">
      <td class="text-right">
          {{ pricePerPiece }}
      </td>
      <td class="text-right">
        {{ item.quantity }} &times;
      </td>
      <td class="text-right">
          {{ priceTotal }}
      </td>
    </template>
    <template v-else>
      <td v-if="hasError" colspan="3" class="text-right">
          <div class="alert alert-danger px-1 py-0 m-0">{{$t('order_review.drawing_cant_be_produced')}}</div>
      </td>
      <td v-else-if="!hasMaterial" colspan="3" class="text-right">
          <div class="alert alert-warning px-1 py-0 m-0">{{$t('order_review.select_a_material')}}</div>
      </td>
      <td v-else-if="!isApproved" colspan="3" class="text-right">
          <div class="alert alert-warning px-1 py-0 m-0">{{$t('order_review.accept_drawing_check')}}</div>
      </td>
      <td v-else-if="hasUndeliverableMaterial" colspan="3" class="text-right">
          <div class="alert alert-danger px-1 py-0 m-0">{{$t('drawing_configuration.material_not_available_anymore')}}</div>
      </td>
    </template>
  </tr>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    showVat: {
      type: Boolean,
      required: true,
    },
    showPrices: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    hasError() {
      return this.dc.feedback.some((f) => f.type === 'ERROR');
    },
    hasWarning() {
      return this.dc && this.dc.feedback.some((f) => f.type === 'WARNING');
    },
    hasMaterial() {
      return !!this.material;
    },
    hasUndeliverableMaterial() {
      // return this.dc.locked && this.hasMaterial && !this.materialIsPublished;
      return this.hasMaterial && !this.materialIsPublished;
    },
    material() {
      if (this.materials[this.dc.material_id]) {
        return this.materials[this.dc.material_id];
      }
      return null;
    },
    materials() {
      return this.$store.state.materials;
    },
    materialIsPublished() {
      if (this.material) {
        return this.material.is_published;
      }
      return false;
    },
    isApproved() {
      return this.dc.approved;
    },
    pricePerPiece() {
      return this.$config.formatPrice(this.item.costs.perPiece, false, true);
    },
    priceTotal() {
      return this.$config.formatPrice(this.item.costs.total, false, true);
    },
    item() {
      return this.$store.state.cart.orderDrawings[this.id];
    },
    dc() {
      return this.$store.state.dc.drawingConfiguration[this.item.drawingConfigurationId];
    },
  },
};
</script>

<style lang="scss">
.cart-review-table-order-drawing-item {
  .overflow-anywhere {
    overflow-wrap: anywhere;
  }
}
</style>
