<template>
  <div class="drawing-product-list form-group products">
    <template v-if="drawingConfiguration.locked">
      <drawing-configuration-product-list :drawing-configuration-id="dcId" />
    </template>
    <template v-else>
      <div v-for="(item) in availableGroups"
        :key="item.keyword">
        <drawing-product-group
          :keyword="item.keyword"
          :dc-id="dcId"
          :disabled="!material.is_published"
          :material-max-height="material.max_size[1]"
          @update="updateDrawingConfigurations" />
      </div>
    </template>
  </div>
</template>

<script>
import DrawingProductGroup from 'src/components/drawingConfiguration/DrawingProductGroup';
import DrawingConfigurationProductList from 'src/components/drawingConfiguration/DrawingConfigurationProductList';

export default {
  components: {
    DrawingConfigurationProductList,
    DrawingProductGroup,
  },
  props: {
    locked: {
      type: Boolean,
      default: false,
    },
    dcId: {
      type: Number,
      default: null,
    },
    materialId: {
      type: Number,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    drawingConfiguration() {
      return this.$store.state.dc.drawingConfiguration[this.dcId];
    },
    material() {
      return this.$store.state.materials[this.materialId];
    },
    availableGroups() {
      return this.material.product_groups.map((groupName) => this.$store.state.drawingProductGroups[groupName]);
    },
  },
  methods: {
    updateDrawingConfigurations(value) {
      const data = { ...this.drawingConfiguration.productsPerGroup };
      data[value.group] = value.value;
      this.$emit('change', Object.values(data));
    },
  },
};
</script>
