<template>
  <footer class="footer-container w-100 bg-light-1">
    <div class="center fluid-container mx-auto p-xs-30 p-md-50">

      <div class="details row">
        <div class="column col-6 col-sm-4 col-md-2 sitemap">
          <div class="content">
            <h6 class="name">{{$t('footer.headers.customer_service')}}</h6>
            <ul>
              <!-- Contact -->
              <li class="menu-item" v-if="this.$i18n.locale === 'nl'">
                <a href="https://snijlab.nl/pages/contact" target="_blank">
                  {{$t('footer.items.contact')}}</a>
              </li>
              <li class="menu-item" v-else>
                <a href="https://snijlab.nl/en/pages/contact" target="_blank">
                  {{$t('footer.items.contact')}}</a>
              </li>

              <!-- Bezorging -->
              <li class="menu-item" v-if="this.$i18n.locale === 'nl'">
                <a href="https://snijlab.nl/pages/levering" target="_blank">
                  {{$t('footer.items.delivery')}}</a>
              </li>
              <li class="menu-item" v-else>
                <a href="https://snijlab.nl/pages/levering" target="_blank">
                  {{$t('footer.items.delivery')}}</a>
              </li>

              <!-- Prijzen -->
              <li class="menu-item" v-if="this.$i18n.locale === 'nl'">
                <a href="https://snijlab.nl/pages/prijzen" target="_blank">
                  {{$t('footer.items.pricing')}}</a>
              </li>
              <li class="menu-item" v-else>
                <a href="https://snijlab.nl/pages/prijzen" target="_blank">
                  {{$t('footer.items.pricing')}}</a>
              </li>

              <!-- Betalen -->
              <li class="menu-item" v-if="this.$i18n.locale === 'nl'">
                <a href="https://snijlab.nl/pages/betalen" target="_blank">
                  {{$t('footer.items.payment')}}</a>
              </li>
              <li class="menu-item" v-else>
                <a href="https://snijlab.nl/pages/betalen" target="_blank">
                  {{$t('footer.items.payment')}}</a>
              </li>

              <!-- Retourneren -->

              <!-- Kwaliteit -->
              <li class="menu-item" v-if="this.$i18n.locale === 'nl'">
                <a href="https://snijlab.nl/pages/kwaliteit" target="_blank">
                  {{$t('footer.items.quality')}}
                </a>
              </li>
              <li class="menu-item" v-else>
                <a href="https://snijlab.nl/pages/kwaliteit" target="_blank">
                  {{$t('footer.items.quality')}}
                </a>
              </li>

              <!-- Zakelijk -->
              <li class="menu-item" v-if="this.$i18n.locale === 'nl'">
                <a href="https://snijlab.nl/pages/zakelijk-bestellen" target="_blank">
                  {{$t('footer.items.business_orders')}}
                </a>
              </li>
              <li class="menu-item" v-else>
                <a href="https://snijlab.nl/pages/zakelijk-bestellen" target="_blank">
                  {{$t('footer.items.business_orders')}}
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="column col-6 col-sm-4 col-md-2 ">
          <div class="content">
            <h6 class="name">{{$t('footer.headers.materials')}}</h6>
            <ul>
              <li class="menu-item" v-if="this.$i18n.locale === 'nl'">
                <a href="https://snijlab.nl/collections/kunststof-lasersnijden" target="_blank">
                  {{$t('mainmenu.materialsmenu.laser_cutting_plastic')}}</a>
              </li>
              <li class="menu-item" v-else>
                <a href="https://snijlab.nl/en/collections/laser-cutting-plastics" target="_blank">
                  {{$t('mainmenu.materialsmenu.laser_cutting_plastic')}}</a>
              </li>
              <li class="menu-item" v-if="this.$i18n.locale === 'nl'">
                <a href="https://snijlab.nl/collections/rubber-lasersnijden" target="_blank">
                  {{$t('mainmenu.materialsmenu.laser_cutting_rubber')}}</a>
              </li>
              <li class="menu-item" v-else>
                <a href="https://snijlab.nl/en/collections/laser-cutting-rubber" target="_blank">
                  {{$t('mainmenu.materialsmenu.laser_cutting_rubber')}}</a>
              </li>
              <li class="menu-item" v-if="this.$i18n.locale === 'nl'">
                <a href="https://snijlab.nl/collections/schuim-lasersnijden" target="_blank">
                  {{$t('mainmenu.materialsmenu.laser_cutting_foam')}}</a>
              </li>
              <li class="menu-item" v-else>
                <a href="https://snijlab.nl/en/collections/laser-cutting-foam" target="_blank">
                  {{$t('mainmenu.materialsmenu.laser_cutting_foam')}}</a>
              </li>
              <li class="menu-item" v-if="this.$i18n.locale === 'nl'">
                <a href="https://snijlab.nl/collections/hout-lasersnijden" target="_blank">
                  {{$t('mainmenu.materialsmenu.laser_cutting_wood')}}</a>
              </li>
              <li class="menu-item" v-else>
                <a href="https://snijlab.nl/en/collections/laser-cutting-wood" target="_blank">
                  {{$t('mainmenu.materialsmenu.laser_cutting_wood')}}</a>
              </li>
              <li class="menu-item" v-if="this.$i18n.locale === 'nl'">
                <a href="https://snijlab.nl/collections/alle-materialen-voor-lasersnijden" target="_blank">
                  {{$t('mainmenu.materialsmenu.material_catalogue')}}</a>
              </li>
              <li class="menu-item" v-else>
                <a href="https://snijlab.nl/en/collections/all-materials-for-laser-cutting" target="_blank">
                  {{$t('mainmenu.materialsmenu.material_catalogue')}}</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="column sitemap col-6 col-sm-4 col-md-2 ">
          <div class="content">
            <h6 class="name">{{$t('footer.headers.support')}}</h6>
            <ul>

              <li class="menu-item" v-if="this.$i18n.locale === 'nl'">
                <a href="https://snijlab.nl/pages/hoe-werkt-het/" target="_blank">{{$t('footer.items.how_it_works')}}
                </a>
              </li>
              <li class="menu-item" v-else>
                <a href="https://snijlab.nl/en/pages/how-does-it-work" target="_blank">{{$t('footer.items.how_it_works')}}
                </a>
              </li>

              <li class="menu-item" v-if="this.$i18n.locale === 'nl'">
                <a href="https://snijlab.nl/pages/tekenregels" target="_blank">{{$t('footer.items.drawing_rules')}}
                </a>
              </li>
              <li class="menu-item" v-else>
                <a href="https://snijlab.nlen/pages/drawing-rules" target="_blank">{{$t('footer.items.drawing_rules')}}
                </a>
              </li>

              <li class="menu-item" v-if="this.$i18n.locale === 'nl'">
                <a href="https://snijlab.nl/blogs/news/tagged/tutorials" target="_blank">{{$t('footer.items.tutorials')}}
                </a>
              </li>
              <li class="menu-item" v-else>
                <a href="https://snijlab.nl/en/blogs/news/tagged/tutorials" target="_blank">{{$t('footer.items.tutorials')}}
                </a>
              </li>

            </ul>
          </div>
        </div>

        <div class="column sitemap col-6 col-sm-4 col-md-2 ">
          <div class="content">
            <h6 class="name">{{$t('footer.headers.company')}}</h6>
            <ul>
              <li class="menu-item" v-if="this.$i18n.locale === 'nl'">
                    <a href="https://snijlab.nl/pages/over-ons" target="_blank">
                      {{$t('footer.items.about')}}</a>
                  </li>
                  <li class="menu-item" v-else>
                    <a href="https://snijlab.nl/en/pages/about" target="_blank">
                      {{$t('footer.items.about')}}</a>
                  </li>
                  <li class="menu-item" v-if="this.$i18n.locale === 'nl'">
                    <a href="https://snijlab.nl/pages/onze-techniek" target="_blank">
                      {{$t('footer.items.innovations')}}</a>
                  </li>
                  <li class="menu-item" v-else>
                    <a href="https://snijlab.nl/pages/our-technology" target="_blank">
                      {{$t('footer.items.innovations')}}</a>
                  </li>
                  <li class="menu-item" v-if="this.$i18n.locale === 'nl'">
                    <a href="https://snijlab.nl/blogs/news" target="_blank">
                      {{$t('footer.items.blog')}}</a>
                  </li>
                  <li class="menu-item" v-else>
                    <a href="https://snijlab.nl/en/blogs/news" target="_blank">
                      {{$t('footer.items.blog')}}</a>
                  </li>
                  <li><a href="https://snijlab.nl/policies/terms-of-service" target="_blank">
                    {{$t('footer.items.terms_of_service')}}</a></li>
                  <li><a href="https://snijlab.nl/policies/privacy-policy" target="_blank">
                    {{$t('footer.items.privacy_statement')}}</a></li>
                  <li><a href="https://www.linkedin.com/company/snijlab/" target="_blank" rel="noopener">LinkedIn</a></li>
                  <li><a href="https://www.instagram.com/snijlab/" target="_blank" rel="noopener">Instagram</a></li>
            </ul>
          </div>
        </div>

        <div class="column sitemap col-6 col-sm-4 col-md-2 ">
          <img class="payment-icon"
            src="@/assets/payment-icons/icon-ideal.svg" alt="Ideal logo">
          <img class="payment-icon"
            src="@/assets/payment-icons/icon-american-express.svg" alt="American Express logo">
          <img class="payment-icon"
            src="@/assets/payment-icons/icon-mastercard.svg" alt="Mastercard logo">
          <img class="payment-icon"
            src="@/assets/payment-icons/icon-visa.svg" alt="Visa logo">
          <img class="payment-icon"
            src="@/assets/payment-icons/icon-bancontact.svg" alt="Bancontact logo">
          <img class="payment-icon"
            src="@/assets/payment-icons/icon-paypal.svg" alt="Paypal logo">
          <img class="payment-icon"
            src="@/assets/payment-icons/icon-sofort.svg" alt="Sofort logo">
          <img class="payment-icon"
            src="@/assets/payment-icons/icon-invoice.svg" alt="Invoice logo">
          <trustpilot location="footer"/>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import Trustpilot from 'src/components/utils/Trustpilot';

export default {
  components: {
    Trustpilot,
  },
};
</script>

<style lang="scss">
  footer {
    .fluid-container {
      max-width: 1340px;
    }

    a:link, a:visited, a:active {
      color: #363636;
      text-decoration: none;
    }

    h6 {
      font-size: 0.8rem;
    }

    .payment-icon {
      width: 35px;
      margin: 0;
      margin-right: 6px;
      margin-bottom: 6px;
    }
  }
</style>
