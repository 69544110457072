<template>
  <div>
    <span v-if="error"
      class="invalid-feedback"
      style="display: block">
      {{ showError(error) }}
    </span>

    <span v-if="warning"
      class="small text-right"
      style="color: orange !important;">
      {{ warning }}</span>

    <b-form-text v-if="help"
      class="text-right">{{ help }}
    </b-form-text>
  </div>
</template>

<script>

export default {
  props: {
    help: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    warning: {
      type: String,
      default: '',
    },
  },
  methods: {
    showError(errorMsg) {
      if (errorMsg === 'FORM_ERROR_REQUIRED') {
        return this.$i18n.t('order_review.address_form.required');
      }
      if (errorMsg === 'FORM_ERROR_INVALID') {
        return this.$i18n.t('order_review.address_form.invalid');
      }
      return errorMsg;
    },
  },
};
</script>

<style>
</style>
