<template>
  <div v-if="exists"
    :data-dc-id="id"
    :class="{selected}"
    class="list-group-item list-group-item-action
      shadow-none cart-list-item p-2 pointer pb-2"
    @click.stop="editDrawingConfiguration" >

    <div class="cart-list-item-select pt-1 m-0 mb-1"
      @click.prevent.stop="selected=!selected">
      <b-form-checkbox
        v-model="selected"
        :tabstop="tabstop">
      </b-form-checkbox>
    </div>

    <div class="cart-list-item-image pr-3 d-flex flex-column">
      <img v-if="showImage && hasSize"
        :alt="`${$config.drawingConfigurationPrefix}${id}`"
        :src="pngFile"
        class="bg-light">
      <div class="drawing-size pt-1">{{width}} mm x {{height}} mm</div>
    </div>

    <div class="cart-list-item-title">
      <div>{{ drawingConfiguration.name }}</div>
      <small>{{$t('cart.product.our_reference')}}: {{ $config.drawingConfigurationPrefix }}{{ drawingConfiguration.id }}</small>
    </div>

    <div class="cart-list-item-details mt-1">
      <drawing-configuration-product-list
        :hasMaterial="hasMaterial"
        :hasUnconfirmedFeedback="hasUnconfirmedFeedback"
        :hasUndeliverableMaterial="hasUndeliverableMaterial"
        :hasError="hasError"
        :drawing-configuration-id="orderDrawing.drawingConfigurationId"
        :show-ordered-before="false" />
    </div>

    <div class="cart-list-item-price-row d-flex flex-row justify-items-between align-items-center">
      <div v-if="isDraft && !hasError && hasMaterial && !hasUnconfirmedFeedback"
        class="cart-list-item-quantity">
        <cart-list-item-quantity-widget
          :id="parseInt(id)"
          :quantity="parseInt(orderDrawing.quantity)"
          productType="OrderDrawing"
          :tabstop="tabstop+1000"/>
      </div>

      <template v-if="!hasError && hasMaterial && canAddToCart && !hasUnconfirmedFeedback">
        <div v-if="!isDraft" class="cart-list-item-static-quantity">{{quantity}}</div>
        <div class="symbol-container text-center">×</div>
        <div class="cart-list-item-price text-center">{{ showPriceExclVat }}</div>
      </template>

      <div class="symbol-container text-center">=</div>

      <div v-if="hasMaterial && canAddToCart && !hasUnconfirmedFeedback"
        class="cart-list-item-subtotal ml-2">
        <div class="cart-list-item-subtotal-total-price">{{ showTotalPriceExclVat }}</div>
        <div class="small text-muted">{{ showTotalPrice }} {{$t('cart.product.total_incl')}}</div>
      </div>
      <div v-else
        class="cart-list-item-subtotal ml-2">
        <div class="cart-list-item-subtotal-total-price text-muted">&euro; -,--</div>
      </div>
    </div>

    <div class="cart-list-item-standard-actions-container mb-1">
      <div class="btn-group shadow-1" role="group">
        <b-button
          size="xs"
          variant="flat"
          class="cart-list-item-edit px-1"
          @click.stop="editDrawingConfiguration">
            <i class="material-icons">edit</i>
        </b-button>

        <b-button
          size="xs"
          variant="flat"
          class="cart-list-item-delete px-1"
          @click.stop="deleteOrderDrawing">
            <i class="material-icons">delete</i>
        </b-button>
      </div>
    </div>
  </div>

  <div v-else>
    <h4>Product {{ id }} niet gevonden</h4>
  </div>
</template>

<script>
import _get from 'lodash/get';
import _has from 'lodash/has';
import CartListItemQuantityWidget from 'src/components/cart/edit/CartListItemQuantityWidget';
import TweenTools from 'src/lib/TweenTools';
import DrawingConfigurationProductList from 'src/components/drawingConfiguration/DrawingConfigurationProductList';

export default {
  components: {
    DrawingConfigurationProductList,
    CartListItemQuantityWidget,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    quantity: {
      type: Number,
      required: false,
    },
    tabstop: {
      type: Number,
      default: 0,
    },
    isDraft: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      is_drawing_product: false, // temp fix to distinguish between drawing and product in sync()
      materialCache: null,
      showImage: true,
      qtyValid: false,
      selected: false,
      taintedValues: {},
      tweenedCSSColor: 'transparent',
      animatedTotal: null,
      animatedTotalExclVat: null,
      animatedPerPiece: null,
      animatedPerPieceExclVat: null,
    };
  },
  computed: {
    class() {
      return { selected: this.selected };
    },
    orderDrawing() {
      return this.$store.state.cart.orderDrawings[this.id];
    },
    drawingConfiguration() {
      return this.$store.state.dc.drawingConfiguration[this.orderDrawing.drawingConfigurationId];
    },
    canAddToCart() {
      return this.drawingConfiguration.approvable && !this.hasUndeliverableMaterial;
    },
    hasError() {
      return this.drawingConfiguration.feedback.some((f) => f.type === 'ERROR');
    },
    hasUnconfirmedFeedback() {
      return this.drawingConfiguration.feedback.some((f) => f.type === 'WARNING' && !f.is_checked);
    },
    hasUndeliverableMaterial() {
      // return this.drawingConfiguration.locked && (this.hasMaterial && !this.materialIsPublished);
      return this.hasMaterial && !this.materialIsPublished;
    },
    materialIsPublished() {
      if (this.material) {
        return this.material.is_published;
      }
      return false;
    },
    hasMaterial() {
      return this.drawingConfiguration.material_id in this.materials && parseInt(this.drawingConfiguration.material_id, 10) !== 1;
    },
    exists() {
      return !!_get(this.$store.state.cart.orderDrawings, this.id)
          && !!_get(this.$store.state.dc.drawingConfiguration, this.orderDrawing.drawingConfigurationId);
    },
    updating() {
      return Object.keys(this.updateQueue).length !== 0;
    },
    totalPriceRaw() {
      return this.$config.getPrice(this.orderDrawing.costs.total, true);
    },
    totalPriceRawExclVat() {
      return this.$config.getPrice(this.orderDrawing.costs.total, false);
    },
    showTotalPrice() {
      if (this.animatedTotal !== null) {
        return this.$config.currencyFormat.format(this.animatedTotal / 100);
      }
      return this.$config.currencyFormat.format(this.totalPriceRaw / 100);
    },
    showTotalPriceExclVat() {
      if (this.animatedTotalExclVat !== null) {
        return this.$config.currencyFormat.format(this.animatedTotalExclVat / 100);
      }
      return this.$config.currencyFormat.format(this.totalPriceRawExclVat / 100);
    },
    totalPrice() {
      return this.$config.formatPrice(this.orderDrawing.costs.total, true, false);
    },
    totalPriceExclVat() {
      return this.$config.formatPrice(this.orderDrawing.costs.total, false, false);
    },
    priceRaw() {
      return this.$config.getPrice(this.orderDrawing.costs.perPiece, true);
    },
    priceRawExclVat() {
      return this.$config.getPrice(this.orderDrawing.costs.perPiece, false);
    },
    price() {
      return this.$config.formatPrice(this.orderDrawing.costs.perPiece, true, false);
    },
    priceExcl() {
      return this.$config.formatPrice(this.orderDrawing.costs.perPiece, false, false);
    },
    showPrice() {
      if (this.animatedPerPiece !== null) {
        return this.$config.currencyFormat.format(this.animatedPerPiece / 100);
      }
      return this.$config.currencyFormat.format(this.priceRaw / 100);
    },
    showPriceExclVat() {
      if (this.animatedPerPieceExclVat !== null) {
        return this.$config.currencyFormat.format(this.animatedPerPieceExclVat / 100);
      }
      return this.$config.currencyFormat.format(this.priceRawExclVat / 100);
    },
    height() {
      if (!_has(this.drawingConfiguration, 'Drawing.dimensions.height')) {
        return '';
      }
      return parseFloat(this.drawingConfiguration.Drawing.dimensions.height)
        .toFixed(0);
    },
    width() {
      if (!_has(this.drawingConfiguration, 'Drawing.dimensions.width')) {
        return '';
      }
      return parseFloat(this.drawingConfiguration.Drawing.dimensions.width)
        .toFixed(0);
    },
    hasSize() {
      return this.width !== '' && this.width !== '0' && this.height !== '' && this.height !== '0';
    },
    pngFile() {
      return `${this.$config.apiUrl}/drawing/png/${this.drawingConfiguration.drawing_id}`;
    },
    materials() {
      return this.$store.state.materials;
    },
    material() {
      if (this.materials[this.orderDrawing.materialId]) {
        return this.materials[this.orderDrawing.materialId];
      }
      return null;
    },
  },
  watch: {
    selected(value) {
      this.$emit('select', { id: this.id, selected: value });
    },
    priceRaw(value, oldValue) {
      TweenTools.tweenNumber(value, oldValue, this, 'animatedPerPiece');
    },
    priceRawExclVat(value, oldValue) {
      TweenTools.tweenNumber(value, oldValue, this, 'animatedPerPieceExclVat');
    },
    totalPriceRaw(value, oldValue) {
      TweenTools.tweenNumber(value, oldValue, this, 'animatedTotal');
    },
    totalPriceRawExclVat(value, oldValue) {
      TweenTools.tweenNumber(value, oldValue, this, 'animatedTotalExclVat');
    },
  },
  methods: {
    deleteOrderDrawing() {
      this.$emit('delete', this);
      return false;
    },
    editDrawingConfiguration() {
      this.$emit('edit', { id: this.orderDrawing.drawingConfigurationId });
      return false;
    },
  },
};
</script>
<style lang="scss">
  .cart-list-item {
    display: grid;
    grid-template-columns: 35px 1fr auto 130px;

    &.selected {
      background-color: rgba(186, 223, 255, 0.25);

      &:hover {
        background-color: rgba(186, 223, 255, 0.4);
      }
    }

    .symbol-container {
      flex: 1;
    }

    .cart-list-item-static-quantity {
      font-size: .8rem;
    }

    .cart-list-item-select {
      grid-column: 1;
      grid-row: 2;
    }

    .cart-list-item-image {
      grid-column: 2/5;
      grid-row: 3;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: flex-start;
      justify-items: center;

      img {
        display: block;
        max-width: 100%;
        max-height: 200px;
        width: auto;
      }

      .drawing-size {
        font-size: 0.7rem;
        font-weight: bold;
      }
    }

    .cart-list-item-title {
      grid-column: 2/5;
      grid-row: 2;
      font-size: 0.8rem;
      font-weight: 400;
      line-height: 0.95rem;
      overflow-wrap: anywhere;
    }

    .cart-list-item-details {
      color: #585858;
      grid-column: 2/5;
      grid-row: 4;
      font-size: 0.65rem;
      margin-bottom: 1rem;
    }

    .cart-list-item-price-row {
      grid-column: 1/6;
      justify-self: end;
      min-width: 350px;

      @media(min-width:992px) {
        grid-column: 3/6;
      }
      grid-row: 5;
      max-width: 500px;
    }

    .cart-list-item-price,
    .cart-list-item-quantity,
    .cart-list-item-subtotal {
      flex: 3;
    }

    // .cart-list-item-quantity {
    //   justify-self: flex-end;
    //   align-items: center;
    //   display: flex;
    // }

    // .cart-list-item-price-container {
    //   grid-column: 4;
    //   grid-row: 5;
    //   display: flex;
    //   justify-content: space-between;
    //   align-items: center;
    //   font-size: .75rem;

    //   .cart-list-item-price {
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: flex-end;
    //     align-items: flex-end;
    //   }
    // }

    // .cart-list-item-subtotal {
    //   font-size: 0.9rem;
    //   grid-column: 3/5;
    //   grid-row: 6;
    //   display: flex;
    //   flex-direction: column;
    //   align-items: flex-end;
    //   justify-content: center;
    // }

    .cart-list-item-standard-actions-container {
      grid-row: 1;
      grid-column: 4;
      justify-self: flex-end;

      .btn {
        min-width: auto;
        color: rgba(0, 0, 0, 0.26);

        &:hover {
        color: rgba(0, 0, 0, 0.87);
        }
      }
    }

    @media (min-width: 576px) {
      .cart-list-item-details {
        grid-column: 3;
        grid-row: 3;
        font-size: 0.65rem;
      }
    }

    @media (min-width: 576px) {
      grid-template-columns: 30px 150px 1fr 130px 130px;

      .cart-list-item-select {
        grid-column: 1;
        grid-row: 1;
      }

      .cart-list-item-standard-actions-container {
        grid-column: 5;
        grid-row: 1;
      }

      .cart-list-item-title {
        grid-column: 3/5;
        grid-row: 1;
      }

      .cart-list-item-image {
        grid-column: 2/3;
        grid-row: 1/4;
      }

      .cart-list-item-details {
        grid-column: 3/6;
        grid-row: 2/4;
      }

      // .cart-list-item-quantity {
      //   grid-column: 2/4;
      //   grid-row: 4;
      //   justify-self: flex-end;
      // }

      // .cart-list-item-price-container {
      //   grid-column: 4;
      //   grid-row: 4;
      // }

      // .cart-list-item-subtotal {
      //   grid-column: 5;
      //   grid-row: 4;
      // }
    }

    @media (min-width: 768px) {
      grid-template-columns: 30px 100px 1fr;

      .cart-list-item-select {
        grid-column: 1;
        grid-row: 1;
      }

      .cart-list-item-standard-actions-container {
        grid-column: 3;
        grid-row: 1;
        justify-self: flex-end;
      }

      .cart-list-item-title {
        grid-column: 3;
        grid-row: 2;
      }

      .cart-list-item-image {
        justify-self: flex-start;
        grid-column: 2;
        grid-row: 2;
      }

      .cart-list-item-details {
        grid-column: 3;
        grid-row: 3;
      }

    //   .cart-list-item-quantity {
    //     grid-column: 2;
    //     grid-row: 4;
    //     justify-self: flex-end;
    //   }

    //   .cart-list-item-price-container {
    //     grid-column: 3;
    //     grid-row: 4;
    //     justify-self: flex-end;
    //   }

    //   .cart-list-item-subtotal {
    //     grid-column: 3;
    //     grid-row: 5;
    //     justify-self: flex-end;
    //   }
    }

  @media (min-width: 992px) {
    grid-template-columns: 30px 150px 1fr 130px 130px;

      .cart-list-item-select {
        grid-column: 1;
        grid-row: 1;
      }

      .cart-list-item-standard-actions-container {
        grid-column: 5;
        grid-row: 1;
      }

      .cart-list-item-title {
        grid-column: 3/5;
        grid-row: 2;
      }

      .cart-list-item-image {
        justify-self: flex-start;
        grid-column: 2/3;
        grid-row: 2/4;
      }

      .cart-list-item-details {
        grid-column: 3/6;
        grid-row: 3/4;
      }

  //     .cart-list-item-quantity {
  //       grid-column: 3;
  //       grid-row: 5;
  //       justify-self: flex-end;
  //     }

  //     .cart-list-item-price-container {
  //       grid-column: 4;
  //       grid-row: 5;
  //     }

  //     .cart-list-item-subtotal {
  //       grid-column: 5;
  //       grid-row: 5;
  //     }
    }
  }
</style>
