<template>
    <div :class="`option-group-${group.keyword}`" style="margin-top: 20px">
      <label>
        <strong>{{ group.name }}</strong> <i class="material-icons info-icon"
        @click.prevent.stop="showHelp(group.keyword)">info</i>

        <template v-if="drawingProductOptions.length === 1">
          <div>{{ drawingProductOptions[0].text }}<br /><br /></div>
        </template>
        <template v-else>
          <b-form-select
            :value="selectedId"
            :options="drawingProductOptions"
            @change="updateProducts"
          >
          </b-form-select>
        </template>
      </label>
    </div>
</template>
<script>
export default {
  props: {
    keyword: {
      type: String,
      required: true,
    },
    selectedId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    group() {
      return this.$store.state.drawingProductGroups[this.keyword];
    },
    drawingProducts() {
      return this.group.DrawingProducts.map(
        (prod) => this.$store.state.drawingProducts[prod.id],
      );
    },
    drawingProductOptions() {
      return this.drawingProducts.map(
        (prod) => ({
          value: prod.id,
          text: prod.name,
        }),
      );
    },
    selectedProduct() {
      return this.$store.state.drawingProducts[this.selectedId];
    },
    helpMap() {
      return {
        bottom_default: 'editor.bottom_film',
        bottom_not_available: 'editor.bottom_film',
        bottom_optional: 'editor.bottom_film',
        direction_corrugated: 'editor.direction_corrugated',
        direction_grain: 'editor.direction_grain',
        selection: 'editor.selection',
        top_default: 'editor.top_film',
        top_not_available: 'editor.top_film',
        top_optional: 'editor.top_film',
      };
    },
  },
  methods: {
    showHelp(type) {
      this.$eventBus.$emit('snlb.showHelp', this.helpMap[type]);
    },
    updateProducts(val) {
      this.$emit('update', { group: this.keyword, value: val });
    },
  },
};
</script>
