<template>
    <div class="contentful-client-review">
      <div class="center-container">
        <!-- <pre>{{data}}</pre> -->
        <h2 class="text-center mb-5">{{data.title}}</h2>

          <div class="grid-container">
          <div v-for="item in data.reviewsReviewCards" :key="item.title" class="review-block">
            <div class="top">
              <div>
                <img :src="resizedImageUrl(item.fields.image.fields.file.url, 150, 150)"
                  :alt="item.fields.image.fields.description" />

                <blockquote>
                  <p class="mb-0">"{{item.fields.quote}}"</p>
                </blockquote>
                <div class="name">{{item.fields.nameCustomer}}</div>
                <div class="position">{{item.fields.positionCompanyName}}</div>
              </div>
            </div>
            <div class="bottom">
              <a class="cta"
                :href="item.fields.ctaLink">{{item.fields.ctaTitle}} <i class="material-icons">chevron_right</i></a>
            </div>
          </div>
        </div>

        <div class="num-items-text">1 — 3</div>
      </div>
    </div>
</template>

<script>

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    removeBeforeDestroy: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    resizedImageUrl(url, width, height) {
      return `${url}?w=${width}&h=${height}`;
    },
    strippedLink(url) {
      const strippedUrl = url.split('snijlab.nl');
      if (strippedUrl.length > 1) {
        return strippedUrl[1];
      }
      return url;
    },
  },
  beforeDestroy() {
    if (this.removeBeforeDestroy) {
      this.$root.$el.parentNode.removeChild(this.$root.$el);
    }
  },
};
</script>

<style lang="scss">
  .contentful-client-review {
    margin-bottom: 4rem;

    blockquote {
      font-size: 1rem;
    }

    h2 {
      font-size: 42px;
      font-weight: 300;
      line-height: 54px;
      text-align: center;
    }

    .center-container {
      margin: auto;
      max-width: 1000px;
      text-align: center;
    }

    .grid-container {
      display: grid;
      column-gap: 1rem;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto;
      margin: 0 1rem;
    }

    .review-block {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-content: center;
      text-align: left;
      padding: 24px 20px 0 20px;
      color: black;
      border: 2px solid #ffe400;
      border-radius: 5px;;

      img {
        margin-bottom: 2.8rem;
      }

      blockquote {
        font-size: .9rem;
      }

      .name {
        font-weight: 400;
        font-size: 1rem;
        margin-bottom: 0.2rem;
      }

      .position {
        font-size: .8rem;
      }

      .cta {
        display: inline-block;
        margin: 2rem 0;
      }
    }

    .num-items-text {
      display: none;
    }

    .info-block {
      color: white;
      background-color: #424242;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-size: 1rem;
        font-weight: 400;
      }
    }

    @media(max-width: 768px) {
      h2 {
        font-size: 2rem;
        margin: 0 1rem;
        margin-bottom: 1rem;
      }
      .grid-container {
        grid-template-columns: 1fr 1fr;
        column-gap: 1rem;

        .review-block:nth-child(n+3) {
          display:none;
        }
      }
    }

    @media(max-width: 575px) {
      .grid-container {
        display: flex;
        overflow-x: scroll;
        padding-bottom: .5rem;

        .review-block, .review-block:nth-child(n+3) {
          display: flex;
          min-width: 305px;
        }
      }

      .num-items-text {
        display: block;
        padding-top: .8rem;
        font-size: .7rem;
      }

    }
  }
</style>
