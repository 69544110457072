<template>
  <form-async-widget-input
    :value="value"
    v-bind="form"
    :disabled="locked"
    :disable-update="disableUpdate"
    class="px-0"
    :max-length="50"
    @update="updateValue"
  />
</template>

<script>
import FormAsyncWidgetInput from 'src/components/form-async/FormAsyncWidgetInput';

export default {
  components: {
    FormAsyncWidgetInput,
  },
  props: {
    dc_id: {
      type: Number,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
    locked: {
      type: Boolean,
      default: false,
    },
    disableUpdate: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    form() {
      return {
        label: this.$i18n.t('drawing_configuration.name'),
        placeholder: this.$i18n.t('drawing_configuration.design_name'),
        help: '',
        error: '',
        required: false,
      };
    },
  },
  methods: {
    reset() {
      this.valueTaintedValue = null;
    },
    updateValue({ promise, value }) {
      // we have certain conditions after an update that a refresh is triggered.
      const currentValue = this.$store.state.dc.drawingConfiguration[this.dc_id].name;
      if (currentValue.trim() !== value.trim()) {
        if (value === '') {
          // eslint-disable-next-line no-param-reassign
          value = `${this.$config.drawingConfigurationPrefix}${this.dc_id}`;
        }
        this.$store.dispatch('updateDrawingReference', {
          dc_id: this.dc_id,
          name: value,
        }).finally(() => promise.resolve());
      } else {
        promise.resolve();
      }
    },
  },
};
</script>

<style>
</style>
