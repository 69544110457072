<template>
  <div class="drawing-configuration-order-list">
    <ul class="order-list" v-if="!showAllStatus">
        <li class="order-list-item-invisible" :key="od.id" v-for="(od) in orderIds.slice(displayCount)">{{ od }}</li>
        <li class="order-list-item" :key="od.id" v-for="(od) in orderIds.slice(0, displayCount)">{{ od }}</li>
        <li class="order-list-item order-list-more" v-if="orderIds.length > displayCount"
            v-on:click.prevent="showAllStatus = true">
            (Show {{ orderIds.length - displayCount }} more)
        </li>
    </ul>
    <ul class="order-list" v-else>
      <li class="order-list-item" :key="od.id" v-for="(od) in orderIds">{{ od }}</li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    displayCount: {
      type: Number,
      default: 10,
    },
    orderIds: {
      type: Array,
      required: true,
    },
    showAll: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showAllStatus: this.showAll,
    };
  },
};
</script>
<style lang="scss">
  .drawing-configuration-order-list {
    .order-list-more {
        cursor: pointer;
    }

    .order-list {
        display: inline;
        list-style: none;
        padding: 0;
    }

    .order-list-item-invisible {
        visibility: hidden;
        display: none;
    }

    .order-list .order-list-item {
        display: inline;
    }
    .order-list .order-list-item:before {
      display: none;
    }

    .order-list .order-list-item:after {
        content: ", ";
    }

    .order-list .order-list-item:last-child:after {
        content: "";
    }

    .order-list-more:hover {
        background-color: #ccc;
    }
  }
</style>
