<template>
    <div class="contentful-blogs">
      <h2 v-if="data.title" class="text-center mb-5">{{data.title}}</h2>
      <div class="content-container">
        <div v-for="blog in data.blogs" :key="blog.sys.id"
          class="blog-item">
          <div class="img-container">
            <img
            loading="lazy"
            :src="`${blog.fields.frontpageImage.fields.file.url}?w=308&h=200`"
            :alt="blog.fields.frontpageImage.fields.description"/>
          </div>
          <div class="text-container pt-2 pl-2">
            <div>
              <div class="d-flex align-items-center pb-2">
                <span class="category alert alert-snijlab p-1 m-0 d-inline-block mr-3">{{blog.fields.category}}</span>
                <span v-if="blog.fields.readTime" class="read-time">
                  {{blog.fields.readTime}} {{ $t('frontpage.blogs.min_read') }}</span>
              </div>
              <h4>{{blog.fields.title}}</h4>
              <p>{{blog.fields.frontpageSnippet}}</p>
            </div>
            <router-link class="cta" :to="{ path: `/${$i18n.locale}/news/${blog.fields.slug}` }">
              {{$t('frontpage.blogs.read_more')}} <i class="material-icons">chevron_right</i></router-link>
          </div>

        </div>
      </div>
      <div class="text-center mt-4 mb-5">
        <!-- <router-link v-if="data.cta" class="btn btn-outline-dark px-1 px-lg-2" @click.native="showMobileMenu=false"
          :to="{ name: 'news-page'}">{{data.cta}}</router-link> -->
      </div>
    </div>
</template>

<script>

export default {
  props: {
    data: {
      type: Object,
      require: true,
    },
    removeBeforeDestroy: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
  },
  beforeDestroy() {
    if (this.removeBeforeDestroy) {
      this.$root.$el.parentNode.removeChild(this.$root.$el);
    }
  },
};
</script>

<style lang="scss">
  .contentful-blogs {

    padding: 3rem 0 1rem 0;

    h2 {
      font-size: 1.8rem;
      // font-size: 42px;
      // font-weight: 300;
      font-weight: 700;
      line-height: 54px;
      text-align: center;
    }

    .content-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      row-gap: 2rem;
      max-width: 1300px;
      margin: auto;
      align-items: flex-start;

      .blog-item {
        flex: 1 1 50%;
        display: flex;
        flex-direction: row;

        @media(max-width: 767px){
          flex-direction: column;
          align-items: center;
        }

        h5 {
          font-size: 24px;
          line-height: 24px;
          font-weight: 300;
        }
        .category {
          font-size: 14px;
          background-color: #FFDD00;
          font-weight: 400;
        }
        p {
          font-size: 16px;
          font-weight: 300;
          line-height: 24px;
          max-height: 200px;
          text-overflow: ellipsis;
          overflow: hidden;
          padding: 0;
          margin: 0;
        }
        .img-container,
        .text-container {
          flex: 1 1 40%;
        }
        .text-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          min-height: 100%;
        }

        .category,
        .read-time {
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
  }
</style>
