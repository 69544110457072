<template>
    <div class="contentful-logo-bar bg-light-3">
      <div class="center-container">
        <h2>{{data.logosTitle}}</h2>
        <div class="logo-flex d-flex flex-column flex-md-row flex-wrap">
          <div v-for="(image, index) in data.logosImages"
            :key="`${image.fields.title}${index}`" class="logo-block">
            <div>
              <img :src="`${image.fields.file.url}?w=150`" :alt="image.fields.description" />
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    removeBeforeDestroy: {
      type: Boolean,
      default: false,
    },
  },
  beforeDestroy() {
    if (this.removeBeforeDestroy) {
      this.$root.$el.parentNode.removeChild(this.$root.$el);
    }
  },
};
</script>

<style lang="scss">
  .contentful-logo-bar {
    padding: 1rem 0 0 0;
    margin-bottom: 4rem;
    margin-block: 4rem;

    h2 {
      font-size: 42px;
      font-weight: 300;
      line-height: 54px;
      text-align: center;
    }

    .center-container {
      margin: auto;
      max-width: 1000px;
      text-align: center;
    }

    .logo-flex {
      justify-content: space-evenly;
    }

    .logo-block {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-content: center;
      text-align: center;
      padding: 24px 20px 0 20px;
      color: black;

      img {
        margin-bottom: 16px;
      }

      h5 {
        font-size: 1.1rem;
        font-weight: 700;
      }

      .btn {
        margin: 1rem 0;
        align-self: center;
      }
    }

    .info-block {
      color: white;
      background-color: #424242;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-size: 1rem;
        font-weight: 400;
      }
    }

    .btn-snijlab-outline {
      color: #ffdd00;
      border: 1px solid #ffdd00;
    }

    @media(max-width: 767px) {
      h3 {
        margin-bottom: 1.5rem;
        font-size: 1.8rem;
      }
    }
  }
</style>
