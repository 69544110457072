<template>
  <b-form-input
    ref="input"
    :id="id"
    :class="classname"
    :size="size"
    :placeholder="placeholder"
    v-model="autocompleteText"
    type="text"
    @focus="onFocus"
    @blur="onBlur"
    @change="onChange"
    @keypress="onKeyPress" />
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: '',
    },
    classname: {
      type: String,
    },
    placeholder: {
      type: String,
      default: 'Start typing',
    },
    types: {
      type: String,
      default: 'address',
    },
    country: {
      type: [String, Array],
      default: null,
    },
    enableGeolocation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      autocomplete: null,
      autocompleteText: '',
    };
  },
  watch: {
    autocompleteText(newVal, oldVal) {
      this.$emit('inputChange', { newVal, oldVal });
    },
    country(newVal) {
      if (newVal) {
        this.autocomplete.setComponentRestrictions({ country: newVal });
      }
    },
  },
  mounted() {
    const options = {};
    if (this.types) {
      options.types = ['address'];
    }

    if (this.country) {
      options.componentRestrictions = {
        country: this.country,
      };
    }

    if (typeof google === 'undefined') {
      return;
    }

    // this should globally exist
    // eslint-disable-next-line no-undef
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById(this.id),
      options,
    );

    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete.getPlace();

      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        this.$emit('no-results-found', place);
        return;
      }

      const addressComponents = {
        street_number: 'short_name',
        routeParams: 'long_name',
        country: 'short_name',
        postal_town: 'long_name',
        locality: 'long_name',
        administrative_area_level_1: 'long_name',
        administrative_area_level_2: 'long_name',
        postal_code: 'short_name',
      };

      const returnData = {};

      if (place.address_components !== undefined) {
        // Get each component of the address from the place details
        for (let i = 0; i < place.address_components.length; i += 1) {
          const addressType = place.address_components[i].types[0];

          if (addressComponents[addressType]) {
            returnData[addressType] = place.address_components[i][addressComponents[addressType]];
          }
        }
        if ('name' in place && place.types.includes('establishment')) {
          returnData.name = place.name;
        }

        returnData.latitude = place.geometry.location.lat();
        returnData.longitude = place.geometry.location.lng();

        // return returnData object and PlaceResult object
        this.$emit('placechanged', returnData, place, this.id);

        // update autocompleteText then emit change event
        this.autocompleteText = returnData.routeParams;
        this.onChange();
      }
    });
  },
  methods: {
    onFocus() {
      this.geolocate();
      this.$emit('focus');
    },
    onBlur() {
      this.$emit('blur');
    },
    onChange() {
      this.$emit('change', this.autocompleteText);
    },
    onKeyPress(event) {
      this.$emit('keypress', event);
    },
    clear() {
      this.autocompleteText = '';
    },
    focus() {
      this.$refs.autocomplete.focus();
    },
    blur() {
      this.$refs.autocomplete.blur();
    },
    update(value) {
      this.autocompleteText = value;
    },
    // Bias the autocomplete object to the user's geographical location,
    // as supplied by the browser's 'navigator.geolocation' object.
    geolocate() {
      if (this.enableGeolocation) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            const geolocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
              // eslint-disable-next-line no-undef
            const circle = new google.maps.Circle({
              center: geolocation,
              radius: position.coords.accuracy,
            });
            this.autocomplete.setBounds(circle.getBounds());
          });
        }
      }
    },
  },
};
</script>
