<template>
  <div>
    <div class="shippingForm card shadow-1 mb-3">
      <div class="card-block-title pl-2 py-1 bg-light">
        <div v-if="invoice_is_delivery_address && !isPickup"
          class="card-title">{{$t('order_review.address_form.delivery_and_invoice_address')}}</div>
        <div v-else class="card-title">{{$t('order_review.address_form.invoice_address')}}
          <template v-if="hasDropshipping"> ({{$t('order_review.your_address')}})</template>
        </div>
      </div>

      <div class="card-body border-top pt-1">
        <form-async-widget-single-checkbox
          :value="is_business"
          :error="is_business_error"
          v-bind="InvoiceForm.is_business"
          :checked-value="true"
          :unchecked-value="false"
          input-name="is_business"
          @update="updateIsBusiness" />

        <form-async-widget-input
          v-show="is_business"
          :clear-tainted="!is_business"
          :value="invoice_company"
          :error="invoice_company_error"
          v-bind="InvoiceForm.company"
          type="text"
          input-name="invoice_company"
          size="sm"
          :max-length="50"
          @update="update" />

        <form-async-widget-input
          :value="invoice_name"
          :error="invoice_name_error"
          v-bind="InvoiceForm.name"
          type="text"
          input-name="invoice_name"
          size="sm"
          :max-length="50"
          @update="update" />

        <form-async-widget-input
          v-show="is_business"
          :clear-tainted="!is_business"
          :value="invoice_email"
          :error="invoice_email_error"
          v-bind="InvoiceForm.email"
          type="text"
          input-name="invoice_email"
          size="sm"
          :max-length="100"
          @update="update" />

        <form-widget-custom
          v-bind="InvoiceForm.country"
          :loading="invoiceCountryLoading"
          :error="invoice_country_error">
          <country-selector
            :value="invoice_country"
            @update="updateInvoiceCountry" />
        </form-widget-custom>

        <form-async-widget-input
          v-show="(invoice_is_delivery_address || invoice_is_delivery_address === null)
            && !isPickup && invoice_country !== 'NL'"
          :clear-tainted="!(invoice_is_delivery_address && !isPickup && invoice_country !== 'NL')"
          :value="delivery_phone_number"
          :error="delivery_phone_number_error"
          v-bind="ShippingForm.delivery_phone_number"
          type="text"
          input-name="delivery_phone_number"
          size="sm"
          :max-length="50"
          @update="update" />

        <form-async-widget-input
          v-show="isApplicableForICP"
          :value="vat_number"
          :clear-tainted="!isApplicableForICP"
          :error="vat_number_error"
          v-bind="InvoiceForm.vat_number"
          type="text"
          input-name="vat_number"
          size="sm"
          :max-length="50"
          @update="update" />

        <div class="shippingForm" v-show="invoice_country === 'NL'">

          <div class="d-flex align-items-start">
            <form-async-widget-input
              row-class="input-row-half postcodeRow"
              :delay="200"
              :value="invoice_zip_code"
              :error="invoice_zip_code_error"
              :tainted-value.sync="invoice_zip_codeTainted"
              v-bind="InvoiceForm.zip_code"
              type="text"
              input-name="invoice_zip_code"
              size="sm"
              :max-length="50"
              @update="updateZipInvoice" />

            <form-async-widget-input
              row-class="input-row-half"
              :delay="200"
              :value="invoice_address_number"
              :error="invoice_address_number_error"
              :tainted-value.sync="invoice_address_numberTainted"
              v-bind="InvoiceForm.address_number"
              type="text"
              input-name="invoice_address_number"
              size="sm"
              :max-length="50"
              @update="updateZipInvoice" />

          </div>

          <div v-show="invoicePostcodeHasError" class="text-warning p-2">{{invoicePostcodeHasError}}</div>

          <form-async-widget-input
            :value="invoice_address"
            :error="invoice_address_error"
            :tainted-value.sync="invoice_addressTainted"
            v-bind="InvoiceForm.address"
            type="text"
            input-name="invoice_address"
            size="sm"
            :max-length="50"
            @update="updateZipInvoice" />

          <form-async-widget-input
            :value="invoice_address_extra"
            :error="invoice_address_extra_error"
            v-bind="ShippingForm.address_extra"
            type="text"
            input-name="invoice_address_extra"
            size="sm"
            :max-length="50"
            @update="update" />

          <form-async-widget-input
            :value="invoice_city"
            :error="invoice_city_error"
            :tainted-value.sync="invoice_cityTainted"
            v-bind="InvoiceForm.city"
            type="text"
            input-name="invoice_address"
            size="sm"
            :max-length="50"
            @update="updateZipInvoice" />
        </div>

        <div class="shippingForm" v-show="invoice_country !== 'NL'">
          <form-async-widget-input
            :value="invoice_address"
            :error="invoice_address_error"
            v-bind="InvoiceForm.address_non_nl"
            type="text"
            input-name="invoice_address"
            size="sm"
            :max-length="50"
            @update="update" />

          <form-async-widget-input
            :value="invoice_address_extra"
            :error="invoice_address_extra_error"
            v-bind="ShippingForm.address_extra"
            type="text"
            input-name="invoice_address_extra"
            size="sm"
            :max-length="50"
            @update="update" />

          <form-async-widget-input
            :value="invoice_zip_code"
            :error="invoice_zip_code_error"
            v-bind="InvoiceForm.zip_code"
            type="text"
            input-name="invoice_zip_code"
            size="sm"
            :max-length="50"
            @update="update" />

          <form-async-widget-input
            :value="invoice_city"
            :error="invoice_city_error"
            v-bind="InvoiceForm.city"
            type="text"
            input-name="invoice_city"
            size="sm"
            :max-length="50"
            @update="update" />
        </div>

        <div class="p-2">* {{$t('order_review.address_form.required')}}</div>
        <form-async-widget-single-checkbox
          v-show="!isPickup && !hasDropshipping"
          :value="invoice_is_delivery_address"
          :error="invoice_is_delivery_address_error"
          v-bind="InvoiceForm.invoice_is_delivery_address"
          input-name="invoice_is_delivery_address"
          :label="$t('order_review.address_form.other_delivery_address')"
          :invert-value="true"
          @update="update" />
      </div>
    </div>

    <div v-show="!invoice_is_delivery_address && !isPickup"
      class="shippingForm card shadow-1 mb-3">
      <div class="card-block-title pl-2 py-1"
        :class="this.$store.state.cart.hasDropshipping ? 'bg-primary' : 'bg-light'">
        <div class="card-title">{{$t('order_review.address_form.delivery_address')}}
          <template v-if="hasDropshipping"> ({{$t('order_review.clients_address')}})</template>
        </div>
      </div>
      <div class="card-body">

        <form-async-widget-input
          :value="delivery_company"
          :error="delivery_company_error"
          v-bind="ShippingForm.company"
          type="text"
          input-name="delivery_company"
          size="sm"
          :max-length="50"
          @update="update" />

        <form-async-widget-input
          :value="delivery_name"
          :error="delivery_name_error"
          v-bind="ShippingForm.name"
          type="text"
          input-name="delivery_name"
          size="sm"
          :max-length="50"
          @update="update" />

        <form-widget-custom
          v-bind="ShippingForm.country"
          :loading="shippingCountryLoading"
          :error="delivery_country_error">
          <country-selector
            :value="delivery_country"
            @update="updateShippingCountry"
            :loading="shippingCountryLoading" />
        </form-widget-custom>

        <div class="shippingForm" v-show="delivery_country === 'NL'">
          <div class="d-flex align-items-start">
            <form-async-widget-input
              row-class="input-row-half postcodeRow"
              :delay="200"
              :value="delivery_zip_code"
              :error="delivery_zip_code_error"
              :tainted-value.sync="delivery_zip_codeTainted"
              v-bind="ShippingForm.zip_code"
              type="text"
              input-name="delivery_zip_code"
              size="sm"
              :max-length="50"
              @update="updateZipDelivery" />

            <form-async-widget-input
              row-class="input-row-half"
              :delay="200"
              :value="delivery_address_number"
              :error="delivery_address_number_error"
              :tainted-value.sync="delivery_address_numberTainted"
              v-bind="ShippingForm.address_number"
              :warning="deliveryPostcodeHasError"
              type="text"
              input-name="delivery_address_number"
              size="sm"
              :max-length="50"
              @update="updateZipDelivery" />
            </div>

          <form-async-widget-input
            :value="delivery_address"
            :error="delivery_address_error"
            :tainted-value.sync="delivery_addressTainted"
            v-bind="ShippingForm.address"
            type="text"
            input-name="delivery_address"
            size="sm"
            :max-length="50"
            @update="updateZipDelivery" />

          <form-async-widget-input
            :value="delivery_address_extra"
            :error="delivery_address_extra_error"
            v-bind="ShippingForm.address_extra"
            type="text"
            input-name="delivery_address_extra"
            size="sm"
            :max-length="50"
            @update="update" />

          <form-async-widget-input
            :value="delivery_city"
            :error="delivery_city_error"
            :tainted-value.sync="delivery_cityTainted"
            v-bind="ShippingForm.city"
            type="text"
            input-name="delivery_address"
            size="sm"
            :max-length="50"
            @update="updateZipDelivery" />
        </div>

        <div class="shippingForm" v-show ="delivery_country !== 'NL'">
          <form-async-widget-input
            :value="delivery_phone_number"
            :error="delivery_phone_number_error"
            v-bind="ShippingForm.delivery_phone_number"
            type="text"
            input-name="delivery_phone_number"
            size="sm"
            :max-length="50"
            @update="update" />

          <form-async-widget-input
            :value="delivery_address"
            :error="delivery_address_error"
            v-bind="ShippingForm.address_non_nl"
            type="text"
            input-name="delivery_address"
            size="sm"
            :max-length="50"
            @update="update" />

          <form-async-widget-input
            :value="delivery_address_extra"
            :error="delivery_address_extra_error"
            v-bind="ShippingForm.address_extra"
            type="text"
            input-name="delivery_address_extra"
            size="sm"
            :max-length="50"
            @update="update" />

          <form-async-widget-input
            :value="delivery_zip_code"
            :error="delivery_zip_code_error"
            v-bind="ShippingForm.zip_code"
            type="text"
            input-name="delivery_zip_code"
            size="sm"
            :max-length="50"
            @update="update" />

          <form-async-widget-input
            :value="delivery_city"
            :error="delivery_city_error"
            v-bind="ShippingForm.city"
            type="text"
            input-name="delivery_city"
            size="sm"
            :max-length="50"
            @update="update" />
        </div>
        <div class="p-2">* {{$t('order_review.address_form.required')}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import _get from 'lodash/get';
import axios from 'axios';
// import Vue from 'vue';
import AsyncQueue from 'src/components/tools/AsyncQueue';
import FormAsyncWidgetInput from 'src/components/form-async/FormAsyncWidgetInput';
import FormWidgetInput from 'src/components/form/FormWidgetInput';
import FormAsyncWidgetSingleCheckbox from 'src/components/form-async/FormAsyncWidgetSingleCheckbox';
import FormWidgetCustom from 'src/components/form/FormWidgetCustom';
import CountrySelector from 'src/components/tools/CountrySelector';
import LoadingWidget from 'src/components/tools/LoadingWidget';
import FormWidgetPlaces from 'src/components/form/FormWidgetPlaces';
import FormAsyncWidgetSingleRadio from 'src/components/form-async/FormAsyncWidgetSingleRadio';

const Component = {
  name: 'AddressForm',
  components: {
    FormAsyncWidgetSingleRadio,
    AsyncQueue,
    FormAsyncWidgetInput,
    FormWidgetInput,
    FormAsyncWidgetSingleCheckbox,
    FormWidgetCustom,
    CountrySelector,
    LoadingWidget,
    FormWidgetPlaces,
  },
  props: {
    hasDropshipping: {
      type: Boolean,
      required: true,
    },
    shippingMethod: {
      type: String,
      required: true,
    },
    storeProperty: {
      type: String,
      required: true,
    },
    storeErrorProperty: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      invoice_zip_codeTainted: null,
      invoice_addressTainted: null,
      invoice_address_numberTainted: null,
      invoice_cityTainted: null,
      delivery_zip_codeTainted: null,
      delivery_addressTainted: null,
      delivery_address_numberTainted: null,
      delivery_cityTainted: null,
      delivery_phone_numberTainted: null,
      invoicePostcodeHasError: null,
      deliveryPostcodeHasError: null,
      invoiceZipUpdating: false,
      deliveryZipUpdating: false,
      scheduleInvoiceUpdate: false,
      shippingCountryLoading: false,
      invoiceCountryLoading: false,
    };
  },
  computed: {
    /**
       * @return {boolean}
       */
    isInvoiceAddressNumberRequired() {
      return this.invoice_country === 'NL';
    },
    isDeliveryAddressNumberRequired() {
      return this.invoice_country === 'NL' && !this.invoice_is_delivery_address;
    },
    InvoiceNeedsUpdate() {
      return this.getTaintedAddressFields('invoice').value.length > 0;
    },
    ShippingForm() {
      return this.shippingWidgetsWithPhoneNumber;
    },
    InvoiceForm() {
      return this.invoiceWidgets;
    },
    /**
       * @return {boolean}
       */
    isApplicableForICP() {
      return this.euVatCountries.includes(this.invoice_country) && this.is_business;
    },
    isPickup() {
      return this.shippingMethod === 'PICKUP';
    },
    euVatCountries() {
      return [
        'BE', 'BG', 'CZ', 'DK', 'DE', 'EE', 'IE', 'GR', 'ES', 'FR', 'HR', 'IT', 'CY', 'LV', 'LT', 'LU', 'HU',
        'MT', 'AT', 'PL', 'PT', 'RO', 'SI', 'SK', 'FI', 'SE', 'GB'];
    },
    shippingWidgets() {
      return {
        name: {
          label: this.$i18n.t('order_review.address_form.for_the_attention_of'),
          placeholder: this.$i18n.t('order_review.address_form.first_and_last_name'),
          help: '',
          error: '',
          required: true,
        },
        company: {
          label: this.$i18n.t('order_review.address_form.company_name'),
          placeholder: '',
          help: '',
          error: '',
          required: false,
        },
        address: {
          label: this.$i18n.t('order_review.address_form.street'),
          placeholder: '',
          help: '',
          error: '',
          required: true,
        },
        address_non_nl: {
          label: this.$i18n.t('order_review.address_form.street_and_number'),
          placeholder: '',
          help: '',
          error: '',
          required: true,
        },
        address_extra: {
          label: this.$i18n.t('order_review.address_form.extra_address_line'),
          placeholder: this.$i18n.t('order_review.address_form.unit_building_floor_optional'),
          help: '',
          error: '',
          required: false,
        },
        address_number: {
          label: this.$i18n.t('order_review.address_form.house_number_and_addition'),
          placeholder: this.$i18n.t('order_review.address_form.house_number_placeholder'),
          help: '',
          error: '',
          required: this.isInvoiceAddressNumberRequired,
        },
        zip_code: {
          label: this.$i18n.t('order_review.address_form.postal_code'),
          placeholder: '',
          help: '',
          error: '',
          required: true,
          warnings: '',
        },
        city: {
          label: this.$i18n.t('order_review.address_form.city'),
          placeholder: '',
          help: '',
          error: '',
          required: true,
        },
        country: {
          label: this.$i18n.t('order_review.address_form.country'),
          placeholder: this.$i18n.t('order_review.address_form.select_country'),
          help: '',
          error: '',
          required: true,
        },
        vat_number: {
          label: this.$i18n.t('order_review.address_form.vat_number'),
          placeholder: '',
          help: this.$i18n.t('order_review.address_form.vat_reversed_hint'),
          helpLink: 'https://www.belastingdienst.nl/wps/wcm/connect/bldcontenten/belastingdienst'
              + '/business/vat/vat_in_the_netherlands/calculating_vat/reverse-charging_vat',
          error: '',
          required: false,
        },
      };
    },
    invoiceWidgets() {
      const widgets = {
        ...this.shippingWidgets,
        email: {
          label: this.$i18n.t('order_review.address_form.email_address_for_invoice'),
          placeholder: '',
          help: this.$i18n.t('order_review.address_form.a_copy_of_the_invoice_will_be_send_to_this_address'),
          error: '',
          required: false,
        },
        is_business: {
          label: this.$i18n.t('order_review.address_form.business'),
        },
      };
      widgets.address_number = {
        label: this.$i18n.t('order_review.address_form.house_number_and_addition'),
        placeholder: this.$i18n.t('order_review.address_form.house_number_placeholder'),
        help: '',
        error: '',
        required: this.isDeliveryAddressNumberRequired,
      };

      return widgets;
    },
    shippingWidgetsWithPhoneNumber() {
      return {
        ...this.shippingWidgets,
        delivery_phone_number: {
          label: this.$i18n.t('order_review.address_form.phone_number_for_shipping'),
          placeholder: '',
          help: this.$i18n.t('order_review.address_form.your_phone_number_will_be_communicated_to_the_shipping_company'),
          error: '',
          required: true,
        },
      };
    },
  },

  methods: {
    updateInvoiceCountry(value) {
      this.invoiceCountryLoading = true;
      const vm = this;
      this.updateFields({
        field: 'invoice_country',
        value,
      })
        .finally(() => {
          if (value !== 'NL') {
            if (this.invoice_address_number !== '') {
              this.invoice_address_numberTainted = '';
              this.updateFields({ field: 'invoice_address_number', value: '' });
            }
            this.$store.dispatch('clearInvoiceCountryErrors');
          } else if (value === 'NL') {
            this.$store.dispatch('clearDeliveryPhoneNumberErrors');
          }
          vm.invoiceCountryLoading = false;
        });
    },
    updateShippingCountry(value) {
      this.shippingCountryLoading = true;
      const vm = this;
      this.updateFields(
        {
          field: 'delivery_country',
          value,
        },
      ).finally(() => {
        if (value !== 'NL') {
          if (this.delivery_address_number !== ' ') {
            this.delivery_address_numberTainted = '';
            this.updateFields({ field: 'delivery_address_number', value: ' ' });
          }
          this.$store.dispatch('clearShippingCountryErrors');
        } else if (value === 'NL') {
          this.$store.dispatch('clearDeliveryPhoneNumberErrors');
        }
        vm.shippingCountryLoading = false;
      });
    },

    updateFields(taintedFields) {
      return new Promise((resolve, reject) => {
        this.$emit('updateFields', taintedFields, resolve, reject);
      });
    },
    update({ promise, value, inputName }) {
      // don't update stuff that's already updated..
      if (this[inputName] === value) {
        promise.resolve();
      }
      this.updateFields({
        field: inputName,
        value,
      }).finally(() => promise.resolve());
    },
    updateIsBusiness({ promise, value, inputName }) {
      if (!value) {
        this.updateFields({
          field: inputName,
          value,
        }).finally(() => {
          promise.resolve();
          this.$store.dispatch('clearCompanyErrors');
        });
      } else {
        this.update({ promise, value, inputName });
      }
    },
    updateZipInvoice({ promise, value, inputName }) {
      this.updateZipLookup(promise, value, 'invoice', inputName);
    },
    updateZipDelivery({ promise, value, inputName }) {
      this.updateZipLookup(promise, value, 'delivery', inputName);
    },
    updateZipLookup(promise, value, type, inputName) {
      if (this[`${type}ZipUpdating`]) {
        promise.resolve();
        return;
      }

      this[`${type}ZipUpdating`] = true;
      const params = {};
      const taintedFields = this.getTaintedAddressFields(type);
      const zipOrNumberTainted = taintedFields.field
        .some((item) => item === `${type}_zip_code` || item === `${type}_address_number`);

      const postcodeNotFound = this.$i18n.t('order_review.address_form.no_address_with_provided_zipcode_and_number_found');
      let hasError = false;

      if (!zipOrNumberTainted) {
        if (taintedFields.value.length === 0) {
          promise.resolve();
          this[`${type}ZipUpdating`] = false;
          return;
        }

        this.updateFields(taintedFields)
          .finally(() => {
            promise.resolve();
            this[`${type}ZipUpdating`] = false;
          });
        return;
      }

      params.postcode = (this[`${type}_zip_codeTainted`] || this[`${type}_zip_code`]).replace(/\s/, '');
      params.huisnummer = (this[`${type}_address_numberTainted`]
       || this[`${type}_address_number`] || '').replace(/^(\d+).*$/, '$1');

      let showPostcodeCheckError = true;
      if ((inputName.match(/zip_code$/) && params.huisnummer === '')
        || (inputName.match(/address_number$/) && params.postcode === '')) {
        showPostcodeCheckError = false;
      }

      axios.get(this.$config.postcodeUrl, { params })
        .then((response) => {
          if (response.data.length === 0 && showPostcodeCheckError) {
            hasError = true;
            promise.resolve();
            return;
          }
          const resultAddress = _get(response, 'data[0].openbareruimte');
          if (this[`${type}_address`] !== resultAddress) {
            this[`${type}_addressTainted`] = resultAddress;
          }
          const resultCity = _get(response, 'data[0].woonplaats');
          if (this[`${type}_city`] !== resultCity) {
            this[`${type}_cityTainted`] = resultCity;
          }
        })
        .catch((response) => {
          if (response.response.status === 400) {
            hasError = true;
          }
        })
        .finally(() => {
          if (hasError) {
            // commit error..
            this[`${type}PostcodeHasError`] = postcodeNotFound;
          } else {
            this[`${type}PostcodeHasError`] = null;
          }
          const fields = this.getTaintedAddressFields(type);
          if (fields.value.length === 0) {
            promise.resolve();
            this[`${type}ZipUpdating`] = false;
          }
          this.updateFields(fields)
            .finally(() => {
              promise.resolve();
              this[`${type}ZipUpdating`] = false;
            });
        });
    },
    getTaintedAddressFields(type) {
      const fields = [];
      const values = [];
      if ((this[`${type}_addressTainted`] || this[`${type}_addressTainted`] === '')
        && this[`${type}_addressTainted`] !== this[`${type}_address`]) {
        values.push(this[`${type}_addressTainted`]);
        fields.push(`${type}_address`);
      }
      if ((this[`${type}_address_numberTainted`] || this[`${type}_address_numberTainted`] === '')
        && this[`${type}_address_numberTainted`] !== this[`${type}_address_number`]) {
        values.push(this[`${type}_address_numberTainted`]);
        fields.push(`${type}_address_number`);
      }
      if ((this[`${type}_cityTainted`] || this[`${type}_cityTainted`] === '')
        && this[`${type}_cityTainted`] !== this[`${type}_city`]) {
        values.push(this[`${type}_cityTainted`]);
        fields.push(`${type}_city`);
      }
      if ((this[`${type}_zip_codeTainted`] || this[`${type}_zip_codeTainted`] === '')
        && this[`${type}_zip_codeTainted`] !== this[`${type}_zip_code`]) {
        values.push(this[`${type}_zip_codeTainted`]);
        fields.push(`${type}_zip_code`);
      }
      return {
        value: values,
        field: fields,
      };
    },
    // setInvoiceAddressData(data) {
    //   this.setAddressData('invoice', data);
    // },
    // setDeliveryAddressData(data) {
    //   this.setAddressData('delivery', data);
    // },
    // setAddressData(type, data) {
    //   if ('locality' in data) {
    //     Vue.set(this, `${type}_cityTainted`, data.locality);
    //   }
    //   if ('postal_code' in data) {
    //     Vue.set(this, `${type}_zip_codeTainted`, data.postal_code);
    //   } else {
    //     Vue.set(this, `${type}_zip_codeTainted`, '');
    //   }
    //   if ('routeParams' in data) {
    //     Vue.set(this, `${type}_addressTainted`, data.routeParams);
    //   }
    //   if ('street_number' in data) {
    //     Vue.set(this, `${type}_address_numberPublic`, data.street_number);
    //   } else {
    //     Vue.set(this, `${type}_address_numberPublic`, '');
    //   }
    // },
  },
};

[
  'delivery_phone_number',
  'delivery_address',
  'delivery_address_extra',
  'delivery_address_number',
  'delivery_city',
  'delivery_company',
  'delivery_country',
  'delivery_name',
  'delivery_zip_code',
  'invoice_address',
  'invoice_address_extra',
  'invoice_address_number',
  'invoice_city',
  'invoice_company',
  'invoice_country',
  'invoice_email',
  'invoice_is_delivery_address',
  'invoice_name',
  'invoice_zip_code',
  'vat_number',
  'is_business']
  .forEach((item) => {
    Component.computed[item] = {
      get() {
        return _get(this.$store.state, `${this.storeProperty}.${item}`, '');
      },
    };
    Component.computed[`${item}_error`] = {
      get() {
        return _get(this.$store.state, `${this.storeErrorProperty}.${item}`, '');
      },
    };
  });
export default Component;
</script>
<style lang="scss">
  .shippingForm {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .invalid-feedback {
      margin-top: 0;
    }
  }

  .postcodeRow {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
</style>
