<template>
  <form @submit.prevent="submit">
    <div class="client-details-form">
      <form-async-widget-single-checkbox
        :value="is_business"
        :error="is_business_error"
        v-bind="UserForm.is_business"
        :checked-value="true"
        :unchecked-value="false"
        input-name="is_business"
        @update="updateIsBusiness" />

      <form-async-widget-input
        v-show="is_business"
        :clear-tainted="!is_business"
        :value="organisation"
        :error="organisation_error"
        v-bind="UserForm.organisation"
        type="text"
        input-name="organisation"
        size="sm"
        @update="update" />

      <form-async-widget-input
        :value="first_name"
        :error="first_name_error"
        :tainted-value.sync="first_nameTainted"
        v-bind="UserForm.first_name"
        type="text"
        input-name="firstname"
        size="sm"
        @update="update" />

      <form-async-widget-input
        :value="last_name"
        :error="last_name_error"
        :tainted-value.sync="last_nameTainted"
        v-bind="UserForm.last_name"
        type="text"
        input-name="lastname"
        size="sm"
        @update="update" />

      <form-async-widget-input
        :value="phone_number"
        :error="phone_number_error"
        :tainted-value.sync="phone_numberTainted"
        v-bind="UserForm.phone_number"
        type="text"
        input-name="phone_number"
        size="sm"
        @update="update" />

    </div>
  </form>
</template>
<script>

import FormAsyncWidgetInput from 'src/components/form-async/FormAsyncWidgetInput';
import FormAsyncWidgetSingleCheckbox from 'src/components/form-async/FormAsyncWidgetSingleCheckbox';
import debounce from 'lodash/debounce';

export default {
  components: {
    FormAsyncWidgetInput,
    FormAsyncWidgetSingleCheckbox,
  },
  data() {
    return {
      loading: false,
      first_nameTainted: null,
      first_name_error: null,
      last_nameTainted: null,
      last_name_error: null,
      phone_numberTainted: null,
      phone_number_error: null,
      is_business_error: null,
      organisationTainted: null,
      organisation_error: null,
      invoice_companyTainted: null,
      invoice_company_error: null,
      userForm: null,
    };
  },
  computed: {
    UserForm() {
      return {
        first_name: {
          label: this.$i18n.t('user_settings.first_name'),
          placeholder: this.$i18n.t('user_settings.first_name'),
          help: '',
          error: '',
          required: false,
        },
        last_name: {
          label: this.$i18n.t('user_settings.last_name'),
          placeholder: this.$i18n.t('user_settings.last_name'),
          help: '',
          error: '',
          required: false,
        },
        organisation: {
          label: this.$i18n.t('user_settings.company_name'),
          placeholder: '',
          help: '',
          error: '',
          required: false,
        },
        phone_number: {
          label: this.$i18n.t('user_settings.phone_number'),
          placeholder: this.$i18n.t('user_settings.phone_number'),
          help: '',
          error: '',
          required: false,
        },
        is_business: {
          label: this.$i18n.t('user_settings.business'),
        },
      };
    },
    invoice_company() {
      return this.$store.state.profile.invoice_company;
    },
    first_name() {
      return this.$store.state.profile.first_name;
    },
    last_name() {
      return this.$store.state.profile.last_name;
    },
    phone_number() {
      return this.$store.state.profile.phone_number;
    },
    is_business() {
      return this.$store.state.profile.is_business;
    },
    organisation() {
      return this.$store.state.profile.organisation;
    },
  },
  methods: {
    // eslint-disable-next-line
    updateField: debounce(function (value) {
    }, 500),
    // eslint-disable-next-line no-unused-vars
    error(name) {
      return '';
    },
    // eslint-disable-next-line no-unused-vars
    update({ promise, value, inputName }) {
      // don't update stuff that's already updated..
      if (this[inputName] === value) {
        promise.resolve();
      }
      this.updateFields({
        field: inputName,
        value,
      }).finally(() => promise.resolve());
    },
    updateFields(taintedFields) {
      return new Promise((resolve, reject) => {
        this.$emit('updateFields', taintedFields, resolve, reject);
      });
    },
    updateIsBusiness({ promise, value, inputName }) {
      if (!value) {
        this.updateFields({
          field: inputName,
          value,
        }).finally(() => {
          promise.resolve();
          this.$store.dispatch('clearCompanyErrors');
        });
      } else {
        this.update({ promise, value, inputName });
      }
    },
  },
};
</script>
<style>
</style>
