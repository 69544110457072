<template>
  <div class="admin-bar message-bar px-2">
    <vue-multiselect
      class="admin-multiselect"
      v-model="value"
      :options="options"
      group-label="category"
      group-values="results"
      :group-select="false"
      :placeholder="$i18n.t('admin.type_to_search')"
      track-by="name"
      label="name"
      :loading="searchIsLoading"
      :internal-search="false"
      :resetAfter="false"
      :preserveSearch="true"
      :clear-on-select="false"
      @search-change="asyncFind"
      @select="adminSearchAction">
      <template slot="placeholder">
        <i class="material-icons compact">search</i> {{$t('admin.search')}}
      </template>
      <template slot="noResult">-{{$t('admin.no_results')}}</template>
      <template slot="noOptions"><div class="display: none;" /></template>
      <template slot="option" slot-scope="props">
        <div v-if="props.option.$isLabel" class="option__grouplabel" v-html="props.option.$groupLabel" />
        <template v-else-if="props.option.itemType === 'advanced-search'">
          <div class="option__row">
            <div class="option__col">
              <div class="option__title">
                <span v-if="props.option.name">{{$t('admin.no_results_for')}}
                  <strong><i>{{props.option.name}}</i></strong><br></span>
                <i class="material-icons compact">search</i> {{$t('admin.advanced_search')}}
              </div>
            </div>
            <div class="option__col option__action">{{$t('admin.press_return_to_select')}}</div>
          </div>
        </template>
        <template v-else>
          <div class="option__row">
            <div class="option__col">
              <div class="option__id">{{props.option.id}}</div>
              <div class="option__title">{{props.option.name}}</div>
            </div>
            <div class="option__col option__action">{{$t('admin.press_return_to_select')}}</div>
          </div>
        </template>
      </template>
    </vue-multiselect>

    <template v-if="showAdminUserWarning && userLoaded">
      <div class="user-warning">
        <i class="material-icons compact warning-icon mr-2">error_outline</i> {{$t('admin.logged_in_as')}}
         <span class="client-name">{{this.$store.getters.profileFullName}}</span>

        <b-button
          size="xs"
          class="p-1 ml-2"
          variant="light"
          @click="logoutClientUser()">
          {{$t('admin.logout_client')}}
        </b-button>
      </div>

    </template>
    <template v-else>
      <div />
    </template>
  </div>
</template>

<script>

import VueMultiselect from 'vue-multiselect';
import axios from 'axios';

export default {
  components: {
    VueMultiselect,
  },
  computed: {
    userLoaded() {
      return this.$store.getters.loaded([
        'user',
      ]);
    },
    showAdminUserWarning() {
      return this.$store.state.admin_user.id !== this.$store.state.user.id;
    },

    selectOption() {
      return null;
    },
  },
  data() {
    return {
      searchIsLoading: false,
      searchString: [],
      options: [],
      value: [],
    };
  },
  methods: {
    logoutClientUser() {
      this.$store.state.masqUserId = null;
      window.location = '/nl';
    },
    updateSearch(value) {
      this.searchString = value.toLowerCase().split(/\s+/);
    },
    open() {
      this.placeholder = this.$i18n.t('admin.type_here_to_filter');
    },
    input(value) {
      this.$emit('input', parseInt(value.id, 10));
    },
    close() {
      this.placeholder = this.$i18n.t('admin.admin_search');
    },
    adminSearchAction(selectedOption) {
      switch (selectedOption.itemType) {
        case 'user':
          window.location = `/${this.$i18n.locale}/app/orders/cart?userId=${selectedOption.userId}`;
          break;
        case 'order':
          window.location = `/${this.$i18n.locale}/app/orders/${selectedOption.id}/cart?userId=${selectedOption.userId}`;
          break;
        case 'product':
          window.location = `/${this.$i18n.locale}/app/products/editor/${selectedOption.id}?userId=${selectedOption.userId}`;
          break;
        default:
          //
      }
    },
    asyncFind(query) {
      this.searchIsLoading = true;

      const formData = new FormData();
      formData.append('search_string', query);
      return axios.post(
        `${this.$config.apiUrl}/searchAsAdmin/simple`,
        formData,
        { withCredentials: true },
      )
        .then((response) => {
          this.options = response.data.result;
          this.searchIsLoading = false;
        })
        .catch((err) => {
          this.$eventBus.error(err);
          this.searchIsLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.admin-bar {
  background-color: #ffe400;
  display: flex;
  color: #000;
  align-items: center;
  justify-content: space-between;

  .user-warning {
    display: flex;
    font-size: 0.8rem;
    align-items: center;

    .warning-icon {
      font-size: 1.5rem;
      background-color: #fff;
      color: #f00;
      border-radius: 50%;
    }

    .client-name {
      margin-left: 0.4rem;
      font-weight: bold;
    }
  }

  .admin-multiselect {
    max-width: 600px;

    .multiselect__option.multiselect__option--group {
      color: rgb(109, 109, 109);
      font-weight: bold;;
      padding-left: 0;
      font-size: 0.6rem;
    }

    .multiselect__option {
      color: #000;
      border-bottom: 1px solid  rgb(221, 221, 221);
      padding-top: 0.3rem;
      font-size: 0.8rem;

      .option__action {
        display: none;
        font-style: italic;
        color:rgb(109, 109, 109);
      }

      .option__col div {
        display: inline-block;
      }

      .option__id {
        width: 50px;
        color: #555;
      }
    }

    .multiselect__option--highlight {
      background-color: #ffe400;

      .option__action {
        display: inline-block;
      }
    }

    .option__grouplabel {
      color: #000;
      font-size: 0.6rem;
    }

    .option__row {
      display: flex;
      grid-template-columns: 1fr 1fr;;
      justify-content: space-between;
    }
  }
}
</style>
