<template>
  <div class="determinate-progress"
    :style="`width: ${(radius+offset)*2}px; height: ${(radius+offset)*2}px;`">
    <svg x="0" y="0" :width="(radius+offset)*2" :height="(radius+offset)*2"
      :viewBox="`0, 0, ${(radius+offset)*2}, ${(radius+offset)*2}`">
      <circle :r="radius" :cx="radius+offset" :cy="radius+offset"
        class="backgroundCircle"></circle>

      <circle :r="radius" :cx="radius+offset" :cy="radius+offset"
        :transform="`rotate(-90 ${radius+offset} ${radius+offset})`"
        class="progressCircle"
        :stroke-dasharray="`${length}px ${length}px`"
        :stroke-dashoffset="`${(length)-(length*percentage)}px`"></circle>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    percentage: {
      type: Number,
      default: 0,
      required: true,
    },
    offset: {
      type: Number,
      default: 2,
    },
    radius: {
      type: Number,
      default: 35,
    },
  },
  computed: {
    length() {
      return Math.PI * 2 * this.radius;
    },
  },
};
</script>

<style lang="scss">
.determinate-progress {
  .progressCircle {
    stroke: #000000;
    stroke-width: 3px;
    fill: none;
  }

  .backgroundCircle {
    stroke: #ccc;
    stroke-width: 3px;
    fill: none;
  }
}
</style>
