<template>
  <div></div>
</template>

<script>
import _get from 'lodash/get';

export default {
  mounted() {
    this.$eventBus.$off('snlb.error', this.showError);
    this.$eventBus.$on('snlb.error', this.showError);
    this.$eventBus.$off('snlb.success', this.showSuccess);
    this.$eventBus.$on('snlb.success', this.showSuccess);
  },
  methods: {
    showError(error) {
      const errMessageAjax = _get(error, 'response.data.error');
      const errObj = {
        type: 'error',
        icon: 'error_outline',
        duration: 15000,
        position: 'top-left',
      };
      if (errMessageAjax === 'AUTHENTICATION_REQUIRED') {
        if (this.$store.state.user !== null) {
          window.location.hash = '#signinAgain';
          window.location.reload();
        }
        return;
      }
      if (errMessageAjax) {
        this.$toasted.show(errMessageAjax, errObj);
        return;
      }

      if (typeof error === typeof new Error()) {
        this.$toasted.show(error.message, errObj);
        return;
      }
      this.$toasted.show(error, errObj);
    },
    showSuccess(error) {
      const successObj = {
        type: 'success',
        icon: 'check_circle',
        duration: 2000,
        position: 'top-left',
      };
      if (typeof error === typeof new Error()) {
        this.$toasted.show(error.message, successObj);
        return;
      }
      this.$toasted.show(error, successObj);
    },
  },
};
</script>
