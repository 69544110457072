<template>
  <div>
    <label v-if="label"
      :for="id"
      labelClass="labelClass">
      <slot name="before"/>
      {{ label }} <template v-if="required">*</template>
      <slot name="after"/>
      <a v-if="helpLink"
        :href="helpLink"><i class="material-icons"/></a>
    </label>
  </div>
</template>

<script>

export default {
  props: {
    label: {
      type: String,
    },
    id: {
      type: String,
    },
    helpLink: {
      type: String,
    },
    required: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style>
</style>
