<template>
  <div v-show="!filtered"
    :data-dc-id="id"
    :class="{ selected: selected}"
    class="card shadow-1 dc-list-item m-2 p-2">
    <!-- <div class="dc-list-item-first-column"> -->
      <div class="dc-list-item-select pt-2"
        @click.capture.prevent="toggleSelected">
        <b-form-checkbox
          v-model="selected"
          @click="toggleSelected"/>
      </div>
      <div class="dc-list-item-id"
        @click.capture.prevent="toggleSelected">
        <span
          :class="{ 'text-warning': !hasMaterial && canAddToCart, 'text-danger': !canAddToCart }">
          <strong>{{ this.$config.drawingConfigurationPrefix }}{{ id }}</strong><br>
          {{this.createdDate}}
        </span>
      </div>

      <div class="dc-list-item-image d-flex flex-column" @click="showSelectMaterial">
        <img v-if="hasSize && !filtered"
          class="bg-light"
          :alt="drawingConfiguration.name"
          :src="pngFile">
        <div class="drawing-size pt-1">{{width}} mm x {{height}} mm</div>
      </div>
    <!-- </div> -->
    <div class="dc-list-item-specification" @click="showSelectMaterial">
      <strong>{{ drawingConfiguration.name }}</strong>
      <br>
      <span class="text-right"> {{ width }} mm x {{ height }} mm</span><br>
      <span class="text-warning font-weight-bold"
        v-if="hasUnconfirmedFeedback && !hasError">
          {{$t('drawing_configuration.warnings_not_accepted_yet')}}</span>
      <span v-if="hasUndeliverableMaterial">
        <a class="normal text-danger font-weight-bold">{{$t('drawing_configuration.material_not_available_anymore')}}</a>
      </span>
      <drawing-configuration-product-list class="mt-2" :drawing-configuration-id="id" />
    </div>

    <!-- <div class="dc-list-item-last-column"> -->
      <div class="dc-list-item-price">
        <div v-if="hasMaterial && priority > 0"
          class="text-left">
          <drawing-configuration-price-button
            :id="id"
            :priority-id="priority"
            :show-vat="showVat"
            :show-nested-price="showNestedPrice"
            :material-id="parseInt(drawingConfiguration.material_id, 10)"
          />
        </div>
      </div>
      <div class="dc-list-item-cart">
        <span v-if="isInCart" class="text-success"><i class="material-icons">check</i> {{$t('my_designs.in_cart')}}</span>
      </div>
      <div class="dc-list-item-actions">
        <!-- Split button -->
        <!-- Secondary, outline button -->
        <div class="btn-group-vertical row-actions">
          <loading-button
            v-if="canAddToCart"
            :loading="addToOrderLoading"
            :variant="addToCartVariant"
            :disabled="disabled"
            size="xs"
            stroke-color="#fff"
            @click="addToOrder"><i class="material-icons compact">addshopping_cart</i>
          </loading-button>

          <loading-button
            v-if="!canAddToCart"
            :loading="deleteLoading"
            :disabled="disabled"
            variant="empty"
            size="xs"
            @click="deleteDrawingConfiguration">
            <i class="material-icons compact">delete</i> {{ $t('my_designs.delete') }}
          </loading-button>
          <!-- Indicates a successful or positive action -->
          <button type="button"
            class="btn btn-error btn-xs"
            :class="{ 'btn-warning' : !hasError && !hasMaterial && !hasUndeliverableMaterial}"
            @click="showSelectMaterial">
            <template v-if="canAddToCart"><i class="material-icons compact">edit</i> {{ $t('my_designs.edit') }}</template>
            <template v-else><i class="material-icons compact">visibility</i> {{ $t('my_designs.show') }}</template>
          </button>
        </div>
      </div>
    <!-- </div> -->
  </div>
</template>

<script>
import _get from 'lodash/get';
import _has from 'lodash/has';

import DrawingConfigurationPriceButton from 'src/components/drawingConfiguration/DrawingConfigurationPriceButton';
import LoadingButton from 'src/components/tools/LoadingButton';
import DrawingConfigurationProductList from 'src/components/drawingConfiguration/DrawingConfigurationProductList';

export default {
  components: {
    DrawingConfigurationProductList,
    LoadingButton,
    DrawingConfigurationPriceButton,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    showTable: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      required: true,
    },
    showVat: {
      type: Boolean,
      required: true,
    },
    priority: {
      required: true,
    },
    showNestedPrice: {
      type: Boolean,
      default: true,
    },
    orderId: {
      type: Number,
      required: true,
    },
    selected: {
      type: Boolean,
      required: true,
      default: false,
    },
    filtered: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      addToOrderLoading: false,
      deleteLoading: false,
    };
  },
  computed: {
    hasError() {
      return this.drawingConfiguration.feedback.some((f) => f.type === 'ERROR');
    },
    hasUndeliverableMaterial() {
      // return this.drawingConfiguration.locked && (this.hasMaterial && !this.materialIsPublished);
      return this.hasMaterial && !this.materialIsPublished;
    },
    materialIsPublished() {
      if (this.material) {
        return this.material.is_published;
      }
      return false;
    },
    canAddToCart() {
      return this.drawingConfiguration.approvable && !this.hasUndeliverableMaterial;
    },
    addToCartVariant() {
      if (this.canAddToCart && this.hasMaterial) {
        return 'primary';
      }
      return 'empty';
    },
    hasUnconfirmedFeedback() {
      return this.drawingConfiguration.feedback.some((f) => f.type === 'WARNING' && !f.is_checked);
    },
    hasMaterial() {
      return this.drawingConfiguration.material_id in this.materials && parseInt(this.drawingConfiguration.material_id, 10) !== 1;
    },
    material() {
      if (this.materials[this.drawingConfiguration.material_id]) {
        return this.materials[this.drawingConfiguration.material_id];
      }
      return null;
    },
    isInCart() {
      return _has(this.drawingConfiguration.draftOrders, this.$store.state.cart.orderId);
    },
    // timesInCart() {
    //   return this.drawingConfiguration.draftOrders[this.$store.state.cart.orderId];
    // },
    // priorities() {
    //   return Object.values(this.$store.state.priorities);
    // },
    materials() {
      return this.$store.state.materials;
    },
    // drawingConfigurationCount() {
    //   return Object.keys(this.$store.state.dc.drawingConfiguration).length;
    // },
    drawingConfiguration() {
      return this.$store.state.dc.drawingConfiguration[parseInt(this.id, 10)];
    },
    height() {
      if (!_has(this.drawingConfiguration, 'Drawing.dimensions.height')) {
        return '';
      }
      return parseFloat(this.drawingConfiguration.Drawing.dimensions.height)
        .toFixed(0);
    },
    width() {
      if (!_has(this.drawingConfiguration, 'Drawing.dimensions.width')) {
        return '';
      }
      return parseFloat(this.drawingConfiguration.Drawing.dimensions.width)
        .toFixed(0);
    },
    hasSize() {
      return this.width !== '' && this.width !== '0' && this.height !== '' && this.height !== '0';
    },
    pngFile() {
      return `${this.$config.apiUrl}/drawing/pngsmall/${this.drawingConfiguration.drawing_id}`;
    },
    kerfWidth() {
      return parseFloat(this.kerfWidth);
    },
    createdDate() {
      return new Date(this.drawingConfiguration.created_at).toLocaleDateString(this.$i18n.locale, {
        year: 'numeric', month: '2-digit', day: '2-digit',
      });
    },
    priceLabel() {
      const minPrice = parseFloat(this.getPrice(-1, this.showVat));
      const maxPrice = parseFloat(this.getPrice(0, this.showVat));
      const diff = Math.round(((maxPrice - minPrice) / maxPrice) * 100);
      const qty = this.priceQty;

      let qtyTable = '';
      qty.forEach((amount, idx) => {
        const price = this.getPrice(idx, this.showVat);
        qtyTable += `${amount} - &euro; ${price} &#10;&#13;`;
      });

      // TODO: check if this needs a translation
      if (this.showNestedPrice) {
        return `De goedkoopste prijs bereikt bij afname van > ${qty[qty.length - 1]} \n ${qtyTable}`;
      }
      return `Bij afname van 1 stuks, prijs kan tot ${diff}% goedkoper bij `
          + `afname van meerdere aantallen \n ${qtyTable}`;
    },
    cartRouteParams() {
      return {
        orderId: this.$store.state.cart.orderId,
        lang: this.$route.params.lang,
      };
    },
    editorRoute() {
      return {
        name: 'products-list-me-editor',
        params: {
          orderId: this.$route.params.orderId,
          lang: this.$route.params.lang,
          productId: this.id,
        },
      };
    },
  },
  methods: {
    deleteDrawingConfiguration() {
      if (this.deleteLoading) {
        return;
      }

      if (!this.drawingConfiguration.locked) {
        this.deleteDrawingConfigurationById(this.id);
        return;
      }

      this.$bvModal.msgBoxConfirm(this.$i18n.t('drawing_configuration.well_delete_your_drawings'), {
        title: this.$i18n.t('drawing_configuration.delete_drawing'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$i18n.t('drawing_configuration.delete'),
        cancelTitle: this.$i18n.t('drawing_configuration.cancel'),
        footerClass: 'p-2',
        hideHeaderClose: true,
        centered: true,
      }).then((isConfirmed) => {
        if (!isConfirmed) { return; }
        this.deleteDrawingConfigurationById(this.id);
      }).catch(() => {});
    },
    deleteDrawingConfigurationById(id) {
      this.deleteLoading = true;
      const request = this.$store.dispatch(
        'deleteConfigurations',
        {
          ids: [id],
          orderId: this.$store.state.cart.orderId,
        },
      );
      request.then(() => {
      }).finally(() => {
        this.deleteLoading = false;
      });
    },
    ordersMatchesString(text) {
      return this.drawingConfiguration.OrderDrawing.find((od) => od.order_id.string.indexOf(text) !== -1);
    },
    addToOrder() {
      this.addToOrderLoading = true;
      this.$store.dispatch('addDrawingConfiguration', {
        dcId: this.id,
        orderId: this.orderId,
      })
        .finally(() => {
          // this.$eventBus.$emit('gtm.add_to_cart', [this.id]);
          this.addToOrderLoading = false;
        });
    },
    showSelectMaterial() {
      this.$emit('showSelectMaterial', this);
      return false;
    },

    getPrice(idx, showVat) {
      let id = idx;
      const vatMultiplier = showVat ? (this.$config.vat + 1) : 1;
      if (idx < 0) {
        const obj = _get(this.drawingConfiguration, `pricerange[${this.priority}]`, null);
        if (obj === null) {
          return '-';
        }
        id = obj.length + idx;
      }
      // const objStr = `pricerange[${this.priority}][${id}].total`;
      const value = _get(this.drawingConfiguration, `pricerange[${this.priority}][${id}].total`, null);
      if (value !== null) {
        return (Math.round((value * vatMultiplier)) / 100).toFixed(2);
      }

      return '-';
    },
    toggleSelected() {
      this.$emit('selected', !this.selected);
    },
  },
};
</script>

<style lang="scss">
  .dc-list-item {
    border-bottom: 1px dotted #ddd;
    display: grid;
    grid-template-columns: 395px 1fr 450px;
    box-sizing: border-box;

    .drawing-size {
      font-size: 0.7rem;
      font-weight: bold;
    }

    &:hover {
      background-color: rgb(250,250,250);
    }

    &.selected {
      background-color: rgba(186, 223, 255, 0.25);
      &:hover {
        background-color: rgba(186, 223, 255, 0.4);
      }
    }

    .dc-list-item-price {
      .btn {
        width: 150px;
      }
    }

    .order-list {
      padding-left: 0;
    }

    .dc-list-item-image {
      img {
        max-height: 200px;
        width: auto;
        max-width: 100%;
      }
    }

    .dc-list-item-select .custom-control.custom-checkbox {
      min-height: auto;
    }

    .dc-list-item-select,
    .dc-list-item-id,
    .dc-list-item-specification {
      cursor: pointer;
    }

    // .dc-list-item-actions .row-actions {
    //   width: 100%;
    // }

    .dc-list-item-specification {
      // padding: 17.5px 10px 10px;
      overflow-wrap: anywhere;
    }
  }

  .btn-empty {
    border-bottom: 1px solid #eeeeee;
  }

  .dc-list-item-first-column {
    display: grid;
    grid-template-columns: 75px 100px 220px;
  }

  .dc-list-item-first-column > .dc-list-item-select {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .dc-list-item-first-column > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .dc-list-item-image {
    padding: 10px;
    display: flex;
    justify-content: center;
    cursor: zoom-in;
  }

  .dc-list-item-last-column {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 165px 150px 170px;
  }

  .dc-list-item-last-column > * {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  // @media (min-width: 1200px) {
  //   .dc-list-item {
  //     padding-right: 15px;
  //   }
  // }

  // @media (max-width: 1200px) {
  //   .dc-list-item {
  //     display: grid;
  //     grid-template-columns: 395px 1fr 300px;
  //   }
  //   .dc-list-item-last-column {
  //     display: grid;
  //     align-items: center;
  //     justify-content: center;
  //     grid-template-columns: 165px 125px;
  //     grid-template-rows: 1fr 1fr;
  //   }
  //   .dc-list-item-cart {
  //     grid-column: 1 / 2;
  //     grid-row: 1 / 2;
  //     /*border: 1px solid;*/
  //     align-items: flex-end;
  //     padding-bottom: 15px;
  //   }
  //   .dc-list-item-price {
  //     grid-column: 1 / 2;
  //     grid-row: 2 / 3;
  //     display: flex;
  //     align-items: flex-start;
  //     /*border: 1px solid;*/

  //   }
  //   .dc-list-item-actions {
  //     grid-column: 2 / 3;
  //     grid-row: 1 / 3;
  //   }
  // }

  // @media (max-width: 992px) {
  //   .dc-list-item {
  //     display: grid;
  //     grid-template-columns: 220px 1fr auto;
  //   }
  //   .dc-list {
  //     margin: 0;
  //   }

  //   .dc-list-item-first-column {
  //     grid-template-columns: 65px 170px;
  //     grid-template-rows: 50px 1fr;
  //   }

  //   .dc-list-item-first-column > .dc-list-item-select {
  //     justify-content: flex-start;
  //   }
  //   .dc-list-item-last-column {
  //     // display: grid;
  //     // grid-template-columns: 1fr;
  //     // grid-template-rows: 1fr 1fr;
  //     display: flex;
  //     flex-direction: column;
  //     // align-content: flex-end;
  //     align-items: flex-end;
  //     // justify-content: flex-end;
  //     justify-items: flex-end;
  //     border: 1px solid red;
  //   }
  //   .dc-list-item-price {
  //     height: auto;
  //     justify-self: flex-end;
  //     // grid-row: 1;
  //     // grid-column: 1;
  //   }
  //   .dc-list-item-actions {
  //     height: auto;
  //     justify-self: flex-end;
  //     // grid-row: 2;
  //     // grid-column: 1;
  //   }
  //   .dc-list-item-first-column > .dc-list-item-id {
  //     grid-row: 1 / 2;
  //     grid-column: 2 / 3;
  //     display: flex;
  //     justify-content: flex-start;
  //   }

  //   .dc-list-item-first-column > .dc-list-item-image {
  //     grid-row: 2 / 3;
  //     grid-column: 1 / 3;
  //     display: flex;
  //     padding: 10px 10px 20px;
  //   }
  // }
  @media (min-width: 1201px) {
    .dc-list-item {
      display: grid;
      grid-template-columns: 30px 150px 1fr 200px;
      grid-template-rows: 30px auto 1fr;
    }
    .dc-list-item-select {
      grid-column: 1;
      grid-row: 1;
      width: 30px;
    }
    .dc-list-item-id {
      grid-column: 2;
      grid-row: 1;
      justify-self: flex-start;
      padding: 0 !important;
      padding: 0;
    }
    .dc-list-item-specification {
      grid-column: 3;
      grid-row: 2/4;
      padding: 0 !important;
    }
    .dc-list-item-image {
      grid-column: 1/3;
      grid-row: 2/4;
      display: flex;
      align-items: flex-start;
    }

    .dc-list-item-price {
      grid-column: 3;
      grid-row: 3;
      margin-top: 20px;
    }
    .dc-list-item-actions {
      grid-column: 4;
      grid-row: 3;
      margin-top: 10px;
      margin-bottom: 10px;
      justify-self: flex-end;
    }
    .dc-list-item-cart {
      grid-column: 4;
      grid-row: 2;
      justify-self: flex-end;
      padding: 0;
    }
  }
  @media (max-width: 1200px) {
    .dc-list-item {
      display: grid;
      grid-template-columns: 30px 150px 1fr 200px;
      grid-template-rows: 30px auto 1fr;
    }
    .dc-list-item-select {
      grid-column: 1;
      grid-row: 1;
      width: 30px;
    }
    .dc-list-item-id {
      grid-column: 2;
      grid-row: 1;
      justify-self: flex-start;
      padding: 0 !important;
      padding: 0;
    }
    .dc-list-item-specification {
      grid-column: 3;
      grid-row: 2/4;
      padding: 0 !important;
    }
    .dc-list-item-image {
      grid-column: 1/3;
      grid-row: 2/4;
      display: flex;
      align-items: flex-start;
    }

    .dc-list-item-price {
      grid-column: 3;
      grid-row: 3;
      margin-top: 20px;
    }
    .dc-list-item-actions {
      grid-column: 4;
      grid-row: 3;
      margin-top: 10px;
      margin-bottom: 10px;
      justify-self: flex-end;
    }
    .dc-list-item-cart {
      grid-column: 4;
      grid-row: 2;
      justify-self: flex-end;
      padding: 0;
    }
  }
  @media (max-width: 992px) {
    .dc-list-item {
      display: grid;
      grid-template-columns: 30px 150px 1fr;
      grid-template-rows: 30px 1fr;
    }
    .dc-list-item-select {
      grid-column: 1;
      grid-row: 1;
      width: 30px;
    }
    .dc-list-item-id {
      grid-column: 2;
      grid-row: 1;
      justify-self: flex-start;
      padding: 0 !important;
      padding: 0;
    }
    .dc-list-item-specification {
      grid-column: 3/4;
      grid-row: 2/3;
      padding: 0 !important;
    }
    .dc-list-item-image {
      grid-column: 1/3;
      grid-row: 2/4;
      display: flex;
      align-items: flex-start;
    }

    .dc-list-item-price {
      grid-column: 3/4;
      grid-row: 3;
      margin-top: 20px;
    }
    .dc-list-item-actions {
      grid-column: 3/4;
      grid-row: 5;
      margin-top: 10px;
      margin-bottom: 10px;
      justify-self: flex-end;
    }
    .dc-list-item-cart {
      grid-column: 3/4;
      grid-row: 4;
      justify-self: flex-end;
      padding: 0;
    }
  }

  @media (max-width: 768px) {
    .dc-list-item {
      display: grid;
      grid-template-columns: 30px 150px 1fr;
      grid-template-rows: 30px 1fr;
    }
    .dc-list-item-select {
      grid-column: 1;
      grid-row: 1;
      width: 30px;
    }
    .dc-list-item-id {
      grid-column: 2;
      grid-row: 1;
      justify-self: flex-start;
      padding: 0 !important;
      padding: 0;
    }
    .dc-list-item-specification {
      grid-column: 3/4;
      grid-row: 2/3;
      padding: 0 !important;
    }
    .dc-list-item-image {
      grid-column: 1/3;
      grid-row: 2/4;
      display: flex;
      align-items: flex-start;
    }

    .dc-list-item-price {
      grid-column: 3/4;
      grid-row: 3;
      margin-top: 20px;
    }
    .dc-list-item-actions {
      grid-column: 3/4;
      grid-row: 5;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .dc-list-item-cart {
      grid-column: 3/4;
      grid-row: 4;
      justify-self: flex-end;
      padding: 0;
    }
  }

  // @media (max-width: 576px) {
  //   .dc-list-item-first-column > .dc-list-item-select {
  //     display: none;
  //   }
  //   .dc-list-item-first-column {
  //     padding-left: 15px !important;
  //   }
  //   .dc-list-item-first-column > .dc-list-item-id {
  //     justify-content: flex-start;
  //     grid-column: 1/2;
  //   }

  //   .dc-list-item {
  //     font-size: 14px;
  //     display: grid;
  //     grid-template-columns: minmax(160px, 1fr) 2fr;
  //   }

  //   .dc-list-item-first-column {
  //     grid-template-columns: 50px 1fr;
  //   }

  //   .dc-list-item-last-column {
  //     grid-template-columns: minmax(160px, 1fr) 2fr;
  //     padding-right: 5px;
  //   }

  //   .dc-list-item-price, .dc-list-item-cart {
  //     padding-right: 5px;
  //     padding-left: 5px;
  //   }

  //   .dc-list-item-actions {
  //     padding-left: 5px;
  //   }
  //   .dc-list-item-specification {
  //     padding-left: 0;
  //   }

  //   .dc-list-item-actions {
  //     padding: 0;
  //     margin: 0;
  //   }
  // ;

  //   .dc-list-item-first-column {
  //     grid-template-columns: 50px 1fr;
  //   }

  //   .dc-list-item-image {
  //     width: 100%;

  //   }

  //   .card-header.sidecolumn {
  //     display: none;
  //   }
  //   .card.p-3.m-3.text-center.btn {
  //     display: none;
  //   }

  // }

  @media (max-width: 576px) {
    .dc-list-item {
      display: grid;
      grid-template-columns: 30px 1fr;
      grid-template-rows: 30px 1fr;
    }
    .dc-list-item-select {
      grid-column: 1;
      grid-row: 1;
      width: 30px;
    }
    .dc-list-item-id {
      grid-column: 2;
      grid-row: 1;
      justify-self: flex-start;
      padding: 0 !important;
      padding: 0;
    }
    .dc-list-item-specification {
      grid-column: 1/3;
      grid-row: 3;
      padding: 0 !important;
    }
    .dc-list-item-image {
      grid-column: 1/3;
      grid-row: 2;
      display: flex;
      align-items: flex-start;
    }

    .dc-list-item-price {
      grid-column: 1/3;
      grid-row: 4;
      margin-top: 20px;
    }
    .dc-list-item-actions {
      grid-column: 1/3;
      grid-row: 6;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .dc-list-item-cart {
      grid-column: 1/3;
      grid-row: 5;
      justify-self: flex-end;
      padding: 0;
    }
  }
  /*
  .dc-list-item-id
  .dc-list-item-image
  .dc-list-item-specification
  .dc-list-item-price
  .dc-list-item-cart
  .dc-list-item-actions*/
</style>
