<template>
    <div class="contentful-header-with-three-columns mb-5 px-3">
      <h2 v-if="data.title" class="display-4">{{data.title}}</h2>
      <div v-if="data.mainText" class="main-text" v-html="htmlString(data.mainText)"></div>
      <div class="columns-container">
        <div v-for="col in data.columns" :key="col.sys.id" class="column mt-5">
          <img
            loading="lazy"
            :src="col.fields.image.fields.file.url"
            :srcset="`${col.fields.image.fields.file.url} 100w`"
            sizes="100px"
            :alt="col.fields.image.fields.description"
            class="rounded"
            width="100" height="100" />
          <h3>{{col.fields.title}}</h3>
          <p>{{col.fields.text}}</p>
        </div>
      </div>
    </div>
</template>

<script>

import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

export default {
  props: {
    data: {
      type: Object,
      require: true,
    },
    bgColor: {
      type: String,
      default: '#ffffff',
    },
    removeBeforeDestroy: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    htmlString(text) {
      return documentToHtmlString(text);
    },
  },
  beforeDestroy() {
    if (this.removeBeforeDestroy) {
      this.$root.$el.parentNode.removeChild(this.$root.$el);
    }
  },
};
</script>

<style lang="scss">
  .contentful-header-with-three-columns {
    max-width: 1269px;
    margin: auto;
    padding: 3rem 0 0 0;

    h2 {
      font-size: 1.8rem;
      // line-height: 54px;
      font-weight: 700;
      // text-align: center;
    }
    .main-text p{
      font-size: 18px;
      // text-align: center;
      font-weight: 300;
      line-height: 30px;
    }

    .columns-container {
      display: flex;
      justify-content: space-around;
      @media(max-width:992px) {
        flex-wrap: wrap;
        justify-items: center;
      }

      .column {
        max-width: 450px;
        padding: 40px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        img {
          max-width: 300px;
        }

        h3 {
          font-size: 24px;
          line-break: 36px;
          font-weight: 300;
        }

        p {
          font-size: 18px;
          font-weight: 300;
          line-height: 30px;
        }
      }
    }
  }
</style>
