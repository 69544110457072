<template>
  <div v-if="priority && ready" class="dc-editor">

    <div class="row mt-2">

        <div class="col-12 col-md-12 col-xl-8 d-xl-flex pr-xl-0">

          <div class="col-md-12 col-xl-4 mb-3 px-0">
            <div v-if="(!isMobile || !showMobilePreview)"
              class="card shadow-1 feedback-container p-1">
              <dc-editor-feedback
                :has-error="hasError"
                :feedback="feedback"
                :height="height"
                :id="id"
                :show-feedback="showFeedback"
                :has-cutting="hasCutting"
                :has-vector-engrave="hasVectorEngrave"
                :has-raster-engrave="hasRasterEngrave"
                :has-ignored="hasIgnored"
                :drawing-check="drawingCheck"
                :width="width" />

              <div v-if="hasUndeliverableMaterial"
                class="alert mb-0"
                :class="{'bg-warning': hasMaterial, 'bg-light': !hasMaterial}">
                <div v-if="hasMaterial">
                  {{$t('editor.the_material')}} <strong>{{ material.name }}</strong> {{$t('editor.is_no_longer_available')}}
                  <div v-if="storeData.locked">
                    {{$t('editor.create_a_copy_to_select_an_alternative_material')}}
                  </div>
                  <div v-else>
                    <br>{{$t('editor.select_a_different_material')}}
                  </div>
                </div>

                <div class="mt-0" v-if="storeData.locked">
                  <loading-button
                    :block="false"
                    :loading="copyLoading"
                    variant="primary"
                    size="sm"
                    class="mt-1"
                    @click="duplicate">{{$t('editor.copy_design')}}
                  </loading-button>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 col-xl-8 px-0 px-xl-3 mb-xl-3">
            <div class="card shadow-1 preview-container">
              <div class="preview">
                <drawing-svg-viewer
                  v-if="showSvgDrawing"
                  :svg="svgFile"
                  :width="width"
                  :height="height"
                  :has-cutting="hasCutting"
                  :has-raster-engrave="hasRasterEngrave"
                  :has-vector-engrave="hasVectorEngrave"
                  :has-ignored="hasIgnored"
                  @loading="drawingLoading=true"
                  @loaded="drawingLoading=false" />

                <div class="fallback-container" v-else>
                    <h3>{{$t('drawing_configuration.this_drawing_contains_a_large_amount_of_lines')}}</h3>
                    <p>{{$t('drawing_configuration.the_live_preview_might_slow_down_your_browser_and_has_been_disabled')}}<br>
                      {{$t('drawing_configuration.download_the_pdf_preview')}}</p>
                    <button class="btn btn-sm btn-primary mr-2" @click="download('pdf')">
                      {{$t('drawing_configuration.download_pdf_preview')}}</button>
                    <button class="btn btn-sm" @click="forcePreview = true">
                      {{$t('drawing_configuration.load_interactive_preview')}}
                    </button>
                    <img :src="pngFile" class="fallback-image" /><br />
                </div>

                <div v-if="$store.getters.isAdmin"
                  class="download m-1">

                  <a :href="getUrl('orig')"
                    class="btn btn-xs btn-admin"
                    target="_blank">
                    <i class="material-icons">get_app</i>{{$t('drawing_configuration.original_drawing')}}</a>

                  <a :href="getUrl('pdf')"
                    class="btn btn-xs btn-admin"
                    target="_blank">
                      <i class="material-icons">get_app</i>{{$t('drawing_configuration.pdf_preview')}}</a>

                  <b-button v-if="false"
                    variant="light"
                    size="xs"
                    class="btn btn-xs btn-admin"
                    @click="download('svg')">
                    <i class="material-icons">get_app</i>{{$t('drawing_configuration.svg')}}
                  </b-button>

                  <b-button
                    variant="light"
                    size="xs"
                    class="btn btn-xs btn-admin"
                    @click="download('rhino')">
                    <i class="material-icons">get_app</i>{{$t('drawing_configuration.rhino')}}
                  </b-button>
                </div>
              </div>
            </div>
          </div>

        </div>

        <!-- col2 -->
        <div class="col-md-12 mt-3 col-xl-4 mt-xl-0 pl-xl-0">
          <div v-if="(!isMobile || !showMobilePreview)" class="material-container">

            <div class="card shadow-1 mb-3">
              <material-select-drilldown
                ref="materialDrilldown"
                :dc-id="dcId"
                :has-material="hasMaterial"
                :drawing-width="width"
                :drawing-height="height"
                :product-loading="productLoading"
                :loading="loading"
                :show-vat="showVat"
                :copyLoading="copyLoading"
                :update-material="updateMaterial"
                :material="material"
                :hasUndeliverableMaterial="hasUndeliverableMaterial"
                @duplicate="duplicate"
                @update-material="updateMaterial"
                @update-drawing-products="updateDrawingProducts" />
            </div>

            <div class="card shadow-1 mb-3" v-if="material && material.is_published && !hasError">
              <div class="cart-main-block">
                <div class="card-title d-flex bg-light p-0 px-2">
                  <span v-if="orderDrawing" class="flex-start">{{$t('editor.quantity')}}</span>
                  <span v-else class="flex-start">{{$t('editor.price_per_piece')}}</span>
                </div>
              </div>

              <div class="border-top card-body">
                <div v-if="orderDrawing && storeData.approvable && !hasUndeliverableMaterial"
                  class="pricing-headers-container d-flex justify-content-between border-bottom px-2 pt-1 pb-1">
                  <span class="text-muted text-center" style="flex: 3">{{ $t('editor.quantity') }}</span>
                  <span style="flex: 1"></span>
                  <span class="text-muted text-center" style="flex: 3">{{ $t('editor.price_per_piece') }}</span>
                  <span style="flex: 1"></span>
                  <span class="text-muted text-center" style="flex: 3">{{ $t('editor.total') }}</span>
                </div>

                <div v-if="storeData.approvable && !hasUndeliverableMaterial" class="pricing-container p-2">

                  <cart-list-item-quantity-widget v-if="!!orderDrawing"
                    ref='cartlistitemquantitywidget'
                    :id="parseInt(orderDrawing.id, 10)"
                    productType="OrderDrawing"
                    :quantity="getQuantity"/>

                  <span v-if="orderDrawing" class="symbol-container text-center">×</span>

                  <drawing-configuration-price-button
                    :id="id"
                    :priority-id="priority.id"
                    :show-vat="showVat"
                    :show-cart="!!orderDrawing"
                    :show-nested-price="!orderDrawing"
                    :use-modal="true"
                    :show-in-place="false"
                    :material-id="materialId" />

                  <span v-if="orderDrawing" class="symbol-container text-center">=</span>

                  <div v-if="!!orderDrawing && hasMaterial" class="price-total-container text-center">
                    <div class="price-total-amount mb-1 mt-3">{{ cartPriceTotalExclVat }}</div>
                    <div class="price-total-amount-vat-msg text-muted">
                      {{ cartPriceTotal }} {{$t('editor.incl')}}</div>
                  </div>
                </div>

                <div class="editor-add-to-cart mt-1">
                  <template v-if="!orderDrawing && !hasError">
                    <div v-if="!hasMaterial"
                      class="text-muted text-right text-warning pt-1">{{$t('editor.select_a_material_to_see_its_price')}}
                      <!-- TODO: hide this button if not available -->
                    </div>
                    <div v-else class="text-muted p-1">
                      {{$t('editor.the_exact_price_depends_on_selected_quantity_and_delivery_moment')}}
                      <!-- TODO: hide this button if not available -->
                    </div>

                    <loading-button
                      v-if="!orderDrawing && material.is_published"
                      :loading="addToCartLoading"
                      :variant="hasMaterial && drawingCheck ? 'primary' : 'light'"
                      size="sm"
                      class="mt-2"
                      stroke-color="#fff"
                      @click="addToCart">
                      <i class="material-icons">addshopping_cart</i>
                    </loading-button>
                  </template>

                  <div v-if="orderDrawing && hasError" class="btn btn-sm btn-warning" @click="deleteOrderDrawing">
                    <i class="material-icons">delete</i> {{$t('editor.remove_from_cart')}}
                  </div>
                </div>

                <div v-show="showCustomQuoteMesage" class="alert alert-gray mb-2">
                  {{ $t('drawingConfiguration.priceMatrix.for_this_quantity_we_like_to_create_a_custom_quote')}}
                  <!--
                    <b-button variant="outline-dark" class="d-block custom-quote-btn mt-2" @click.prevent="openRequestQuoteModal">
                    {{ $t('drawingConfiguration.priceMatrix.request_a_custom_quote')}}
                  </b-button>
                  -->
                  <div v-html="$t('drawingConfiguration.priceMatrix.mail_for_a_custom_quote')" />

                </div>

                <div class="d-flex justify-content-center pb-2 pt-3">
                  <b-button v-show="!showBulk && orderDrawing"
                    class="px-5"
                    size="xs"
                    variant="light"
                    @click.prevent="showBulkInfo()">
                    {{$t('editor.view_batch_discount')}} <i class="material-icons">expand_more</i>
                  </b-button>
                </div>

                <div v-show="showBulk" class="bulk-discount">
                  <drawing-configuration-price-matrix
                    :id="dcId"
                    :priority-id="priority.id"
                    :show-vat="showVat"
                    @setOrderDrawingQuantity="setOrderDrawingQuantity" />
                  <b-button variant="flat" class="w-100" @click="hideBulkInfo()">
                    <i class="material-icons">expand_less</i>
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    <dc-editor-bottom-navigation
      class="mt-3"
      :current-index="currentIndex"
      :is-cart="isCart"
      :has-valid-configuration="hasValidConfiguration"
      :has-next="next !== null"
      :next-route-params="nextRouteParams"
      :has-previous="previous !== null"
      :previous-route-params="previousRouteParams"
      :queueLength="queue.length"/>
  </div>
</template>

<script>
import _get from 'lodash/get';

import LoadingButton from 'src/components/tools/LoadingButton';
// import DrawingProductList from 'src/components/drawingConfiguration/DrawingProductList';
// import MaterialSelector from 'src/components/material/MaterialSelector';
import MaterialSelectDrilldown from 'src/components/material/MaterialSelectDrilldown';
// import MaterialSelectedOverview from 'src/components/material/MaterialSelectedOverview';
import DrawingConfigurationPriceButton from 'src/components/drawingConfiguration/DrawingConfigurationPriceButton';
import CartListItemQuantityWidget from 'src/components/cart/edit/CartListItemQuantityWidget';
import DrawingSvgViewer from 'src/components/drawing/DrawingSvgViewer';
import DcEditorFeedback from 'src/components/dcEditor/DcEditorFeedback';
// import MaterialSelectorNative from 'src/components/material/MaterialSelectorNative';
import DcEditorBottomNavigation from 'src/components/dcEditor/DcEditorBottomNavigation';
import DrawingConfigurationPriceMatrix from 'src/components/drawingConfiguration/DrawingConfigurationPriceMatrix';

export default {
  components: {
    DcEditorBottomNavigation,
    // MaterialSelectorNative,
    // MaterialSelectedOverview,
    DcEditorFeedback,
    DrawingSvgViewer,
    CartListItemQuantityWidget,
    DrawingConfigurationPriceButton,
    DrawingConfigurationPriceMatrix,
    // MaterialSelector,
    // DrawingProductList,
    LoadingButton,
    // LoadingWidget,
    MaterialSelectDrilldown,
  },
  props: {
    visible: {
      type: Boolean,
      default: null,
    },
    // This is is only the initial parameter. the new Id will be dcId.
    id: {
      type: Number,
      required: true,
    },
    isCart: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      forcePreview: false,
      showMobilePreview: false,
      showFeedback: false,
      copyLoading: false,
      materialLoading: false,
      productLoading: false,
      drawingLoading: false,
      addToCartLoading: false,
      delta: 1.0, // TODO: not sure if this serves any purpose?
      batch: false,
      showBulk: false,
    };
  },
  computed: {
    ready() {
      return this.$store.getters.loaded([
        'drawingProducts',
        'errors',
      ]);
    },
    showCustomQuoteMesage() {
      return this.getQuantity > this.largestQuantity;
    },
    largestQuantity() {
      const quantities = this.$store.getters.nestingQty(this.dcId).slice();
      return quantities[quantities.length - 1];
    },
    isMobile() {
      return this.svgViewerSizes.includes(this.$mq);
    },
    svgViewerSizes() {
      return ['xs', 'sm', 'md'];
    },
    showDownload() {
      return this.$store.state.settings.status === true && false;
    },
    showSvgDrawing() {
      return this.forcePreview || this.svgObjectCount < 10000;
    },
    svgObjectCount() {
      const data = _get(this.storeData, 'Drawing.dimensions.svgElementCount');
      if (data === undefined) { return -1; }
      return data;
    },
    orderDrawingsSorted() {
      return this.storeData.OrderDrawing.concat()
        .sort((a, b) => (parseInt(a.id, 10) < parseInt(b.idm, 10) ? 1 : -1));
    },
    hasError() {
      return this.storeData.feedback.some((f) => f.type === 'ERROR');
    },
    hasUndeliverableMaterial() {
      // return this.storeData.locked && (this.hasMaterial && !this.materialIsPublished);
      return this.hasMaterial && !this.materialIsPublished;
    },
    materialIsPublished() {
      if (this.material) {
        return this.material.is_published;
      }
      return false;
    },
    dcId() {
      return this.id;
    },
    feedback() {
      return this.storeData.feedback;
    },
    drawingCheck: {
      get() {
        return this.storeData.feedback.length === 0 || this.storeData.feedback.every((v) => v.is_checked === true);
      },
    },
    loading() {
      return this.copyLoading || this.materialLoading || this.drawingLoading || this.productLoading;
    },
    storeData() {
      return this.$store.state.dc.drawingConfiguration[this.dcId];
    },
    priority() {
      return this.$store.getters.selectedPriority;
    },
    width() {
      return Math.round(this.storeData.Drawing.dimensions.width);
    },
    height() {
      return Math.round(this.storeData.Drawing.dimensions.height);
    },
    showVat() {
      return this.$store.state.settings.showVat;
    },
    cartPriceTotal() {
      if (this.orderDrawing && this.hasMaterial) {
        return this.$config.formatPrice(this.orderDrawing.costs.total, true, true);
      }
      return '-';
    },
    cartPriceTotalExclVat() {
      if (this.orderDrawing && this.hasMaterial) {
        return this.$config.formatPrice(this.orderDrawing.costs.total, false, true);
      }
      return '-';
    },
    hasVectorEngrave() {
      return _get(this.totals, ['vector-engrave', 'length'], 0) > 0;
    },
    hasCutting() {
      return _get(this.totals, ['vector-cut', 'length'], 0) > 0;
    },
    hasRasterEngrave() {
      return _get(this.totals, ['raster-engrave', 'length'], 0) > 0;
    },
    hasIgnored() {
      return _get(this.totals, ['ignored', 'length'], 0) > 0;
    },
    totals() {
      return _get(this.storeData, 'Drawing.dimensions.totals', {
        ignored: { length: 0 },
        'raster-engrave': { length: 0 },
        'vector-cut': { length: 0 },
        'vector-engrave': { length: 0 },
      });
    },
    material() {
      if (this.storeData.material_id in this.$store.state.materials) {
        return this.$store.state.materials[this.storeData.material_id];
      }
      return null;
    },
    orderDrawing() {
      const drawings = Object.values(this.$store.state.cart.orderDrawings)
        .filter((od) => od.drawingConfigurationId === this.dcId);
      if (drawings.length > 0) {
        return drawings[0];
      }
      return false;
    },
    svgFile() {
      return `${this.$config.apiUrl}/drawing/svg/${this.storeData.Drawing.id}`;
    },
    pngFile() {
      return `${this.$config.apiUrl}/drawing/png/${this.storeData.Drawing.id}`;
    },
    hasMaterial() {
      return !!this.material && parseInt(this.material.id, 10) !== 1;
    },
    materialId() {
      if (!this.hasMaterial) {
        return null;
      }
      return parseInt(this.material.id, 10);
    },
    hasValidConfiguration() {
      return (this.hasMaterial && (this.storeData.approvable && this.storeData.approved));
    },
    queue() {
      if (this.isCart) {
        return Object.values(this.$store.state.cart.orderDrawings)
          .map((item) => item.drawingConfigurationId);
      }
      return this.$store.state.dc.drawingConfigurationFilter;
    },
    currentIndex() {
      return this.queue.indexOf(this.dcId);
    },
    previous() {
      if (this.currentIndex === 0) {
        return null;
      }
      return this.queue[this.currentIndex - 1];
    },
    previousRouteParams() {
      return {
        name: this.$route.name,
        params: {
          lang: this.$route.params.lang,
          orderId: this.$route.params.orderId,
          productId: this.previous,
        },
      };
    },
    next() {
      if (this.currentIndex === this.queue.length - 1) {
        return null;
      }
      return this.queue[this.currentIndex + 1];
    },
    nextRouteParams() {
      return {
        name: this.$route.name,
        params: {
          lang: this.$route.params.lang,
          orderId: this.$route.params.orderId,
          productId: this.next,
        },
      };
    },
    getQuantity() {
      return parseInt(this.orderDrawing.quantity, 10);
    },
  },
  watch: {
    id(value, oldvalue) {
      if (value !== oldvalue) {
        Object.assign(this.$data, this.$options.data());
      }
    },
  },
  methods: {
    openRequestQuoteModal() {
      this.$eventBus.$emit('snlb.showRequestQuoteModal', {
        dcId: this.dcId,
        // dcReference: `${this.$config.drawingConfigurationPrefix}${this.dcId}`,
        materialName: this.material.pretty_name,
        quantity: this.$refs.cartlistitemquantitywidget.quantity,
      });
    },
    setOrderDrawingQuantity(quantity) {
      this.$refs.cartlistitemquantitywidget.setQuantity(quantity);
    },
    showBulkInfo() {
      this.showBulk = true;
    },
    hideBulkInfo() {
      this.showBulk = false;
    },
    confirmDrawing({ value, promise }) {
      if (value === false) {
        promise.reject();
      }
    },
    deleteOrderDrawing() {
      this.$store.dispatch('deleteOrderDrawing', {
        orderDrawingIds: [this.orderDrawing.id],
        orderId: this.$store.state.cart.orderId,
      });
      // this.$eventBus.$emit('gtm.remove_from_cart', [this.orderDrawing.id]);
    },
    showHelp(name) {
      this.$eventBus.$emit('snlb.showHelp', name);
    },
    duplicate() {
      this.copyLoading = true;
      const self = this;
      this
        .$store
        .dispatch('duplicateConfigurations', {
          ids: [this.storeData.id],
          replaceInOrders: true,
          orderId: this.$store.state.cart.orderId,
        })
        .then((data) => {
          const replaced = data.data.result.drawingConfigurationReplaced;
          if (_get(replaced, this.dcId)) {
            const id = parseInt(replaced[this.dcId], 10);
            const { params } = this.$route;
            params.productId = id;
            this.$router.push({
              name: this.$route.name,
              params,
            });
          }
          this.$nextTick(() => {
            this.copyLoading = false;
          });
        })
        .catch((err) => {
          self.$eventBus.$emit('snlb.error', err);
        })
        .finally(() => { this.copyLoading = false; });
    },
    updateMaterial(newMaterialId) {
      const self = this;
      this.materialLoading = true;
      this
        .$store
        .dispatch(
          'updateMaterial',
          {
            id: [this.storeData.id],
            materialId: newMaterialId,
            orderId: this.$store.state.cart.orderId,
          },
        )
        .catch((err) => {
          self.$eventBus.$emit('snlb.error', err);
        })
        .finally(() => {
          this.materialLoading = false;
          // this.hideMaterialSelector();
          this.$refs.materialDrilldown.hideMaterialSelector();
        });
    },
    updateDrawingProducts(values) {
      this.productLoading = true;
      const self = this;
      this.$store
        .dispatch('updateMaterial', {
          id: [this.storeData.id],
          orderId: this.$store.state.cart.orderId,
          materialId: this.materialId,
          productIds: values,
        })
        .catch((err) => {
          self.$eventBus.$emit('snlb.error', err);
        })
        .finally(() => {
          this.$nextTick(() => {
            this.productLoading = false;
          });
        });
    },
    addToCart() {
      const self = this;
      this.addToCartLoading = true;
      this.$store.dispatch(
        'addDrawingConfiguration',
        {
          dcId: [this.dcId],
          orderId: this.$store.state.cart.orderId,
        },
      )
        .catch((err) => {
          self.$eventBus.$emit('snlb.error', err);
        })
        .finally(() => {
          this.addToCartLoading = false;
        });
    },

    getUrl(val) {
      let url = '';
      switch (val) {
        case 'svg':
          url = `${this.$config.apiUrl}/drawing/svg/${this.storeData.Drawing.id}`;
          break;
        case 'rhino':
          url = `${this.$config.apiUrl}/drawing/rhino/${this.storeData.Drawing.id}`;
          break;
        case 'orig':
          url = `${this.$config.apiUrl}/drawing/orig/${this.storeData.Drawing.id}`;
          break;
        case 'pdf':
          url = `${this.$config.apiUrl}/drawing/pdf/${this.storeData.Drawing.id}`;
          break;
        default:
          break;
      }
      return url;
    },
    download(val) {
      let url = this.getUrl(val);
      url += '?forceDownload=true';
      window.location = url;
    },
    getImage(material, size) {
      const image = _get(material, `images[0].${size}`);
      if (image === undefined) {
        return null;
      }
      return `${this.$config.imageUrl}/${image}`;
    },
  },
};
</script>

<style lang="scss">
.dc-editor {

  // .custom-quote-alert {

  // }

  .custom-quote-btn {
    // font-size: 15px;
    font-size: 0.75rem;
  }

  .material-info {
    height: 80px;
  }

  // .material-category-family {
  //   width: 100%;
  // }

  .fallback-image {
    margin-top: 10px;
    max-width: 100%;
    max-height: 500px
  }
  .pricing-headers-container {
    width: 100%;
    span {
      flex: 3;
    }
  }
  .pricing-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .symbol-container {
      flex: 1;
    }
  }

  .pricebutton-editor {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    align-content: center;
    align-items: center;
    text-align: right;
  }

  .pricebutton-editor.in-cart {
    grid-template-columns: 30px 1fr;

    .btn {
      width: auto;
    }
  }

  .price-total-container {
    flex: 3;
    text-align: right;
  }

  .price-total-amount {
    font-size: 1.5rem;
    font-weight: 400;

    @media (min-width: 1200px) and (max-width: 1400px) {
      font-size: 1.3rem;
    }
  }
  .price-total-amount-vat-msg {
    font-size: 0.7rem;
    // font-size: 14px;
  }

  .fallback-container{
    margin: 1rem;
  }

  .copy-button .btn {
    margin-top: 1rem;
  }

  .ordered-before-container {
    margin-bottom: 1rem;
  }

  .download .btn {
    margin-right: 10px;
   }

  .material-link:link,
  .material-link:active,
  .material-link:visited {
    color: #000;
    text-decoration: underline;
  }
  .material-link:hover {
    text-decoration: none;
  }

  .editor-add-to-cart .btn {
    width: 100%;
  }
}
</style>
