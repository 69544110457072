<template>
  <div :class="rowClasses">
    <div v-if="label"
      class="input-row-label">
      <form-label-widget
        :label="labelValue"
        :id="id"
        :help-link="helpLink"
        :required="required" />

      <async-queue
        slot="after"
        :watch-value="valuePublic"
        :delay="delay"
        @process="update" />

    </div>

    <async-queue v-else
      slot="after"
      :watch-value="valuePublic"
      :delay="delay"
      style="display: none"
      @process="update" />

    <div style="position: relative">
      <i v-if="iconFront !== null"
        class="material-icons input-icon-front">{{iconFront}}</i>
      <b-form-input
        ref="input"
        :id="id"
        :value="valuePublic"
        :class="{inputClass, 'pl-4' : iconFront !== null}"
        :disabled="disabled"
        :readonly="readonly"
        :size="size"
        :type="type"
        :state="currentState"
        :aria-invalid="hasError"
        :placeholder="placeholder"
        :autofocus="autofocus"
        :maxlength="maxLength"
        @input="onInput"
        @change="onChange" />
      <i v-if="icon !== null"
        class="material-icons input-icon">{{icon}}</i>
    </div>
    <form-help-widget
      :error="error"
      :warning="warning"
      :help="help" />
  </div>
</template>

<script>
import FormWidgetBase from 'src/components/form/FormWidgetBase';
import AsyncQueue from 'src/components/tools/AsyncQueue';
import VolatileField from 'src/lib/Volatile';

export default {
  components: {
    AsyncQueue,
  },
  extends: FormWidgetBase,
  mixins: [VolatileField('value')],
  props: {
    value: {
      default: null,
    },
    inputName: {
      default: '',
      type: String,
    },
    taintedValue: {
      default: null,
    },
    type: {
      default: 'text',
      type: String,
    },
    delay: {
      default: 1000,
      type: Number,
    },
    useState: {
      default: true,
      type: Boolean,
    },
    clearTainted: {
      default: false,
      type: Boolean,
    },
    showLabelWhenEmpty: {
      default: true,
      type: Boolean,
    },
    icon: {
      type: String,
      default: null,
    },
    iconFront: {
      type: String,
      default: null,
    },
    maxLength: {
      type: Number,
    },
  },
  data() {
    return {
      preventLoop: false,
    };
  },
  computed: {
    overrideState() {
      if (typeof this.state !== 'undefined') {
        return this.state;
      }
      if (!this.error) {
        return '';
      }
      return this.error.length > 0 ? 'invalid' : '';
    },
    currentState() {
      if (this.error) {
        return false;
      }
      if (this.valueIsTainted) {
        return null;
      }
      if (this.value && this.value.toString().trim().length > 0) {
        return true;
      }
      return null;
    },
    labelVisible() {
      if (this.showLabelWhenEmpty) {
        return true;
      }
      return (this.valuePublic && this.valuePublic.toString().length > 0);
    },
    labelValue() {
      if (this.labelVisible) {
        return this.label;
      }
      return ' ';
    },
  },
  watch: {
    valueTaintedValue(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('update:taintedValue', newValue);
      }
    },
    valueIsTainted(newValue) {
      if (newValue === false) {
        this.valueTaintedValue = null;
      }
    },
    taintedValue(newValue) {
      this.valueTaintedValue = newValue;
    },
    clearTainted(value) {
      if (value === true) {
        this.valueTaintedValue = null;
      }
    },
  },
  methods: {
    onChange(value) {
      this.valueTaintedValue = value;
    },
    onInput(value) {
      this.valueTaintedValue = value;
    },
    update(promise, value) {
      if (this.valueIsTainted) {
        this.$emit('update', {
          promise,
          value,
          inputName: this.inputName,
        });
      } else {
        promise.resolve();
      }
    },
  },
};
</script>

<style lang="scss">
  .input-icon-front:hover {
    cursor: default;
  }
  .input-row-label {
    width: 100%;
    position: relative;
    .queue {
      position: absolute;
      right: 0;
      top: -5px;
    }
  }
</style>
