<template>
  <tr v-if="orderProduct" class="cart-review-table-order-product-item product-row align-top">
    <td>
      <!-- <strong>X{{ dc.id }}</strong> -->
    </td>
    <td>
      {{ translatedName }}
    </td>
    <td class="text-right">
        {{ pricePerPiece }}
    </td>
    <td class="text-right">
      {{ orderProduct.quantity }} &times;
    </td>
    <td class="text-right">
        {{ priceTotal }}
    </td>
  </tr>
  <tr v-else>

  </tr>
</template>

<script>
import _get from 'lodash/get';

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    showVat: {
      type: Boolean,
      required: true,
    },
    showPrices: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    pricePerPiece() {
      return this.$config.formatPrice(this.orderProduct.costs.perPiece, false, true);
    },
    priceTotal() {
      return this.$config.formatPrice(this.orderProduct.costs.total, false, true);
    },
    orderProduct() {
      return this.$store.state.cart.orderProducts[this.id];
    },
    product() {
      return this.orderProduct.productId in this.$store.state.products
        ? this.$store.state.products[this.orderProduct.productId] : null;
    },
    translatedName() {
      if (_get(this.product, `Translation.${this.$i18n.locale}.name`)) {
        return this.product.Translation[this.$i18n.locale].name;
      }
      return 'xxx';
    },
  },
};
</script>

<style>
</style>
