<template>
  <div>
    <orders-loader/>
    <loading-widget-full-width :loading="!ready"/>
    <div v-if="ready" class="order-list">
      <div class="card shadow-1 dc-list">
        <table class="table table-condensed order-table mb-0">
          <thead>
            <tr>
              <th>
                {{$t('orderhistory.order_number')}}
              </th>
              <th>
                {{$t('orderhistory.order_date')}}
              </th>
              <th>
                {{$t('orderhistory.your_reference')}}
              </th>
              <th>
                {{$t('orderhistory.total')}}
              </th>
              <th>
                {{$t('orderhistory.status')}}
              </th>
              <th>
                {{$t('orderhistory.delivery_method')}}
              </th>
              <th>
                {{$t('orderhistory.expected_delivery_date')}}
              </th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            <tr v-if="orders.length == 0">
              <td colspan="10" class="text-center py-4">
                {{$t('orderhistory.you_havent_placed_any_orders_yet')}}</td>
            </tr>

            <tr v-for="(order, key) in ordersWithStatusbar" :key="key">
              <td>
                L{{ order.orderId }}
              </td>
              <td>
                {{ order.acceptedAt }}
              </td>
              <td class="reference-field">
                {{ order.yourReference }}
              </td>
              <td>
                {{ getTotal(order) }}
              </td>
              <td>
                <div>
                  {{ order.barLabel }}
                </div>
                <div>
                </div>
                <b-progress
                  :value="order.barValue"
                  :variant="order.barVariant"/>
              </td>
              <td>
                <div v-if="order.shippingMethod === 'SHIPPING' && !order.shippingTrackingCompany"
                  class="shipping-method badge badge-light badge-pill">
                  {{$t('orderhistory.shipping')}}
                </div>
                <div v-else-if="order.shippingMethod === 'SHIPPING' && order.shippingTrackingCompany"
                  class="shipping-method badge badge-light badge-pill">
                  {{order.shippingTrackingCompany}}
                </div>
                <div v-else class="shipping-method badge badge-light badge-pill">
                  {{$t('orderhistory.pickup')}}
                </div>
              </td>
              <td v-if="order.deliveryDate !== null && order.deliveryDate.match(/^\d+-\d+-\d+/)"
                :class="{'needs-confirmation': order.status === 'SUBMITTED'}">
                {{ formatAcceptedDate(order.deliveryDate.match(/^\d+\-\d+\-\d+/)[0]) }}
              </td>
              <td v-else></td>
              <td>
                <a v-if="order.shippingTrackingUrl" :href="order.shippingTrackingUrl"
                  target="_blank">{{$t('dashboard.track')}}</a>
              </td>
              <td class="actions">
                <router-link v-if="$store.getters.isAdmin"
                  class="btn btn-xs px-1 btn-admin m-0 mb-1"
                  :to="{
                    name:'order-cart',
                    params: {
                      lang: $i18n.locale,
                      orderId: order.orderId,
                      userId: $route.query.userId,
                    }
                  }">{{$t('orderhistory.view_order')}}
                </router-link>

                <router-link
                  class="btn btn-sm btn-info btn-xs px-1 ml-1 mb-1"
                  :to="{
                    name:'products-list-me',
                    params: { lang: $i18n.locale },
                    query: { search: `L${order.orderId}` }
                  }">
                  {{$t('orderhistory.show_designs')}}
                </router-link>

                <router-link
                  v-if="order.status === 'SUBMITTED'"
                  class="btn btn-xs btn-primary mb-1"
                  :to="{
                    name:'order-payment',
                    params: {
                      lang: $i18n.locale,
                      orderId: order.orderId,
                    },
                  }">
                  {{$t('orderhistory.pay')}}
                </router-link>
                <template v-else>
                  <template v-if="order.invoiceId !== null">
                    <a :href="invoiceLink(order)"
                      target="_blank"
                      class="btn btn-sm btn-secondary btn-xs px-1 ml-1 mb-1">
                      {{$t('orderhistory.invoice')}}
                    </a>
                  </template>
                  <button v-else
                    class="btn btn-sm btn-xs disabled px-1 ml-1 mb-1" disabled>
                    {{$t('orderhistory.invoice')}}
                  </button>
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import _orderBy from 'lodash/orderBy';
import _filter from 'lodash/filter';
import _reject from 'lodash/reject';
import OrdersLoader from 'src/components/loader/OrdersLoader';
import LoadingWidgetFullWidth from 'src/components/tools/LoadingWidgetFullWidth';

export default {
  components: {
    LoadingWidgetFullWidth,
    OrdersLoader,
  },
  data() {
    return {};
  },
  computed: {
    showVat() {
      return this.$store.state.settings.showVat;
    },
    ready() {
      return this.$store.getters.loaded([
        'orders',
      ]);
    },
    ordersWithStatusbar() {
      return this.orders.map((order) => {
        switch (order.status) {
          case 'DRAFT':
            return {
              ...order,
              barLabel: this.$t('status_labels.draft'),
              barValue: 0,
              barVariant: 'info',
            };
          case 'SUBMITTED':
            return {
              ...order,
              barLabel: this.$t('status_labels.payment_pending'),
              barValue: 10,
              barVariant: 'success',
            };
          case 'ACCEPTED':
            return {
              ...order,
              barLabel: this.$t('status_labels.payment_received'),
              barValue: 30,
              barVariant: 'success',
            };
          case 'IN_PROCESS':
            return {
              ...order,
              barLabel: this.$t('status_labels.in_production'),
              barValue: 50,
              barVariant: 'success',
            };
          case 'PROCESSED':
            return {
              ...order,
              barLabel: this.$t('status_labels.done'),
              barValue: 75,
              barVariant: 'success',
            };
          case 'SHIPPED':
            return {
              ...order,
              barLabel: this.$t('status_labels.shipped'),
              barValue: 90,
              barVariant: 'success',
            };
          case 'DELIVERED':
            return {
              ...order,
              barLabel: this.$t('status_labels.delivered'),
              barValue: 100,
              barVariant: 'success',
            };
          case 'ON_HOLD':
            return {
              ...order,
              barLabel: this.$t('status_labels.on_hold'),
              barValue: 100,
              barVariant: 'warning',
            };
          case 'CANCELLED':
            return {
              ...order,
              barLabel: this.$t('status_labels.cancelled'),
              barValue: 0,
              barVariant: 'danger',
            };
          case 'EXPIRED':
            return {
              ...order,
              barLabel: this.$t('status_labels.expired'),
              barValue: 0,
              barVariant: 'danger',
            };
          default:
            return {
              ...order,
              barLabel: order.status,
              barValue: 100,
              barVariant: 'danger',
            };
        }
      });
    },
    orders() {
      const nonDraftOrders = _reject(this.$store.state.orders, ['status', 'DRAFT']);
      let submittedOrders = _filter(nonDraftOrders, ['status', 'SUBMITTED']);
      submittedOrders = _orderBy(submittedOrders, [(o) => o.updatedAtTimestamp], ['desc']);
      let otherOrders = _reject(nonDraftOrders, ['status', 'SUBMITTED']);
      otherOrders = _orderBy(otherOrders, [(o) => o.acceptedAtTimestamp], ['desc']);
      return submittedOrders.concat(otherOrders);
    },
    lang() {
      return this.$route.params.lang;
    },
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    priorityNameById(priorityId) {
      if (priorityId in this.$store.state.cart.priorities) {
        return this.$store.state.cart.priorities[priorityId].translation[this.$i18n.locale].name;
      }
      return this.$i18n.t('orderhistory.unknown');
    },
    getTotal(order) {
      return this.$config.formatPrice(order.orderTable.costItems.total, this.showVat, true);
    },
    formatAcceptedDate(date) {
      const options = {
        // weekday: 'short',
        // year: 'numeric',
        // month: 'short',
        // day: 'numeric',
      };
      const event = new Date(date);
      return event.toLocaleDateString(this.$i18n.locale, options);
    },
    formatDeliveryDate(dateObj) {
      const options = {
        // weekday: 'short',
        // year: 'numeric',
        // month: 'short',
        // day: 'numeric',
      };
      const event = new Date(dateObj.date);
      return event.toLocaleDateString(this.$i18n.locale, options);
    },
    invoiceLink(order) {
      return `${this.$config.apiUrl}/document/invoice/${order.invoiceId}`;
    },
    searchLink(order) {
      return `../products?search=L${order.orderId}`;
    },
    createNewOrder() {
      this.$store.dispatch('createNewOrder')
        .then(() => {
          this.$router.push({
            name: 'order-cart',
            params: {
              lang: this.$route.params.lang,
              orderId: this.$store.state.cart.orderId,
            },
          });
        });
    },
    statusLabel(status) {
      switch (status) {
        case 'DRAFT':
          return this.$t('status_labels.draft');
        case 'SUBMITTED':
          return this.$t('status_labels.payment_pending');
        case 'ACCEPTED':
          return this.$t('status_labels.in_production');
        case 'IN_PROCESS':
          return this.$t('status_labels.in_production');
        case 'PROCESSED':
          return this.$t('status_labels.done');
        case 'SHIPPED':
          return this.$t('status_labels.shipped');
        case 'CANCELLED':
          return this.$t('status_labels.cancelled');
        case 'EXPIRED':
          return this.$t('status_labels.expired');
        default:
          return status;
      }
    },
  },
};
</script>
<style lang="scss">

  .order-list {
    .shipping-method {
      font-size: .6rem;
    }

    .progress-bar {
      &::after {
        background-color: rgba(0, 0, 0, 0.2) !important;
      }
    }

    table.order-table {
      border-collapse: collapse;

      tr {
        border-bottom: 1px solid #cccccc;
      }
      th,
      td {
        margin: 0;
        padding: 0;
        border: 0;
        vertical-align: top;
        color: #000000;
      }

      td:last-child,
      th:last-child {
        padding-right: 0;
      }

    .reference-field {
        max-width: 200px;
      }

      thead {
        box-shadow: 0 5px 12px -5px #a3a1a3;
      }

      thead th {
        font-weight: lighter;
        position: sticky;
        top: 130px;
        z-index: 1;
        background-color: #ffffff;
        border-bottom: 2px solid #cccccc;
        margin-bottom: 2px;
        box-shadow: 0 2px 0 0 #cccccc;
      }

      .background-white {
        background-color: white;
      }
    }

    @media (min-width: 1200px) {
      table.order-table {
        td,
        th {
          padding: 0.9rem 1rem;
        }
      }
    }

    @media (min-width: 1000px) {
      table.order-table {
        width: 100%;

        td,
        th {
          padding: 0.4rem 0.3rem;
          height: auto;
        }

        td:last-child,
        th:last-child {
          padding-right: 0.5rem;
        }

        td.actions,
        th.actions {
          text-align: right;

          .btn + .btn {
            margin-top: 0;
            margin-left: 0.5rem;
          }
        }
      }
    }

    @media (max-width: 1000px) {
      table.order-table {
        td:first-child,
        th:first-child {
          padding: 0;
        }

        thead {
          display: none;
        }

        tr {
          display: block;
          padding: 1rem;
        }

        td,
        th {
          padding: 0;
        }

        td {
          display: block;
          height: auto;
        }

        td.actions,
        th.actions {
          display: flex;
          flex-direction: column;
          padding-top: 1rem;

          .btn + .btn {
            margin-top: 0.5rem;
            margin-left: 0;
          }
        }
      }
    }

    .needs-confirmation {
      font-style: italic;
    }
  }
</style>
