<template>
  <div class="drawing-configuration-product-list">

    <span v-if="hasUndeliverableMaterial"
      class="text-danger d-inline-block"
      @click.capture.prevent="editDrawingConfiguration">
      {{$t('drawing_configuration.material_not_available_anymore')}}
    </span>

    <div v-if="hasMaterial">{{ materialName }} ({{materialCode}})</div>

    <div v-if="!hasMaterial && !hasError"
      class="alert alert-warning p-1 pl-2 d-inline-block">{{$t('drawing_configuration.no_material_selected')}}
    </div>

    <div v-else-if="hasUnconfirmedFeedback"
      class="alert alert-warning p-1 pl-2 d-inline-block" >
      {{$t('drawing_configuration.drawing_check_not_accepted')}}
    </div>

    <ul v-else class="pl-3 m-0">
      <li v-for="(configProduct) in drawingConfigurationProducts" :key="configProduct.id">
        {{ getProduct(configProduct.drawing_product_id).group.name }}:
        {{ getProduct(configProduct.drawing_product_id).name.toLowerCase() }}
      </li>

      <template v-if="!showOnlyProducts">

        <template v-for="(feedback, key) in data.feedback">
          <li :key="key" v-if="feedback.type !== 'ERROR'">
            {{$t('drawing_configuration.warning')}}: {{ getError(feedback.message) }}
          </li>
        </template>

        <template v-for="(feedback, key) in data.feedback">
          <li :key="key" v-if="feedback.type === 'ERROR'"
            class="text-danger">
            {{$t('drawing_configuration.error')}}: {{ getErrorVerbose(feedback.message) }}
          </li>
        </template>

        <li v-if="showOrderedBefore && data.OrderDrawing.length > 0"
          class="d-none">
          <drawing-configuration-order-list
            :display-count="10"
            :order-ids="data.OrderDrawing.map(od => `L${od.order_id}`)" />
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import _has from 'lodash/has';
import _get from 'lodash/get';
import DrawingConfigurationOrderList from 'src/components/dcEditor/DrawingConfigurationOrderList';

export default {
  components: {
    DrawingConfigurationOrderList,
  },
  props: {
    drawingConfigurationId: {
      type: Number,
    },
    hasUnconfirmedFeedback: {
      type: Boolean,
      default: false,
    },
    hasUndeliverableMaterial: {
      type: Boolean,
      default: false,
    },
    showOnlyProducts: {
      type: Boolean,
      default: false,
    },
    showOrderedBefore: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  methods: {
    getProduct(productId) {
      if (productId in this.drawingProducts
        && typeof this.drawingProducts[productId] !== 'undefined'
        && _has(this.drawingProducts[productId], 'group.id')
      ) {
        return this.drawingProducts[productId];
      }
      return null;
    },
    getError(message) {
      if (message.toLowerCase() in this.errors) {
        return this.errors[message.toLowerCase()].documentName;
      }
      return message;
    },
    getErrorVerbose(message) {
      if (message.toLowerCase() in this.errors) {
        return this.errors[message.toLowerCase()].editorName.replace('drawingSize', this.getDrawingSize);
      }
      return message;
    },
  },
  computed: {
    methods() {
      const output = [];
      if (this.hasCutting) {
        output.push('snijden');
      }
      if (this.hasVectorEngrave) {
        output.push('lijngraveren');
      }
      if (this.hasRasterEngrave) {
        output.push('vlakgraveren');
      }
      return output.join(', ');
    },
    hasVectorEngrave() {
      return _get(this.totals, ['vector-engrave', 'length'], 0) > 0;
    },
    hasCutting() {
      return _get(this.totals, ['vector-cut', 'length'], 0) > 0;
    },
    hasRasterEngrave() {
      return _get(this.totals, ['raster-engrave', 'length'], 0) > 0;
    },
    totals() {
      return _get(this.drawingConfiguration, 'Drawing.dimensions.totals', {
        ignored: { length: 0 },
        'raster-engrave': { length: 0 },
        'vector-cut': { length: 0 },
        'vector-engrave': { length: 0 },
      });
    },
    drawingConfiguration() {
      return this.$store.state.dc.drawingConfiguration[this.drawingConfigurationId];
    },
    errors() {
      return this.$store.state.errors;
    },
    drawingProducts() {
      return this.$store.state.drawingProducts;
    },
    drawingConfigurationProducts() {
      const data = this.data.ConfigurationProducts.filter((obj) => this.getProduct(obj.drawing_product_id) !== null).concat();
      data
        .sort((a, b) => this.getProduct(a.drawing_product_id).group.id > this.getProduct(b.drawing_product_id).group.id);
      return data;
    },
    data() {
      return this.$store.state.dc.drawingConfiguration[parseInt(this.drawingConfigurationId, 10)];
    },
    hasError() {
      return this.drawingConfiguration.feedback.some((f) => f.type === 'ERROR');
    },
    getDrawingSize() {
      return `${parseFloat(this.drawingConfiguration.Drawing.dimensions.width).toFixed(0)} x
        ${parseFloat(this.drawingConfiguration.Drawing.dimensions.height).toFixed(0)} mm`;
    },
    material() {
      if (this.materials[this.drawingConfiguration.material_id]) {
        return this.materials[this.drawingConfiguration.material_id];
      }
      return null;
    },
    materials() {
      return this.$store.state.materials;
    },
    hasMaterial() {
      return this.drawingConfiguration.material_id in this.materials
      && parseInt(this.drawingConfiguration.material_id, 10) !== 1;
    },
    materialName() {
      if (this.hasMaterial) {
        return this.material.pretty_name;
      }
      return null;
    },
    materialCode() {
      if (this.hasMaterial) {
        return this.material.snijlab_code;
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
  .drawing-configuration-product-list {
    ul {
      line-height: normal;
      text-indent: 0;
    }
    .alert {
      font-size: 0.7rem;
    }
  }
</style>
