<template>
  <div v-if="exists"
    class="list-group-item list-group-item-action
      shadow-none cart-list-item p-2 pointer pb-2">

    <!-- <div class="cart-list-item-select pt-1 m-0 mb-1"
      @click.prevent.stop="selected=!selected">
      <b-form-checkbox
        v-model="selected"
        :tabstop="tabstop">
      </b-form-checkbox>
    </div> -->

    <div class="cart-list-item-image pr-3">
      <simple-svg v-if="materialColor !== '#ffffff'"
        :src="require('@/assets/material-sample.svg')"
        fill-class-name="fill-to-change"
        width="100%"
        :fill="`${materialColor}`" />
      <simple-svg v-else
        width="100%"
        :src="require('@/assets/material-sample-white.svg')"/>
    </div>

    <div class="cart-list-item-title">
      <div>{{translatedName}}</div>
      <small>{{translatedDescription}}</small>
    </div>

    <div class="cart-list-item-details mt-1">
      <!--  -->
    </div>

    <div class="cart-list-item-quantity">
      <cart-list-item-quantity-widget
        v-if="isDraft"
        :id="parseInt(id)"
        :quantity="parseInt(orderProduct.quantity)"
        productType="OrderProduct"
        :tabstop="tabstop+1000"/>

      <div v-else class="pr-2">
        {{orderProduct.quantity}}
      </div>
    </div>

    <div class="cart-list-item-price-container pl-2">
      <div>×</div>
      <div class="cart-list-item-price">
        <div>{{ showPriceExclVat }}</div>
        <div class="small text-muted">{{ showPrice }} incl.</div>
      </div>
    </div>

    <div class="cart-list-item-subtotal ml-2">
      <div class="cart-list-item-subtotal-total-price">{{ showTotalPriceExclVat }}</div>
      <div class="small text-muted">{{ showTotalPrice }} incl.</div>
    </div>

    <div class="cart-list-item-standard-actions-container">
        <b-button
          size="xs"
          variant="flat"
          class="cart-list-item-delete px-1 shadow-1"
          @click.stop="deleteOrderProduct">
            <i class="material-icons">delete</i>
        </b-button>
    </div>
  </div>

  <div v-else>
    <h4>Product {{ id }} niet gevonden</h4>
  </div>
</template>

<script>
import _get from 'lodash/get';
import CartListItemQuantityWidget from 'src/components/cart/edit/CartListItemQuantityWidget';
import TweenTools from 'src/lib/TweenTools';

export default {
  components: {
    CartListItemQuantityWidget,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    tabstop: {
      type: Number,
      default: 0,
    },
    isDraft: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      is_drawing_product: false, // temp fix to distinguish between drawing and product in sync()
      animatedTotal: null,
      animatedTotalExclVat: null,
      animatedPerPiece: null,
      animatedPerPieceExclVat: null,
    };
  },
  computed: {
    exists() {
      return !!_get(this.$store.state.cart.orderProducts, this.id) && this.product !== null;
    },
    orderProduct() {
      return this.$store.state.cart.orderProducts[this.id];
    },
    product() {
      return this.orderProduct.productId in this.$store.state.products
        ? this.$store.state.products[this.orderProduct.productId] : null;
    },
    totalPriceRaw() {
      return this.$config.getPrice(this.orderProduct.costs.total, true);
    },
    totalPriceRawExclVat() {
      return this.$config.getPrice(this.orderProduct.costs.total, false);
    },
    showTotalPrice() {
      if (this.animatedTotal !== null) {
        return this.$config.currencyFormat.format(this.animatedTotal / 100);
      }
      return this.$config.currencyFormat.format(this.totalPriceRaw / 100);
    },
    showTotalPriceExclVat() {
      if (this.animatedTotalExclVat !== null) {
        return this.$config.currencyFormat.format(this.animatedTotalExclVat / 100);
      }
      return this.$config.currencyFormat.format(this.totalPriceRawExclVat / 100);
    },
    totalPrice() {
      return this.$config.formatPrice(this.orderProduct.costs.total, false);
    },
    priceRaw() {
      return this.$config.getPrice(this.orderProduct.costs.perPiece, true);
    },
    priceRawExclVat() {
      return this.$config.getPrice(this.orderProduct.costs.perPiece, false);
    },
    price() {
      return this.$config.formatPrice(this.orderProduct.costs.perPiece, false);
    },
    showPrice() {
      if (this.animatedPerPiece !== null) {
        return this.$config.currencyFormat.format(this.animatedPerPiece / 100);
      }
      return this.$config.currencyFormat.format(this.priceRaw / 100);
    },
    showPriceExclVat() {
      if (this.animatedPerPieceExclVat !== null) {
        return this.$config.currencyFormat.format(this.animatedPerPieceExclVat / 100);
      }
      return this.$config.currencyFormat.format(this.priceRawExclVat / 100);
    },
    materialColor() {
      const material = Object.values(this.$store.state.materials).filter(
        (mat) => mat.related_products.indexOf(parseInt(this.orderProduct.productId, 10)) !== -1,
      );
      if (material.length === 0) {
        return '#ffffff';
      }
      return material[0].color.toLowerCase();
    },
    translatedName() {
      if (_get(this.product, `Translation.${this.$i18n.locale}.name`)) {
        return this.product.Translation[this.$i18n.locale].name;
      }
      return this.product.name;
    },
    translatedDescription() {
      if (_get(this.product, `Translation.${this.$i18n.locale}.body`)) {
        return this.product.Translation[this.$i18n.locale].body;
      }
      return '';
    },
  },
  watch: {
    cost(value, oldValue) {
      TweenTools.tweenNumber(value, oldValue, this, 'animatedCost');
    },
    costExclVat(value, oldValue) {
      TweenTools.tweenNumber(value, oldValue, this, 'animatedCostExclVat');
    },
  },
  methods: {
    deleteOrderProduct(event) {
      event.preventDefault();
      event.stopPropagation();
      this.$emit('delete', this);
      return false;
    },
  },
};
</script>

<style lang="scss">
  .cart-list-item {
    display: grid;
    grid-template-columns: 35px 1fr auto 130px;

    &.selected {
      background-color: rgba(186, 223, 255, 0.25);

      &:hover {
        background-color: rgba(186, 223, 255, 0.4);
      }
    }

    .cart-list-item-static-quantity {
      font-size: .8rem;
    }

    .cart-list-item-select {
      grid-column: 1;
      grid-row: 2;
    }

    .cart-list-item-image {
      grid-column: 2/5;
      grid-row: 3;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: flex-start;
      justify-items: center;

      img {
        display: block;
        max-width: 100%;
        max-height: 200px;
        width: 100%;
      }
    }

    .cart-list-item-title {
      grid-column: 2/5;
      grid-row: 2;
      font-size: 0.8rem;
      font-weight: 400;
      line-height: 0.95rem;
      overflow-wrap: anywhere;
    }

    .cart-list-item-details {
      color: #585858;
      grid-column: 2/5;
      grid-row: 4;
      font-size: 0.65rem;
      margin-bottom: 1rem;
    }

    .cart-list-item-quantity {
      grid-column: 1/4;
      grid-row: 5;
      justify-self: flex-end;
      align-items: center;
      display: flex;
    }

    .cart-list-item-price-container {
      grid-column: 4;
      grid-row: 5;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: .75rem;

      .cart-list-item-price {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
      }
    }

    .cart-list-item-subtotal {
      font-size: 0.9rem;
      grid-column: 3/5;
      grid-row: 6;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
    }

    .cart-list-item-standard-actions-container {
      grid-row: 1;
      grid-column: 4;
      justify-self: flex-end;

      .btn {
        min-width: auto;
        color: rgba(0, 0, 0, 0.26);

        &:hover {
        color: rgba(0, 0, 0, 0.87);
        }
      }
    }

    @media (min-width: 576px) {
      .cart-list-item-details {
        grid-column: 3;
        grid-row: 3;
        font-size: 0.65rem;
      }
    }

    @media (min-width: 576px) {
      grid-template-columns: 30px 150px 1fr 130px 130px;

      .cart-list-item-select {
        grid-column: 1;
        grid-row: 1;
      }

      .cart-list-item-standard-actions-container {
        grid-column: 5;
        grid-row: 1;
      }

      .cart-list-item-title {
        grid-column: 3/5;
        grid-row: 1;
      }

      .cart-list-item-image {
        grid-column: 2/3;
        grid-row: 1/4;
      }

      .cart-list-item-details {
        grid-column: 3/6;
        grid-row: 2/4;
      }

      .cart-list-item-quantity {
        grid-column: 2/4;
        grid-row: 4;
        justify-self: flex-end;
      }

      .cart-list-item-price-container {
        grid-column: 4;
        grid-row: 4;
      }
      .cart-list-item-subtotal {
        grid-column: 5;
        grid-row: 4;
      }
    }

    @media (min-width: 768px) {
      grid-template-columns: 30px 100px 1fr;

      .cart-list-item-select {
        grid-column: 1;
        grid-row: 1;
      }

      .cart-list-item-standard-actions-container {
        grid-column: 3;
        grid-row: 1;
        justify-self: flex-end;
      }

      .cart-list-item-title {
        grid-column: 3;
        grid-row: 2;
      }

      .cart-list-item-image {
        justify-self: flex-start;
        grid-column: 2;
        grid-row: 2;
      }

      .cart-list-item-details {
        grid-column: 3;
        grid-row: 3;
      }

      .cart-list-item-quantity {
        grid-column: 2;
        grid-row: 4;
        justify-self: flex-end;
      }

      .cart-list-item-price-container {
        grid-column: 3;
        grid-row: 4;
        justify-self: flex-end;
      }

      .cart-list-item-subtotal {
        grid-column: 3;
        grid-row: 5;
        justify-self: flex-end;
      }
    }

  @media (min-width: 992px) {
    grid-template-columns: 30px 150px 1fr 130px 130px;

      .cart-list-item-select {
        grid-column: 1;
        grid-row: 1;
      }

      .cart-list-item-standard-actions-container {
        grid-column: 5;
        grid-row: 1;
      }

      .cart-list-item-title {
        grid-column: 3/5;
        grid-row: 2;
      }

      .cart-list-item-image {
        justify-self: flex-start;
        grid-column: 2/3;
        grid-row: 2/4;
      }

      .cart-list-item-details {
        grid-column: 3/6;
        grid-row: 3/4;
      }

      .cart-list-item-quantity {
        grid-column: 3;
        grid-row: 5;
        justify-self: flex-end;
      }

      .cart-list-item-price-container {
        grid-column: 4;
        grid-row: 5;
      }

      .cart-list-item-subtotal {
        grid-column: 5;
        grid-row: 5;
      }
    }
  }
</style>
