<template>
  <div :class="rowClasses">
    <div class="input-row-label" style="margin-bottom: 5px;">
      <form-label-widget
        :label="label"
        :id="id"
        :help-link="helpLink"
        :required="required" />

      <async-queue
        slot="after"
        :watch-value="valuePublic"
        :delay="delay"
        @process="update" />
    </div>

    <b-form-textarea
      ref="input"
      :id="id"
      :value="valuePublic"
      :class="inputClass"
      :disabled="disabled"
      :readonly="readonly"
      :size="size"
      :state="currentState"
      :aria-invalid="hasError"
      :placeholder="placeholder"
      :autofocus="autofocus"
      :rows="rows"
      :max-rows="maxRows"
      :maxlength="maxLength"
      @input="onInput"
      @change="onChange" />

    <div v-if="showAvailableLength && currentLength > maxLength / 2"
      class="mt-1 text-right"
      :class="{'text-danger' : showLengthError}">
      {{currentLength}}/500 tekens
    </div>

    <form-help-widget
      :error="error"
      :warning="warning"
      :help="help" />
  </div>
</template>

<script>
import FormWidgetBase from 'src/components/form/FormWidgetBase';
import AsyncQueue from 'src/components/tools/AsyncQueue';
import VolatileField from 'src/lib/Volatile';

export default {
  mixins: [VolatileField('value')],
  extends: FormWidgetBase,
  name: 'FormAsyncWidgetTextarea',
  components: {
    AsyncQueue,
  },
  props: {
    value: {
      default: '',
    },
    inputName: {
      default: '',
      type: String,
    },
    taintedValue: {
      default: null,
    },
    delay: {
      default: 1000,
      type: Number,
    },
    useState: {
      default: true,
      type: Boolean,
    },
    rows: {
      type: Number,
    },
    maxRows: {
      type: Number,
    },
    maxLength: {
      type: Number,
    },
    showAvailableLength: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      preventLoop: false,
      currentLength: 0,
      showLengthError: false,
    };
  },
  computed: {
    overrideState() {
      if (typeof this.state !== 'undefined') {
        return this.state;
      }
      if (!this.error) {
        return '';
      }
      return this.error.length > 0 ? 'invalid' : '';
    },
    currentState() {
      if (this.error) {
        return false;
      }
      if (this.valueIsTainted) {
        return null;
      }
      if (this.value && this.value.toString().trim().length > 0) {
        return true;
      }
      return null;
    },
  },
  watch: {
    valueTaintedValue(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('update:taintedValue', newValue);
      }
    },
    valueIsTainted(newValue) {
      if (newValue === false) {
        this.valueTaintedValue = null;
      }
    },
    taintedValue(newValue) {
      this.valueTaintedValue = newValue;
    },
  },
  methods: {
    onChange(value) {
      this.valueTaintedValue = value;
    },
    onInput(value) {
      if (this.showAvailableLength) {
        this.currentLength = value.length;
        this.showLengthError = this.currentLength >= this.maxLength;
      }
      this.valueTaintedValue = value;
    },
    update(promise, value) {
      this.$emit('update', { promise, value, inputName: this.inputName });
    },
  },
};
</script>

<style>
</style>
