import Vue from 'vue';
import axios from 'axios';
import _get from 'lodash/get';
import _has from 'lodash/has';
import config from 'src/config';
import EventBus from 'src/event-bus';
import i18n from '@/i18n';

/* eslint-disable no-param-reassign */
const moduleDrawing = {
  state: {
    drawingConfiguration: {},
    // filter = queue
    drawingConfigurationFilter: [],
    index: null,
  },
  mutations: {
    SET_DRAWING_CONFIGURATION: (state, { list }) => {
      list.forEach((item) => {
        Vue.set(state.drawingConfiguration, item.id, item);
      });
    },
    // eslint-disable-next-line camelcase
    UPDATE_DRAWING_REFERENCE: (state, { dc_id, name }) => {
      // eslint-disable-next-line camelcase
      if (_has(state, `drawingConfiguration[${dc_id}]`)) {
        Vue.set(state.drawingConfiguration[dc_id], 'name', name);
      }
    },
    ADD_DRAWING_CONFIGURATION: (state, { list }) => {
      list.forEach((item) => {
        Vue.set(state.drawingConfiguration, item.id, item);
        state.drawingConfigurationFilter.unshift(parseInt(item.id, 10));
      });
    },

    DELETE_DRAWING_CONFIGURATIONS: (state, { drawingConfigurationsDeleted }) => {
      drawingConfigurationsDeleted.forEach((item) => {
        Vue.delete(state.drawingConfiguration, item);
        const idx = state.drawingConfigurationFilter.indexOf(parseInt(item, 10));
        if (idx !== -1) {
          state.drawingConfigurationFilter.splice(idx, 1);
        }
      });
    },
    UPDATE_DRAWING_CONFIGURATION_FILTERS: (state, { productIds }) => {
      Vue.set(state, 'drawingConfigurationFilter', productIds);
    },
  },
  actions: {
    updateFilter(state, { productIds }) {
      state.commit('UPDATE_DRAWING_CONFIGURATION_FILTERS', { productIds });
    },

    deleteConfigurations(state, { ids, orderId }) {
      const data = new FormData();
      data.append('method', 'delete');
      data.append('order_id', orderId);
      ids.forEach((id) => data.append('id[]', id));
      data.append('id[]', ids);
      const url = `${config.apiUrl}/drawingConfiguration/delete`;
      return axios.post(url, data, { withCredentials: true })
        .then((response) => {
          state.commit('DELETE_DRAWING_CONFIGURATIONS', response.data.result);
          state.commit('DELETE_ORDER_DRAWINGS', response.data.result);
          state.commit('UPDATE_CART', response.data.result);
          EventBus.success(i18n.t('drawing_configuration.design_removed_alert'));
          return response;
        }, (err) => {
          EventBus.error(err);
        });
    },
    duplicateConfigurations(state, { ids, replaceInOrders, orderId }) {
      const data = new FormData();
      data.append('method', 'duplicate');
      ids.forEach((id) => data.append('id[]', id));
      data.append('id[]', ids);
      data.append('order_id', orderId);
      data.append('replace_in_orders', replaceInOrders);
      const url = `${config.apiUrl}/drawingConfiguration/duplicate`;
      return axios.post(url, data, { withCredentials: true })
        .then((response) => {
          state.commit('ADD_DRAWING_CONFIGURATION', { list: Object.values(response.data.result.drawingConfiguration) });
          state.commit('UPDATE_CART', response.data.result);
          EventBus.success('Product gekopiëerd');
          return response;
        }, (err) => {
          // eslint-disable-next-line no-console
          EventBus.error(err);
        });
    },
    /**
     * @param state
     * @returns {Promise<AxiosResponse<any> | never>}
     */
    loadDrawingConfigurations(state) {
      return axios.get(`${config.apiUrl}/drawingConfiguration/list`, {
        withCredentials: true,
      }).then((response) => {
        state.commit(
          'SET_DRAWING_CONFIGURATION',
          { list: response.data.result },
        );
        state.commit('SET_LOADED', {
          type: 'drawingConfigurations',
          isLoaded: true,
        });
      }, (err) => {
        EventBus.error(err);
      });
    },
    /**
     * Upload a drawing
     * @param state
     * @param axiosConfig
     * @param file
     * @returns {Promise.<T>|*|Promise|Promise<any>}
     */
    uploadDrawing(state, { axiosConfig, file }) {
      const url = `${config.apiUrl}/drawingConfiguration/upload`;
      const data = new FormData();
      // const self = this;
      data.append('method', 'upload');
      data.append('drawing[filename_temp]', file.data);
      return new Promise(
        (resolve, reject) => axios.post(url, data, axiosConfig)
          .then((response) => {
            file.status = 'UPLOAD_FINISHED';
            if (!(response.data.result instanceof Array)) {
              const error = _get(response, 'data.error');
              reject(error, response);
            }

            state.commit(
              'ADD_DRAWING_CONFIGURATION',
              { list: response.data.result },
            );
            resolve(response.data.result[0]);
          })
          .catch((err) => {
            file.status = 'ERROR';
            const msg = _get(err, 'response.data.error');
            if (msg) {
              reject(msg);
            } else {
              reject(err.message);
            }
          }),
      );
    },
    /**
     * Update a material, and update the cart.
     * @param state
     * @param id
     * @param material_id
     * @param orderId
     */
    // eslint-disable-next-line camelcase
    updateMaterial(state, {
      id, materialId, orderId, productIds,
    }) {
      const data = new FormData();
      data.append('method', 'updateMaterial');
      data.append('material_id', materialId);
      data.append('order_id', orderId);
      data.append('id[]', id);
      if (typeof productIds !== 'undefined') {
        productIds.forEach((prodId) => {
          data.append('product_ids[]', prodId);
        });
      }
      const url = `${config.apiUrl}/drawingConfiguration/updateMaterial`;
      return axios.post(url, data, { withCredentials: true })
        .then((response) => {
          state.commit(
            'SET_DRAWING_CONFIGURATION',
            { list: Object.values(response.data.result.drawingConfiguration) },
          );
          state.commit('UPDATE_CART', response.data.result);
          EventBus.success('Product aangepast en opgeslagen');
        });
    },
    approveDrawingConfiguration(state, { id, orderId }) {
      const data = new FormData();
      data.append('method', 'approve');
      data.append('dc_id', id);
      data.append('order_id', orderId);
      const url = `${config.apiUrl}/drawingConfiguration/approve`;
      return axios.post(url, data, { withCredentials: true })
        .then((response) => {
          state.commit(
            'SET_DRAWING_CONFIGURATION',
            { list: Object.values(response.data.result.drawingConfiguration) },
          );
          state.commit('UPDATE_CART', response.data.result);
          EventBus.success('Waarschuwingen bevestigd');
        });
    },
    updateMaterials(state, {
      // eslint-disable-next-line camelcase
      ids, material_id, product_ids, replaceInOrders, order_id,
    }) {
      const data = new FormData();
      data.append('method', 'updateMaterial');
      data.append('material_id', material_id);
      data.append('order_id', order_id);
      ids.forEach((id) => {
        data.append('id[]', id);
      });
      product_ids.forEach((pid) => {
        data.append('product_ids[]', pid);
      });
      replaceInOrders.forEach((oid) => {
        data.append('replace_in_orders[]', oid);
      });
      const url = `${config.apiUrl}/drawingConfiguration/updateMaterial`;
      return axios.post(url, data, { withCredentials: true })
        .then((response) => {
          state.commit(
            'SET_DRAWING_CONFIGURATION',
            { list: Object.values(response.data.result.drawingConfiguration) },
          );
          state.commit('UPDATE_CART', response.data.result);
          if (ids.length > 1) {
            EventBus.success('Producten aangepast en opgeslagen');
          } else {
            EventBus.success('Product aangepast en opgeslagen');
          }
        });
    },
    // eslint-disable-next-line camelcase
    updateDrawingReference(state, { dc_id, name }) {
      const url = `${config.apiUrl}/drawingConfiguration/updateName`;
      const data = new FormData();
      data.append('dc_id', dc_id);
      data.append('name', name);
      return axios.post(url, data, { withCredentials: true }).then(() => {
        state.commit('UPDATE_DRAWING_REFERENCE', { name, dc_id });
        EventBus.success('Referentie aangepast en opgeslagen');
      });
    },
    updateOrderDrawingProduct(state, { ids, products, orderId }) {
      const data = new FormData();
      Object.keys(products).forEach((id) => {
        if (products[id]) {
          data.append('product_ids[]', id);
        }
      });
      data.append('order_id', orderId);
      data.append('drawing_configuration_id[]', ids);
      const url = `${config.apiUrl}/drawingConfiguration/updateProducts`;
      return axios.post(url, data, { withCredentials: true })
        .then((response) => {
          state.commit(
            'SET_DRAWING_CONFIGURATION',
            { list: Object.values(response.data.result.drawingConfiguration) },
          );
          state.commit('UPDATE_CART', response.data.result);
          EventBus.success('Product aangepast en opgeslagen');
        });
    },
    updateDcField(context, { field, value, id }) {
      const formData = new FormData();
      formData.append('id', id);
      if (field instanceof Array && value instanceof Array) {
        for (let i = 0; i < Math.min(field.length, value.length); i += 1) {
          formData.append('field[]', field[i]);
          formData.append('value[]', value[i]);
        }
      } else {
        formData.append('field[]', field);
        formData.append('value[]', value);
      }
      return axios.post(
        `${config.apiUrl}/drawingConfiguration/updateFields`,
        formData,
        { withCredentials: true },
      )
        .then((response) => {
          if (response.data.error !== null) {
            EventBus.error('Niet alle velden zijn correct ingevuld');
          } else {
            context.commit('UPDATE_', response.data.result);
            EventBus.success('Adres opgeslagen');
          }
        }).catch((err) => {
          EventBus.error(err);
        });
    },
  },
  getters: {
    priceByQty: (state) => (productId, priorityIdx, quantityIdx, showVat, section) => {
      if (!section) {
        section = 'total';
      }
      // const quantityIdx = showNestedPrice ? -1 : 0;
      let qtyPositionId = quantityIdx;
      const productData = state.drawingConfiguration[productId];
      const vatMultiplier = showVat ? (config.vat + 1) : 1;
      if (quantityIdx < 0) {
        const obj = _get(productData, `pricerange[${priorityIdx}]`, null);
        if (obj === null) {
          return '-';
        }
        qtyPositionId = obj.length + quantityIdx;
      }
      // const objStr = `pricerange[${this.priority}][${id}].total`;
      const value = _get(
        productData,
        `pricerange[${priorityIdx}][${qtyPositionId}][${section}]`, null,
      );
      if (value !== null) {
        return (Math.round((value * vatMultiplier)) / 100).toFixed(2);
      }

      return '-';
    },
    /**
     * Get which nesting quantity are available for a drawing configuration
     * @returns {*}
     */
    nestingQty: (state) => (dcId) => {
      const priceRanges = _get(
        state.drawingConfiguration[dcId], 'pricerange',
        null,
      );
      if (priceRanges === null) {
        return [];
      }
      const priceRangePerPriority = Object.values(priceRanges);
      if (priceRangePerPriority.length > 0) {
        // go through the first set of priorities
        return priceRangePerPriority[0].map((o) => o.quantity);
      }
      return [];
    },
  },
};

export default moduleDrawing;
