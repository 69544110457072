<template>
  <div/>
</template>

<script>
export default {
  props: {
    orderId: {
      type: Number,
      default: null,
      required: true,
    },
  },
  computed: {
    userLoaded() {
      return this.$store.state.loaded.user;
    },
  },
  watch: {
    userLoaded(isLoaded) {
      if (!isLoaded) return;
      this.$store.dispatch('loadDrawingConfigurations');
      if (this.orderId > 0) {
        this.$store.dispatch('loadOrder', this.orderId);
      }
    },
    orderId() {
      if (this.orderId > 0) {
        this.$store.dispatch('loadOrder', this.orderId);
      }
    },
  },
};
</script>
