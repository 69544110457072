<template>
  <div
    :class="{ loading: loading }"
    class="alert alert-warning errors"
    @click="highlight()">
    <ul>
      <li v-for="(item, key) in cart.orderErrors"
        :key="key">
        <i class="material-icons compact">warning</i>
        {{ getMessage(item) }}
        <i v-if="!(hideHelp.indexOf(item) !== -1)"
          class="material-icons compact info-icon"
          @click="showHelp(item)">info</i>
      </li>
    </ul>
    <ul>
      <li v-if="hasWarnings">
        <i class="material-icons">warning</i>
        {{$t('order_review.enter_all_required_fields')}}
        <a class="badge badge-light" href="#" @click="$emit('show-form-errors')">{{$t('order_review.show_required_fields')}}</a>
      </li>
      <li v-if="paymentMethod === 'invalid'">
        <i class="material-icons">warning</i> {{$t('order_review.select_valid_payment_method')}}
      </li>
    </ul>
    <ul v-if="cart.phoneNumberError">
      <li>
        {{$t('order_review.required_phone_number')}}
      </li>
    </ul>
    <ul v-if="Object.keys(cart.addressErrors).length > 0">
      <li v-for="value in Object.keys(cart.addressErrors)" :key="value">
        {{translateError(value, cart.addressErrors[value])}}
      </li>
    </ul>

    <div class="mt-2">
      <strong>{{$t('order_review.need_help')}}</strong> {{$t('order_review.mail_to')}}
      <a href="mailto:info@snijlab.nl">info@snijlab.nl</a>
      {{$t('order_review.or_call')}} <a href="tel:0031103070949">+31 (0)10-3070949</a> {{$t('order_review.provide_order_number')}}
      <strong>L{{ cart.orderId }}.</strong>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    paymentMethod: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      cartErrors: ['ORDER_DRAWING_CONFIGURATION_INVALID', 'ORDER_DRAWING_CONFIGURATION_NO_MATERIAL',
        'ORDER_DRAWING_CONFIGURATION_UNAVAILABLE_MATERIAL', 'ORDER_DRAWING_CONFIGURATION_NOT_APPROVED',
        'ORDER_EMPTY', 'ORDER_MINIMUM_AMOUNT_NOT_MET'],
      hideHelp: ['ORDER_DRAWING_CONFIGURATION_INVALID', 'ORDER_DRAWING_CONFIGURATION_NO_MATERIAL',
        'ORDER_DRAWING_CONFIGURATION_UNAVAILABLE_MATERIAL', 'ORDER_DRAWING_CONFIGURATION_NOT_APPROVED',
        'ORDER_EMPTY', 'ORDER_MINIMUM_AMOUNT_NOT_MET'],
      errorTranslations: {
        is_business: this.$i18n.t('order_review.address_form.errors.is_business'),
        invoice_name: this.$i18n.t('order_review.address_form.errors.invoice_name'),
        invoice_company: this.$i18n.t('order_review.address_form.errors.invoice_company'),
        invoice_email: this.$i18n.t('order_review.address_form.errors.invoice_email'),
        invoice_country: this.$i18n.t('order_review.address_form.errors.invoice_country'),
        invoice_is_delivery_address: this.$i18n.t('order_review.address_form.errors.invoice_is_delivery_address'),
        invoice_zip_code: this.$i18n.t('order_review.address_form.errors.invoice_zip_code'),
        invoice_address: this.$i18n.t('order_review.address_form.errors.invoice_address'),
        invoice_address_number: this.$i18n.t('order_review.address_form.errors.invoice_address_number'),
        invoice_address_extra: this.$i18n.t('order_review.address_form.errors.invoice_address_extra'),
        invoice_city: this.$i18n.t('order_review.address_form.errors.invoice_city'),
        delivery_company: this.$i18n.t('order_review.address_form.errors.delivery_company'),
        delivery_name: this.$i18n.t('order_review.address_form.errors.delivery_name'),
        delivery_country: this.$i18n.t('order_review.address_form.errors.delivery_country'),
        delivery_address: this.$i18n.t('order_review.address_form.errors.delivery_address'),
        delivery_address_number: this.$i18n.t('order_review.address_form.errors.delivery_address_number'),
        delivery_address_extra: this.$i18n.t('order_review.address_form.errors.delivery_address_extra'),
        delivery_zip_code: this.$i18n.t('order_review.address_form.errors.delivery_zip_code'),
        delivery_city: this.$i18n.t('order_review.address_form.errors.delivery_city'),
        delivery_phone_number: this.$i18n.t('order_review.address_form.errors.delivery_phone_number'),
      },
    };
  },
  computed: {
    cart() {
      return this.$store.state.cart;
    },
    hasWarnings() {
      return Object.keys(this.cart.addressErrors).length > 0
        || Object.keys(this.cart.addressWarnings).length > 0
        || this.cart.phoneNumberWarning;
    },
  },
  methods: {
    getMessage(item) {
      return this.$store.state.popups[`cart.${item}`.toLowerCase()].title;
    },
    showHelp(item) {
      this.$eventBus.$emit('snlb.showHelp', `cart.${item}`);
    },
    highlight() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 750);
    },
    translateError(field, value) {
      let returnMsg = '';
      if (value === 'FORM_ERROR_REQUIRED') {
        returnMsg = `${this.$i18n.t('cart_review.required')}: `;
      } else if (value === 'FORM_ERROR_INVALID') {
        returnMsg = `${this.$i18n.t('cart_review.invalid')}: `;
      } else {
        returnMsg = `${value}: `;
      }

      if (field in this.errorTranslations) {
        returnMsg += `${this.errorTranslations[field]}`;
      } else {
        returnMsg += `${field}`;
      }
      return returnMsg;
    },
  },
};
</script>

<style lang="scss">
  .errors ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
</style>
