<template>
  <div>
    <b-modal
      id="signin"
      ref="signInModal"
      :modal-class="{'signin-modal': true, 'limit-width': !showForgotPassword}"

      :visible="show"
      :no-close-on-backdrop="forced"
      :no-close-on-esc="forced"
      :hide-header-close="true"
      @hidden="hidden()">

      <div v-if="!showForgotPassword"
        class="col-12 col-md-6 login p-0 pt-2">
        <create-account-form @updateEmail="email = $event" />
      </div>

      <div class="col-12 bg-primary pt-2 p-0 login-panel"
        :class="{'col-md-6' : !showForgotPassword}">
        <sign-in-form v-if="!showForgotPassword"
          @show-forgot-password="showForgotPassword = true" />
        <forgot-password-form
          v-else-if="forgotPasswordCode === null && showForgotPassword"
          :initial-email="email"
          @showConfirmPassword="forgotPasswordCode = $event" />
        <forgot-password-confirm-code
          v-else-if="forgotPasswordCode !== null && forgotPasswordValidation === null"
          :code="forgotPasswordCode"
          @newConfirmationCode="forgotPasswordValidation = $event" />
        <forgot-password-save-new
          v-else-if="forgotPasswordCode !== null && forgotPasswordValidation !== null"
          :code="forgotPasswordCode" :confirmation-code="forgotPasswordValidation" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import CreateAccountForm from 'src/components/user/CreateAccountForm';
import SignInForm from 'src/components/user/SignInForm';
import ForgotPasswordForm from 'src/components/user/ForgotPasswordForm';
import ForgotPasswordConfirmCode from 'src/components/user/ForgotPasswordConfirmCode';
import ForgotPasswordSaveNew from 'src/components/user/ForgotPasswordSaveNew';

export default {
  components: {
    ForgotPasswordSaveNew,
    ForgotPasswordConfirmCode,
    ForgotPasswordForm,
    SignInForm,
    CreateAccountForm,
  },
  data() {
    return {
      showForgotPassword: false,
      forgotPasswordCode: null,
      forgotPasswordValidation: null,
      email: null,
    };
  },
  props: {
    forced: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    hidden() {
      this.$emit('hidden');
      if (this.signInRequired) {
        this.$router.push('/');
      }
    },
  },
};
</script>
<style lang="scss">
  #signin {
    padding: 0;
    padding-left: 0 !important;
  }
  .signin-modal {
    header, footer {
      display: none;
    }
    &.limit-width {
      .modal-dialog {
        max-width: 50rem;
      }
    }
    .modal {
      padding-left: 0 !important;
    }

    .modal-body {
      padding: 0;
      display: flex;
      flex-wrap: wrap;
    }
  }

  .login-panel {
    display: flex;
  }
</style>
