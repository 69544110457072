function getEnvironmentVariable(name, def) {
  if (process.env && `VUE_APP_${name}` in process.env) {
    return process.env[`VUE_APP_${name}`];
  }
  return def;
}

/* eslint-disable prefer-destructuring */
const config = {
  dropshippingCosts: 350, // in cents
  phpDevMode: getEnvironmentVariable('PHP_DEV_MODE', null) !== null,
  baseUrl: getEnvironmentVariable('BASE_URL', ''),
  imageUrl: getEnvironmentVariable('IMG_URL', ''),
  vat: 0.21,
  drawingConfigurationPrefix: 'X',
  postcodeUrl: getEnvironmentVariable('POSTCODE_API', 'https://postcode.studioavw.nl/postcode.php'),
  recaptchaSiteKey: getEnvironmentVariable('RECAPTCHA_SITE_KEY', ''),
  maxFileSize: 50 * 1024 * 1024, // 50mb
  // gtagId: getEnvironmentVariable('GOOGLE_ANALYTICS_GTAG_ID', ''),
  // gtagAdId: getEnvironmentVariable('GOOGLE_AD_GTAG_ID', ''),
  googleTagManagerId: getEnvironmentVariable('GOOGLE_TAG_MANAGER_ID', 'GTM-XXXXXXX'),
  googleTagManagerDebug: getEnvironmentVariable('GOOGLE_TAG_MANAGER_DEBUG', false) === 'true',
  sentryDSN: getEnvironmentVariable('SENTRY_DSN', false),
  odooLeadFormUrl: 'https://snijlab.odoo.com/website_api/leadform',
  odooUserEvent: 'https://snijlab.odoo.com/website_api/event',
  // odooUserEvent: 'https://snijlab-event-api-5987192.dev.odoo.com/website_api/event',
};

if (config.phpDevMode) {
  config.baseApiUrl = `${config.baseUrl}/frontend_dev.php/api`;
} else {
  config.baseApiUrl = `${config.baseUrl}/api`;
}
config.apiUrl = `${config.baseApiUrl}/nl`;

// TODO: Update this when changing language
const lang = process.env.VUE_APP_I18N_LOCALE || 'nl';
config.currencyFormat = new Intl.NumberFormat(lang, {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 2, /* this might not be necessary */
});

// TODO: Update this when changing language
config.numberFormat = new Intl.NumberFormat(
  lang,
  { minimumFractionDigits: 2 },
);

config.getPrice = (costItem, useVat) => {
  let amount = costItem.amount;
  if (useVat) {
    amount = costItem.amount
      + Object.values(costItem.vat).reduce((a, b) => a + b, 0);
  }
  return amount;
};

config.getVat = (costItem) => Object.values(costItem.vat).reduce((a, b) => a + b, 0);

config.formatPrice = (costItem, useVat, useCurrency) => {
  const amount = config.getPrice(costItem, useVat) / 100.0;
  return useCurrency
    ? config.currencyFormat.format(amount)
    : config.numberFormat.format(amount);
};

export default config;
