<template>
  <div class="drawing-svg-viewer">
    <loading-widget class="svg-loading-widget" :loading="showSvgLoader"/>
    <div class="drawing-size pl-1">{{width}} mm x {{height}} mm</div>

    <div class="btn-toolbar-layers border-top p-1">

      <i class="btn-flat material-icons compact info-icon pointer"
        @click="$eventBus.$emit('snlb.showHelp', 'uploader.layers')">info</i>

      <b-button
        class="btn-outline-secondary p-1 btn-all ml-1 mb-1"
        size="xs"
        variant="light"
        :class="{ active: showAll }"
        @click="show('all')">
        <i class="material-icons">panorama_fish_eye</i> {{$t('drawing_configuration.show_all')}}</b-button>

      <b-button v-if="hasCutting"
        class="btn-cutting btn-outline-secondary p-1 ml-1 mb-1"
        size="xs"
        variant="light"
        :class="{ active: cuttingVisible && !showAll }"
        @click="show('vector-cut')">
        <i class="material-icons">lens</i> {{$t('drawing_configuration.cutting_lines')}}</b-button>

      <b-button v-if="hasRasterEngrave"
        class="btn-area-engrave btn-outline-secondary p-1 ml-1 mb-1"
        size="xs"
        variant="light"
        @click="show('raster-engrave')"
        :class="{ active: rasterEngraveVisible && !showAll }">
        <i class="material-icons">lens</i> {{$t('drawing_configuration.area_engravings')}}</b-button>

      <b-button v-if="hasVectorEngrave"
        class="btn-line-engrave btn-outline-secondary p-1 ml-1 mb-1"
        size="xs"
        variant="light"
        @click="show('vector-engrave')"
        :class="{ active: vectorEngraveVisible && !showAll }">
        <i class="material-icons">lens</i> {{$t('drawing_configuration.line_engravings')}}</b-button>

      <b-button v-if="hasIgnored"
        class="btn-ignored btn-outline-secondary p-1 ml-1 mb-1"
        size="xs"
        variant="light"
        :class="{ active: ignoredVisible && !showAll }"
        @click="show('ignored')">
        <i class="material-icons">lens</i> {{$t('drawing_configuration.removed')}}</b-button>

      <b-button v-if="hasRasterEngrave && $store.getters.isAdmin"
        class="btn-outline-secondary btn-area-engrave-original btn-admin p-1 ml-1 mb-1"
        size="xs"
        variant="light"
        @click="show('raster-engrave-original')"
        :class="{ active: rasterEngraveOriginalVisible && !showAll }">
        <i class="material-icons">panorama_fish_eye</i> {{$t('drawing_configuration.area_engravings_input')}}</b-button>

      <b-button
        v-if="$store.getters.isAdmin"
        class="btn-outline-secondary btn-admin p-1 ml-1 mb-1"
        size="xs"
        variant="light"
        @click="showHairline = !showHairline">
        <i class="material-icons" v-if="!showHairline">radio_button_unchecked</i>
        <i class="material-icons" v-if="showHairline">radio_button_checked</i>
        {{$t('drawing_configuration.show_hairline')}}</b-button>

      <b-button
        v-if="$store.getters.isAdmin"
        class="btn-outline-secondary btn-admin p-1 ml-1 mb-1"
        size="xs"
        variant="light"
        @click="gridVisible = !gridVisible">
        <i class="material-icons" v-if="gridVisible">grid_on</i>
        <i class="material-icons" v-if="!gridVisible">grid_off</i> {{$t('drawing_configuration.grid_toggle')}}</b-button>

      <div v-if="!showAll">
        <strong>{{$t('drawing_configuration.note')}}</strong> {{$t('drawing_configuration.design_is_produced_using')}}
      </div>
    </div>

    <div class="btn-toolbar-controls border-bottom">
      <div class="btn-toolbar-controls-first-set">
        <span role="button" class="btn btn-flat p-1"
          @click="panleft"><i class="material-icons">keyboard_arrow_left</i></span>
        <span role="button" class="btn btn-flat p-1"
          @click="panup"><i class="material-icons">keyboard_arrow_up</i></span>
        <span role="button" class="btn btn-flat p-1"
          @click="pandown"><i class="material-icons">keyboard_arrow_down</i></span>
        <span role="button" class="btn btn-flat p-1"
          @click="panright"><i class="material-icons">keyboard_arrow_right</i></span>
      </div>

      <div class="btn-toolbar-controls-second-set">
        <span role="button" class="btn btn-flat p-1"
          @click="zoomin"><i class="material-icons">zoom_in</i></span>
        <span role="button" class="btn btn-flat p-1"
          @click="zoomout"><i class="material-icons">zoom_out</i></span>
        <span role="button" class="btn btn-flat p-1"
          @click="zoomreset"><i class="material-icons">crop_free</i></span>
      </div>
    </div>

    <svg-canvas
      class="svg-canvas"
      ref="svgPreviewCanvas"
      :svg="svgString"
      :isHairline="showHairline"
    />

  </div>
</template>
<script>

import axios from 'axios';
import loadingWidget from 'src/components/tools/LoadingWidget';

import SvgCanvas from 'src/components/drawing/SvgCanvas';

export default {
  components: {
    SvgCanvas,
    loadingWidget,
  },
  props: {
    svg: {
      type: String,
      required: true,
    },
    kerfWidthEngrave: {
      type: Number,
      default: 0.3,
    },
    kerfWidthCutting: {
      type: Number,
      default: 0.3,
    },
    kerfWidthAreaEngrave: {
      type: Number,
      default: 0.3,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    hasCutting: {
      type: Boolean,
      default: false,
    },
    hasVectorEngrave: {
      type: Boolean,
      default: false,
    },
    hasRasterEngrave: {
      type: Boolean,
      default: false,
    },
    hasIgnored: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showAll: true,
      cuttingVisible: true,
      ignoredVisible: false,
      vectorEngraveVisible: true,
      rasterEngraveVisible: true,
      rasterEngraveOriginalVisible: false,
      gridVisible: true,
      showHairline: false,
      showImage: false,
      materialCache: null,
      materialId: 269,
      error: false,
      svgString: '',
      showSvgLoader: false,
    };
  },
  computed: {
  },
  watch: {
    svg(value, oldValue) {
      if (value !== oldValue) {
        this.loading = true;
        this.error = false;
        this.loadSvg();
        this.show('all');
      }
    },
    cuttingVisible(value) {
      this.setVisible('vector-cut', value);
    },
    ignoredVisible(value) {
      // this.setVisible('boundingbox', value);
      this.setVisible('ignored', value);
    },
    vectorEngraveVisible(value) {
      this.setVisible('vector-engrave', value);
    },
    rasterEngraveVisible(value) {
      this.setVisible('raster-engrave', value);
    },
    rasterEngraveOriginalVisible(value) {
      this.setVisible('raster-engrave-original', value);
    },
    gridVisible(value) {
      this.setVisible('grid', value);
    },
  },
  // Initial data...
  mounted() {
    this.loadSvg();
    this.setVisible('ignored', false);
  },
  methods: {
    show(layer) {
      switch (layer) {
        case 'ignored': {
          this.showAll = false;
          this.ignoredVisible = true;
          this.cuttingVisible = false;
          this.rasterEngraveVisible = false;
          this.vectorEngraveVisible = false;
          this.rasterEngraveOriginalVisible = false;
          break;
        }
        case 'all': {
          this.showAll = true;
          this.ignoredVisible = false;
          this.cuttingVisible = true;
          this.rasterEngraveVisible = true;
          this.vectorEngraveVisible = true;
          this.rasterEngraveOriginalVisible = false;
          break;
        }
        case 'vector-cut': {
          this.showAll = false;
          this.ignoredVisible = false;
          this.cuttingVisible = true;
          this.rasterEngraveVisible = false;
          this.vectorEngraveVisible = false;
          this.rasterEngraveOriginalVisible = false;
          break;
        }
        case 'vector-engrave': {
          this.showAll = false;
          this.ignoredVisible = false;
          this.cuttingVisible = false;
          this.rasterEngraveVisible = false;
          this.vectorEngraveVisible = true;
          this.rasterEngraveOriginalVisible = false;
          break;
        }
        case 'raster-engrave': {
          this.showAll = false;
          this.ignoredVisible = false;
          this.cuttingVisible = false;
          this.rasterEngraveVisible = true;
          this.vectorEngraveVisible = false;
          this.rasterEngraveOriginalVisible = false;
          break;
        }
        case 'raster-engrave-original': {
          this.showAll = false;
          this.ignoredVisible = false;
          this.cuttingVisible = false;
          this.rasterEngraveVisible = false;
          this.vectorEngraveVisible = false;
          this.rasterEngraveOriginalVisible = true;
          break;
        }
        default:
          break;
      }
    },
    setVisible(id, value) {
      const element = document.getElementById(id);
      if (element != null) {
        element.style.display = value ? 'block' : 'none';
      }
    },
    loadSvg() {
      this.showSvgLoader = true;
      const url = `${this.svg}?${new Date()}`;
      axios.get(url, { withCredentials: true })
        .then((result) => {
          if (result.config.url !== url) {
            return;
          }
          this.svgString = result.data;
        }).finally(() => {
          setTimeout(() => {
            this.showSvgLoader = false;
          }, 250);
        });
    },
    zoomin() {
      this.$refs.svgPreviewCanvas.zoomin();
    },
    zoomout() {
      this.$refs.svgPreviewCanvas.zoomout();
    },
    zoomreset() {
      this.$refs.svgPreviewCanvas.zoomreset();
    },
    panleft() {
      this.$refs.svgPreviewCanvas.panleft();
    },
    panup() {
      this.$refs.svgPreviewCanvas.panup();
    },
    pandown() {
      this.$refs.svgPreviewCanvas.pandown();
    },
    panright() {
      this.$refs.svgPreviewCanvas.panright();
    },
  },
};
</script>
<style lang="scss">
.drawing-svg-viewer {
  display: grid;

  .btn-toolbar-layers {
    grid-row: 3;
    grid-column: 1;
    font-size: 0.8em;
    .btn-outline-secondary {
      font-size: 1em;
      border: 1px solid rgba(0,0,0,.2);
    }
    .btn:disabled,
    .btn[disabled] {
      .material-icons {
        opacity: .6;
      }
    }

  }

  .btn-toolbar-controls {
    grid-row: 1;
    grid-column: 1;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr;
    justify-content: flex-end;

    .btn {
      min-width: inherit;
    }

    .btn-toolbar-controls-first-set,
    .btn-toolbar-controls-second-set {
      display: flex;
      grid-row: 1fr;
      justify-content: flex-end;
    }

    .btn-toolbar-controls-first-set {
      grid-column: 1;
    }
    .btn-toolbar-controls-second-set {
      grid-column: 2;
    }
  }

  @media (max-width: 530px) {
    .btn-toolbar-controls {
      margin-top: 1rem;
    }
  }

  @media (max-width: 400px) {
    .btn-toolbar-controls {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;

      .btn-toolbar-controls-first-set,
      .btn-toolbar-controls-second-set {
        grid-column: 1;
        justify-content: flex-start;
      }
      .btn-toolbar-controls-first-set {
        grid-row: 1;
      }
      .btn-toolbar-controls-second-set {
        grid-row: 2;
      }
    }
  }

  .svg-canvas {
    grid-row: 2;
    grid-column: 1;
  }

  .preview .bg-error {
    background-color: red;
    height: 100%;
  }

  .btn.btn-outline {
    border: 10px solid red;
    margin: 3px;
    font-weight: normal;
    background-image: none;
    background-color: #ffffff;
    &.active {
      border-width: 2px;
      margin: 2px;
      background-image: none;
    }
  }

  .btn.btn-all .material-icons {
    color: #000;
  }

  .btn.btn-cutting .material-icons {
    color: #00f;
  }

  .btn.btn-ignored .material-icons {
    color: #aaaaaa;
  }

  .btn.btn-help .material-icons {
    color: #000000;
  }

  .btn.btn-area-engrave .material-icons {
    color: magenta;
  }

  .btn.btn-area-engrave-original .material-icons {
    color: magenta;
  }

  .btn.btn-line-engrave .material-icons {
    color: red;
  }

  .btn.btn-outline:hover, .btn.btn-outline:active, .btn.btn-outline:focus {
    background-color: #ffffff;
    background-image: none;
    font-weight: normal;
  }

  .svg-loading-widget {
    position: absolute;
    margin-top: 30px;
    z-index: 1;
  }

  .drawing-size {
    font-size: 1rem;
    position: absolute;
  }
}
</style>
