<template>
  <div class="svg-canvas-container">
    <div class="use-two-fingers" :class="{ show: showSwipeAlert }">
      <h1>Use two fingers to pan/zoom</h1>
    </div>
    <div class="svg-canvas"
         style="background-color: #F3F3F3"
         ref="canvas"
         @mousedown="addDraggingIcon"
         @mouseup="removeDraggingIcon"
         @mouseenter="removeDraggingIcon"
         @mouseleave="removeDraggingIcon"
         v-hammer="onHammer">
    </div>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */

import svgPanZoom from 'svg-pan-zoom';
import Hammer from 'hammerjs';

export default {
  props: {
    svg: {
      type: String,
      default: '',
    },
    isHairline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      scrollParent: null,
      panzoom: null,
      layers: {
        boundingbox: null,
        grid: null,
        cutting: null,
        vectorEngrave: null,
        rasterEngrave: null,
        ignored: null,
        raster: null,
      },
      touch: {
        pan: { x: 0, y: 0 },
        zoom: null,
        scrollTop: null,
        scrollLeft: null,
      },
      showSwipeAlert: false,
    };
  },
  watch: {
    svg() {
      this.updateDrawing();
    },
    $mq() {
      this.updateMouseWheel();
    },
    isHairline() {
      this.updateLineWeight();
    },
  },
  computed: {
    noMouseWheelSizes() {
      return ['xs', 'sm', 'md'];
    },
  },
  directives: {
    hammer: {
      // eslint-disable-next-line no-unused-vars
      bind(el, settings, vnode) {
      // create a simple instance
      // by default, it only adds horizontal recognizers
        const mc = new Hammer.Manager(el);
        const single = new Hammer.Pan({
          event: 'single',
          direction: Hammer.DIRECTION_ALL,
          pointers: 1,
        });
        const multipan = new Hammer.Pan({
          event: 'multipan',
          direction: Hammer.DIRECTION_ALL,
          pointers: 2,
        });
        const pinch = new Hammer.Pan({
          event: 'pinch',
          pointers: 2,
        });
        mc.add(single);
        mc.add(multipan);
        mc.add(pinch);
        multipan.recognizeWith(pinch);
        pinch.recognizeWith(multipan);
        multipan.requireFailure(single);
        pinch.requireFailure(single);

        mc.on('single', (e) => settings.value('singlepan', e));
        mc.on('singlestart', (e) => settings.value('singlepanstart', e));
        mc.on('singleend', (e) => settings.value('singlepanend', e));
        mc.on('multipan', (e) => settings.value('pan', e));
        mc.on('multipanstart', (e) => settings.value('panstart', e));
        mc.on('pinch', (e) => settings.value('pinch', e));
        mc.on('pinchstart', (e) => settings.value('pinchstart', e));
      },
    },
  },
  methods: {
    updateMouseWheel() {
      if (this.panzoom == null) {
        return;
      }
      if (this.noMouseWheelSizes.includes(this.$mq)) {
        this.panzoom.disableMouseWheelZoom();
      } else {
        this.panzoom.enableMouseWheelZoom();
      }
    },
    getScrollParent(node) {
      if (node == null) {
        return null;
      }

      if (node.scrollHeight > node.clientHeight) {
        return node;
      }
      return this.getScrollParent(node.parentNode);
    },

    getScrollParentCool(node) {
      const regex = /(auto|scroll)/;
      const parents = (_node, ps) => {
        if (_node.parentNode === null) { return ps; }
        return parents(_node.parentNode, ps.concat([_node]));
      };

      const style = (_node, prop) => getComputedStyle(_node, null).getPropertyValue(prop);
      const overflow = (_node) => style(_node, 'overflow') + style(_node, 'overflow-y') + style(_node, 'overflow-x');
      const scroll = (_node) => regex.test(overflow(_node));

      /* eslint-disable consistent-return */
      const scrollParent = (_node) => {
        if (!(_node instanceof HTMLElement || _node instanceof SVGElement)) {
          return;
        }

        const ps = parents(_node.parentNode, []);

        for (let i = 0; i < ps.length; i += 1) {
          if (scroll(ps[i])) {
            return ps[i];
          }
        }

        return document.scrollingElement || document.documentElement;
      };

      return scrollParent(node);
      /* eslint-enable consistent-return */
    },
    // eslint-disable-next-line no-unused-vars
    onHammer(type, args) {
      if (this.scrollParent == null) {
        this.scrollParent = this.getScrollParentCool(this.$el);
      }
      if (args.pointerType !== 'touch') {
        return;
      }
      // eslint-disable-next-line default-case
      switch (type) {
        case 'singlepanstart': {
          this.touch.scrollLeft = this.scrollParent.scrollLeft;
          this.touch.scrollTop = this.scrollParent.scrollTop;
          this.showSwipeAlert = true;
          break;
        }
        case 'singlepan': {
          this.scrollParent.scrollTo(this.touch.scrollLeft, this.touch.scrollTop - args.deltaY);
          break;
        }
        case 'singlepanend': {
          this.showSwipeAlert = false;
          break;
        }
        case 'panstart': {
          this.showSwipeAlert = false;
          this.touch.pan = { x: 0, y: 0 };
          break;
        }
        case 'pan': {
          this.panzoom.panBy({ x: args.deltaX - this.touch.pan.x, y: args.deltaY - this.touch.pan.y });
          this.touch.pan = { x: args.deltaX, y: args.deltaY };
          break;
        }
        case 'pinchstart': {
          this.touch.zoom = this.panzoom.getZoom();
          this.panzoom.zoomAtPoint(this.touch.zoom * args.scale, { x: args.center.x, y: args.center.y });
          break;
        }
        case 'pinch': {
          this.panzoom.zoomAtPoint(this.touch.zoom * args.scale, { x: args.center.x, y: args.center.y });
        }
      }
      // this.showSwipeAlert = true;
      // setTimeout(() => { this.showSwipeAlert = false; }, 1000);
    },
    onTap() {
      this.panzoom.zoomIn();
    },
    updateDrawing() {
      this.$refs.canvas.innerHTML = '';
      try {
        if (this.panzoom != null) {
          this.panzoom.destroy();
        }
        // eslint-disable-next-line no-empty
      } catch (ex) {
      }
      const tmp = document.createElement('div');
      tmp.innerHTML = this.svg;
      const svg = tmp.firstElementChild;

      // Generate grid using svg
      // <defs>
      //   <pattern id="smallGrid" width="1" height="1" patternUnits="userSpaceOnUse">
      //     <path d="M 1 0 L 0 0 0 1" fill="none" stroke="gray" stroke-width="0.01"/>
      //   </pattern>
      //   <pattern id="grid" width="10" height="10" patternUnits="userSpaceOnUse">
      //     <rect width="10" height="10" fill="url(#smallGrid)"/>
      //     <path d="M 10 0 L 0 0 0 10" fill="none" stroke="gray" stroke-width="0.1"/>
      //   </pattern>
      // </defs>
      const svgNS = svg.namespaceURI;
      const sizeSmall = 1;
      const sizeSmallStrokeWidth = 0.01;
      const smallGrid = document.createElementNS(svgNS, 'pattern');
      smallGrid.setAttribute('id', 'smallGrid');
      smallGrid.setAttribute('patternUnits', 'userSpaceOnUse');
      smallGrid.setAttribute('width', 1);
      smallGrid.setAttribute('height', 1);

      const path = document.createElementNS(svgNS, 'path');
      path.setAttribute('d', `M ${sizeSmall} 0 L 0 0 0 ${sizeSmall}`);
      path.setAttribute('fill', 'none');
      path.setAttribute('stroke', 'grey');
      path.setAttribute('stroke-width', sizeSmallStrokeWidth);
      smallGrid.appendChild(path);

      const sizeLarge = 10;
      const grid = document.createElementNS(svgNS, 'pattern');
      grid.setAttribute('id', 'largeGrid');
      grid.setAttribute('patternUnits', 'userSpaceOnUse');
      grid.setAttribute('width', sizeLarge);
      grid.setAttribute('height', sizeLarge);

      const smallGridRect = document.createElementNS(svgNS, 'rect');
      smallGridRect.setAttribute('width', sizeLarge);
      smallGridRect.setAttribute('height', sizeLarge);
      smallGridRect.setAttribute('fill', 'url(#smallGrid)');

      const largePath = document.createElementNS(svgNS, 'path');
      largePath.setAttribute('d', `M ${sizeLarge} 0 L 0 0 0 ${sizeLarge}`);
      largePath.setAttribute('fill', 'none');
      largePath.setAttribute('stroke', 'grey');
      largePath.setAttribute('stroke-width', sizeSmallStrokeWidth);

      grid.appendChild(smallGridRect);
      grid.appendChild(largePath);

      const defs = svg.querySelector('defs')
        || svg.insertBefore(document.createElementNS(svgNS, 'defs'), svg.firstChild);

      defs.appendChild(smallGrid);
      defs.appendChild(grid);

      // eslint-disable-next-line no-unreachable
      tmp.firstElementChild.setAttribute('width', '100%');
      tmp.firstElementChild.setAttribute('height', '100%');
      this.initializeDrawing(tmp.firstElementChild);
      this.$refs.canvas.appendChild(tmp.firstElementChild);
      const self = this;
      this.panzoom = svgPanZoom(this.$refs.canvas.firstElementChild, {
        zoomEnabled: true,
        panEnabled: true,
        mouseWheelZoomEnabled: !this.noMouseWheelSizes.includes(this.$mq),
        controlIconsEnabled: false,
        zoomScaleSensitivity: 0.2,
        maxZoom: 400,
        minZoom: 0.1,
        customEventsHandler: {
          init() {},
          destroy() {},
          haltEventListeners: ['touchstart', 'touchend', 'touchmove', 'touchleave', 'touchcancel'],
        },
        onZoom() { if (self.isHairline) { self.updateLineWeight(); } },
      });

      this.updateMouseWheel();
      this.updateLineWeight();
    },
    /**
     *
     * @param draw Element
     */
    initializeDrawing(draw) {
      const engrave = draw.querySelector('#vector-engrave');
      if (engrave != null) {
        this.layers.engrave = engrave;
      }

      const raster = draw.querySelector('#raster-engrave');
      if (raster != null) {
        this.layers.raster = raster;
      }

      const rasterOriginal = draw.querySelector('#raster-engrave-original');
      if (rasterOriginal != null) {
        rasterOriginal.setAttribute('stroke', '#FF00FF');
      }

      const ignored = draw.querySelector('#ignored');
      if (ignored != null) {
        this.layers.ignored = ignored;
        ignored.style.display = 'none';
      }

      const cut = draw.querySelector('#vector-cut');
      if (cut != null) {
        this.layers.cut = cut;
      }

      const bb = draw.querySelector('#boundingbox');

      if (bb != null) {
        const gridDom = bb.cloneNode(true);
        gridDom.setAttribute('id', 'grid');
        gridDom.setAttribute('stroke', 'none');
        // gridDom.setAttribute('display', 'none');
        gridDom.firstElementChild.setAttribute('fill', 'url(#largeGrid)');
        gridDom.firstElementChild.setAttribute('stroke', 'none');
        bb.parentNode.insertBefore(gridDom, bb.nextElementSibling);
        // bb.setAttribute('cursor', 'pointer');
        bb.setAttribute('fill', '#ffffff');
        bb.setAttribute('stroke', 'none');
        const firstElement = bb.firstElementChild;
        if (firstElement != null) {
          firstElement.setAttribute('fill', '#ffffff');
          firstElement.setAttribute('stroke', 'none');
        }
        this.layers.boundingbox = bb;
      }
    },
    updateLineWeight() {
      const svgEl = document.querySelector('.svg-canvas svg');
      if (!svgEl) {
        return;
      }
      if (this.isHairline) {
        svgEl.style.strokeWidth = 0.3 / this.panzoom.getZoom();
        if (this.layers.raster != null) {
          this.layers.raster.setAttribute('fill', 'none');
          this.layers.raster.setAttribute('stroke', '#FF00FF');
          this.layers.raster.removeAttribute('stroke-width');
        }
      } else {
        svgEl.style.strokeWidth = 0.3;
        if (this.layers.raster) {
          this.layers.raster.setAttribute('fill', '#FF00FF');
          this.layers.raster.setAttribute('stroke', 'none');
          this.layers.raster.setAttribute('stroke-width', 0);
        }
      }
    },
    zoomin() {
      if (this.panzoom != null) {
        this.panzoom.zoomIn();
      }
    },
    zoomout() {
      if (this.panzoom != null) {
        this.panzoom.zoomOut();
      }
    },
    zoomreset() {
      if (this.panzoom != null) {
        this.panzoom.center();
        this.panzoom.resetZoom();
      }
    },
    panleft() {
      if (this.panzoom != null) {
        this.panzoom.panBy({ x: 50, y: 0 });
      }
    },
    panup() {
      if (this.panzoom != null) {
        this.panzoom.panBy({ x: 0, y: 50 });
      }
    },
    pandown() {
      if (this.panzoom != null) {
        this.panzoom.panBy({ x: 0, y: -50 });
      }
    },
    panright() {
      if (this.panzoom != null) {
        this.panzoom.panBy({ x: -50, y: 0 });
      }
    },
    addDraggingIcon() {
      const svgEl = document.querySelector('.svg-canvas svg');
      if (svgEl) { svgEl.classList.add('isDragging'); }
    },
    removeDraggingIcon() {
      const svgEl = document.querySelector('.svg-canvas svg');
      if (svgEl) { svgEl.classList.remove('isDragging'); }
    },
  },
};
</script>

<!-- eslint-disable max-len //-->
<style lang="scss">

  .svg-canvas svg {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;

    &.isDragging {
      /* closed hand cursor */
      cursor: url('data:image/png;base64,AAACAAEAICACAAcABQAwAQAAFgAAACgAAAAgAAAAQAAAAAEAAQAAAAAAAAEAAAAAAAAAAAAAAgAAAAAAAAAAAAAA////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD8AAAA/AAAAfwAAAP+AAAH/gAAB/8AAAH/AAAB/wAAA/0AAANsAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA//////////////////////////////////////////////////////////////////////////////////////gH///4B///8Af//+AD///AA///wAH//+AB///wAf//4AH//+AD///yT/////////////////////////////8='), auto;
    }
  }
  .svg-canvas svg {
    // border: 1px solid black;
    vector-effect: non-scaling-stroke;
    // height: calc(100vh - 150px);
  }

  @media (min-width: 768px) {
    .svg-canvas {
      height: calc(100vh - 360px);
    }
  }

  .svg-canvas-container {
    position: relative;
  }

  .use-two-fingers {
    &.show {
      display: block;
    }
    position: absolute;
    width: 100%; height: 100%;
    background-color: #777777;
    color: #ffffff;
    text-align: center;
    padding-top: 30px;
    display: none;
  }
  .svg-pan-zoom_viewport {
    background-color: #cccccc;
  }
</style>
