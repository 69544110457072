<template>
  <div class="thank-you-page row no-gutters justify-content-center helpModal">
    <div class="col-md-12 main-col pt-3">
      <div class="row no-gutters">
        <div v-if="cart"
          class="col-md-12 col-lg-12">
          <div class="card shadow-1 text-center pb-0 mb-2 mb-md-3 mx-md-2 bg-success">
            <div class="card-body p-3"
              v-html="renderPage($store.state.pages.thank_you)"
              @click="handleClick">
            </div>
          </div>
        </div>
      </div>

      <div class="row no-gutters">

        <div v-if="cart"
          class="col-sm-12 col-md-6">
          <div class="card shadow-1 pb-3 mb-2 mb-md-3 mx-md-2 mr-md-1">
            <div class="card-body p-3"
              v-html="renderPage($store.state.pages.what_happens_next)"
              @click="handleClick">
            </div>
          </div>

          <div class="card pb-3 mb-2 mb-md-3 mx-md-2 mr-md-1">
            <div class="card-body p-3"
              v-html="renderPage($store.state.pages.questions)"
              @click="handleClick">
            </div>
          </div>
        </div>

        <div v-if="cart"
          class="col-sm-12 col-md-6">
          <div class="card pb-3 mr-md-2 mb-2 mb-md-3 ml-md-1">
            <div class="card-body p-3"
              v-html="renderPage($store.state.pages.payment_information)"
              @click="handleClick">
            </div>

            <div class="card-body px-3 pb-3" v-if="cart.confirmation.paymentMethod === 'PREPAID'">
              <p>
                <strong>{{$t('thank_you_page.order_paid')}}</strong><br />
                <strong>{{$t('thank_you_page.payment_method')}}:</strong> {{$t('thank_you_page.online_payment')}}<br/>
                <strong>{{$t('thank_you_page.payment_date')}}:</strong> {{ paymentDate }}<br />
                <strong>{{$t('thank_you_page.paid_by')}}:</strong> {{ cart.confirmation.paymentReference }} <br />
              </p>
            </div>

            <div class="card-body px-3 pb-3" v-if="cart.confirmation.paymentMethod === 'POSTPAID'">
              <p>
                <strong>{{$t('thank_you_page.payment_method')}}:</strong> {{$t('thank_you_page.by_invoice')}}<br />
                <strong>{{$t('thank_you_page.due_date')}}: </strong> {{invoiceDueDate}}
              </p>
            </div>

            <div class="card-body pl-3">
              <b-button
                size="xs"
                variant="light"
                :href="invoiceLink"
                target="_blank"
                class="download-btn mb-3 mr-2">
                  <i class="material-icons">get_app</i> {{$t('thank_you_page.download_invoice')}}
              </b-button>

              <b-button
                size="xs"
                variant="light"
                :href="confirmationLink"
                target="_blank"
                class="download-btn mb-3 mr-2">
                  <i class="material-icons">get_app</i> {{$t('thank_you_page.download_order_confirmation')}}
              </b-button>

            </div>
          </div>
          <div v-if="cart.shippingMethod === 'SHIPPING'"
            class="card shadow-1 pb-3 mr-md-2 mb-2 mb-md-3 ml-md-1" >
            <div class="card-body p-3"
                 v-html="renderPage($store.state.pages.delivery_information)"
                 @click="handleClick"></div>
          </div>

          <div v-if="cart.shippingMethod === 'PICKUP'"
            class="card shadow-1 pb-3 mr-md-2 mb-2 mb-md-3 ml-md-1">
            <div class="card-body p-3"
              v-html="renderPage($store.state.pages.pickup_information)"
              @click="handleClick">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <google-survey-opt-in v-if="paymentIsRecent && dataForSurveyLoaded"
      :order-id="orderId"
      :email="userEmail"
      :delivery-country="deliveryCountry"
      :estimated-delivery-date="estimatedDelivery" /> -->

  </div>
</template>

<script>
import _get from 'lodash/get';
import axios from 'axios';
import Vue from 'vue';
// import GoogleSurveyOptIn from 'src/components/tools/GoogleSurveyOptIn';

export default {
  // components: {
  //   GoogleSurveyOptIn,
  // },
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    if (this.paymentIsRecent) {
      this.$eventBus.$emit('gtm.seenConfirmation', this.orderId);
      this.$eventBus.$emit('odoo.event', { event: 'payment_succeeded', msg: 'Payment succeeded' });
    }
  },
  computed: {
    variables() {
      let address = '';
      if (this.cart.deliveryAddress !== null) {
        address = this.cart.deliveryAddress.trim().replace(/(?:\n)+/g, '<br />');
      }
      return {
        'order.id': this.orderId,
        invoiceLink: this.invoiceLink,
        'priority.deliveryDays': this.$tools.date.getRelativeDay(this.priority.estimatedDelivery, this.$i18n.locale),
        address,
      };
    },
    estimatedDelivery() {
      if (this.priority.estimatedDelivery) {
        const d = new Date(this.priority.estimatedDelivery);
        return d.toISOString().split('T')[0];
      }
      return '';
    },
    paymentIsRecent() {
      return this.cart.confirmation.paymentIsRecent;
    },
    paymentDate() {
      return `${this.$tools.date.getRelativeDay(this.cart.confirmation.paymentDate, this.$i18n.locale)}
        ${this.$tools.date.getTime(this.cart.confirmation.paymentDate, this.$i18n.locale)}`;
    },
    orderBeforeTime() {
      if (this.priority) {
        return this.priority.orderBeforeTime;
      }
      return '';
    },
    deliveryDays() {
      if (this.priority) {
        return this.priority.deliveryDays;
      }
      return '';
    },
    invoiceDueDate() {
      return `${this.$tools.date.getRelativeDay(this.cart.confirmation.paymentDueDate.date, this.$i18n.locale)}
        ${this.$tools.date.getTime(this.cart.confirmation.paymentDueDate.date, this.$i18n.locale)}`;
    },
    userEmail() {
      return this.$store.state.user.email_address;
    },
    deliveryCountry() {
      return this.cart.address.delivery_country;
    },
    comment() {
      return this.$store.state.cart.userComment;
    },
    // dataForSurveyLoaded() {
    //   if (this.orderId && this.deliveryCountry && this.userEmail && this.estimatedDelivery) {
    //     return true;
    //   }
    //   return false;
    // },
    orderId() {
      const currentId = parseInt(this.$route.params.orderId, 10);
      if (!currentId) {
        return parseInt(this.$store.state.settings.order_id, 10);
      }
      return currentId;
    },
    cart() {
      return this.$store.state.cart;
    },
    name() {
      return this.cart.address.invoice_name;
    },
    orderDrawings() {
      return this.$store.state.cart.orderDrawings;
    },
    showDelivery() {
      return this.$store.state.cart.shippingMethod !== 'PICKUP';
    },
    alternativeDelivery: {
      get() {
        return !this.addressData.invoice_is_delivery_address;
      },
      set(value) {
        Vue.set(this.addressData, 'invoice_is_delivery_address', !value);
      },
    },
    invoiceLink() {
      return `${this.$config.apiUrl}/document/invoice/${this.$store.state.cart.invoiceId}`;
    },
    confirmationLink() {
      return `${this.$config.apiUrl}/document/${this.$store.state.cart.confirmationId}`;
    },
    hasAlternativeDelivery() {
      return this.hasDelivery && this.alternativeDelivery;
    },
    showVat() {
      return this.$store.state.settings.showVat;
    },
    showVatInformation() {
      return this.euVatCountries.some((code) => code === this.cart.address.invoice_country);
    },
    hasShippingToEu() {
      return this.euVatCountries.some((code) => code === this.cart.address.delivery_country);
    },
    priority() {
      return _get(this.$store.state.cart.priorities, this.$store.state.cart.priorityId, '');
    },
    // costTable() {
    //   return this.$store.state.cart.costTable;
    // },
  },
  methods: {
    handleClick(event) {
      if (event.target.tagName === 'A') {
        const target = event.target.attributes.href.nodeValue;
        if (target.startsWith('tel:') || target.startsWith('mailto:')) {
          // don't catch
        } else if (!target.startsWith('http')) {
          event.preventDefault();
          this.$router.push({ path: target });
        } else {
          const targetUrl = new URL(target);
          if (window.location.hostname === targetUrl.hostname) {
            event.preventDefault();
            this.$router.push({ path: targetUrl.pathname, hash: targetUrl.hash });
          } else {
            // external link, don't catch
          }
        }
      }
    },
    renderPage(pageHtml) {
      let html = `${pageHtml}`;
      Object.keys(this.variables).forEach((name) => {
        html = this.replaceVariable(html, name, this.variables[name]);
        html = this.xl_to_large(html);
      });
      return html;
    },
    replaceVariable(html, name, value) {
      const safeName = this.escapeRegex(name).toLowerCase();
      const re = new RegExp(`{{\\s*${safeName}\\s*}}`, 'gi');
      return html.replace(re, value);
    },
    escapeRegex(s) {
      // eslint-disable-next-line no-useless-escape
      return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    },
    // getSubtotalDotNotation(name, vat) {
    //   let total = this.costTable[name].amount;
    //   if (vat) {
    //     total += Object.values(this.costTable[name].vat).reduce((sum, value) => sum + value, 0);
    //   }
    //   return new Intl.NumberFormat('en', { minimumFractionDigits: 2 }).format(total / 100);
    // },
    xl_to_large(html) {
      return html.replace(/thumb\/xl/g, 'thumb/large');
    },
    payOrder() {
      this.$eventBus.$emit('odoo.event', { event: 'payment_started', msg: 'Payment started from thank you page' });
      // this.$eventBus.$emit('gtm.startedPayment', {
      //   orderId: this.$store.state.cart.orderId,
      //   totalPrice: this.getSubtotalDotNotation('total', false),
      // });

      const formData = new FormData();
      formData.append('order_id', this.$store.state.cart.orderId);
      formData.append('payment_method', this.paymentMethod);
      const ga = this.$tools.getCookie('_ga');
      if (ga !== '') {
        formData.append('ga', ga);
      }
      return axios.post(
        `${this.$config.apiUrl}/order/pay`,
        formData,
        { withCredentials: true },
      )
        .then((response) => {
          window.location.href = `${this.$config.baseUrl}/${response.data.result.paymentUrl}`;
        }).catch((err) => {
          this.$eventBus.error(err);
        });
    },
  },
};
</script>
<style lang="scss">
  .thank-you-page {
    .address {
      display: block;
      border :1px solid #f2f2f2;
      margin: .5rem;
      padding: .5rem;
      max-width: 350px;
    }

    img {
      width: 100%;
    }

    a.hreflink i.material-icons {
      display: none;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 100%;
      font-size: 1rem;

      .fix-link::before {
        display: none;
      }
    }

    h1 {
      font-size: 1.2rem;
    }

    ul {
      margin-top: 1rem;
      padding-left: 1.25rem;
    }
  }
</style>
