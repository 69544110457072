<template>
  <form method="post" class="forgot-password-confirm-code form-dark signin-form" @submit.prevent="submit">
    <div class="input-row-full">
      <h2>{{$t('loginpanel.forgot_password_title')}}</h2>
      <p>{{$t('loginpanel.forgot_password_hint')}}</p>
    </div>
    <form-widget-input
      v-model.trim="$v.confirmationCode.$model"
      :error="error('confirmationCode')"
      :placeholder="$t('loginpanel.code')"
      autocomplete="false"
      size="sm"
      row-class="input-row-full" />

    <div class="input-row-full">
      <loading-button
        :loading="loading"
        variant="primary"
        class="btn-light btn-block mt-3"
        @click="submit">{{$t('loginpanel.create_new_password')}}
      </loading-button>
    </div>
  </form>
</template>
<script>
import FormWidgetInput from 'src/components/form/FormWidgetInput';
import axios from 'axios';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import LoadingButton from 'src/components/tools/LoadingButton';

export default {
  components: {
    LoadingButton,
    FormWidgetInput,
  },
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  mixins: [validationMixin],
  data() {
    return {
      loading: false,
      confirmationCode: '',
      serverErrors: {
        confirmationCode: null,
      },
    };
  },
  validations() {
    return {
      confirmationCode: {
        required,
      },
    };
  },
  watch: {
    confirmationCode() {
      this.serverErrors.confirmationCode = null;
    },
  },
  computed: {
    errors() {
      return {
        confirmationCode: this.$i18n.t('loginpanel.enter_the_security_code_you_received_by_email'),
      };
    },
  },
  methods: {
    error(name) {
      if (this.$v[name].$error) {
        return this.errors[name];
      }
      if (name in this.serverErrors && this.serverErrors[name] != null && this.serverErrors[name].length > 0) {
        return this.serverErrors[name];
      }
      return '';
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.loading = true;
      const formData = new FormData();
      formData.append('code', this.code);
      formData.append('confirmation', this.confirmationCode);
      axios.post(`${this.$config.apiUrl}/user/forgotPasswordTokenIsValid`,
        formData, { withCredentials: true })
        .then(() => {
          this.$emit('newConfirmationCode', this.confirmationCode);
        })
        .catch((error) => {
          if (error.response.data.error === 'INCORRECT_FORGOT_PASSWORD_CODE') {
            this.serverErrors.confirmationCode = this.$i18n.t('loginpanel.code_is_invalid_or_older_than_30_minutes');
          } else if (error.response.data.error === 'INCORRECT_FORGOT_PASSWORD_REQUEST') {
            this.serverErrors.confirmationCode = this.$i18n.t('loginpanel.code_is_invalid_or_older_than_30_minutes');
          } else {
            this.$eventBus.$emit('snlb.error', error);
          }
          // TODO: CATCH ERROR AND SET TO VALIDATION CODE
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.forgot-password-confirm-code {
  .invalid-feedback {
    background-color: #fff;
    font-weight: bold;
    padding: 4px;
  }
}
</style>
