<template>
  <!-- eslint-disable -->
  <div class="logo-animated">
    <svg width="100%" height="100%" viewBox="-10 -10 320 130" version="1.1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve">
      <g class="svg-logo-bg">
        <path d="M15.4,59.9c0.3,2.7 1,4.6 2.1,5.8c1.9,2 5.4,3 10.5,3c2.5,0.1 4.9,-0.3 7.1,-1.3c2.2,-1 3.3,-3.6 2.3,-5.9c-0.4,-0.8 -1,-1.5 -1.7,-2c-1.4,-0.9 -6.7,-2.4 -15.9,-4.6c-6.6,-1.6 -11.2,-3.6 -13.9,-6.1c-2.7,-2.4 -4,-5.9 -4,-10.4c0,-5.3 2.3,-10.4 6.3,-13.8c4.2,-3.8 10.1,-5.8 17.8,-5.8c7.3,0 13.2,1.4 17.7,4.3c4.5,2.9 7.2,7.9 7.9,15l-15.3,0c-0.1,-1.7 -0.7,-3.3 -1.7,-4.6c-1.7,-2.1 -4.5,-3.1 -8.5,-3.1c-3.3,0 -5.7,0.5 -7.1,1.5c-1.3,0.8 -2.1,2.1 -2.1,3.6c-0.1,1.6 0.8,3.1 2.2,3.8c1.5,0.8 6.8,2.2 15.8,4.2c6,1.4 10.6,3.5 13.6,6.4c3,2.8 4.6,6.8 4.5,10.9c0.1,5.4 -2.2,10.6 -6.4,14.1c-4.3,3.6 -10.4,5.4 -19.3,5.4c-9.1,0 -16.4,-1.9 -20.8,-5.8c-4.3,-3.5 -6.7,-8.9 -6.5,-14.6l15.4,0Z"/>
        <path d="M104.8,23.8c3.8,3.1 5.7,8.3 5.7,15.6l0,39.1l-15.6,0l0,-35.3c0.1,-2.4 -0.3,-4.8 -1.2,-7c-1.5,-3 -4.3,-4.5 -8.4,-4.5c-5.1,0 -8.6,2.2 -10.5,6.5c-1.1,2.8 -1.7,5.8 -1.6,8.8l0,31.4l-15.1,0l0,-57.9l14.7,0l0,8.5c1.5,-2.4 3.3,-4.6 5.5,-6.4c3.4,-2.4 7.6,-3.7 11.8,-3.5c6,-0.1 10.9,1.5 14.7,4.7"/>
        <rect x="118.5" y="20.4" width="15.4" height="58"/>
        <path d="M134.4,89.6l1.8,0.1l1,0c1.2,0.1 2.4,-0.3 3.4,-1.1c0.8,-0.7 1.1,-2.1 1.1,-4.2l0,-63.7l15.2,0l0,64c0,5.9 -1.2,10.2 -3.5,13c-2.3,2.8 -6.8,4.2 -13.6,4.2c-0.5,0 -1.1,0 -2,-0.1l-3.4,-0.1l0,-12.1Z"/>
        <rect x="167.1" y="0" width="15.2" height="78.4"/>
        <path d="M240.8,74.4c-0.6,-1.2 -0.9,-2.6 -0.9,-4c0,-1.5 -0.1,-2.9 -0.1,-4.1c-0.3,-4.6 -4.2,-8.2 -8.8,-8.2c-3.6,0 -6.5,2.1 -7.4,4.1c-0.7,1.7 -1.8,3.1 -3.2,4.2c-2.7,1.9 -5.9,2.9 -9.2,2.9c-1.9,0.1 -3.8,-0.6 -5.3,-1.7c-1.5,-1.2 -2.2,-3 -2.2,-5.6c-0.1,-2.7 1.2,-5.2 3.6,-6.4c2.2,-1.1 4.6,-1.8 7,-2.1l3.4,-0.6c3.3,-0.7 6.5,-1.7 9.7,-2.8c3.4,-1.3 11.6,-4.8 11.6,-13.5c0,-10.8 -6.5,-14.3 -12.4,-15.9c-3.6,-1 -7.4,-1.5 -11.2,-1.5c-9.8,0 -16.7,2.6 -20.7,7.7c-2.7,3.6 -4.1,8 -4.3,12.5l14.6,0c0.2,-1.9 1,-3.8 2.2,-5.3c1.5,-1.8 4.1,-2.7 7.7,-2.7c3.2,0 5.7,0.5 7.4,1.3c1.7,0.9 2.5,2.5 2.5,4.9c0,2 -1.1,3.4 -3.3,4.3c-2,0.7 -4,1.2 -6.1,1.3l-5.2,0.6c-5.9,0.7 -10.4,2 -13.4,3.7c-5.5,3.2 -8.3,8.4 -8.3,15.5c0,5.5 1.7,9.8 5.2,12.8c3.7,3.1 8.3,4.7 13.1,4.5c3.9,0.1 7.7,-0.9 11.1,-2.8c2.7,-1.6 5.2,-3.5 7.3,-5.8c0.1,1.3 0.3,2.5 0.4,3.5c0.2,1.1 0.6,2.2 1,3.3l16.5,0l0,-2.2c-0.9,-0.5 -1.7,-1.1 -2.3,-1.9"/>
        <path d="M297.5,27.4c-4.3,-5.5 -10.3,-8.3 -18,-8.3c-3.7,-0.1 -7.3,0.8 -10.5,2.7c-2.5,1.6 -4.7,3.7 -6.4,6.2l0,-27.9l-15.2,0l0,34c0,5 4,9.1 9.1,9.1c3.2,0 6.2,-1.7 7.8,-4.4c2.2,-4.3 5.8,-6.4 10.9,-6.4c5.5,0 9.2,2.6 11.3,7.7c1.2,3.3 1.7,6.7 1.6,10.2c0,5.1 -1.1,9.3 -3.3,12.5c-2,3.1 -5.5,4.9 -9.2,4.7c-4.5,0 -7.9,-1.6 -10.1,-4.9c-0.7,-1 -1.2,-2 -1.7,-3.2c-1.1,-2.4 -4.3,-4.1 -7.5,-4.1c-4.9,0 -8.9,4 -8.9,8.9l0,14.2l15,0l0,-7.3c1.5,2.3 3.4,4.4 5.7,6c2.9,1.9 6.8,2.8 11.6,2.8c7.7,0 13.6,-3 17.9,-8.9c4.3,-5.9 6.4,-13.4 6.4,-22.4c0,-8.6 -2.2,-15.7 -6.5,-21.2"/>
      </g>
      <g class="svg-logo-laser">
        <path d="M15.4,59.9c0.3,2.7 1,4.6 2.1,5.8c1.9,2 5.4,3 10.5,3c2.5,0.1 4.9,-0.3 7.1,-1.3c2.2,-1 3.3,-3.6 2.3,-5.9c-0.4,-0.8 -1,-1.5 -1.7,-2c-1.4,-0.9 -6.7,-2.4 -15.9,-4.6c-6.6,-1.6 -11.2,-3.6 -13.9,-6.1c-2.7,-2.4 -4,-5.9 -4,-10.4c0,-5.3 2.3,-10.4 6.3,-13.8c4.2,-3.8 10.1,-5.8 17.8,-5.8c7.3,0 13.2,1.4 17.7,4.3c4.5,2.9 7.2,7.9 7.9,15l-15.3,0c-0.1,-1.7 -0.7,-3.3 -1.7,-4.6c-1.7,-2.1 -4.5,-3.1 -8.5,-3.1c-3.3,0 -5.7,0.5 -7.1,1.5c-1.3,0.8 -2.1,2.1 -2.1,3.6c-0.1,1.6 0.8,3.1 2.2,3.8c1.5,0.8 6.8,2.2 15.8,4.2c6,1.4 10.6,3.5 13.6,6.4c3,2.8 4.6,6.8 4.5,10.9c0.1,5.4 -2.2,10.6 -6.4,14.1c-4.3,3.6 -10.4,5.4 -19.3,5.4c-9.1,0 -16.4,-1.9 -20.8,-5.8c-4.3,-3.5 -6.7,-8.9 -6.5,-14.6l15.4,0Z"/>
        <path d="M104.8,23.8c3.8,3.1 5.7,8.3 5.7,15.6l0,39.1l-15.6,0l0,-35.3c0.1,-2.4 -0.3,-4.8 -1.2,-7c-1.5,-3 -4.3,-4.5 -8.4,-4.5c-5.1,0 -8.6,2.2 -10.5,6.5c-1.1,2.8 -1.7,5.8 -1.6,8.8l0,31.4l-15.1,0l0,-57.9l14.7,0l0,8.5c1.5,-2.4 3.3,-4.6 5.5,-6.4c3.4,-2.4 7.6,-3.7 11.8,-3.5c6,-0.1 10.9,1.5 14.7,4.7"/>
        <rect x="118.5" y="20.4" width="15.4" height="58"/>
        <path d="M134.4,89.6l1.8,0.1l1,0c1.2,0.1 2.4,-0.3 3.4,-1.1c0.8,-0.7 1.1,-2.1 1.1,-4.2l0,-63.7l15.2,0l0,64c0,5.9 -1.2,10.2 -3.5,13c-2.3,2.8 -6.8,4.2 -13.6,4.2c-0.5,0 -1.1,0 -2,-0.1l-3.4,-0.1l0,-12.1Z"/>
        <rect x="167.1" y="0" width="15.2" height="78.4"/>
        <path d="M240.8,74.4c-0.6,-1.2 -0.9,-2.6 -0.9,-4c0,-1.5 -0.1,-2.9 -0.1,-4.1c-0.3,-4.6 -4.2,-8.2 -8.8,-8.2c-3.6,0 -6.5,2.1 -7.4,4.1c-0.7,1.7 -1.8,3.1 -3.2,4.2c-2.7,1.9 -5.9,2.9 -9.2,2.9c-1.9,0.1 -3.8,-0.6 -5.3,-1.7c-1.5,-1.2 -2.2,-3 -2.2,-5.6c-0.1,-2.7 1.2,-5.2 3.6,-6.4c2.2,-1.1 4.6,-1.8 7,-2.1l3.4,-0.6c3.3,-0.7 6.5,-1.7 9.7,-2.8c3.4,-1.3 11.6,-4.8 11.6,-13.5c0,-10.8 -6.5,-14.3 -12.4,-15.9c-3.6,-1 -7.4,-1.5 -11.2,-1.5c-9.8,0 -16.7,2.6 -20.7,7.7c-2.7,3.6 -4.1,8 -4.3,12.5l14.6,0c0.2,-1.9 1,-3.8 2.2,-5.3c1.5,-1.8 4.1,-2.7 7.7,-2.7c3.2,0 5.7,0.5 7.4,1.3c1.7,0.9 2.5,2.5 2.5,4.9c0,2 -1.1,3.4 -3.3,4.3c-2,0.7 -4,1.2 -6.1,1.3l-5.2,0.6c-5.9,0.7 -10.4,2 -13.4,3.7c-5.5,3.2 -8.3,8.4 -8.3,15.5c0,5.5 1.7,9.8 5.2,12.8c3.7,3.1 8.3,4.7 13.1,4.5c3.9,0.1 7.7,-0.9 11.1,-2.8c2.7,-1.6 5.2,-3.5 7.3,-5.8c0.1,1.3 0.3,2.5 0.4,3.5c0.2,1.1 0.6,2.2 1,3.3l16.5,0l0,-2.2c-0.9,-0.5 -1.7,-1.1 -2.3,-1.9"/>
        <path d="M297.5,27.4c-4.3,-5.5 -10.3,-8.3 -18,-8.3c-3.7,-0.1 -7.3,0.8 -10.5,2.7c-2.5,1.6 -4.7,3.7 -6.4,6.2l0,-27.9l-15.2,0l0,34c0,5 4,9.1 9.1,9.1c3.2,0 6.2,-1.7 7.8,-4.4c2.2,-4.3 5.8,-6.4 10.9,-6.4c5.5,0 9.2,2.6 11.3,7.7c1.2,3.3 1.7,6.7 1.6,10.2c0,5.1 -1.1,9.3 -3.3,12.5c-2,3.1 -5.5,4.9 -9.2,4.7c-4.5,0 -7.9,-1.6 -10.1,-4.9c-0.7,-1 -1.2,-2 -1.7,-3.2c-1.1,-2.4 -4.3,-4.1 -7.5,-4.1c-4.9,0 -8.9,4 -8.9,8.9l0,14.2l15,0l0,-7.3c1.5,2.3 3.4,4.4 5.7,6c2.9,1.9 6.8,2.8 11.6,2.8c7.7,0 13.6,-3 17.9,-8.9c4.3,-5.9 6.4,-13.4 6.4,-22.4c0,-8.6 -2.2,-15.7 -6.5,-21.2"/>
      </g>
    </svg>
  </div>
  <!-- eslint-enable-->
</template>

<script>
export default {

};
</script>
