<template>
  <div v-if="exists"
    class="list-group-item shadow-none cart-list-item cart-custom-list-item p-2 pb-2">

    <div class="cart-list-item-title">
      <div>{{ customItem.name }}</div>
    </div>

    <div class="cart-list-item-quantity">
      <cart-list-item-quantity-widget
        v-if="$store.getters.isAdmin && isDraft"
        :id="parseInt(id)"
        :quantity="parseInt(customItem.quantity)"
        productType="OrderCustomItem"
        :tabstop="tabstop+1000"/>

      <div v-else class="pr-2">
        {{customItem.quantity}}
      </div>
    </div>

    <div class="cart-list-item-price-container pl-2">
      <div>×</div>
      <div class="cart-list-item-price">
        <div>{{ showPriceExclVat }}</div>
        <div class="small text-muted">{{ showPrice }} incl.</div>
      </div>
    </div>

    <div class="cart-list-item-subtotal ml-2">
      <div class="cart-list-item-subtotal-total-price">{{ showTotalPriceExclVat }}</div>
      <div class="small text-muted">{{ showTotalPrice }} incl.</div>
    </div>

    <div v-if="$store.getters.isAdmin"
      class="cart-list-item-standard-actions-container">
      <b-button
        size="xs"
        variant="flat"
        class="cart-list-item-delete px-1"
        @click="deleteOrderCustomItem">
          <i class="material-icons">delete</i>
      </b-button>
    </div>
  </div>

  <div v-else>
    <h4>Product {{ id }} niet gevonden</h4>
  </div>
</template>

<script>
import _get from 'lodash/get';
import CartListItemQuantityWidget from 'src/components/cart/edit/CartListItemQuantityWidget';
import TweenTools from 'src/lib/TweenTools';

export default {
  components: {
    CartListItemQuantityWidget,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    tabstop: {
      type: Number,
      default: 0,
    },
    isDraft: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      is_drawing_product: false, // temp fix to distinguish between drawing and product in sync()
      animatedTotal: null,
      animatedTotalExclVat: null,
      animatedPerPiece: null,
      animatedPerPieceExclVat: null,
    };
  },
  computed: {
    exists() {
      return !!_get(this.$store.state.cart.orderCustomItems, this.id);
    },
    customItem() {
      return this.$store.state.cart.orderCustomItems[this.id];
    },
    totalPriceRaw() {
      return this.$config.getPrice(this.customItem.costs.total, true);
    },
    totalPriceRawExclVat() {
      return this.$config.getPrice(this.customItem.costs.total, false);
    },
    showTotalPrice() {
      if (this.animatedTotal !== null) {
        return this.$config.currencyFormat.format(this.animatedTotal / 100);
      }
      return this.$config.currencyFormat.format(this.totalPriceRaw / 100);
    },
    showTotalPriceExclVat() {
      if (this.animatedTotalExclVat !== null) {
        return this.$config.currencyFormat.format(this.animatedTotalExclVat / 100);
      }
      return this.$config.currencyFormat.format(this.totalPriceRawExclVat / 100);
    },
    totalPrice() {
      return this.$config.formatPrice(this.customItem.costs.total, false);
    },
    priceRaw() {
      return this.$config.getPrice(this.customItem.costs.perPiece, true);
    },
    priceRawExclVat() {
      return this.$config.getPrice(this.customItem.costs.perPiece, false);
    },
    price() {
      return this.$config.formatPrice(this.customItem.costs.perPiece, false);
    },
    showPrice() {
      if (this.animatedPerPiece !== null) {
        return this.$config.currencyFormat.format(this.animatedPerPiece / 100);
      }
      return this.$config.currencyFormat.format(this.priceRaw / 100);
    },
    showPriceExclVat() {
      if (this.animatedPerPieceExclVat !== null) {
        return this.$config.currencyFormat.format(this.animatedPerPieceExclVat / 100);
      }
      return this.$config.currencyFormat.format(this.priceRawExclVat / 100);
    },
  },
  watch: {
    cost(value, oldValue) {
      TweenTools.tweenNumber(value, oldValue, this, 'animatedCost');
    },
    costExclVat(value, oldValue) {
      TweenTools.tweenNumber(value, oldValue, this, 'animatedCostExclVat');
    },
  },
  methods: {
    deleteOrderCustomItem(event) {
      event.preventDefault();
      event.stopPropagation();
      this.$emit('delete', this);
      return false;
    },
  },
};
</script>

<style lang="scss">
  .cart-custom-list-item {
    grid-template-columns: 35px auto 130px;

    .cart-list-item-title {
      grid-column: 1/4;
      grid-row: 1;
    }

    .cart-list-item-quantity {
      grid-column: 1;
      grid-row: 2;
      justify-self: flex-end;
    }

    .cart-list-item-price-container {
      grid-column: 2;
      grid-row: 2;
    }

    .cart-list-item-subtotal {
      grid-column: 3;
      grid-row: 2;
      justify-self: flex-end;
    }

    @media (min-width: 992px) {
      grid-template-columns: 30px 1fr 1fr 130px 130px;

      .cart-list-item-title {
        grid-column: 3/6;
      }

      .cart-list-item-quantity {
        grid-column: 3;
      }

      .cart-list-item-price-container {
        grid-column: 4;
      }

      .cart-list-item-subtotal {
        grid-column: 5;
      }
    }
  }
</style>
