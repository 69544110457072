<template>
  <div class="bg-light-3">
    <css />
    <main-menu
      :is-signed-in="userSignedIn"
      :order-id="orderId"
      :cart-count="cartCount" />

    <div class="top-buffer"
      :class="{'and-message-bar': showMessageBar}" />

    <message-bar v-if="showMessageBar && !$store.getters.isAdmin" />
    <admin-bar v-if="$store.getters.isAdmin" />

    <sub-menu
      :show-submenu="showSubmenu"
      :showing-message-bar="showMessageBar || $store.getters.isAdmin"
      :order-id="orderId"
      :loading="!ready" />

    <div v-if="ready" class="main-container"
      :class="{
        'push-down': showSubmenu,
        'container': $route.name !== 'frontpage' && useWrapper,
        'frontpage': $route.name === 'frontpage',
        'container-fluid': $route.name === 'frontpage',
        'and-message-bar': (showMessageBar && !showSubmenu) || $store.getters.isAdmin,
      }">
      <router-view
        :is-signed-in="userSignedIn"
        :order-id="orderId"
        :showing-message-bar="showMessageBar"
        :contentfulPageSlug="contentfulPageSlug" />
    </div>

    <user-loader :sign-in-required="signInRequired" />

    <vuedal-component />

    <notifications/>

    <file-uploader v-if="userLoaded"
      ref="uploader"
      :visible="showUploader" />

    <!-- TODO: move out of global layout -->
    <request-quote-modal v-if="ready" />

    <help-modal v-if="ready" />

    <page-footer v-if="ready" />

    <cookie-banner />
    <gtm-utils />
    <odoo-utils />
    <!-- <live-chat /> -->

  </div>
</template>

<script>
import { Component as VuedalComponent, Bus as VuedalBus } from 'vuedals';
import MainMenu from 'src/components/nav/MainMenu';
import SubMenu from 'src/components/nav/SubMenu';
import MessageBar from 'src/components/nav/MessageBar';
import AdminBar from 'src/components/admin/AdminBar';

// import LiveChat from 'src/components/tools/LiveChat';

import UserLoader from 'src/components/loader/UserLoader';
import Css from 'src/views/Css';

import FileUploader from 'src/components/upload/FileUploader';
import Notifications from 'src/components/tools/Notifications';
import RequestQuoteModal from 'src/views/RequestQuoteModal';
import HelpModal from 'src/components/tools/HelpModal';
import PageFooter from 'src/views/PageFooter';

import GtmUtils from 'src/components/tools/GtmUtils';
import OdooUtils from 'src/components/tools/OdooUtils';
import CookieBanner from 'src/components/tools/CookieBanner';

export default {
  metaInfo() {
    const i18n = this.$i18n;
    const defaultDescription = this.$i18n.t('meta_description');
    const defaultTitle = this.$i18n.t('meta_title');

    return {
      title: defaultTitle,
      meta: [
        { vmid: 'description', name: 'description', content: defaultDescription },
      ],
      htmlAttrs: {
        lang: i18n.locale,
      },
    };
  },
  components: {
    HelpModal,
    RequestQuoteModal,
    Notifications,
    VuedalComponent,
    UserLoader,
    SubMenu,
    MainMenu,
    Css,
    FileUploader,
    PageFooter,
    GtmUtils,
    OdooUtils,
    MessageBar,
    AdminBar,
    // LiveChat,
    CookieBanner,
  },
  data() {
    return {
      showUploader: false,
    };
  },
  computed: {
    useWrapper() {
      return (!('nowrapper' in this.$route.meta) || this.$route.meta.nowrapper === false);
    },
    contentfulPageSlug() {
      if ('contentfulPageSlug' in this.$route.meta) {
        return this.$route.meta.contentfulPageSlug;
      }
      return false;
    },
    vuegtag() {
      return this.$gtag;
    },
    matched() {
      return this.$route.matched;
    },
    signInRequired() {
      const nearestAuthenticated = this.$route.matched.slice()
        .reverse()
        .find((r) => r.meta && r.meta.authenticated);
      return !!nearestAuthenticated;
    },
    userLoaded() {
      return this.$store.getters.loaded([
        'user',
      ]);
    },
    userSignedIn() {
      return this.$store.state.user !== null;
    },
    orderId() {
      const currentId = parseInt(this.$route.params.orderId, 10);
      if (!currentId) {
        return parseInt(this.$store.state.settings.order_id, 10);
      }
      return currentId;
    },
    cartCount() {
      if (this.$route.name === 'order-confirmation'
        || this.$route.name === 'order-payment') {
        return 0;
      }
      let total = 0;
      const drawings = this.$store.state.cart.orderDrawings;
      const custom = this.$store.state.cart.orderCustomItems;
      const products = this.$store.state.cart.orderProducts;
      Object.keys(drawings).forEach((idx) => { total += parseInt(drawings[idx].quantity, 10); });
      Object.keys(custom).forEach((idx) => { total += parseInt(custom[idx].quantity, 10); });
      Object.keys(products).forEach((idx) => { total += parseInt(products[idx].quantity, 10); });
      return total;
    },
    showSubmenu() {
      if (this.signInRequired && !this.userSignedIn) {
        return false;
      }
      return !!this.$route.meta.submenuType;
    },
    showMessageBar() {
      return this.$store.state.showMessageBar;
    },
    ready() {
      return this.$store.getters.loaded([
        'translations',
        'errors',
        'popups',
      ]);
    },
  },
  mounted() {
    this.$store.dispatch('loadSettings');
    this.$store.dispatch('loadMaterials');
    this.$store.dispatch('loadTemplates');
    this.$eventBus.$on('upload', this.showUpload);
    VuedalBus.$on('opened', () => {
      document.documentElement.style.overflowY = 'hidden';
    });
    VuedalBus.$on('close', () => {
      document.documentElement.style.overflowY = 'auto';
    });
    this.$nextTick(() => {
      if (this.signInRequired && !this.userSignedIn) {
        this.$eventBus.$emit('showLogin');
      }
    });
  },
  methods: {
    showUpload() {
      // if user is not signed in, show login screen.
      this.showUploader = false;
      // ugly hack. But meh. it works.
      this.$nextTick(() => {
        this.showUploader = true;
      });
    },
  },
};
</script>
<style lang="scss">
  .hreflink {
    display: none;
  }

  .main-container.container,
  .main-container.container-fluid {
    min-height: calc(80vh - 75px);

    &.and-message-bar {
      @media (min-width: 768px) {
        padding-top: 50px;
      }

      .fix-link::before {
        height: 110px;
      }

      .collapse-toggle.fix-link::before {
        height: 0px;
      }

      h2.fix-link::before,
      h3.fix-link::before {
        height: 75px;
      }
    }
  }

  .top-buffer {
    margin-top: 75px;
  }

  .btn-admin,
  .bg-admin {
    background-color: #ffe400;
    color: #000;
  }

  .border-snijlab {
    border: 1px solid #ffe400;
  }

  .info-icon {
    color: rgb(160, 160, 160);
    cursor: pointer;

    &:hover {
      color: #000;
    }
  }

  .pointer {
    cursor: pointer;
  }

  html {
    width: 100%;
    overflow-x: hidden;

  }

  label {
    font-size: 15px;
  }

  .input-icon-front {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0.2rem;
  }

  .input-icon {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0.2rem;
  }

  .push-down {
    margin-top: 0px;

    .snlb-sticky-flex {
      top: 139px;
    }
  }

  @media (max-width: 578px) {
    .modal-body, .modal-header {
      padding: 0.625rem 0.75rem
    }
  }

  .form-control.has-icon {
    padding-right: 25px;
  }

  html body .vuedals {
    z-index: 200;
  }

  .vuedals .vuedal.lg {
    position: relative;
  }

  .vuedals .vuedal.borderless {
    padding: 0;

    header {
      display: none;
    }
  }

  .vuedals .vuedal.xl {
    width: calc(100vw - 3rem);
    margin-top: 2.5em;
    position: relative;

    @media (max-width: 768px) {
      width: calc(100vw - 1rem);
      padding: 10px;
    }
  }

  .vuedals .vuedal.xs {
    width: calc(40vw);
    margin-top: 2.5em;
    position: relative;
  }

  .vuedals header {
    margin-bottom: 0;
    border: 0;
  }

  .vuedals .vuedal {
    margin: 0 auto;
    transform: none;
    left: initial;
  }
</style>
