<template>
  <div/>
</template>

<script>
import DcEditor from 'src/components/dcEditor/DcEditor';
import DcEditorModalHeader from 'src/components/dcEditor/DcEditorModalHeader';

export default {
  data() {
    return {
      closedByRoute: false,
    };
  },
  computed: {
    id() {
      return parseInt(this.$route.params.productId, 10);
    },
    returnRoute() {
      return { name: this.$route.name.replace('-editor', ''), params: this.$route.params };
    },
    ready() {
      return this.$store.getters.loaded([
        'drawingProducts',
        'materials',
        'translations',
        'user',
        'drawingConfigurations',
        'cart',
      ]) && this.hasCorrectOrder;
    },
  },
  watch: {
    $route() {
      this.closedByRoute = true;
      this.$vuedals.close();
      this.closedByRoute = false;
      this.openModal();
    },
  },
  mounted() {
    // if id does not exist, return to cart with an error..
    this.openModal();
  },
  beforeDestroy() {
    this.closedByRoute = true;
    this.$vuedals.close();
  },
  methods: {
    navigateToCart() {
      if (!this.closedByRoute) {
        this.$router.push(this.returnRoute);
      }
    },
    openModal() {
      const self = this;
      this.$vuedals.open({
        component: DcEditor,
        size: 'xl',
        props: {
          id: this.id,
          isCart: this.$route.name.includes('order'),
        },
        header: {
          component: DcEditorModalHeader,
          props: { },
        },
        onClose() {
          self.navigateToCart();
        },
        onDismiss() {
          self.navigateToCart();
        },
      });
    },
  },
};
</script>
