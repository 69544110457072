<template>
  <div :class="rowClasses">
    <b-form-checkbox
      ref="input"
      :id="id"
      :checked="value"
      :disabled="disabled"
      :readonly="readonly"
      :size="size"
      :state="state"
      :aria-invalid="hasError"
      :autofocus="autofocus"
      @input="onInput">
      {{ label }}
    </b-form-checkbox>

    <form-help-widget
      :error="error"
      :warning="warning"
      :help="help" />

  </div>
</template>

<script>
import FormWidgetBase from 'src/components/form/FormWidgetBase';

export default {
  extends: FormWidgetBase,
  props: {
    value: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    hasError() {
      return Boolean(this.error);
    },
  },
  methods: {
    onInput(event) {
      this.$emit('input', event);
    },
  },
};
</script>

<style>
</style>
