<template>
    <div class="contentful-materials-grid border mb-2" :style="cssProps">
      <div class="center-container">
        <h2 class="display-4">{{data.title}}</h2>
        <div class="grid-container" :class="{'grid-single-row': data.materials.length < 7}">

          <div v-for="material in data.materials" :key="material.sys.id" class="material-block">
            <div>
              <img :src="`${material.fields.image.fields.file.url}?w=150&h=150`" width="150" height="150"
                :alt="material.fields.image.fields.description" />
              <h3>{{material.fields.title}}</h3>
              <div>{{material.fields.text}}</div>
            </div>
            <router-link v-if="material.fields.link" class="btn btn-snijlab"
              :to="{path: strippedLink(material.fields.link)}"
              v-html="data.viewProductButtonTitle" />
          </div>

          <div class="material-block info-block">
            <div>
              <div class="cant-find-text">{{data.cantFindMaterial}}</div>
              <router-link v-if="data.browseButtonLink" class="btn btn-snijlab-outline browse-button"
                :to="{path: strippedLink(data.browseButtonLink)}"
                v-html="data.browseButtonTitle" />
            </div>
          </div>
        </div>
        <div class="num-items-text">1 — 7</div>
        <div class="material-block info-block-below">
          <div>
            <div>{{data.cantFindMaterial}}</div>
            <router-link v-if="data.browseButtonLink" class="btn btn-snijlab-outline"
              :to="{path: strippedLink(data.browseButtonLink)}"
              v-html="data.browseButtonTitle" />
          </div>
        </div>
      </div>
    </div>
</template>

<script>

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    bgColor: {
      type: String,
      default: '#ffe400',
    },
    removeBeforeDestroy: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cssProps() {
      return `background-color: ${this.bgColor}`;
    },
  },
  methods: {
    strippedLink(url) {
      const strippedUrl = url.split('snijlab.nl');
      if (strippedUrl.length > 1) {
        return strippedUrl[1];
      }
      return url;
    },
  },
  beforeDestroy() {
    if (this.removeBeforeDestroy) {
      this.$root.$el.parentNode.removeChild(this.$root.$el);
    }
  },
};
</script>

<style lang="scss">
  .contentful-materials-grid {
    padding-bottom: 4rem;

    // .btn-container {
    //   margin-bottom: 2rem;

    //   .btn {
    //     margin-top: .5rem;
    //   }

    //   .btn-primary {
    //     background-color: #ffdd00;
    //     color: black;
    //   }

    //   .btn-secondary {
    //     background-color: #1b1b1b;
    //     color: #ffdd00;
    //     border: 1px solid #ffdd00;
    //   }
    // }

    h2 {
      font-weight: 600;
      font-size: 1.8rem;
      margin: 70px 0 50px 0;
    }

    h3 {
      font-weight: 500;
      font-size: 1.1rem;
    }

    .center-container {
      margin: auto;
      max-width: 1000px;
      text-align: center;
    }

    .grid-container {
      display: grid;
      column-gap: 16px;
      row-gap: 16px;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      margin: 0 1rem;

      &.grid-single-row {
        grid-template-rows: auto;
      }
    }

    .material-block {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-content: center;
      text-align: center;
      padding: 24px 20px 0 20px;
      background-color: white;
      color: black;
      line-height: 1rem;;

      .browse-button {
        line-height: 28px;
      }

      img {
        margin-bottom: 16px;
      }

      h5 {
        font-size: 1.1rem;
        font-weight: 700;
      }

      .btn {
        margin: 1rem 0;
        align-self: center;
      }
    }

    .info-block {
      color: white;
      background-color: #424242;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-size: 1rem;
        font-weight: 400;
      }

      .cant-find-text {
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
      }
    }

    .num-items-text, .info-block-below {
      display: none;
    }

    .btn-snijlab-outline {
      color: #ffdd00;
      border: 1px solid #ffdd00;
    }

    @media(max-width: 991px) {
      padding-bottom: 1rem;

      h3 {
        font-size: 1.4rem;
        padding: 0 1rem;
      }

      .grid-container {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr auto;
        column-gap: 1rem;
        margin: 0 1rem;

        .material-block:nth-child(n+3) {
          display:none;
        }
        .material-block.info-block{
          display: flex;
        }
      }
    }

    @media(max-width: 767px) {
      padding-bottom: 1rem;

      h3 {
        font-size: 1.4rem;
        padding: 0 1rem;
      }

      .grid-container {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr auto;
        column-gap: 1rem;
        margin: 0 1rem;

        .material-block:nth-child(n+2) {
          display:none;
        }
        .material-block.info-block{
          display: flex;
        }
      }
    }

    @media(max-width: 575px) {
      padding-bottom: 1rem;

      h3 {
        font-size: 1.4rem;
        padding: 0 1rem;
      }

      .grid-container {
        display: flex;
        overflow-x: scroll;
        padding-bottom: .5rem;

        .material-block, .material-block:nth-child(n+2) {
          display: flex;
          width: auto;
          min-width: 310px;
        }
        .material-block.info-block{
           display: none;
        }

      }

      .info-block-below {
        display: block;
        background-color: transparent;
        color: black;
        align-content: start;
        font-size: .8rem;
        .btn {
          color: black;
          border: 1px solid black;
          font-size: .8rem;
        }
      }

      .num-items-text {
        display: block;
        padding-top: .8rem;
        font-size: .7rem;
      }

    }
  }
</style>
