<template>
  <table
    v-if="hasCostTable" class="cart-review-table">
    <thead>
      <tr>
        <th>{{$t('order_review.design')}}</th>
        <th>{{$t('order_review.name')}}</th>
        <th v-if="showPrices"
          class="text-right">{{$t('order_review.price')}}</th>
        <th class="text-right">{{$t('order_review.quantity')}}</th>
        <th v-if="showPrices"
          class="total-column text-right">{{$t('order_review.total')}}</th>
      </tr>
    </thead>

    <tbody>
      <cart-review-table-order-product-item v-for="(item, idx) in orderProducts" :key="idx"
        :id="parseInt(item.id)"
        :show-vat="showVat"
        :show-prices="showPrices" />

      <cart-review-table-order-custom-item v-for="(item, idx) in orderCustomItems" :key="idx"
        :is-custom-item="true"
        :id="parseInt(item.id)"
        :show-vat="showVat"
        :show-prices="showPrices" />

      <cart-review-table-order-drawing-item v-for="(item, idx) in orderDrawings" :key="idx"
        :id="parseInt(item.id)"
        :show-vat="showVat"
        :show-prices="showPrices" />

      <tr>
        <td colspan="3" class="text-white">-</td>
      </tr>

      <tr v-if="showPrices && hasSubtotal('bulk_discount')">
        <td colspan="4"><strong>{{$t('order_review.repeating_material')}}</strong></td>
        <td class="text-right">&euro; {{ getSubtotal('bulk_discount', false) }}</td>
      </tr>

      <tr v-if="showPrices && shippingMethod === 'SHIPPING'">
        <td colspan="4"><strong>
          <span v-if="isExpressPriority">{{$t('order_review.express_shipping')}}</span>
          <span v-else>{{$t('order_review.shipping')}}</span>
        </strong></td>
        <td class="text-right">&euro; {{ getSubtotal('shipping', false) }}</td>
      </tr>

      <template v-if="showPrices">
        <tr v-for="(coupon, idx) in coupons" :key="idx">
          <td colspan="4">
            <strong>{{$t('order_review.coupon')}}:</strong> {{ coupon.name }}
          </td>
          <td class="text-right">
            {{ $config.formatPrice(coupon.amount, false, true) }}
          </td>
        </tr>
      </template>

      <template v-if="showPrices">
        <tr>
          <td class="pt-2" colspan="5"></td>
        </tr>
        <tr class="border-top">
          <td v-if="hasSubtotal('total')"
            class="pt-2"
            colspan="4">
            {{$t('order_review.total_excl_vat')}}
          </td>
          <td class="text-right pt-2">
            &euro; {{ getSubtotal('total', false) }}
          </td>

        </tr>
        <tr>
          <td colspan="4">
            <span v-if="vatType === 'VAT21'">{{$t('order_review.btw_21')}}</span>
            <span v-else-if="vatType === 'ICP'">{{$t('order_review.vat_0_vat_reversed')}}</span>
            <span v-else-if="vatType === 'NONEU'">{{$t('order_review.vat_not_applicable')}}</span>
          </td>
          <td class="text-right">&euro; {{ getSubtotalVatOnly('total') }}</td>
        </tr>
        <tr>
          <td v-if="hasSubtotal('total')"
            colspan="4">
            <strong>{{$t('order_review.to_pay')}} </strong>
          </td>
          <td class="text-right"><strong>&euro; {{ getSubtotal('total', true) }}</strong></td>
        </tr>
      </template>
    </tbody>

  </table>
</template>

<script>
import CartReviewTableOrderDrawingItem from '@/components/cart/review/CartReviewTableOrderDrawingItem';
import CartReviewTableOrderCustomItem from '@/components/cart/review/CartReviewTableOrderCustomItem';
import CartReviewTableOrderProductItem from '@/components/cart/review/CartReviewTableOrderProductItem';

export default {
  components: {
    CartReviewTableOrderDrawingItem,
    CartReviewTableOrderCustomItem,
    CartReviewTableOrderProductItem,
  },
  props: {
    showPrices: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    showVat() {
      return this.$store.state.settings.showVat;
    },
    vatType() {
      return this.$store.state.cart.vat.vatType;
    },
    orderDrawings() {
      return this.$store.state.cart.orderDrawings;
    },
    orderCustomItems() {
      return this.$store.state.cart.orderCustomItems;
    },
    orderProducts() {
      return this.$store.state.cart.orderProducts;
    },
    costTable() {
      return this.$store.state.cart.costTable;
    },
    coupons() {
      return this.$store.state.cart.coupons;
    },
    shippingMethod() {
      return this.$store.state.cart.shippingMethod;
    },
    hasCostTable() {
      return Object.keys(this.costTable).length > 0;
    },
    isExpressPriority() {
      return this.$store.state.cart.priorityId === 5;
    },
  },
  methods: {
    getSubtotalVatOnly(name) {
      const total = Object.values(this.costTable[name].vat).reduce((sum, value) => sum + value, 0);
      return new Intl.NumberFormat(this.$i18n.locale, { minimumFractionDigits: 2 }).format(total / 100);
    },
    getSubtotal(name, vat) {
      let total = this.costTable[name].amount;
      if (vat) {
        total += Object.values(this.costTable[name].vat).reduce((sum, value) => sum + value, 0);
      }
      return new Intl.NumberFormat(this.$i18n.locale, { minimumFractionDigits: 2 }).format(total / 100);
    },
    hasSubtotal() {
      return this.costTable;
    },
    updateVat({ value, promise }) {
      this.$store.dispatch('updateVat', value)
        .finally(() => {
          promise.resolve();
        });
    },
  },
};
</script>

<style lang="scss">
.cart-review-table {
  width: 100%;

  .total-column {
    min-width: 75px;
  }
}
</style>
