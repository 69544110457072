<template>
  <div class="error-center">
    <logo-animated />
    <h1 class="error-center-h1">500</h1>
    <h2 class="error-center-h2">{{$t('page_500.internal_error')}}</h2>
    <p class="error-text-extra">
      {{$t('page_500.does_this_keep_happening')}} <a href="mailto:info@snijlab.nl">info@snijlab.nl</a>
    </p>
    <!-- <div>
      <router-link to="/" class="btn btn-snijlab mb-4">{{$t('page_404.back_to_home')}}</router-link>
    </div> -->
  </div>
</template>

<script>
import LogoAnimated from 'src/components/errors/LogoAnimated';

export default {
  components: { LogoAnimated },
};
</script>

<style lang="scss">
  @import '../../assets/scss/_error.scss';
</style>
