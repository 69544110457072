<template>
  <div class="px-2">
    <orders-loader/>
    <loading-widget-full-width v-if="!ready" :loading="!ready" />
    <div v-if="ready">
      <div>
        <table class="table table-hover order-table">
          <thead>
            <tr>
              <th>
                {{$t('concept_orders.order_number')}}
              </th>
              <th>
                {{$t('concept_orders.last_edited')}}
              </th>
              <th>
                {{$t('concept_orders.your_reference')}}
              </th>
              <th>
                {{$t('concept_orders.number_of_designs')}}
              </th>
              <th>
                {{$t('concept_orders.delivery_method')}}
              </th>
              <th>
                {{$t('concept_orders.total')}}
              </th>
              <th class="actions">
                <loading-button
                  variant="primary"
                  size="xs"
                  :loading="createNewOrderLoading"
                  class="px-1"
                  stroke-color="#fff"
                  @click="createNewOrder">
                  <i class="material-icons">add_circle</i> {{$t('concept_orders.new_concept_order')}}
                </loading-button>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(order, key) in orders" :key="key"
              :class="{ 'bg-success': isActive(order) }">

              <td>
                L{{ order.orderId }}
                <span v-if="isActive(order)" class="text-small font-weight-bold badge badge-success">
                  {{$t('concept_orders.active')}}</span>
              </td>

              <td>
                {{ formatAcceptedDate(order.updatedAt) }}
              </td>

              <td>
                {{ order.yourReference }}
              </td>

              <td>
                {{ order.totalItems }}
              </td>

              <td v-if="order.shippingMethod === 'SHIPPING'">
                {{$t('concept_orders.shipping')}}
              </td>

              <td v-if="order.shippingMethod === 'PICKUP'">
                {{$t('concept_orders.pickup')}}
              </td>

              <td>
                {{ getTotal(order) }}
              </td>

              <td class="actions">
                <b-button
                  v-if="!isActive(order)"
                  variant="light"
                  size="xs"
                  :to="{
                    name: 'order-cart',
                    params: {
                      lang: $i18n.locale,
                      orderId: order.orderId
                    }
                  }">
                  {{$t('concept_orders.activate')}}</b-button>

                <b-button v-else
                  variant="light"
                  size="xs"
                  :to="{
                    name: 'order-cart',
                    params: {
                      lang: $i18n.locale,
                      orderId: order.orderId
                    }
                  }">
                  {{$t('concept_orders.view_concept_order')}}</b-button>

                <loading-button
                  v-if="!isActive(order)"
                  :loading="isLoading(order)"
                  variant="secondary"
                  size="xs"
                  stroke-color="#fff"
                  @click="deleteDraft(order)">
                  {{$t('concept_orders.remove')}}
                </loading-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import _orderBy from 'lodash/orderBy';
import _filter from 'lodash/filter';
import OrdersLoader from 'src/components/loader/OrdersLoader';
import LoadingButton from 'src/components/tools/LoadingButton';
import LoadingWidgetFullWidth from 'src/components/tools/LoadingWidgetFullWidth';

export default {
  components: {
    LoadingButton,
    OrdersLoader,
    LoadingWidgetFullWidth,
  },
  data() {
    return {
      loading: { },
      createNewOrderLoading: false,
    };
  },
  computed: {
    showVat() {
      return this.$store.state.settings.showVat;
    },
    ready() {
      return this.$store.getters.loaded(['orders']);
    },
    orders() {
      const nonDraftOrders = _filter(this.$store.state.orders, (o) => o.status === 'DRAFT');
      return _orderBy(nonDraftOrders, ['updatedAt'], ['desc']);
    },
    lang() {
      return this.$route.params.lang;
    },

  },
  methods: {
    isLoading(order) {
      return order.orderId in this.loading && this.loading[order.orderId] === true;
    },
    setLoading(order, value) {
      if (value) {
        Vue.set(this.loading, order.orderId, value);
      }
    },
    isActive(order) {
      return parseInt(order.orderId, 10) === this.$store.state.cart.orderId;
    },
    getTotal(order) {
      return this.$config.formatPrice(order.orderTable.costItems.total, this.showVat, true);
    },
    formatAcceptedDate(date) {
      const options = {
        // weekday: 'short',
        // year: 'numeric',
        // month: 'short',
        // day: 'numeric',
      };
      const event = new Date(date);
      return event.toLocaleDateString(this.$i18n.locale, options);
    },
    formatDeliveryDate(dateObj) {
      const options = {
        // weekday: 'short',
        // year: 'numeric',
        // month: 'short',
        // day: 'numeric',
      };
      const event = new Date(dateObj.date);
      return event.toLocaleDateString(this.$i18n.locale, options);
    },
    invoiceLink(order) {
      return `${this.$config.apiUrl}/document/invoice/${order.orderId}`;
    },
    searchLink(order) {
      return `../products?search=L${order.orderId}`;
    },
    createNewOrder() {
      if (this.createNewOrderLoading) {
        return;
      }
      this.createNewOrderLoading = true;
      this.$store.dispatch('createNewOrder')
        .then(() => {
          this.$store.dispatch('loadUserOrders')
            .then(() => {
              this.$router.push({
                name: 'order-cart',
                params: {
                  lang: this.$route.params.lang,
                  orderId: this.$store.state.cart.orderId,
                },
              });
            });
        });
    },
    deleteDraft(order) {
      if (this.isLoading(order)) {
        return;
      }
      this.setLoading(order, true);
      this.$store.dispatch('deleteDraftOrder', order.orderId)
        .finally(() => {
          this.setLoading(order, false);
        });
    },
  },
};
</script>
