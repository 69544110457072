import Vue from 'vue';
import VueGtm from 'vue-gtm';
import VueRouter from 'vue-router';
import Toasted from 'vue-toasted';
import Vuedals from 'vuedals';
import VueMq from 'vue-mq';
import axios from 'axios';
import VueMeta from 'vue-meta';
import VueScrollTo from 'vue-scrollto';
import VueSimpleSVG from 'vue-simple-svg';

import {
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadioGroup,
  BFormSelect,
  BFormText,
  BFormTextarea,
  BModal,
  BNav,
  BNavbar,
  BNavItem,
  BProgress,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  // BCard,
  // BCardBody,
  ModalPlugin,
  BCollapse,
  VBToggle,
} from 'bootstrap-vue';

import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
// import { VueReCaptcha } from 'vue-recaptcha-v3';

import Layout from '@/views/Layout';
import store from '@/store/store';
import router from '@/router/router';
import config from '@/config';
import EventBus from '@/event-bus';
import tools from '@/tools';
import i18n from './i18n';

if (config.sentryDSN) {
  Sentry.init({
    dsn: config.sentryDSN,
    integrations: [new VueIntegration({ Vue, attachProps: true, logErrors: true })],
  });
}

// Add a request interceptor
axios.interceptors.request.use((axiosConfig) => {
  if (axiosConfig.url.startsWith(config.baseUrl)
    // double redundant
    && !axiosConfig.url.includes(config.postcodeUrl)
    && !axiosConfig.url.includes('searchAsAdmin')
    && !axiosConfig.url.includes('orderEmail')
    && !axiosConfig.url.includes('baseframe')
    && !axiosConfig.url.includes('odoo.com')) {
    // eslint-disable-next-line no-param-reassign
    axiosConfig.headers = { 'X-Snijlab-User-Id': store.state.masqUserId };
  }
  return axiosConfig;
});

router.beforeEach((to, from, next) => {
  if (!to.query.userId && store.state.masqUserId) {
    // eslint-disable-next-line no-param-reassign
    to.query.userId = store.state.masqUserId;
    next(to);
  } else {
    const queryUserId = parseInt(to.query.userId, 10);
    if (queryUserId && queryUserId !== store.state.masqUserId) {
      store.commit('SET_MASQ_USER_ID', queryUserId);
    }
    next();
  }
});

Vue.component('b-button', BButton);
Vue.component('b-form', BForm);
Vue.component('b-form-checkbox', BFormCheckbox);
Vue.component('b-form-group', BFormGroup);
Vue.component('b-form-input', BFormInput);
Vue.component('b-form-invalid-feedback', BFormInvalidFeedback);
Vue.component('b-form-radio-group', BFormRadioGroup);
Vue.component('b-form-select', BFormSelect);
Vue.component('b-form-text', BFormText);
Vue.component('b-form-textarea', BFormTextarea);
Vue.component('b-modal', BModal);
Vue.component('b-nav', BNav);
Vue.component('b-navbar', BNavbar);
Vue.component('b-nav-item', BNavItem);
Vue.component('b-collapse', BCollapse);
Vue.component('b-progress', BProgress);
Vue.component('b-dropdown', BDropdown);
Vue.component('b-dropdown-item', BDropdownItem);
Vue.component('b-dropdown-divider', BDropdownDivider);
// Vue.component('b-card', BCard);
// Vue.component('b-card-body', BCardBody);
Vue.directive('b-toggle', VBToggle);

Vue.use(VueMq, {
  breakpoints: { // default breakpoints - customize this
    xs: 570,
    sm: 768,
    md: 990,
    lg: 1200,
    xl: Infinity,
  },
  defaultBreakpoint: 'xs',
});

// Add aliases to vue
// eventbus and config.
Vue.prototype.$eventBus = EventBus;
Vue.prototype.$config = config;
Vue.prototype.$tools = tools;
Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(Vuedals);
Vue.use(Toasted);
Vue.use(ModalPlugin);
Vue.use(VueScrollTo);
Vue.use(VueSimpleSVG);

// Vue.use(VueReCaptcha, {
//   siteKey: '6LfFY94hAAAAAE2dkRmiF-4zJS1P9U41YM1En6VN',
// });

let gtagEnabled = true;
const currentCookie = tools.getCookie('snijlabCookiesAccepted');
if (currentCookie === false) {
  gtagEnabled = false;
}

Vue.use(VueGtm, {
  id: config.googleTagManagerId,
  // queryParams: { // Add url query string when load gtm.js with GTM ID (optional)
  //   gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
  //   gtm_preview: 'env-4',
  //   gtm_cookies_win: 'x'
  // },
  enabled: gtagEnabled, // defaults to true.
  debug: config.googleTagManagerDebug,
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  // ignoredViews: ['homepage'], // Don't trigger events for specified router names (case insensitive) (optional)
  // trackOnNextTick: true, // Whether or not call trackView in Vue.nextTick
});

Vue.use(VueMeta);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(Layout),
}).$mount('#app');
