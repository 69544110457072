<template>
  <div>
    <!-- copy paste this template -->
    <div :class="rowClasses">
      <form-label-widget
        :label="label"
        :id="id"
        :help-link="helpLink"
        :required="required"/>
      <form-help-widget
        :error="error"
        :warning="warning"
        :help="help"/>
    </div>
  </div>
</template>

<script>
import FormLabelWidget from 'src/components/form/FormLabelWidget';
import FormHelpWidget from 'src/components/form/FormHelpWidget';

export default {
  components: {
    FormHelpWidget,
    FormLabelWidget,
  },
  props: {
    value: String,
    size: {
      type: String,
      default: '',
      validator(value) {
        return ['sm', 'lg', ''].includes(value);
      },
      required: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: '',
    },
    inputClass: {
      type: String,
      default: '',
    },
    labelClass: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    rowClass: {
      type: String,
      default: 'input-row-full',
    },
    placeholder: {
      type: String,
    },
    label: {
      type: String,
    },
    help: {
      type: String,
    },
    error: {
      type: String,
    },
    warning: {
      type: String,
    },
    icon: {
      type: String,
      default: null,
    },
    state: {
      type: String,
      required: false,
      validator(value) {
        return ['valid, invalid', null, ''].includes(value);
      },
    },
    helpLink: {
      type: String,
    },
    className: {
      type: String,
    },
  },
  data() {
    return {};
  },
  computed: {
    rowClasses() {
      return this.rowClass.trim()
        .split(/\s+/)
        .filter((item) => typeof item === 'string' && item.length > 0);
    },
    id() {
      // eslint-disable-next-line no-underscore-dangle
      return `input-${this._uid}`;
    },
    hasError() {
      return Boolean(this.error);
    },
  },
  methods: {
    onInput(event) {
      this.$emit('input', event);
    },
  },
};
</script>

<style>
</style>
