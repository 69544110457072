<template>
  <sign-in-panel v-if="!profileLoading && !isSignedIn && showModal"
    :required="signInRequired"
    @hidden="showModal = false"
    :show="!isSignedIn"
    :forced="signInRequired" />
</template>

<script>
import axios from 'axios';
import SignInPanel from 'src/components/user/SignInPanel';

export default {
  components: {
    SignInPanel,
  },
  props: {
    signInRequired: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
      isSignedIn: false,
      profileLoading: true,
    };
  },
  mounted() {
    this.$store.dispatch('updateLang', this.$route.params.lang);
    this.$eventBus.$on('newLogin', this.newLogin);
    this.$eventBus.$on('login', this.login);
    this.$eventBus.$on('showLogin', this.showLogin);
    this.$eventBus.$on('logout', this.logout);
    this.profileLoading = true;

    const formData = new FormData();
    return axios.post(`${this.$config.apiUrl}/user/profile`, formData, { withCredentials: true })
      .then((response) => {
        if (response.data.result === null
          || (response.data.error !== undefined && response.data.error !== null && response.data.error.length > 0)) {
          // we got an error while loading the profile..
          return;
        }
        this.$eventBus.$emit('login', response.data.result);
      }).catch((error) => {
        if (error.response && error.response.status === 401) {
          // do nothing.. we're not signed in.
        } else {
          this.$eventBus.$emit('snlb.error', error);
        }
      }).finally(() => { this.profileLoading = false; });
  },
  computed: {
    userSignedIn() {
      return this.$store.state.user !== null;
    },
  },
  methods: {
    showLogin() {
      this.showModal = true;
    },
    login(data) {
      this.isSignedIn = true;
      this.$store.dispatch('loadSettings').then(() => {
        this.$store.dispatch('loadUser', data).then(() => {
          if (data.new_account) {
            // eslint-disable-next-line no-param-reassign
            data.profile.phone_number = data.tmp_phone_number;
            this.$eventBus.$emit('odoo.event', { event: 'user_registered', msg: 'New user registration' });
          }
        });
      });
    },
    newLogin(data) {
      this.$eventBus.$emit('gtm.login');
      this.isSignedIn = true;
      this.$store.dispatch('loadSettings').then(() => {
        this.$store.dispatch('loadUser', data).then(() => {
          if (data.new_account) {
            if (data.tmp_phone_number) {
              // eslint-disable-next-line no-param-reassign
              data.profile.phone_number = data.tmp_phone_number;
            }
            this.$eventBus.$emit('odoo.event', { event: 'user_registered', msg: 'New user registration' });
          }
          if (this.$route.meta.authenticated === false) {
            // this.$router.push({
            //   name: 'order-cart',
            //   params: { lang: this.$i18n.locale, orderId: this.$store.state.settings.order_id },
            // });
            this.$router.push({
              name: 'dashboard',
              params: { lang: this.$i18n.locale, orderId: this.$store.state.settings.order_id },
            });
          }
        });
      });
    },
    logout() {
      const formData = new FormData();
      this.loading = true;
      return axios.post(`${this.$config.apiUrl}/user/logout`, formData, { withCredentials: true })
        .then((response) => {
          if (response.data.error !== null) {
            // handle error..
            this.$eventBus.$emit('snlb.error', response.data.error);
            this.loading = false;
          } else {
            this.isSignedIn = false;
            this.loading = false;
          }
        })
        .catch((err) => {
          this.$config.$emit('snlb.error', err.message);
        }).finally(() => {
          this.showModal = false;
          window.location = '/';
        });
    },
  },
};
</script>
