<template>
  <div class="email-template-widget mt-2">
    <b-button
      v-b-modal.email-modal
      variant="admin"
      size="xs"
      class="px-2"
      @click="showEmailWidget=!showEmailWidget">
        <i class="material-icons">email</i> {{$t('admin.send_email')}}
    </b-button>

    <b-modal
      id="email-modal"
      size="xl"
      button-size="sm"
      @hidden="modalHidden()"
      :title="$t('admin.send_email')">
      <template v-slot:modal-footer="{ cancel }">
        <b-button
          variant="dark"
          size="xs"
          @click="cancel()">
          {{$t('admin.close_window')}}<i class="material-icons compact">close</i>
        </b-button>

        <b-button v-show="!emailSent"
          size="xs"
          variant="success"
          @click="sendEmail">$t('admin.send_email') <i class="material-icons compact">send</i>
        </b-button>

        <b-button v-show="emailSent"
          size="xs"
          variant="light"
          :disabled="true"
          @click="sendEmail">{{$t('admin.email_sent')}} <i class="material-icons compact">check</i>
        </b-button>

      </template>
      <div class="row email-modal">
        <div class="col-md-6">

          <div class="form-group mb-0">
            <label class="input-group-text template-select-label" for="templateSelect">Templates:</label>
            <select
              id="templateSelect"
              class="template-select custom-select"
              v-model="selectedTemplate">
              <option v-for="(template, id) in emailTemplates" :key="id"
                :value="id"
                :selected="{'true': selectedTemplate == id}">
                {{template.description}}
              </option>
            </select>
          </div>

          <div>

            <div class="form-group">
              <div class="email-template-lang-container mt-2">
                <div class="form-check form-check-inline">
                  <input v-model="emailLang" class="form-check-input" type="radio" id="radioNL" value="nl">
                  <label class="form-check-label" for="radioNL">{{$t('admin.dutch')}}</label>
                </div>
                <div class="form-check form-check-inline">
                  <input v-model="emailLang" class="form-check-input" type="radio" id="radioEN" value="en">
                  <label class="form-check-label" for="radioEN">{{$t('admin.english')}}</label>
                </div>
              </div>
            </div>

            <div class="form-group mb-0">
              <label for="emailTitle">{{$t('admin.email_subject')}}</label>
              <input v-model="emailSubject" type="input" class="form-control form-control-sm"
              id="emailTitle" :placeholder="$t('admin.email_subject')">
            </div>

            <form-widget-single-checkbox
              class="pl-0 mt-2"
              :label="$t('admin.attach_invoice_quote')"
              v-model="emailAttachment"
              @input="updateEmailPreview"
              size="sm" row-class="input-row-full" />

            <div class="form-group mb-0">
              <textarea class="template-body form-control form-control-sm m-0 mt-2 p-1"
                v-model="emailBody" rows="20" max-rows="30"/>
            </div>

            <div class="mt-2">
              <b-button
                variant="light"
                size="xs"
                class="mb-2 mr-2"
                @click="showEmailVariables=!showEmailVariables">
                <span v-show="!showEmailVariables">{{$t('admin.show_available_variables')}}</span>
                <span v-show="showEmailVariables">{{$t('admin.hide_available_variables')}}</span>
              </b-button>
            </div>

            <div v-show="showEmailVariables" class="row mt-3">
              <div class="col-12">
                <table class="table table-striped table-hover table-sm variables-table">
                  <thead>
                    <tr>
                      <th scope="col"><strong>Naam</strong> <small>{{$t('admin.click_to_copy')}}</small></th>
                      <th scope="col" class="pl-3"><strong>{{$t('admin.function')}}</strong></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(varDescription, varKeyword, id) in availableVariables" :key="id"
                      @click="copyToClipboard(`{${varKeyword}}`)"
                      class="pointer">
                      <th scope="row">{{keywordWithBrackets(varKeyword)}}</th>
                      <td class="pl-3">{{varDescription}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 border-left">
          <div class="card shadow-1 email-preview-container">
            <div class="card-body p-2">
              <div class="email-preview-recipient mt-2">
                <strong class="text-muted">{{$t('admin.recipient')}}</strong><br>
                <div v-html="emailPreview.recipient" class="pl-2" />
              </div>
              <div class="email-preview-subject mt-2">
                <strong class="text-muted">{{$t('admin.subject')}}</strong><br>
                <div v-html="emailPreview.subject" class="pl-2" />
                <div v-show="!emailSubject" class="text-muted pl-2">{{$t('admin.no_subject')}}</div>
              </div>
              <div class="email-preview-subject mt-2">
                <strong class="text-muted">bijlage</strong><br>
                <div v-if="emailPreview.attachment === 'Quote'" class="pl-2">
                  <i class="material-icons compact">attachment</i> {{$t('admin.quote')}}</div>
                <div v-else-if="emailPreview.attachment === 'Invoice'" class="pl-2">
                  <i class="material-icons compact">attachment</i> {{$t('admin.invoice')}}</div>
                <div v-else class="pl-2 text-muted">{{$t('admin.no_attachment')}}</div>
              </div>
              <div class="email-preview-body mt-2">
                <strong class="text-muted">{{$t('admin.content')}}</strong><br>
                <div v-html="emailPreview.body" class="pl-2"/>
                <div v-show="!emailBody" class="text-muted pl-2">{{$t('admin.no_text')}}</div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
import debounce from 'lodash/debounce';
import FormWidgetSingleCheckbox from 'src/components/form/FormWidgetSingleCheckbox';

export default {
  components: {
    FormWidgetSingleCheckbox,
  },
  data() {
    return {
      showEmailWidget: true,
      showEmailVariables: false,
      selectedTemplate: null,
      emailTemplates: [],
      emailSubject: '',
      emailBody: '',
      emailLang: this.$i18n.locale,
      emailPreview: {},
      emailAttachment: false,
      emailSent: false,
      availableVariables: {
        'employee.name': 'Naam ingelogde Snijlab medewerker. Let op: alleen voor handmatig verzonden mails',
        'client.fullname': 'Volledige naam van klant',
        'client.firstname': 'Voornaam van klant',
        'client.lastname': 'Achternaam van klant',
        'client.username': 'Username van klant',
        'client.email': 'Emailadres van klant',
        'client.phone': 'Telefoonnummer van klant',
        'client.id': 'Id-nummer van klant',
        'client.password': 'Toont wachtwoord van gebruiker. Let op: genereert een nieuw wachtwoord!',
        'order.your_reference': 'Order referentienummer',
        'order.id': 'Order id nummer',
        'order.invoice_amount': 'Totaalbedrag van order, incl. btw',
        'order.invoice_date': 'Factuur datum',
        'order.delivery_address': 'Leveradres',
        'order.delivery_date': '(aagepaste) leverdatum',
        'order.delivery_date_formatted': '(aagepaste) leverdatum: dag, maand (12 maart, of 12 March)',
        'order.delivery_date_weekday_formatted': '(aangepaste leverdatum) maandag of Monday',
        'order.shipping_tracking_url': 'URL voor tracking',
        'order.shipping_tracking_code': 'Tracking code',
        'order.shipping_tracking_company': 'Leveringsdienst',
        baseUrl: 'https://snijlab.nl',
      },
    };
  },
  methods: {
    modalHidden() {
      this.emailSent = false;
    },
    keywordWithBrackets(keyword) {
      return `{${keyword}}`;
    },
    sendEmail() {
      this.emailSent = true;
      const formData = new FormData();
      formData.append('order_id', this.$store.state.cart.orderId);
      formData.append('subject', this.emailSubject);
      formData.append('body', this.emailBody);
      formData.append('lang', this.emailLang);
      formData.append('attachment', this.emailAttachment);
      axios.post(
        `${this.$config.apiUrl}/orderEmail/send`,
        formData,
        { withCredentials: true },
      )
        .then((response) => {
          this.$eventBus.success('Email verzonden');
          setTimeout(() => {
            this.emailSent = false;
          }, 2000);
          this.emailPreview = response.data.result;
        })
        .catch((err) => {
          this.emailSent = false;
          this.$eventBus.error(err);
        });
    },
    getTemplates() {
      const formData = new FormData();
      axios.post(
        `${this.$config.apiUrl}/orderEmail/getTemplates`,
        formData,
        { withCredentials: true },
      )
        .then((response) => {
          this.emailTemplates = response.data.result;
          this.updateEmailPreview();
        })
        .catch((err) => {
          this.$eventBus.error(err);
        });
    },
    // eslint-disable-next-line func-names
    updateEmailPreview: debounce(function () {
      const formData = new FormData();
      formData.append('order_id', this.$store.state.cart.orderId);
      formData.append('subject', this.emailSubject);
      formData.append('body', this.emailBody);
      formData.append('lang', this.emailLang);
      formData.append('attachment', this.emailAttachment);

      axios.post(
        `${this.$config.apiUrl}/orderEmail/preview`,
        formData,
        { withCredentials: true },
      )
        .then((response) => {
          this.emailPreview = response.data.result;
        })
        .catch((err) => {
          this.$eventBus.error(err);
        });
    }, 500),
    copyToClipboard(node) {
      if (!window.getSelection) {
        return false;
      }
      const doc = document;
      let result = false;
      const ranges = [];
      const selection = window.getSelection();
      if (typeof node === 'string' || node instanceof String) {
        const container = doc.createElement('div');
        container.appendChild(doc.createTextNode(node));

        doc.body.appendChild(container);
        result = this.copyToClipboard(container);
        doc.body.removeChild(container);
        return result;
      }
      for (let i = 0; i < selection.rangeCount; i += 1) {
        ranges[i] = selection.getRangeAt(i);
      }
      selection.selectAllChildren(node);
      try {
        result = doc.execCommand('copy');
      } catch (err) {
        //
      }
      this.$eventBus.success('Naar clipboard gekopieerd');
      // Restore previous selection if any
      selection.removeAllRanges();
      ranges.forEach(selection.addRange.bind(selection));
      return result;
    },
  },
  mounted() {
    this.getTemplates();
  },
  watch: {
    selectedTemplate() {
      this.emailSubject = this.emailTemplates[this.selectedTemplate].Translation[this.emailLang].title;
      this.emailBody = this.emailTemplates[this.selectedTemplate].Translation[this.emailLang].body;
      this.updateEmailPreview();
    },
    emailLang() {
      this.emailSubject = this.emailTemplates[this.selectedTemplate].Translation[this.emailLang].title;
      this.emailBody = this.emailTemplates[this.selectedTemplate].Translation[this.emailLang].body;
      this.updateEmailPreview();
    },
    emailSubject() {
      this.updateEmailPreview();
    },
    emailBody() {
      this.updateEmailPreview();
    },
  },
};
</script>

<style lang="scss">
  .email-modal {
    .email-template-lang-container .form-check-label {
      padding-top: 3px;
    }

    .variables-table {
      th, td {
        padding: 0.1rem;
        height: auto;
      }
    }
  }
</style>
