<template>
  <div class="dashboard-previous-orders-block bg-light-4 border border-light rounded p-2">
    <h2>{{$t('dashboard.previous_orders')}}</h2>
    <table class="table table-condensed mb-0">
      <thead class="w-100">
        <tr>
          <th class="d-none d-md-table-cell">
            <!-- {{$t('dashboard.order_number')}} -->
          </th>
          <th colspan="2">
            {{$t('dashboard.order_date')}}
          </th>
          <!-- <th> {{$t('dashboard.status')}} </th> -->
          <th colspan="2">
            {{$t('dashboard.delivery_date')}}
          </th>
          <!-- <th class="d-none d-md-table-cell">pill</th> -->
          <th><!-- track --></th>
          <th><!-- actions --></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(order, key) in orderStatusBars" :key="key">
          <!-- <pre>{{order}}</pre> -->
          <td class="d-none d-md-table-cell">
            L{{ order.orderId }}
            <div v-if="order.your_reference">{{ order.your_reference }}</div>
          </td>
          <td>
            {{ formatAcceptedDate(order.acceptedAt) }}
          </td>
          <td>
            <div>
              {{ order.barLabel }}
            </div>
            <div>
            </div>
            <b-progress
              :value="order.barValue"
              :variant="order.barVariant"/>
          </td>
          <!-- <span v-if="order.deliveryDate">{{order.deliveryDate.match(/^\d+-\d+-\d+/)}}</span> -->
          <td class="d-none d-md-table-cell" v-if="order.deliveryDate !== null && order.deliveryDate.match(/^\d+-\d+-\d+/)"
            :class="{'needs-confirmation': order.status === 'SUBMITTED'}">
            <div>
              {{ formatAcceptedDate(order.deliveryDate.match(/^\d+\-\d+\-\d+/)[0]) }}
            </div>
          </td>
          <td v-else class="d-none d-md-table-cell"></td>

          <td>
            <div v-if="order.shippingMethod === 'SHIPPING' && !order.shippingTrackingCompany"
              class="shipping-method badge badge-light badge-pill">
              {{$t('orderhistory.shipping')}}
            </div>
            <div v-else-if="order.shippingMethod === 'SHIPPING' && order.shippingTrackingCompany"
              class="shipping-method badge badge-light badge-pill">
              {{order.shippingTrackingCompany}}
            </div>
            <div v-else class="shipping-method badge badge-light badge-pill">
              {{$t('orderhistory.pickup')}}
            </div>
          </td>

          <td>
            <a v-if="order.shippingTrackingUrl" :href="order.shippingTrackingUrl"
              target="_blank">{{$t('dashboard.track')}}</a>
          </td>
          <td class="actions">
            <b-dropdown right :text="$t('dashboard.actions')" variant="light" class="dropdown-menu-left">

              <b-dropdown-item>
                <router-link
                  class="dropdown-item"
                  :to="{
                    name:'products-list-me',
                    params: { lang: $i18n.locale },
                    query: { search: `L${order.orderId}` }
                  }">
                  {{$t('orderhistory.show_designs')}}
                </router-link>
              </b-dropdown-item>

              <b-dropdown-item v-if="order.status === 'SUBMITTED'">
                <router-link
                  class="dropdown-item"
                  :to="{
                    name:'order-payment',
                    params: {
                      lang: $i18n.locale,
                      orderId: order.orderId,
                    },
                  }">
                  {{$t('orderhistory.pay')}}
                </router-link>
              </b-dropdown-item>

              <b-dropdown-item v-else>
                <a v-if="order.invoiceId !== null" :href="invoiceLink(order)"
                  target="_blank"
                  class="dropdown-item">
                  {{$t('orderhistory.invoice')}}
                </a>
                <button v-else
                  class="dropdown-item" disabled>
                  {{$t('orderhistory.invoice')}}
                </button>
              </b-dropdown-item>
            </b-dropdown>

              <!-- <b-dropdown-divider></b-dropdown-divider> -->
              <!-- <b-dropdown-item active>Active action</b-dropdown-item> -->
              <!-- <b-dropdown-item disabled>Disabled action</b-dropdown-item> -->
          </td>
        </tr>
      </tbody>
    </table>
    <router-link v-if="recentOrders.length > 5" class="btn btn-link mt-2"
      :to="{name: 'orders-list-me', params: {lang: $i18n.locale}}">
      <i class="material-icons">history</i> {{$t('dashboard.all_your_orders')}}
    </router-link>
  </div>
</template>

<script>
// import _has from 'lodash/has';
// import LoadingButton from 'src/components/tools/LoadingButton';

export default {
  components: {
    // LoadingButton,
  },
  props: {
    recentOrders: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      // addToOrderLoading: false,
    };
  },
  computed: {
    orderStatusBars() {
      return this.recentOrders.slice(0, 5).map((order) => {
        switch (order.status) {
          case 'DRAFT':
            return {
              ...order,
              barLabel: this.$t('status_labels.draft'),
              barValue: 0,
              barVariant: 'info',
            };
          case 'SUBMITTED':
            return {
              ...order,
              barLabel: this.$t('status_labels.payment_pending'),
              barValue: 10,
              barVariant: 'success',
            };
          case 'ACCEPTED':
            return {
              ...order,
              barLabel: this.$t('status_labels.payment_received'),
              barValue: 30,
              barVariant: 'success',
            };
          case 'IN_PROCESS':
            return {
              ...order,
              barLabel: this.$t('status_labels.in_production'),
              barValue: 50,
              barVariant: 'success',
            };
          case 'PROCESSED':
            return {
              ...order,
              barLabel: this.$t('status_labels.done'),
              barValue: 75,
              barVariant: 'success',
            };
          case 'SHIPPED':
            return {
              ...order,
              barLabel: this.$t('status_labels.shipped'),
              barValue: 90,
              barVariant: 'success',
            };
          case 'DELIVERED':
            return {
              ...order,
              barLabel: this.$t('status_labels.delivered'),
              barValue: 100,
              barVariant: 'success',
            };
          case 'ON_HOLD':
            return {
              ...order,
              barLabel: this.$t('status_labels.on_hold'),
              barValue: 100,
              barVariant: 'warning',
            };
          case 'CANCELLED':
            return {
              ...order,
              barLabel: this.$t('status_labels.cancelled'),
              barValue: 0,
              barVariant: 'danger',
            };
          case 'EXPIRED':
            return {
              ...order,
              barLabel: this.$t('status_labels.expired'),
              barValue: 0,
              barVariant: 'danger',
            };
          default:
            return {
              ...order,
              barLabel: order.status,
              barValue: 100,
              barVariant: 'danger',
            };
        }
      });
    },
    // isInCart() {
    //   return _has(this.drawingConfiguration.draftOrders, this.$store.state.cart.orderId);
    // },
    // canAddToCart() {
    //   return this.drawingConfiguration.approvable && !this.hasUndeliverableMaterial;
    // },
    // hasError() {
    //   return this.drawingConfiguration.feedback.some((f) => f.type === 'ERROR');
    // },
    // hasUndeliverableMaterial() {
    //   return this.drawingConfiguration.locked && (this.hasMaterial && !this.materialIsPublished);
    // },
    // materialIsPublished() {
    //   if (this.material) {
    //     return this.material.is_published;
    //   }
    //   return false;
    // },
    // hasMaterial() {
    //   return this.drawingConfiguration.material_id in this.materials
    //   && parseInt(this.drawingConfiguration.material_id, 10) !== 1;
    // },
    // materials() {
    //   return this.$store.state.materials;
    // },
    // material() {
    //   if (this.materials[this.drawingConfiguration.material_id]) {
    //     return this.materials[this.drawingConfiguration.material_id];
    //   }
    //   return null;
    // },
    // addToCartVariant() {
    //   if (this.canAddToCart && this.hasMaterial) {
    //     return 'primary';
    //   }
    //   return 'empty';
    // },
    // drawingConfiguration() {
    //   return this.$store.state.dc.drawingConfiguration[parseInt(this.id, 10)];
    // },
    // height() {
    //   if (!_has(this.drawingConfiguration, 'Drawing.dimensions.height')) {
    //     return '';
    //   }
    //   return parseFloat(this.drawingConfiguration.Drawing.dimensions.height)
    //     .toFixed(0);
    // },
    // width() {
    //   if (!_has(this.drawingConfiguration, 'Drawing.dimensions.width')) {
    //     return '';
    //   }
    //   return parseFloat(this.drawingConfiguration.Drawing.dimensions.width)
    //     .toFixed(0);
    // },
    // hasSize() {
    //   return this.width !== '' && this.width !== '0' && this.height !== '' && this.height !== '0';
    // },
    // pngFile() {
    //   return `${this.$config.apiUrl}/drawing/pngsmall/${this.drawingConfiguration.drawing_id}`;
    // },
    // createdDate() {
    //   return new Date(this.drawingConfiguration.created_at).toLocaleDateString(this.$i18n.locale, {
    //     year: 'numeric', month: '2-digit', day: '2-digit',
    //   });
    // },
  },
  methods: {
    formatAcceptedDate(date) {
      if (!date) {
        return '';
      }
      const options = {
        // weekday: 'short',
        // year: 'numeric',
        // month: 'short',
        // day: 'numeric',
      };
      const event = new Date(date);
      return event.toLocaleDateString(this.$i18n.locale, options);
    },
    statusLabel(status) {
      switch (status) {
        case 'DRAFT':
          return this.$t('status_labels.draft');
        case 'SUBMITTED':
          return this.$t('status_labels.payment_pending');
        case 'ACCEPTED':
          return this.$t('status_labels.payment_received');
        case 'IN_PROCESS':
          return this.$t('status_labels.in_production');
        case 'PROCESSED':
          return this.$t('status_labels.done');
        case 'SHIPPED':
          return this.$t('status_labels.shipped');
        case 'DELIVERED':
          return this.$t('status_labels.delivered');
        case 'HOLD':
          return this.$t('status_labels.on_hold');
        case 'CANCELLED':
          return this.$t('status_labels.cancelled');
        case 'EXPIRED':
          return this.$t('status_labels.expired');
        default:
          return status;
      }
    },
    statusValue(status) {
      switch (status) {
        case 'DRAFT':
          return 0;
        case 'SUBMITTED':
          return 10;
        case 'ACCEPTED':
          return 30;
        case 'IN_PROCESS':
          return 50;
        case 'PROCESSED':
          return 75;
        case 'SHIPPED':
          return 90;
        case 'DELIVERED':
          return 100;
        case 'HOLD':
          return 100;
        case 'CANCELLED':
          return 50;
        case 'EXPIRED':
          return 0.0;
        default:
          return 0.0;
      }
    },
    statusVariant(status) {
      switch (status) {
        // case 'DRAFT':
        //   return 'secondary';
        // case 'SUBMITTED':
        //   return 'info';
        // case 'ACCEPTED':
        //   return 'primary';
        // case 'IN_PROCESS':
        //   return 'primary';
        // case 'PROCESSED':
        //   return 'primary';
        // case 'SHIPPED':
        //   return 'success';
        // case 'CANCELLED':
        //   return 'success';
        // case 'EXPIRED':
        //   return 'danger';
        default:
          return 'primary';
      }
    },
    invoiceLink(order) {
      return `${this.$config.apiUrl}/document/invoice/${order.invoiceId}`;
    },

    // addToOrder() {
    //   this.addToOrderLoading = true;
    //   this.$store.dispatch('addDrawingConfiguration', {
    //     dcId: this.id,
    //     orderId: this.orderId,
    //   })
    //     .finally(() => {
    //       // this.$eventBus.$emit('gtm.add_to_cart', [this.id]);
    //       this.addToOrderLoading = false;
    //     });
    // },
    // showSelectMaterial() {
    //   this.$emit('showSelectMaterial', this);
    //   return false;
    // },
    // openProductEditor() {
    //   this.$router.push({
    //     name: 'products-list-me-editor',
    //     params: { productId: this.id },
    //   });
    // },
  },
};
</script>

<style lang="scss">
  .dashboard-previous-orders-block {
    th, td {
      padding: .2rem !important;
      height: auto !important;

      .shipping-method {
        font-size: 0.6rem;
      }
    }

    .progress-bar {
      &::after {
        background-color: rgba(0, 0, 0, 0.2) !important;
      }
    }

    .dropdown-toggle {
      font-size: .8rem;
      padding: .3rem;
      &::after {
        line-height: .8rem;
      }
    }
    .dropdown-item {
      font-size: 0.8rem;
      padding: 0 5px;
    }
  }
</style>
