import Vue from 'vue';
import axios from 'axios';
import EventBus from 'src/event-bus';
import config from 'src/config';
import i18n from '@/i18n';

/* eslint-disable no-param-reassign */
const moduleCart = {
  namespace: 'cart/',
  state: {
    orderDrawings: {},
    orderCustomItems: {},
    orderProducts: {},
    costTable: {},
    priorities: {},
    coupons: [],
    orderDrawingQueue: {},
    yourReference: '',
    vat: {
      vatType: null,
      validatedVatNumber: false,
      vatResponse: '',
      vatCountry: '',
      validationType: '',
    },
    address: {},
    addressErrors: {},
    phoneNumberWarning: false,
    phoneNumberError: false,
    addressWarnings: {},
    confirmation: {
      paymentDate: null,
      paymentDueDate: null,
      paymentReference: null,
      paymentIsRecent: null,
      paymentMethod: null,
    },
    deliveryAddress: null,
    invoiceAddress: null,
    hasDropshipping: false,
    lock: {},
  },
  getters: {
    /**
     * Get current priority
     * Todo: Cache the results?
     * @param state
     * @param getters
     * @param rootState
     * @param rootGetters
     * @returns {any}
     */
    // eslint-disable-next-line no-unused-vars
    selectedPriority(state, getters, rootState, rootGetters) {
      return Object.values(state.priorities)
        .find((obj) => obj.id === state.priorityId);
    },
  },
  mutations: {
    LOCK(state, { name, value }) {
      Vue.set(state.lock, name, value);
    },
    UNLOCK(state, { name }) {
      Vue.delete(state.lock, name);
    },
    /**
     * Set list of custom order items
     * @param state
     * @param orderCustomItems
     * @constructor
     */
    SET_ORDER_CUSTOM_ITEMS_LIST: (state, { orderCustomItems }) => {
      Vue.set(state, 'orderCustomItems', orderCustomItems);
    },
    /**
     * Delete order drawings
     * @param state
     * @param orderDrawingsDeleted
     * @constructor
     */
    DELETE_ORDER_DRAWINGS: (state, { orderDrawingsDeleted }) => {
      orderDrawingsDeleted.forEach((id) => {
        Vue.delete(state.orderDrawings, id);
      });
      Vue.set(
        state, 'orderDrawings',
        JSON.parse(JSON.stringify(state.orderDrawings)),
      );
    },
    /**
     * Update qty, are we in progress?
     * @param state
     * @param id
     * @param inProgress
     * @param cancelObj
     * @param value
     * @constructor
     */
    QTY_UPDATE_IN_PROGRESS: (state, {
      id, inProgress, cancelObj, value,
    }) => {
      if (id in state.orderDrawingQueue) {
        if (inProgress) {
          // we have a new request. Cancel the old one.
          state.orderDrawingQueue[id].cancelObj.cancel();
        }
        // We're done, or we've cancelled the current one.
        Vue.delete(state.orderDrawingQueue, id);
      }
      if (inProgress) {
        Vue.set(state.orderDrawingQueue, id, {
          quantity: value,
          cancelObj,
        });
        // prevent annoying switching
        Vue.set(state.orderDrawings[id], 'quantity', parseInt(value, 10));
      }
    },
    /**
     * Clear cart errors
     * TODO: Where is this used?
     * @param state
     * @param fields
     * @constructor
     */
    REMOVE_CART_ERRORS: (state, fields) => {
      if (fields instanceof Array) {
        fields.forEach((item) => {
          Vue.delete(state.addressErrors, item);
        });
      } else {
        Vue.delete(state.addressErrors, fields);
      }
    },
    REMOVE_CART_WARNINGS: (state, fields) => {
      if (fields instanceof Array) {
        fields.forEach((item) => {
          Vue.delete(state.addressWarnings, item);
        });
      } else {
        Vue.delete(state.addressWarnings, fields);
      }
    },
    /**
     * Update cart error states
     * @param state
     * @param errorObj
     * @constructor
     */
    UPDATE_CART_ERRORS: (state, errorObj) => {
      Object.keys(errorObj)
        .forEach((field) => Vue.set(state.addressErrors, field, errorObj[field]));
    },
    /**
     * Update cart, add seperate object items
     * @param state
     * @param orderDrawings
     * @param orderTable
     * @param shippingMethod
     * @param hasDropshipping
     * @param priorities
     * @param priorityId
     * @param orderCustomItems
     * @param coupons
     * @param address
     * @param orderId
     * @param yourReference
     * @param vat
     * @param userComment
     * @param orderErrors
     * @param addressWarnings
     * @param updatedAt
     * @param submittedAt
     * @param status
     * @param paymentDate
     * @param paymentDueDate
     * @param paymentReference
     * @param paymentIsRecent
     * @param paymentMethod
     * @param deliveryAddress
     * @param invoiceAddress
     * @param invoiceId
     * @param confirmationId
     * @param orderProducts
     * @constructor
     */
    UPDATE_CART: (
      state,
      {
        orderDrawings, orderTable,
        shippingMethod, hasDropshipping, priorities,
        priorityId, orderCustomItems, orderProducts,
        coupons, address, orderId, yourReference, vat,
        userComment, orderErrors, addressWarnings,
        updatedAt, submittedAt, status,
        paymentDate, paymentDueDate, paymentReference, paymentIsRecent, paymentMethod,
        deliveryAddress, invoiceAddress,
        invoiceId, confirmationId,
      },
    ) => {
      if (orderDrawings !== undefined) {
        // remove all order drawings..
        Vue.set(state, 'orderDrawings', {});
        Object.values(orderDrawings)
          .forEach((value) => {
            Vue.set(state.orderDrawings, parseInt(value.id, 10), value);
          });
      }
      if (shippingMethod !== undefined) {
        Vue.set(state, 'shippingMethod', shippingMethod);
      }
      if (hasDropshipping !== undefined) {
        Vue.set(state, 'hasDropshipping', hasDropshipping);
      }
      if (priorityId !== undefined) {
        Vue.set(state, 'priorityId', parseInt(priorityId, 10));
      }
      if (orderId !== undefined) {
        Vue.set(state, 'orderId', parseInt(orderId, 10));
      }
      if (orderCustomItems !== undefined) {
        Vue.set(state, 'orderCustomItems', {});
        Object.values(orderCustomItems)
          .forEach((value) => {
            Vue.set(state.orderCustomItems, parseInt(value.id, 10), value);
          });
      }
      if (orderProducts !== undefined) {
        Vue.set(state, 'orderProducts', {});
        Object.values(orderProducts)
          .forEach((value) => {
            Vue.set(state.orderProducts, parseInt(value.id, 10), value);
          });
      }
      if (coupons !== undefined) {
        Vue.set(state, 'coupons', coupons);
      }
      if (address !== undefined) {
        Vue.set(state, 'address', address);
      }
      if (yourReference !== undefined) {
        Vue.set(state, 'yourReference', yourReference);
      }

      if (userComment !== undefined) {
        Vue.set(state, 'userComment', userComment);
      }

      if (orderErrors !== undefined) {
        Vue.set(state, 'orderErrors', orderErrors);
      }

      if (updatedAt !== undefined) {
        Vue.set(state, 'updatedAt', updatedAt);
      }

      if (submittedAt !== undefined) {
        Vue.set(state, 'submittedAt', submittedAt);
      }
      if (invoiceId !== undefined) {
        Vue.set(state, 'invoiceId', invoiceId);
      }
      if (confirmationId !== undefined) {
        Vue.set(state, 'confirmationId', confirmationId);
      }

      if (status !== undefined) {
        Vue.set(state, 'status', status);
      }
      if (addressWarnings !== undefined) {
        Vue.set(state, 'addressWarnings', addressWarnings);
      }

      if (vat !== undefined) {
        Object.keys(vat)
          .forEach((key) => {
            Vue.set(state.vat, key, vat[key]);
          });
      }

      if (priorities !== undefined) {
        // reset priorities
        state.priorities = {};
        priorities.forEach((value) => {
          Vue.set(state.priorities, parseInt(value.id, 10), value);
        });
      }

      if (orderTable !== undefined) {
        Object.keys(orderTable.costItems)
          .forEach((name) => {
            Vue.set(state.costTable, name, orderTable.costItems[name]);
          });
      }

      if (paymentDate !== undefined) {
        Vue.set(state.confirmation, 'paymentDate', paymentDate);
      }
      if (paymentDueDate !== undefined) {
        Vue.set(state.confirmation, 'paymentDueDate', paymentDueDate);
      }
      if (paymentReference !== undefined) {
        Vue.set(state.confirmation, 'paymentReference', paymentReference);
      }
      if (paymentIsRecent !== undefined) {
        Vue.set(state.confirmation, 'paymentIsRecent', paymentIsRecent);
      }
      if (paymentMethod !== undefined) {
        Vue.set(state.confirmation, 'paymentMethod', paymentMethod);
      }
      if (deliveryAddress !== undefined) {
        Vue.set(state, 'deliveryAddress', deliveryAddress);
      }
      if (invoiceAddress !== undefined) {
        Vue.set(state, 'invoiceAddress', invoiceAddress);
      }
    },
  },
  actions: {
    addCoupon(context, { couponCode, orderId }) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('coupon_code', couponCode);
        formData.append('order_id', orderId);
        return axios.post(
          `${config.apiUrl}/order/addCoupon`,
          formData,
          { withCredentials: true },
        )
          .then((response) => {
            context.commit('UPDATE_CART', response.data.result);
            EventBus.success('Coupon toegevoegd');
            resolve();
          })
          .catch((err) => {
            const couponErrors = {
              INVALID_COUPON: {
                nl: 'Ongeldige coupon',
                en: 'Invalid coupon',
              },
              INVALID_COUPON_NOT_STACKABLE: {
                nl: 'Deze coupon kan niet gecombineerd worden',
                en: 'This coupon can\'t be combined',
              },
              INVALID_COUPON_USED: {
                nl: 'Coupon al gebruikt',
                en: 'Coupon was already used',
              },
              INVALID_COUPON_ALREADY_USED: {
                nl: 'Coupon al gebruikt',
                en: 'Coupon was already used',
              },
              INVALID_COUPON_EXPIRED: {
                nl: 'Coupon verlopen',
                en: 'Coupon expired',
              },
              COUPONITEM_NOT_FOUND: {
                nl: 'Coupon niet gevonden',
                en: 'Coupon niet gevonden',
              },
            };
            const errorType = err.response.data.error;
            if (errorType in couponErrors) {
              reject(couponErrors[errorType][i18n.locale]);
            } else {
              EventBus.error(err);
              reject(errorType);
            }
            // eslint-disable-next-line dot-notation
          });
      });
    },
    deleteCoupon(context, { couponId, orderId }) {
      const formData = new FormData();
      formData.append('coupon_id', couponId);
      formData.append('order_id', orderId);
      return axios.post(
        `${config.apiUrl}/order/deleteCoupon`,
        formData,
        { withCredentials: true },
      )
        .then((response) => {
          context.commit('UPDATE_CART', response.data.result);
        })
        .catch((err) => {
          EventBus.error(err);
        });
    },
    loadOrder(context, orderId) {
      if (context.state.lock.LOADING_ORDER === orderId) {
        return null;
      }
      context.commit('LOCK', {
        name: 'LOADING_ORDER',
        value: orderId,
      });
      context.commit('SET_LOADED', {
        type: 'cart',
        isLoaded: false,
      });
      context.commit('UPDATE_CART', {
        orderDrawings: {},
        orderCustomItems: {},
        costTable: {},
        priorities: [],
        coupons: [],
        orderDrawingQueue: {},
        yourReference: '',
        vat: {
          vatType: null,
          validatedVatNumber: false,
          vatResponse: '',
          vatCountry: '',
          validationType: '',
        },
        address: {},
        addressErrors: {},
        addressWarnings: {},
        confirmation: {
          paymentDate: null,
          paymentDueDate: null,
          paymentReference: null,
          paymentIsRecent: null,
          paymentMethod: null,
        },
        deliveryAddress: null,
        invoiceAddress: null,
        orderErrors: [],
      });
      return axios.get(`${config.apiUrl}/order/get`, {
        withCredentials: true,
        params: {
          id: orderId,
        },
      })
        .then((response) => {
          context.commit('UPDATE_CART', response.data.result);
          if (response.data.result.status === 'DRAFT') {
            context.commit('SET_CURRENT_ORDER', response.data.result.orderId);
          }
          context.commit('SET_LOADED', {
            type: 'cart',
            isLoaded: true,
          });
        }, (err) => {
          EventBus.error(err);
        })
        .finally(() => {
          if (context.state.lock.LOADING_ORDER === orderId) {
            context.commit('UNLOCK', { name: 'LOADING_ORDER' });
          }
        });
    },
    deleteOrderDrawing(context, { orderDrawingIds, orderId }) {
      const formData = new FormData();
      formData.append('order_id', orderId);
      orderDrawingIds.forEach((id) => {
        formData.append('order_drawing_id[]', id);
      });
      return axios.post(
        `${config.apiUrl}/order/deleteOrderDrawing`,
        formData,
        { withCredentials: true },
      )
        .then((response) => {
          context.commit('DELETE_ORDER_DRAWINGS', response.data.result);
          context.commit('UPDATE_CART', response.data.result);
          context.commit(
            'SET_DRAWING_CONFIGURATION',
            { list: Object.values(response.data.result.drawingConfigurations) },
          );
          EventBus.success(i18n.t('cart.design_removed_alert'));
        })
        .catch((err) => {
          EventBus.error(err);
        });
    },
    addOrderProduct(context, {
      productId, orderId,
    }) {
      const formData = new FormData();
      formData.append('product_id', productId);
      formData.append('order_id', orderId);

      return axios.post(
        `${config.apiUrl}/orderProduct/create`,
        formData,
        { withCredentials: true },
      )
        .then((response) => {
          context.commit('UPDATE_CART', response.data.result);
          EventBus.success(i18n.t('cart.design_added'));
        })
        .catch((err) => {
          EventBus.error(err);
        });
    },

    createOrderCustomItem(context, {
      name, price, quantity, orderId,
    }) {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('cost', price);
      formData.append('quantity', quantity);
      formData.append('order_id', orderId);

      return axios.post(
        `${config.apiUrl}/orderCustomItem/create`,
        formData,
        { withCredentials: true },
      )
        .then((response) => {
          context.commit('UPDATE_CART', response.data.result);
          EventBus.success(i18n.t('cart.design_added'));
        })
        .catch((err) => {
          EventBus.error(err);
        });
    },
    deleteOrderCustomItem(context, { orderCustomItemIds, orderId }) {
      const formData = new FormData();
      formData.append('order_id', orderId);
      orderCustomItemIds.forEach((id) => {
        formData.append('order_custom_item_id[]', id);
      });
      return axios.post(
        `${config.apiUrl}/orderCustomItem/delete`,
        formData,
        { withCredentials: true },
      )
        .then((response) => {
          context.commit('UPDATE_CART', response.data.result);
          EventBus.success(i18n.t('cart.design_removed_alert'));
        })
        .catch((err) => {
          EventBus.error(err);
        });
    },
    deleteOrderProduct(context, { orderProductId, orderId }) {
      const formData = new FormData();
      formData.append('order_id', orderId);
      formData.append('order_product_id', orderProductId);
      return axios.post(
        `${config.apiUrl}/orderProduct/delete`,
        formData,
        { withCredentials: true },
      )
        .then((response) => {
          context.commit('UPDATE_CART', response.data.result);
          EventBus.success(i18n.t('cart.design_removed_alert'));
        })
        .catch((err) => {
          EventBus.error(err);
        });
    },
    updateOrderPriority(context, { priorityId, orderId }) {
      const formData = new FormData();
      formData.append('priority_id', priorityId);
      formData.append('id', orderId);
      return axios.post(
        `${config.apiUrl}/order/updatePriority`,
        formData,
        { withCredentials: true },
      )
        .then((response) => {
          context.commit('UPDATE_CART', response.data.result);
          EventBus.success('Levertijd aangepast en opgeslagen');
        })
        .catch((err) => {
          EventBus.error(err);
        });
    },
    adminAcceptedWithEmail(context, { orderId }) {
      const formData = new FormData();
      formData.append('id', orderId);
      return axios.post(
        `${config.apiUrl}/order/adminAcceptedWithEmail`,
        formData,
        { withCredentials: true },
      )
        .then((response) => {
          context.commit('UPDATE_CART', response.data.result);
          EventBus.success('Levertijd aangepast en opgeslagen');
        })
        .catch((err) => {
          EventBus.error(err);
        });
    },
    updateShippingField(context, field, value, matches) {
      const formData = new FormData();
      formData.append('field', field);
      formData.append('value', value);
      return axios.post(
        `${config.apiUrl}/order/updateDeliveryField`,
        formData,
        { withCredentials: true },
      )
        .then((response) => {
          if (matches(field)) {
            context.commit('UPDATE_CART', response.data.result);
            if (value === 'PICKUP') {
              context.commit('REMOVE_CART_ERRORS', 'delivery_phone_number');
            }
          }
          EventBus.success('Leverwijze aangepast en opgeslagen');
        })
        .catch((err) => {
          EventBus.error(err);
        });
    },
    updateReference(context, { value, valid }) {
      const formData = new FormData();
      formData.append('order_reference', value);
      return axios.post(
        `${config.apiUrl}/order/updateOrderField`,
        formData,
        { withCredentials: true },
      )
        .then((response) => {
          if (valid()) {
            context.commit('UPDATE_CART', response.data.result);
            EventBus.success('Referentie opgeslagen');
          }
        })
        .catch((err) => {
          EventBus.error(err);
        });
    },
    updateDeliveryMethod(context, { deliveryMethod, orderId }) {
      const formData = new FormData();
      formData.append('shippingMethod', deliveryMethod);
      formData.append('id', orderId);
      return axios.post(
        `${config.apiUrl}/order/updateDeliveryMethod`,
        formData,
        { withCredentials: true },
      )
        .then((response) => {
          context.commit('UPDATE_CART', response.data.result);
          EventBus.success('Leverwijze aangepast en opgeslagen');
        })
        .catch((err) => {
          EventBus.error(err);
        });
    },
    updateDropshipping(context, { hasDropshipping, orderId }) {
      const formData = new FormData();
      formData.append('hasDropshipping', hasDropshipping);
      formData.append('id', orderId);
      return axios.post(
        `${config.apiUrl}/order/updateDropshipping`,
        formData,
        { withCredentials: true },
      )
        .then((response) => {
          context.commit('UPDATE_CART', response.data.result);
          EventBus.success('Leverwijze aangepast en opgeslagen');
        })
        .catch((err) => {
          EventBus.error(err);
        });
    },
    updateQty(context, { id, value, productType }) {
      const formData = new FormData();
      formData.append('order_drawing_or_item_id', id);
      formData.append('quantity', value);
      return axios.post(
        `${config.apiUrl}/order/updateQty${productType}`,
        formData,
        { withCredentials: true },
      )
        .then((response) => {
          context.commit('UPDATE_CART', response.data.result);
          if (productType === 'OrderDrawing') {
            context.commit('SET_DRAWING_CONFIGURATION', {
              list: Object.values(response.data.result.drawingConfigurations),
            });
          }
          EventBus.success(i18n.t('cart.quantity_updated_and_saved'));
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            EventBus.error(err);
          }
        });
    },
    addDrawingConfiguration(context, { dcId, orderId }) {
      const formData = new FormData();
      formData.append('method', 'addToOrder');
      formData.append('id', orderId);
      if (dcId instanceof Array) {
        dcId.forEach((id) => formData.append('dc_id[]', id));
      } else {
        formData.append('dc_id[]', dcId);
      }
      return axios.post(
        `${config.apiUrl}/order/addDrawingConfiguration`,
        formData, { withCredentials: true },
      )
        .then((response) => {
          context.commit('UPDATE_CART', response.data.result);
          context.commit('SET_DRAWING_CONFIGURATION', {
            list: Object.values(response.data.result.drawingConfigurations),
          });
          EventBus.success(i18n.t('cart.design_added'));
        })
        .catch((err) => {
          EventBus.$emit('snlb.error', err);
        });
    },
    updateDeliveryCountry(context, { value, orderId }) {
      const formData = new FormData();
      formData.append('country', value);
      formData.append('id', orderId);
      return axios.post(
        `${config.apiUrl}/order/updateDeliveryCountry`,
        formData,
        { withCredentials: true },
      )
        .then((response) => {
          context.commit('UPDATE_CART', response.data.result);
          EventBus.success('Land aangepast en opgeslagen');
        })
        .catch((err) => {
          EventBus.error(err);
        });
    },
    updateCartAddress(context, { address }) {
      const formData = new FormData();
      Object.keys(address)
        .forEach((key) => {
          if (address[key] === null) {
            address[key] = '';
          }
          formData.append(`address[${key}]`, address[key]);
        });
      return axios.post(
        `${config.apiUrl}/order/updateAddress`,
        formData,
        { withCredentials: true },
      )
        .then((response) => {
          context.commit('UPDATE_CART', response.data.result);
          EventBus.success('Adres opgeslagen');
        })
        .catch((err) => {
          EventBus.error(err);
        });
    },
    upgradeCartAddressWarningsToErrors(context) {
      if (context.state.addressWarnings) {
        context.commit('UPDATE_CART_ERRORS', context.state.addressWarnings);
      } else {
        context.commit('UPDATE_CART_ERRORS', {});
      }
      if (context.state.phoneNumberWarning) {
        context.state.phoneNumberError = true;
      }
    },
    // eslint-disable-next-line no-unused-vars
    clearInvoiceCountryErrors(context) {
      context.commit('REMOVE_CART_WARNINGS', 'invoice_address_number');
      context.commit('REMOVE_CART_ERRORS', 'invoice_address_number');
    },
    clearDeliveryPhoneNumberErrors(context) {
      context.commit('REMOVE_CART_WARNINGS', 'delivery_phone_number');
      context.commit('REMOVE_CART_ERRORS', 'delivery_phone_number');
    },
    clearShippingCountryErrors(context) {
      context.commit('REMOVE_CART_WARNINGS', 'delivery_address_number');
      context.commit('REMOVE_CART_ERRORS', 'delivery_address_number');
    },
    clearCompanyErrors(context) {
      context.commit('REMOVE_CART_WARNINGS', 'invoice_company');
      context.commit('REMOVE_CART_ERRORS', 'invoice_company');
      context.commit('REMOVE_CART_WARNINGS', 'invoice_email');
      context.commit('REMOVE_CART_ERRORS', 'invoice_email');
      context.commit('REMOVE_CART_WARNINGS', 'vat_number');
      context.commit('REMOVE_CART_ERRORS', 'vat_number');
    },
    updateCartField(context, { field, value, orderId }) {
      const formData = new FormData();
      formData.append('order_id', orderId);
      let hasInvoiceCountryNl = false;
      let removeDeliveryErrors = false;
      if (field instanceof Array && value instanceof Array) {
        for (let i = 0; i < Math.min(field.length, value.length); i += 1) {
          formData.append('field[]', field[i]);
          formData.append('value[]', value[i]);
          if (field[i] === 'invoice_country' && value[i] === 'NL') {
            hasInvoiceCountryNl = true;
          }
          if (field[i] === 'invoice_is_delivery_address') {
            removeDeliveryErrors = true;
          }
        }
      } else {
        removeDeliveryErrors = (field === 'invoice_is_delivery_address');
        hasInvoiceCountryNl = (field === 'invoice_country' && value === 'NL');
        formData.append('field[]', field);
        formData.append('value[]', value);
      }
      if (field.length === 0) {
        return null;
      }
      return axios.post(
        `${config.apiUrl}/order/updateFields`,
        formData,
        { withCredentials: true },
      )
        .then((response) => {
          if (response.data.error !== null) {
            context.commit('UPDATE_CART_ERRORS', response.data.error);
          } else {
            context.commit('REMOVE_CART_ERRORS', field);
          }

          if (removeDeliveryErrors) {
            context.commit('REMOVE_CART_ERRORS', ['delivery_zip_code', 'delivery_address', 'delivery_address_extra',
              'delivery_address_number', 'delivery_zip_code', 'delivery_city', 'delivery_company', 'delivery_name']);
          }

          if (hasInvoiceCountryNl) {
            context.commit('REMOVE_CART_ERRORS', 'vat_number');
          }
          context.commit('UPDATE_CART', response.data.result);
        })
        .catch((err) => {
          if (err.response.status === 417) {
            context.commit('UPDATE_CART_ERRORS', err.response.data.result);
          } else {
            EventBus.error(err);
          }
        });
    },
  },
};

export default moduleCart;
