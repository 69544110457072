<template>
  <div class="global-watcher"/>
</template>

<script>
export default {
  data() {
    return {
      activeTargets: [],
    };
  },
  computed: {
    isDragging() {
      return this.activeTargets.length !== 0;
    },
    events() {
      return ['drop', 'dragend', 'dragover', 'dragenter', 'dragexit', 'dragleave'];
    },
    isActive() {
      return window.dndWatcher === this;
    },
  },
  destroyed() {
    this.disableGlobalDragAndDrop();
  },
  mounted() {
    window.dndWatcher = this;
    this.enableGlobalDragAndDrop();
  },
  methods: {
    isFile(event) {
      const { dataTransfer } = event;
      // Source detection for Firefox on Windows.
      if (typeof DOMStringList !== 'undefined') {
        const DragDataType = dataTransfer.types;
        if (DragDataType.constructor === DOMStringList) {
          return !!DragDataType.contains('Files');
        }
      }

      // Source detection for Chrome on Windows.
      if (typeof Array !== 'undefined') {
        const DragDataType = dataTransfer.types;
        if (DragDataType.constructor === Array) {
          return DragDataType.constructor === Array && DragDataType.indexOf('Files') !== -1;
        }
      }
      return false;
    },
    handleDragEvent(event) {
      if (!this.isActive) {
        this.disableGlobalDragAndDrop();
        return true;
      }

      event.stopPropagation();
      event.preventDefault();
      const oldLength = this.activeTargets.length;
      // eslint-disable-next-line no-param-reassign
      event.dataTransfer.effectAllowed = 'copyMove';

      if (event.type === 'dragover') {
        // eslint-disable-next-line no-param-reassign
        event.dataTransfer.effectAllowed = 'copyMove';
        return false;
      }

      if (event.type === 'dragover') {
        // eslint-disable-next-line no-param-reassign
        event.dataTransfer.effectAllowed = 'copyMove';
        return false;
      }

      if (!this.isFile(event)) {
        return false;
      }

      if (event.type === 'dragenter') {
        this.activeTargets.push(event.target);
        if (oldLength === 0) {
          this.$emit('dragstart', event);
        }
      }

      if (event.type === 'dragleave') {
        for (let i = this.activeTargets.length - 1; i >= 0; i -= 1) {
          if (this.activeTargets[i] === event.target) {
            this.activeTargets.splice(i, 1);
          }
        }

        if (this.activeTargets.length === 0) {
          this.$emit('dragstop', event);
        }
      }

      if (event.type === 'drop') {
        this.activeTargets = [];
        this.$emit('dragstop', event);
        this.$emit('drop', event);
      }
      return false;
    },

    enableGlobalDragAndDrop() {
      this.events.map((item) => {
        document.addEventListener(item, this.handleDragEvent);
        return item;
      });
    },
    disableGlobalDragAndDrop() {
      this.events.map((item) => {
        document.removeEventListener(item, this.handleDragEvent);
        return item;
      });
    },
  },
};
</script>
