<template>
  <div class="btn-group dc-batch-actions" role="group">
    <loading-button
      :loading="false"
      size="xs"
      variant="empty"
      class="px-2"
      :disabled="selected.length === 0 || isLoading"
      @click="batchSelectMaterial">
      <i class="material-icons">edit</i>
    </loading-button>

    <loading-button
      :loading="batchDuplicateLoading"
      size="xs"
      variant="empty"
      class="px-2"
      title="Kopieer producten"
      :disabled="selected.length === 0 || isLoading"
      @click="batchDuplicate">
      <i class="material-icons">file_copy</i>
    </loading-button>

    <loading-button
      :loading="batchAddToCartLoading"
      size="xs"
      variant="empty"
      class="px-2"
      title="Aan winkelwagen toevoegen"
      :disabled="selected.length === 0 || isLoading || selectedHasErrors"
      @click="batchAddToOrder">
      <i class="material-icons">addshopping_cart</i>
    </loading-button>

    <loading-button
      :loading="batchDeleteLoading"
      size="xs"
      variant="empty"
      class="px-2"
      title="Verwijderen"
      :disabled="selected.length === 0 || isLoading"
      @click="batchDeleteModal">
      <i class="material-icons">delete</i>
    </loading-button>

    <drawing-configuration-batch-editor
      v-if="batchSelectMaterialVisible"
      :selected="selected"
      :visible="batchSelectMaterialVisible"
      @hide="batchSelectMaterialVisible = false"
    />
  </div>
</template>
<script>
import DrawingConfigurationBatchEditor from 'src/components/drawingConfiguration/DrawingConfigurationBatchEditor';
import LoadingButton from 'src/components/tools/LoadingButton';

export default {
  components: { LoadingButton, DrawingConfigurationBatchEditor },
  props: {
    selected: {
      type: Array,
      required: true,
    },
    orderId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      batchMaterialLoading: false,
      batchDuplicateLoading: false,
      batchAddToCartLoading: false,
      batchDeleteLoading: false,
      batchSelectMaterialVisible: false,
    };
  },
  computed: {
    isLoading() {
      return this.batchMaterialLoading || this.batchCopyLoading || this.batchAddToCartLoading || this.batchDeleteLoading;
    },
    selectedHasErrors() {
      return this.selected.map((id) => this.$store.state.dc.drawingConfiguration[parseInt(id, 10)])
        .some((dc) => dc.feedback.some((f) => f.type === 'ERROR'));
    },
    selectedHasLocked() {
      return this.selected.map((id) => this.$store.state.dc.drawingConfiguration[parseInt(id, 10)])
        .some((dc) => dc.locked);
    },
  },
  watch: {
    loading() {
      this.$emit('loading', this.isLoading);
    },
  },
  methods: {
    upload() {
      this.$eventBus.$emit('upload');
    },
    batchAddToOrder() {
      this.batchAddToCartLoading = true;
      const keys = this.selected
        .map((id) => parseInt(id, 10));
      this.$store.dispatch('addDrawingConfiguration', {
        dcId: keys,
        orderId: this.orderId,
      }).finally(() => {
        this.batchAddToCartLoading = false;
      });
    },
    batchDuplicate() {
      this.batchDuplicateLoading = true;
      const keys = this.selected
        .map((id) => parseInt(id, 10));
      this.$store.dispatch('duplicateConfigurations', {
        ids: keys,
        replaceInOrders: false,
        orderId: this.$store.state.cart.orderId,
      }).finally(() => { this.batchDuplicateLoading = false; });
    },
    batchSelectMaterial() {
      this.batchSelectMaterialVisible = true;
    },
    batchDeleteModal() {
      if (!this.selectedHasLocked) {
        this.batchDelete();
        return;
      }
      this.$bvModal.msgBoxConfirm(this.$i18n.t('my_designs.we_will_delete_your_drawings'), {
        title: this.$t('drawing_configuration.delete_drawing'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t('drawing_configuration.batch_editor.delete'),
        cancelTitle: this.$t('drawing_configuration.batch_editor.cancel'),
        footerClass: 'p-2',
        hideHeaderClose: true,
        centered: true,
      }).then((isConfirmed) => {
        if (!isConfirmed) { return; }
        this.batchDelete();
      }).catch(() => {});
    },
    batchDelete() {
      this.batchDeleteLoading = true;
      const deleted = this.selected;
      const request = this.$store.dispatch(
        'deleteConfigurations',
        {
          ids: deleted,
          orderId: this.$store.state.cart.orderId,
        },
      );
      request.then(() => {
      }).finally(() => {
        this.$emit('deselectAll');
        this.batchDeleteLoading = false;
      });
    },
  },
};
</script>

<style lang="scss">
  // @media (max-width: 992px) {
  //   .dc-batch-actions {
  //     .btn {
  //       padding-left: 0.8rem;
  //       padding-right: 0.8rem;
  //     }
  //   }
  // }
</style>
