<template>
    <div class="contentful-text-and-image pt-5"
      :class="{'flip': data.textAndImageOrderDesktop, 'flip-mobile': data.textAndImageOrderMobile}"
      :style="cssBackgroundImage()">
      <div class="content-container" :class="{'on-frontpage': onFrontpage}">
        <div class="text-container p-2">
          <h2 v-if="data.title" class="display-4">{{data.title}}</h2>
          <div v-if="data.text" v-html="htmlString(data.text)" />
          <div class="btn-container">
            <template v-if="showLoginBtn">
              <b-button
                size="md"
                class="shadow-none"
                variant="secondary"
                @click.prevent="uploadBtnClicked()">
                {{data.ctaSecondaryTitle}}
              </b-button>
            </template>
            <template v-else>
              <a v-if="data.ctaPrimaryTitle && data.ctaPrimaryLink && data.ctaPrimaryLink.endsWith('/dashboard')"
                class="btn btn-success mr-2 shadow-none mb-2 p-2"
                :href="$router.resolve({ 'name': 'dashboard' }).href">{{data.ctaPrimaryTitle}}</a>
              <router-link v-else-if="data.ctaPrimaryTitle && data.ctaPrimaryLink"
                class="btn btn-success mr-2 shadow-none mb-2 p-2"
                :to="{path: strippedLink(data.ctaPrimaryLink)}">{{data.ctaPrimaryTitle}}</router-link>

                <router-link v-if="data.ctaSecondaryTitle && data.ctaSecondaryLink"
                  class="btn btn-outline-dark shadow-none mb-2 p-2"
                  :to="{path: strippedLink(data.ctaSecondaryLink)}">{{ data.ctaSecondaryTitle}}</router-link>
            </template>
          </div>
        </div>
        <div class="img-container" :class="{'px-4': onFrontpage}">
          <img v-if="data.image && !youtubeVideoId()" class="img-fluid"
            loading="lazy"
            :src="`${data.image.fields.file.url}?w=550`"
            :srcset="`${data.image.fields.file.url}?w=480 480w, ${data.image.fields.file.url}?w=550 550w`"
            sizes="(max-width: 480px) 480px,
                  550px"
            :alt="data.image.fields.description" />

            <iframe v-else-if="youtubeVideoId()"
              class="youtube-video img-fluid"
              width="550" height="309"
              :src=youtubeEmbedUrl
              title="We are Snijlab" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen rel="false"></iframe>

          <template v-if="arrowStyle == 1">
            <img loading="lazy" class="arrows arrow-1" src="@/assets/frontpage/arrows-1.svg" alt="" />
            <img loading="lazy" class="arrows arrow-2" src="@/assets/frontpage/arrows-2.svg" alt="" />
          </template>
          <template v-else-if="arrowStyle == 2">
            <img loading="lazy" class="arrows arrow-3" src="@/assets/frontpage/arrows-3.svg" alt="" />
            <img loading="lazy" class="arrows arrow-4" src="@/assets/frontpage/arrows-4.svg" alt="" />
          </template>
        </div>
      </div>
    </div>
</template>

<script>

import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

export default {
  props: {
    data: {
      type: Object,
      require: true,
    },
    showLoginBtn: {
      type: Boolean,
      require: false,
      default: false,
    },
    isSignedIn: {
      type: Boolean,
      require: false,
      default: false,
    },
    bgColor: {
      type: String,
      default: '#ffffff',
    },
    arrowStyle: {
      type: Number,
      default: 0,
    },
    onFrontpage: {
      type: Boolean,
      default: false,
    },
    removeBeforeDestroy: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    youtubeEmbedUrl() {
      return `https://www.youtube.com/embed/${this.youtubeVideoId()}?rel=0`;
    },
  },
  methods: {
    htmlString(text) {
      return documentToHtmlString(text);
    },
    youtubeVideoId() {
      if (this.data.youtubeVideoId) {
        return this.data.youtubeVideoId;
      }
      return null;
    },
    cssBackgroundImage() {
      if (this.bgImage) {
        return `background-image: url("${this.bgImage.url}"?w=1800);`;
      }
      return `background-color: ${this.bgColor}`;
    },
    strippedLink(url) {
      const strippedUrl = url.split('snijlab.nl');
      if (strippedUrl.length > 1) {
        return strippedUrl[1];
      }
      return url;
    },
    uploadBtnClicked() {
      if (this.isSignedIn) {
        this.$router.push({
          name: 'order-cart',
          params: {
            lang: this.$i18n.locale,
            orderId: this.orderId,
            openUploadModal: true,
          },
        });
      } else {
        this.$eventBus.$emit('showLogin');
        this.uploadAfterLogin = true;
      }
    },
  },
  beforeDestroy() {
    if (this.removeBeforeDestroy) {
      this.$root.$el.parentNode.removeChild(this.$root.$el);
    }
  },
};
</script>

<style lang="scss">
  .contentful-text-and-image {

    .content-container {
      column-gap: 1.2rem;
      display: grid;
      grid-template-columns: 54% fit-content(46%);
      grid-template-rows: auto;
      padding-bottom: 3rem;
      max-width: 1300px;
      margin: auto;

      .youtube-video {
        aspect-ratio: 16/9;
        height: auto;
      }

      @media(max-width: 767px){
        .img-container {
          position: relative;
          overflow: hidden;
        }
      }

      &.on-frontpage {
        @media(min-width: 1300px) {
          max-width: 1300px;
        }
      }

      .arrows {
        pointer-events: none;
        position: absolute;
        &.arrow-1 {
          width: 240px;
          margin-left: -575px;
          margin-top: 160px;
        }
        &.arrow-2 {
          width: 200px;
          margin-top: 8px;
          margin-left: -145px;
        }
        &.arrow-3 {
          width: 210px;
          margin-top: 130px;
          margin-left: -550px;
        }
        &.arrow-4 {
          width: 250px;
          margin-top: 0;
          margin-left: -245px
        }
      }

      .text-container {
        // padding-right: 0;
        // padding-left: 1rem;

        grid-column: 1;
        grid-row: 1;

        // font-size: .8rem;
        font-size: 18px;
        line-height: 30px;
        font-weight: 300;

        ul {
          list-style: none;
          padding: 0 0 0 1rem;

          li:before {
            font-weight: bold;
            content: '✓';
            text-indent: 0;
            float: left;
            padding: 0 .5rem 0 0;
          }

          li p {
            padding: 0;
            margin: 0;
          }
        }

        h2 {
          font-weight: 700;
          font-size: 1.8rem;
        }
      }
    }

    &.flip {
      @media(min-width: 767px) {
        .content-container {
          grid-template-columns: fit-content(46%) 54%;

          .text-container {
            grid-column: 2;
            // padding-left: 0;
            // padding-right: 1rem;
          }
          img {
            grid-column: 1;
          }
        }
      }
    }

    img {
      grid-column: 2;
      grid-row: 1;
      max-width: 600px;
      width: 100%;
    }

    @media(max-width: 767px) {
      .content-container {
        grid-template-columns: auto;
        grid-template-rows: 1fr auto;
        padding-bottom: 2rem;

        .text-container {
          grid-column: 1;
          grid-row: 1;
          // padding: 0 1rem 1rem 1rem;

          h3 {
            margin-bottom: 1.5rem;
          }
        }

        img {
          grid-column: 1;
          grid-row: 2;
          width: 100%;
          max-width: 100%;
        }
      }

      &.flip-mobile {
        grid-template-rows: auto 1fr;
        .text-container {
          grid-row: 2;
        }
        img {
          grid-row: 1;
        }
      }
    }
  }
</style>
