<template>
  <div class="custom-order-item card shadow-none">
    <div class="card-body p-2 bg-snijlab">
      <div>
        <div class="field-element">
          <label for="custom-order-item-name">{{$t('admin.description')}}</label><br>
          <input v-model="customOrderItemName" class="custom-order-item-name mr-2"
            value="" :placeholder="$t('admin.eg_drawing')" />
        </div>

        <div class="field-element">
          <label for="custom-order-item-cost">{{$t('admin.price_per_piece_excl_vat')}}</label><br>
          &euro; <input v-model="customOrderItemPrice" value="" placeholder="65.00" class="custom-order-item-cost mr-2" />
        </div>

        <div class="field-element">
          <label for="custom-order-item-qty">{{$t('admin.quantity')}}</label><br>
          <input v-model="customOrderItemQty" value="" placeholder="1" class="custom-order-item-qty mr-2"/>
        </div>
      </div>

      <div class="submit-btn-field">
        <b-button
        :disabled="customOrderItemName === '' || customOrderItemPrice === '' || customOrderItemQty === ''"
          class="btn btn-xs btn-light" @click="create">
          <i class="material-icons">add</i> {{$t('admin.add')}}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    orderId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      customOrderItemName: '',
      customOrderItemPrice: 0.00,
      customOrderItemQty: 1,
    };
  },
  methods: {
    create() {
      const priceInt = parseInt(100 * parseFloat(String(this.customOrderItemPrice).replace(/,/, '.')), 10);
      const quantityInt = Math.max(1, parseInt(this.customOrderItemQty, 10));

      this.$store.dispatch('createOrderCustomItem', {
        name: this.customOrderItemName,
        price: priceInt,
        quantity: quantityInt,
        orderId: this.orderId,
      });
    },
  },
};
</script>

<style lang="scss">
  .custom-order-item .card-body {
    display: flex;
    justify-content: space-between;

    .field-element {
      display: inline-block;
    }

    .custom-order-item-qty {
      width: 100px;
    }

    .submit-btn-field {
      align-self: end;
    }
  }
</style>
