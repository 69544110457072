<template>
  <div>
    <orders-loader/>
    <loading-widget-full-width :loading="!ready"/>

    <div v-if="ready" class="dashboard-view d-flex flex-wrap mb-2 py-2">
      <template v-if="bodyLoaded && body.items">
        <div v-for="(block) in body.items" :key="block.sys.id" class="block bg-light-4">
          <recent-orders-list v-if="block.fields.title.toLowerCase() === 'recent_orders' && !isNewClient"
            :recent-orders="recentOrders" />

          <recent-uploads-list v-else-if="block.fields.title.toLowerCase() === 'recent_uploads' && !isNewClient"
            :drawing-configurations-sorted="drawingConfigurationsSorted" />

          <contentful-block v-else :block="block.fields" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>

import _orderBy from 'lodash/orderBy';
import _reject from 'lodash/reject';
import _filter from 'lodash/filter';
import OrdersLoader from 'src/components/loader/OrdersLoader';
import LoadingWidgetFullWidth from 'src/components/tools/LoadingWidgetFullWidth';
import RecentOrdersList from 'src/components/dashboard/RecentOrdersList';
import RecentUploadsList from 'src/components/dashboard/RecentUploadsList';
import ContentfulBlock from 'src/components/dashboard/ContentfulBlock';
import { createClient } from 'contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

export default {
  components: {
    OrdersLoader,
    LoadingWidgetFullWidth,
    RecentOrdersList,
    RecentUploadsList,
    ContentfulBlock,
  },
  orderId: {
    type: Number,
    required: true,
  },
  data() {
    return {
      body: { },
      bodyLoaded: false,
    };
  },
  computed: {
    isNewClient() {
      return this.noneDraftOrders.length === 0;
    },
    recentOrders() {
      let noneDraftSubmittedOrders = _reject(this.$store.state.orders,
        (o) => ['DRAFT', 'SUBMITTED', 'EXPIRED'].includes(o.status));
      noneDraftSubmittedOrders = _orderBy(noneDraftSubmittedOrders, [(o) => o.acceptedAt], ['desc']);
      return this.submittedOrders.concat(noneDraftSubmittedOrders).splice(0, 6);
    },
    noneDraftOrders() {
      const nonDraftOrders = _reject(this.$store.state.orders, ['status', 'DRAFT']);
      return _orderBy(nonDraftOrders, [(o) => o.updatedAtTimestamp], ['desc']);
    },
    submittedOrders() {
      const submittedOrders = _filter(this.noneDraftOrders, ['status', 'SUBMITTED']);
      return _orderBy(submittedOrders, [(o) => o.updatedAtTimestamp], ['desc']);
    },
    drawingConfigurationsSorted() {
      return _orderBy(this.$store.state.dc.drawingConfiguration, ['id'], ['desc'])
        .map((item) => parseInt(item.id, 10));
    },
    drawingConfigurations() {
      return this.drawingConfigurationsSorted.map((item) => this.$store.state.dc.drawingConfiguration[item]);
    },
    contentfulAccessKey() {
      if (this.isAdmin) {
        return process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN_PREVIEW;
      }
      return process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    orderId() {
      const currentId = parseInt(this.$route.params.orderId, 10);
      if (!currentId) {
        return parseInt(this.$store.state.settings.order_id, 10);
      }
      return currentId;
    },
    hasCorrectOrder() {
      if (!this.$store.getters.loaded(['cart'])) {
        return true;
      }
      return this.$store.state.cart.orderId === this.orderId;
    },
    ordersLoaded() {
      return this.$store.getters.loaded(['orders']);
    },
    ready() {
      return this.ordersLoaded && this.bodyLoaded;
    },
  },
  watch: {
    async $route() {
      if (this.ordersLoaded) {
        await this.getDashboardBlock();
      }
    },
    async ordersLoaded(newValue) {
      if (newValue) {
        await this.getDashboardBlock();
      }
    },
  },
  async mounted() {
    this.$eventBus.$off('uploadFinishedNotify');
    if (this.ordersLoaded) {
      await this.getDashboardBlock();
    }
  },
  methods: {
    getTotal(order) {
      return this.$config.formatPrice(order.orderTable.costItems.total, true, true);
    },
    priorityNameById(priorityId) {
      if (priorityId in this.$store.state.cart.priorities) {
        return this.$store.state.cart.priorities[priorityId].translation[this.$i18n.locale].name;
      }
      return this.$i18n.t('orderhistory.unknown');
    },
    async getDashboardBlock() {
      let client = createClient({
        space: process.env.VUE_APP_CONTENTFUL_SPACE_ID,
        accessToken: this.contentfulAccessKey,
      });

      if (this.isAdmin) {
        client = createClient({
          space: process.env.VUE_APP_CONTENTFUL_SPACE_ID,
          accessToken: this.contentfulAccessKey,
          host: 'preview.contentful.com',
        });
      }

      await client
        .getEntries({
          content_type: 'dashboardBlock',
          include: 2,
          locale: `${this.$i18n.locale}`,
          'fields.newUser': this.isNewClient,
          order: 'fields.order',
        })
        .then((entry) => {
          if (entry.items.length === 0) {
            this.$router.push('/404');
            return;
          }
          this.body = entry;
          this.bodyLoaded = true;
        });
      // .catch(console.error);
    },
    htmlString(text) {
      return documentToHtmlString(text);
    },
  },
};
</script>

<style lang="scss">
.dashboard-view {
  h2 {
    font-size: 1.2rem;
  }
  display: flex;
  flex-direction: row;
  gap: 1rem;
  .block {
    width: calc(50% - 1rem);

    @media(max-width: 1068px) {
      width: 100%;
    }
  }
}

</style>
