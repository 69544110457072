<template>
  <div class="cart-reference-widget mt-2">
    <form-async-widget-input
      class="px-2"
      :value="phoneNumber"
      v-bind="form"
      :show-label-when-empty="true"
      :max-length="50"
      @update="updateValue"/>
  </div>
</template>

<script>
import FormAsyncWidgetInput from 'src/components/form-async/FormAsyncWidgetInput';

export default {
  components: {
    FormAsyncWidgetInput,
  },
  props: {
    order_id: {
      type: Number,
      required: true,
    },
    use_label: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    cart() {
      return this.$store.state.cart;
    },
    phoneNumber() {
      const phoneNumber = this.cart.address.phone_number;
      if (phoneNumber) {
        return phoneNumber;
      }
      return '';
    },
    form() {
      return {
        label: this.use_label === true ? this.$i18n.t('order_review.address_form.phone_number') : '',
        placeholder: this.$i18n.t('order_review.address_form.phone_number_hint'),
        help: '',
        error: this.hasPhoneNumberError ? this.$i18n.t('order_review.address_form.being_able_to_reach_you_by_phone') : '',
        required: true,
      };
    },
    hasPhoneNumberError() {
      return this.cart.phoneNumberError;
    },
    hasPhoneNumberWarning() {
      return this.cart.phoneNumberWarning;
    },
  },
  methods: {
    updateValue({ promise, value }) {
      this.$store.dispatch(
        'updateCartField',
        { orderId: this.order_id, field: 'phone_number', value },
      ).then(() => {
        this.checkIfPhoneNumberWarning();
        this.checkIfPhoneNumberError();
        promise.resolve();
      });
    },
    checkIfPhoneNumberError() {
      if (!('phone_number' in this.cart.address)) {
        this.cart.phoneNumberError = true;
      } else if (this.phoneNumber.length < 5) {
        this.cart.phoneNumberError = true;
      } else {
        this.cart.phoneNumberError = false;
      }
    },
    checkIfPhoneNumberWarning() {
      if (!('phone_number' in this.cart.address)) {
        this.cart.phoneNumberWarning = true;
      } else if (this.phoneNumber.length < 5) {
        this.cart.phoneNumberWarning = true;
      } else {
        this.cart.phoneNumberWarning = false;
      }
    },
  },
  mounted() {
    this.checkIfPhoneNumberWarning();
  },
};
</script>

<style lang="scss">
</style>
