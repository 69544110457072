<!-- NB: this is the universal page code with the page-slug hardcoded tot 'app-frontpage' (line 109) -->
<template>
  <div class="universal-contentful-page container-fluid">
    <loading-widget-full-width v-if="!bodyLoaded" :loading="true" />
    <div v-show="bodyLoaded" class="main-container" ref="mainContainer" />
  </div>
</template>

<script>

import Vue from 'vue';
import { createClient } from 'contentful';

// eslint-disable-next-line no-unused-vars
import Jumbotron from '@/components/contentful/Jumbotron';
// eslint-disable-next-line no-unused-vars
import HeaderWithThreeColumns from '@/components/contentful/HeaderWithThreeColumns';
// eslint-disable-next-line no-unused-vars
import TextAndImage from '@/components/contentful/TextAndImage';
// eslint-disable-next-line no-unused-vars
import LogoBar from '@/components/contentful/LogoBar';
// eslint-disable-next-line no-unused-vars
import MaterialsGrid from '@/components/contentful/MaterialsGrid';
// eslint-disable-next-line no-unused-vars
import BlogsBlock from '@/components/contentful/BlogsBlock';
// eslint-disable-next-line no-unused-vars
import TextWithGallery from '@/components/contentful/TextWithGallery';
// eslint-disable-next-line no-unused-vars
import ClientFeedback from '@/components/contentful/ClientFeedback';
import LoadingWidgetFullWidth from 'src/components/tools/LoadingWidgetFullWidth';

export default {
  components: {
    LoadingWidgetFullWidth,
  },
  data() {
    return {
      body: { },
      bodyLoaded: false,
      requested: false,
      blockTypes: {},
      blockInstances: [],
      // pageLoaded: false,
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    contentfulAccessKey() {
      if (this.isAdmin) {
        return process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN_PREVIEW;
      }
      return process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN;
    },
    metaTitle() {
      if (this.bodyLoaded && this.body.fields.metaTitle) {
        return this.body.fields.metaTitle;
      }
      return 'Snijlab';
    },
    metaDescription() {
      if (this.bodyLoaded && this.body.fields.metaDescription) {
        return this.body.fields.metaDescription;
      }
      return this.$i18n.t('meta_description');
    },
  },
  metaInfo() {
    return {
      title: `${this.metaTitle} - Snijlab`,
      meta: [
        { vmid: 'description', name: 'description', content: this.metaDescription },
      ],
    };
  },
  watch: {
    async $route() {
      this.bodyLoaded = false;
      await this.getUniversalPage();
      await this.buildPage();
    },
  },
  async created() {
    await this.getUniversalPage();
    await this.buildPage();
    // this.pageLoaded = true;
  },
  methods: {
    async getUniversalPage() {
      let client = createClient({
        space: process.env.VUE_APP_CONTENTFUL_SPACE_ID,
        accessToken: this.contentfulAccessKey,
      });
      if (this.isAdmin) {
        client = createClient({
          space: process.env.VUE_APP_CONTENTFUL_SPACE_ID,
          accessToken: this.contentfulAccessKey,
          host: 'preview.contentful.com',
        });
      }

      const entryInfo = {
        content_type: 'universalPage',
        limit: 1,
        include: 2,
        locale: `${this.$i18n.locale}`,
        'fields.slug': 'app-frontpage',
      };
      await client
        .getEntries(entryInfo)
        .then(async (entry) => {
          if (entry.items.length !== 0) {
            [this.body] = entry.items;
            this.bodyLoaded = true;
          } else if (entry.items.length === 0) {
            const otherLang = this.$i18n.locale === 'nl' ? 'en' : 'nl';
            delete entryInfo['fields.slug'];
            entryInfo[`fields.slug.${otherLang}`] = this.$route.params.route;

            await client
              .getEntries(entryInfo)
              .then((entryOtherLang) => {
                if (entryOtherLang.items.length === 0) {
                  this.$router.push(`/${this.$i18n.locale}/404`);
                  return;
                }
                this.$router.push(`/${this.$i18n.locale}/page/${entryOtherLang.items[0].fields.slug}`);
              });
          }
        });
      // .catch(console.error);
    },
    buildPage() {
      this.blockInstances.forEach((instance) => {
        instance.$destroy();
      });
      this.blockInstances = [];

      if (!this.bodyLoaded) {
        return;
      }

      this.body.fields.universalBlocks.forEach((block, index) => {
        if (!this.isAdmin && !block.sys.contentType) {
          return;
        }

        const propsToPass = {
          router: this.$router,
          propsData: {
            data: block.fields,
            isLastElement: index === this.body.fields.universalBlocks.length - 1,
            removeBeforeDestroy: true,
            h1Header: index === 0,
          },
        };

        let instance = null;
        switch (block.sys.contentType.sys.id) {
          case 'headerBlock':
            if (!this.blockTypes.HeaderBlockClass) {
              this.blockTypes.HeaderBlockClass = Vue.extend(Jumbotron);
            }
            instance = new this.blockTypes.HeaderBlockClass(propsToPass);
            break;

          case 'universalBlockTextAndImage':
            if (!this.blockTypes.TextAndImageClass) {
              this.blockTypes.TextAndImageClass = Vue.extend(TextAndImage);
            }
            instance = new this.blockTypes.TextAndImageClass(propsToPass);
            break;

          case 'universalBlokLogos':
            if (!this.blockTypes.LogoBarClass) {
              this.blockTypes.LogoBarClass = Vue.extend(LogoBar);
            }
            instance = new this.blockTypes.LogoBarClass(propsToPass);
            break;

          case 'universalBlokReviews':
            if (!this.blockTypes.ClientFeedbackClass) {
              this.blockTypes.ClientFeedbackClass = Vue.extend(ClientFeedback);
            }
            instance = new this.blockTypes.ClientFeedbackClass(propsToPass);
            break;

          case 'materialGroup':
            if (!this.blockTypes.MaterialsGridClass) {
              this.blockTypes.MaterialsGridClass = Vue.extend(MaterialsGrid);
            }
            instance = new this.blockTypes.MaterialsGridClass(propsToPass);
            break;

          case 'universalBlockIntroduction':
            if (!this.blockTypes.IntroductionClass) {
              this.blockTypes.IntroductionClass = Vue.extend(HeaderWithThreeColumns);
            }
            instance = new this.blockTypes.IntroductionClass(propsToPass);
            break;

          case 'textAndGallery':
            if (!this.blockTypes.TextWithGalleryClass) {
              this.blockTypes.TextWithGalleryClass = Vue.extend(TextWithGallery);
            }
            instance = new this.blockTypes.TextWithGalleryClass(propsToPass);
            break;

          case 'universalBlockBlogsBlock':
            if (!this.blockTypes.BlogsBlockClass) {
              this.blockTypes.BlogsBlockClass = Vue.extend(BlogsBlock);
            }
            propsToPass.i18n = this.$i18n;
            instance = new this.blockTypes.BlogsBlockClass(propsToPass);
            break;

          default:
            // console.log('unknown block type:', block.sys.contentType.sys.id);
            break;
        }
        if (instance) {
          this.blockInstances.push(instance);
          instance.$mount();
          this.$refs.mainContainer.appendChild(instance.$el);
        }
      });
    },
  },
};
</script>

<style lang="scss">

</style>
