<template>
  <form method="post" class="form-dark signin-form" name="signin" @submit.prevent="login">
    <div class="input-row-full">
      <h2>{{$t('loginpanel.login_title')}}</h2>
      <p>{{$t('loginpanel.login_intro')}}</p>
    </div>

    <form-widget-input
      v-model.trim="$v.email.$model"
      :error="error('email')"
      :placeholder="$t('loginpanel.placeholder_email')"
      autocomplete="email username"
      size="sm"
      row-class="input-row-full" />

    <form-widget-input
      v-model.trim="$v.password.$model"
      :error="error('password')"
      :placeholder="$t('loginpanel.placeholder_password')"
      autocomplete="current-password"
      size="sm"
      type="password"
      row-class="input-row-full" />

    <div class="input-row-full">
      <div v-show="loginError" class="alert alert-danger p-1 m-0">{{$t('loginpanel.login_error')}}</div>
      <button class="btn btn-light btn-block p-0 login-btn" type="submit">
        <loading-widget :loading="loading" :radius="10" />
        <div v-if="!loading" class="login-text">
          {{$t('loginpanel.login_button')}}
        </div>
      </button>

      <div id="google-button-placeholder" class="w-100 d-flex justify-content-center mt-2" />

      <div class="text-center pt-2">
        <a href="#"
          @click.prevent="$emit('show-forgot-password')">
          {{$t('loginpanel.forgot_password')}}</a>
      </div>
      <hr>
    </div>
    <div class="row-stick-bottom" />
  </form>
</template>
<script>
import axios from 'axios';
import FormWidgetInput from 'src/components/form/FormWidgetInput';
import { validationMixin } from 'vuelidate';
import {
  required,
  // minLength,
  // , email,
} from 'vuelidate/lib/validators';
import LoadingWidget from 'src/components/tools/LoadingWidget';

import VueScript2 from 'vue-script2';
// import VueJwtDecode from 'vue-jwt-decode';

export default {
  mixins: [validationMixin],
  name: 'SignInForm',
  components: {
    LoadingWidget,
    FormWidgetInput,
  },
  data() {
    return {
      loading: false,
      email: null,
      password: null,
      serverErrors: {
        email: null,
        password: null,
      },
      loginError: false,
    };
  },
  computed: {
    errors() {
      return {
        // password: this.$i18n.t('loginpanel.error_enter_password'),
        // email: this.$i18n.t('loginpanel.error_enter_valid_email'),
      };
    },
    googleClientId() {
      // return this.$config.googleClientId;
      return '177768808005-unfhhppt7h3kr7clcrknvgjv72vot9vp.apps.googleusercontent.com';
    },
  },
  validations() {
    return {
      email: {
        required,
        // email,
      },
      password: {
        required,
        // minLength: minLength(4),
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.setupGoogleLogin();
    });
  },
  methods: {
    setupGoogleLogin() {
      VueScript2.load('https://accounts.google.com/gsi/client').then(() => {
        window.google.accounts.id.initialize({
          client_id: this.googleClientId,
          callback: this.handleGoogleCredentialResponse,
          cancel_on_tap_outside: true,
          ux_mode: 'popup',
        });

        window.google.accounts.id.renderButton(
          document.getElementById('google-button-placeholder'),
          {
            theme: 'outline',
            size: 'large',
            width: 300,
            logo_alignment: 'center',
            locale: this.$i18n.locale,
          },
        );
      });
    },
    error(name) {
      if (this.$v[name].$error) {
        return this.errors[name];
      }
      if (name in this.serverErrors && this.serverErrors[name] != null && this.serverErrors[name].length > 0) {
        return this.serverErrors[name];
      }
      return '';
    },
    login() {
      const formData = new FormData();
      formData.set('login[username]', this.email);
      formData.set('login[password]', this.password);
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      axios.post(`${this.$config.apiUrl}/user/login`, formData, { withCredentials: true })
        .then((response) => {
          this.valid = true;
          this.$eventBus.$emit('newLogin', response.data.result);
        })
        .catch(() => {
          this.loginError = true;
          // this.$eventBus.$emit('snlb.error', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    googleLogin(jwt) {
      const formData = new FormData();
      formData.set('jwt', jwt);
      this.loading = true;
      axios.post(`${this.$config.apiUrl}/user/googleLogin`, formData, { withCredentials: true })
        .then((response) => {
          this.valid = true;
          this.$eventBus.$emit('newLogin', response.data.result);
        })
        .catch(() => {
          this.loginError = true;
          // this.$eventBus.$emit('snlb.error', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handleGoogleCredentialResponse(response) {
      this.googleLogin(response.credential);
    },
  },
};
</script>
<style lang="scss">
  .signin-form {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;

    .login-btn {
      margin: auto;
      width: 300px;
      max-width: 300px;
      max-height: 50px;

      .login-text {
        padding: 13px;
      }
    }

.invalid-feedback {
      background-color: #fff;
      font-weight: bold;
      padding: 4px;
    }
  }
</style>
