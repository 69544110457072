<template>
  <div class="uploaded-file-item">
    <div class="uploaded-file-check-icon"
      :class="{
        'bg-analyse': isAnalyzing,
        'bg-checked': isProcessed && !hasError,
        'bg-error': (isProcessed && hasError) || hasFatalError
      }" >

      <determinate-progress
        :radius="18"
        v-if="file.progress < 100 && file.status === 'TODO'"
        :percentage="file.progress/100" />

      <indeterminate-progress
        :radius="18"
        v-else-if="isAnalyzing || !isProcessed" />

      <div class="analyse-check" v-else-if="isProcessed">
        <i v-if="hasError" class="icon-analyse-check material-icons">close</i>
        <i v-else class="icon-analyse-check material-icons">check</i>
      </div>
    </div>

    <div class="uploaded-file-description">
      <div class="uploaded-file-name">
        {{ file.data.name }}
      </div>

      <div class="uploaded-file-analyse">
        <template v-if="isUploading">
          {{$t('file_uploader.uploading_drawing')}} {{file.progress}}%
        </template>
        <template v-else-if="isAnalyzing || !isProcessed">
          {{$t('file_uploader.analysing_drawing')}}
        </template>

        <p class="error" v-if="isProcessed && (hasError || hasFatalError)">
          {{$t('file_uploader.drawing_not_accepted')}}<br />
          <template v-if="file.error">
            <ul class="ul-snijlab">
              <li class="pointer" @click="showHelp(file.error)">
                {{ getError(file.error) }}&nbsp;<i class="material-icons info-icon">info</i>
              </li>
            </ul>

            <a href="#" @click.prevent="$emit('hide', index)"><i class="material-icons">close</i>
            {{$t('file_uploader.hide')}}</a>
          </template>

          <template v-else>
            <div v-if="file.feedback.length > 0">
              <ul class="ul-snijlab">
                <template v-for="(item, key) in file.feedback">
                  <li v-if="item.type === 'ERROR'"
                    :key="key"
                    :id="'feedback-'+item.id">
                    {{ getError(item.message) }}
                    <i class="material-icons info-icon pointer" @click="showHelp(item.message)">info</i>
                  </li>
                </template>
              </ul>
            </div>
            <a href="#" @click.prevent="$emit('hide', index)"><i class="material-icons">close</i>
            {{$t('file_uploader.hide')}}</a>
          </template>
        </p>

        <p v-else-if="isProcessed">
          {{$t('file_uploader.added_to_cart')}}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import DeterminateProgress from 'src/components/tools/DeterminateProgress';
import IndeterminateProgress from 'src/components/tools/IndeterminateProgress';

export default {
  components: {
    DeterminateProgress,
    IndeterminateProgress,
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  watch: {
    isProcessed(value) {
      if (value && !this.hasError && !this.hasFatalError) {
        this.$eventBus.$emit('odoo.event', { event: 'upload_success', msg: `File upload success, ${this.file.data.name}` });
        this.$eventBus.$emit('gtm.uploadSucceeded');
      } else if (value && (this.hasError || this.hasFatalError)) {
        this.$eventBus.$emit('odoo.event', { event: 'upload_error', msg: `File upload error, ${this.file.data.name}` });
        this.$eventBus.$emit('gtm.uploadFailed');
      }
    },
  },
  computed: {
    isCart() {
      return this.$route.name.includes('order');
    },
    hasError() {
      return !this.dc || (this.dc && this.dc.feedback.some((f) => f.type === 'ERROR'));
    },
    hasWarning() {
      return this.dc && this.dc.feedback.some((f) => f.type === 'WARNING');
    },
    dc() {
      if (this.file.dcId in this.$store.state.dc.drawingConfiguration) {
        return this.$store.state.dc.drawingConfiguration[this.file.dcId];
      }
      return null;
    },
    feedback() {
      if (this.dc) {
        return this.dc.feedback;
      }
      return [];
    },
    isUploading() {
      return this.file.status === 'TODO' && this.file.progress < 100;
    },
    isDone() {
      return this.file.status === 'UPLOAD_FINISHED' || this.file.status === 'PROCESSED';
    },
    isProcessed() {
      return this.file.status === 'PROCESSED' || this.hasFatalError;
    },
    isAnalyzing() {
      return this.file.progress === 100 && this.file.status === 'TODO' && this.file.status !== 'ERROR';
    },
    hasFatalError() {
      return this.file.status === 'ERROR';
    },
    helpText() {
      return this.$store.state.errors;
    },
    storeData() {
      return this.$store.state.dc.drawingConfiguration[this.id];
    },
    errors() {
      return this.$store.state.errors;
    },
  },
  methods: {
    getError(message) {
      if (message.toLowerCase() in this.errors) {
        return this.errors[message.toLowerCase()].editorName.replace('drawingSize', this.file.drawingSize);
      }
      return message;
    },
    showHelp(name) {
      this.$eventBus.$emit('snlb.showHelp', name);
    },
  },
};
</script>
<style lang="scss">
  @import '../../assets/scss/_variables.scss';

  .uploaded-file-check-icon {

    &.bg-analyse {
      background-color: $color-info;
      color: $color-white;
    }

    &.bg-checked {
      background-color: $color-success;
      color: $color-white;
    }

    &.bg-warning {
      background-color: $color-warning;
      color: $color-white;
    }

    &.bg-error {
      background-color: $color-danger;
      color: $color-white;
    }
  }

  .uploaded-file-analyse {
    .ul-snijlab li {
      padding-top: 0.15rem;
      padding-bottom: 0.15rem;
    }
  }

  .analyse-check {
    display: table;
    width: 100%;
    height: 100%;
  }

  .analyse-questionmark {
    font-family: arial;
  }

  .icon-analyse-check.material-icons {
    font-size: 1.5rem;
    line-height: 1.5rem;
    padding: 0;
    display: table-cell;
    vertical-align: middle;
  }

  .uploaded-file-item {
    display: block;
    position: relative;
  }

  .uploaded-file-check-icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $color-info;
    text-align: center;
    vertical-align: top;
    margin-right: 1rem;
  }

  .uploaded-file-description {
    display: inline-block;
    width: calc(100% - 3rem);
    vertical-align: top;
  }

  .uploaded-file-name {
    font-weight: bolder;
  }

  .uploaded-file-analyse {
    color: $color-text-gray;

    &.warning {
      color: $color-warning;
    }

    &.error {
      color: $color-warning;
    }
  }

</style>
