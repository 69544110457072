<template>
  <div class="cart-main-block material-select-drilldown">

    <div v-show="showMaterialList"
      class="card-title bg-light p-0 pl-2 mb-0">{{$t('drawing_configuration.material_options')}}</div>

    <div v-show="showSelectedMaterial"
      class="card-title bg-light p-0 pl-2 mb-0">{{$t('drawing_configuration.selected_material')}}</div>

    <div class="card-body border-top p-2 pb-3 d-flex flex-row">

      <div v-if="showMaterialList" class="product-info-no-material-selected">
        <label class="material-category-family mt-1 w-100">
          {{$t('editor.filter_by_category')}}:
          <b-form-select
            class="pl-2"
            :value="selectedCategory"
            :options="categoryDropdownOptions"
            v-model="selectedCategory"
            @change="selectedFamily='0_all'" />
        </label>

        <label class="material-category-family mt-1 w-100"
          :class="{'text-muted' : selectedCategory === '0_all'}">
          {{$t('editor.filter_by_family')}}:
          <div v-show="selectedCategory === '0_all'" class="text-muted pl-2">{{ $t('editor.select_a_category_first') }}</div>
          <b-form-select
            class="pl-2"
            v-show="selectedCategory !== '0_all'"
            :value="selectedFamily"
            :options="familyDropdownOptions"
            v-model="selectedFamily" />
        </label>

        <label class="material-category-family mt-1 w-100">
          {{$t('editor.filter_by_text')}}:
          <b-form-input v-model="searchString"
            :placeholder="$t('editor.code_name_color')"
            class="pl-2 pt-0"></b-form-input>
        </label>

        <template v-if="!storeData || (storeData && !storeData.locked)">
          <label class="mt-3">{{$t('editor.select_your_material')}}
            <i class="material-icons compact info-icon"
              @click.prevent.stop="showHelp('editor.material_selection')">info</i>
            <loading-widget :loading="materialLoading"/>
          </label>

          <div class="border p-2" style="overflow-y: scroll; min-height: 100px; max-height: 300px;">
            <div v-for="(material, index) in materials" :key="material.id">
              <div v-if="index === 0 || material.family_name !== materials[index-1].family_name"
                class="material-list-family-name pb-1 pt-2" style="font-weight: bold;">
                {{material.family_name}}
              </div>
              <div class="material-list-item pl-2 py-1 border-bottom"
                :class="{'disabled text-muted bg-light-2' : material.isDisabled}"
                @click="!material.isDisabled ? updateMaterial(material.id) : null">
                <img class="mr-1" style="width: 1rem;" :src="getImage(material, 'small')" />
                <span class="text-muted">{{material.snijlab_code}}</span>
                  {{material.pretty_name}}
                <div v-if="material.isDisabled" class="text-danger pl-3">
                  {{$t('drawing_configuration.maximum_size')}}: {{ material.size }}</div>
              </div>
            </div>
            <div v-if="materials.length === 0">{{$t('drawing_configuration.no_materials_with_these_filters')}}</div>
          </div>

          <!-- <div class="mt-2" v-if="!storeData.locked && !hasMaterial"> -->
          <div class="mt-2">
            <div v-if="hasMaterial && !batch">
            {{$t('drawing_configuration.previous_selection')}}
            <img :src="getImage(material, 'large')" style="height: 1rem;" />
              <b-button variant="link" size="xs" class="px-1" @click="hideMaterialSelector()">
                {{material.family_name}} {{material.name_specification}} {{material.thickness}} mm</b-button><br />
            </div>
          </div>
        </template>
      </div>

      <div v-else-if="showSelectedMaterial"
        class="product-info-material-selected d-flex flex-column flex-sm-row align-items-stretch" >
        <div class="selected-material-overview mr-1 d-flex flex-column justify-content-between pt-2">
          <div class="material-name-and-image d-flex flex-row">
            <img class="material-image border"
              :class="{'material-image-unavailable': !material.is_published}"
              :src="getImage(previewMaterial, 'small')" />
            <div class="pl-2"
              :class="{'text-muted': !material.is_published}">
              <strong>{{previewMaterial.family_name}} {{previewMaterial.surface}}</strong><br>
              <span v-if="previewMaterial.name_specification">{{previewMaterial.name_specification}}<br></span>
              {{parseFloat(previewMaterial.thickness).toFixed(1)}} mm<br>
              <small>{{previewMaterial.snijlab_code}}</small>
            </div>
          </div>
          <div class="switch-material-btn my-2 my-sm-0">
            <b-button v-if="storeData && !storeData.locked" variant="light" size="xs" class="px-1"
              @click="showMaterialSelector()">
              <i class="material-icons compact">undo</i>{{ $t('editor.select_other_material') }}</b-button>
          </div>
        </div>

        <div v-if="!batch" class="material-option-dropdowns-container">
          <drawing-product-list v-if="hasMaterial"
            :loading="productLoading"
            :dc-id="dcId"
            :material-id="parseInt(previewMaterial.id, 10)"
            :locked="storeData.locked || loading"
            :show-vat="showVat"
            :show-nested-price="true"
            @change="updateDrawingProducts" />
        </div>

        <template v-else>
          <drawing-product-batch-list
            v-if="material"
            :material-id="parseInt(previewMaterial.id, 10)"
            :locked="loading"
            v-model="productIds"
            @change="testProducts" />
        </template>
      </div>
    </div>

    <div v-if="!batch && storeData.locked && !hasUndeliverableMaterial"
      class="mt-1 mb-0 alert bg-light-1 p-2"
      :class="{'bg-light': !hasUndeliverableMaterial, 'bg-warning': !hasUndeliverableMaterial}">
      <div v-if="!hasUndeliverableMaterial">
        {{$t('drawing_configuration.this_design_has_been_ordered_before')}}
      </div>
      <div v-else>
        {{$t('drawing_configuration.this_design_has_been_ordered_before_but_the_material_is_not_available')}}
      </div>

      <div class="copy-button">
        <loading-button
          :block="false"
          :loading="copyLoading"
          variant="light"
          size="xs"
          @click="duplicate"><i class="material-icons compact">content_copy</i> {{$t('drawing_configuration.copy')}}
        </loading-button>
      </div>
    </div>

  </div>
</template>

<script>
// import sortBy from 'lodash/sortBy';
// import VueMultiselect from 'vue-multiselect';
import _get from 'lodash/get';
import DrawingProductList from 'src/components/drawingConfiguration/DrawingProductList';
import LoadingWidget from 'src/components/tools/LoadingWidget';
import sortBy from 'lodash/sortBy';
import DrawingProductBatchList from 'src/components/drawingConfiguration/DrawingProductBatchList';
import LoadingButton from 'src/components/tools/LoadingButton';

export default {
  components: {
    DrawingProductList,
    DrawingProductBatchList,
    LoadingWidget,
    LoadingButton,
  },
  props: {
    dcId: {
      type: Number,
      required: false,
    },
    hasMaterial: {
      type: Boolean,
      default: false,
    },
    drawingWidth: {
      type: Number,
      default: 0,
    },
    drawingHeight: {
      type: Number,
      default: 0,
    },
    productLoading: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    copyLoading: {
      type: Boolean,
    },
    material: {
      type: Object,
      required: false,
    },
    hasUndeliverableMaterial: {
      type: Boolean,
    },
    delta: {
      type: Number,
      default: 1.0,
    },
    showVat: {
      type: Boolean,
    },
    batch: {
      type: Boolean,
      default: false,
    },
    productIds: {
      type: Array,
    },
  },
  data() {
    return {
      selectedCategory: '0_all',
      selectedFamily: '0_all',
      searchString: '',
      materialLoading: false,
      showingMaterialList: false,
      batchMaterial: null,
    };
  },
  computed: {
    showMaterialList() {
      return (this.batch && !this.batchMaterial)
        || (!this.batch && (!this.hasMaterial || this.showingMaterialList));
    },
    showSelectedMaterial() {
      return (this.batch && this.batchMaterial)
        || (!this.batch && this.hasMaterial && !this.showingMaterialList);
    },
    categoryDropdownOptions() {
      return [
        {
          value: '0_all', text: this.$i18n.t('materials.categories.all_categories'), disabled: false,
        },
        ...this.$store.state.materialCategories.map((cat) => ({
          value: cat,
          text: this.$i18n.t(`materials.categories.${cat.split('_').slice(1).join('_')}`),
        })).sort((a, b) => a.text > b.text),
      ];
    },
    familyDropdownOptions() {
      const familiesInCategory = [];
      Object.values(this.$store.state.materials)
        .filter((material) => material.tags.indexOf(`_cat_${this.selectedCategory}`) !== -1)
        .forEach((material) => {
          if (familiesInCategory.filter((family) => family.value === material.family_id).length === 0) {
            familiesInCategory.push({
              value: material.family_id,
              text: material.family_name,
            });
          }
        });

      if (this.selectedCategory === '8_self_supplied') {
        return [
          {
            value: '0_all',
            text: this.$i18n.t('materials.categories.all_self_supplied_materials'),
          }];
      }
      return [
        {
          value: '0_all',
          // eslint-disable-next-line max-len
          text: `${this.$i18n.t('materials.categories.all_sentence_start')} ${this.$i18n.t(`materials.categories.${this.selectedCategory.split('_').slice(1).join('_')}`)
            .toLowerCase()}${this.$i18n.t('materials.categories.families')}`,
        },
        ...familiesInCategory.sort((a, b) => a.text > b.text)];
    },
    storeData() {
      if (this.dcId) {
        return this.$store.state.dc.drawingConfiguration[this.dcId];
      }
      return null;
    },
    materials() {
      const materials = sortBy(this.$store.state.materials, [(mat) => mat.name.toLowerCase()], ['asc']);
      return materials
        .filter((material) => {
          if (!material.is_published) { return false; }
          if (this.searchStringArray.length === 0 && this.selectedCategory === '' && this.selectedFamily === '') { return true; }

          if (this.selectedCategory !== '0_all') {
            if (material.tags.indexOf(`_cat_${this.selectedCategory}`) === -1) {
              return false;
            }
          }

          if (this.selectedFamily !== '0_all') {
            if (material.family_id !== this.selectedFamily) {
              return false;
            }
          }
          return this.searchStringArray.every((s) => material.pretty_name.toLowerCase().includes(s)
              || material.aka.join(' ').toLowerCase().includes(s)
              || material.snijlab_code.toLowerCase().includes(s));
        })
        .map((material) => ({
          ...material,
          isDisabled: (!this.batch) && !(((Number.parseFloat(material.max_size[0]) + this.delta > this.drawingWidth)
            && (Number.parseFloat(material.max_size[1]) + this.delta > this.drawingHeight))
            || ((Number.parseFloat(material.max_size[1]) + this.delta > this.drawingWidth)
            && (Number.parseFloat(material.max_size[0]) + this.delta > this.drawingHeight))),
          size: `${material.max_size[0]} mm x ${material.max_size[1]} mm`,
        }));
    },
    searchStringArray() {
      let searchArray = [];
      const val = this.searchString.toLowerCase().replace(/,/g, '.').replace(/([0-9]+)\s*mm/g, '$1');
      searchArray = val.split(/\s+/);
      searchArray.forEach((el, i) => {
        if (el === 'mm') {
          searchArray[i] = '';
        // } else if (el.charAt(0) !== '0' && parseFloat(el)) {
        //   searchArray.push(parseFloat(el).toFixed(1));
        }
      });
      return searchArray.filter((el) => el !== '');
    },
    previewMaterial() {
      if (this.batch) {
        return this.batchMaterial;
      }
      return this.material;
    },
  },
  methods: {
    duplicate(value) {
      this.$emit('duplicate', value);
    },
    testProducts(value) {
      this.$emit('test-products', value);
    },
    showHelp(item) {
      this.$eventBus.$emit('snlb.showHelp', item);
    },
    updateMaterial(materialId) {
      // eslint-disable-next-line no-param-reassign
      materialId = parseInt(materialId, 10);
      if (!this.batch) {
        this.$emit('update-material', materialId);
      } else {
        [this.batchMaterial] = this.materials.filter((mat) => parseInt(mat.id, 10) === materialId);
        this.$emit('set-material-id-from-batch', parseInt(this.batchMaterial.id, 10));
        this.hideMaterialSelector();
      }
    },
    showMaterialSelector() {
      this.showingMaterialList = true;
      if (this.batch) {
        this.batchMaterial = null;
        this.$emit('set-material-id-from-batch', null);
      }
    },
    hideMaterialSelector() {
      this.showingMaterialList = false;
    },
    updateDrawingProducts(value) {
      this.$emit('update-drawing-products', value);
    },
    getImage(material, size) {
      const image = _get(material, `images[0].${size}`);
      if (image === undefined) {
        return null;
      }
      return `${this.$config.imageUrl}/${image}`;
    },
  },
};
</script>
<style lang="scss">
  .material-select-drilldown {

    .material-image.material-image-unavailable {
      opacity: 0.5;
    }

    .material-category-family {
      .text-muted {
        color: #b4b4b4 !important;
      }

      ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #b4b4b4 !important;
        opacity: 1; /* Firefox */
        font-weight: normal !important;
        font-size: 15px;
      }

      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #b4b4b4 !important;
      }

      ::-ms-input-placeholder { /* Microsoft Edge */
        color: #b4b4b4 !important;
      }
    }

    .product-info-material-selected {
      flex: 1;
    }
    .material-option-dropdowns-container {
      flex: 1;
    }
    .selected-material-overview {
      flex: 1;
      // width: 100%;
      // display: flex;
      // flex: 1;
      // display: grid;
      // grid-template-columns: auto;
      // grid-template-rows: auto auto;

      .switch-material-btn {
        // align-self: flex-end;
        // grid-row: 1;
        // grid-column: 1;
        // justify-self: end;
      }

      .material-name-and-image {
        // flex: 1;
        // grid-row: 2;
        // grid-column: 1;
        // min-height: 150px;

        img {
          width: 130px;
          height: 130px;
        }
      }
    }

// @media(max-width: 767px) {
//       .selected-material-overview {

//         .switch-material-btn {
//           grid-row: 1;
//           justify-self: start;
//         }

//         .material-name-and-image {
//           grid-row: 2;
//         }
//       }
//     }

    .material-list-family-name {
      cursor: default;
    }
    .material-list-item {
      &:hover {
        cursor: default;
      }
      &:hover:not(.disabled){
        cursor: pointer;
        font-weight: 400;
      }
      &.cursor-default {
        cursor: default;
      }
    }

    .hide-scrollbar{
      overflow-y: scroll;
      overflow-x: hidden;
    }

    .hide-scrollbar::-webkit-scrollbar{
      background-color: white;
      border-radius: 10px;
      width: 20px;
    }

    .hide-scrollbar::-webkit-scrollbar-thumb{
      background-color: red;
      border-radius: 10px;
      border: 5px solid white;
    }

    .hide-scrollbar::-webkit-scrollbar-thumb:vertical{
      height: 20px!important;
      width: 20px;
    }
  }
</style>
