<template>
  <div>

  </div>
</template>

<script>
import axios from 'axios';

export default {
  mounted() {
    // TODO [x] user_registered
    // TODO [x] upload_success
    // TODO [x] upload_error
    // TODO [x] order_updated
    // TODO [x] payment_started
    // TODO [x] payment_succeeded
    this.$eventBus.$on('odoo.event', this.send_odoo_event);
  },
  computed: {
    cartValue() {
      if (this.$store.state.cart.costTable.total) {
        return this.$store.state.cart.costTable.total.amount;
      }
      return -1;
    },
  },
  watch: {
    cartValue(newValue, oldValue) {
      if (newValue !== oldValue && oldValue !== -1) {
        this.$eventBus.$emit('odoo.event', { event: 'order_updated', msg: 'New cart value' });
      }
    },
  },
  methods: {
    send_odoo_event(eventInfo) {
      const odooData = new FormData();
      odooData.append('event', eventInfo.event);
      odooData.append('msg', eventInfo.msg);
      if (this.$store.state.cart.orderId) {
        odooData.append('order_id', this.$store.state.cart.orderId);
      } else {
        let currentId = parseInt(this.$route.params.orderId, 10);
        if (!currentId) {
          currentId = parseInt(this.$store.state.settings.order_id, 10);
        }
        odooData.append('order_id', currentId);
      }

      if (this.$store.state.cart.costTable.total) {
        odooData.append('cart_total', parseFloat(this.$store.state.cart.costTable.total.amount) / 100.0);
      } else {
        odooData.append('cart_total', 0.0);
      }
      odooData.append('user_id', parseInt(this.$store.state.user.id, 10));
      odooData.append('is_company', this.$store.state.profile.is_business ? 'true' : '');
      odooData.append('company_name', this.$store.state.profile.organisation);
      odooData.append('contact_name', `${this.$store.state.user.first_name} ${this.$store.state.user.last_name}`);
      odooData.append('email', this.$store.state.user.email_address);
      odooData.append('phone', this.$store.state.profile.phone_number);
      odooData.append('admin', this.$store.state.admin_user ? this.$store.state.admin_user.email_address : '');
      odooData.append('lang', this.$i18n.locale);

      axios.post(this.$config.odooUserEvent,
        odooData,
        { withCredentials: false });
    },
  },
};
</script>
