<template>
  <div>

  </div>
</template>

<script>
export default {
  mounted() {
    // this.$eventBus.$on('gtm.disable', this.disable);
    // this.$eventBus.$on('gtm.enable', this.enable);
    this.$eventBus.$on('gtm.login', this.login);
    this.$eventBus.$on('gtm.accountCreated', this.accountCreated);
    this.$eventBus.$on('gtm.openPriceMatrix', this.openPriceMatrix);
    this.$eventBus.$on('gtm.brochureRequested', this.brochureRequested);
    this.$eventBus.$on('gtm.laserCuttingGuideRequested', this.guideRequested);
    this.$eventBus.$on('gtm.sampleKitRequested', this.sampleKitRequested);
    this.$eventBus.$on('gtm.quoteRequested', this.quoteRequested);
    this.$eventBus.$on('gtm.volumeQuoteRequested', this.volumeQuoteRequested);

    // this.$eventBus.$on('gtm.sign_up', this.sign_up);
    // this.$eventBus.$on('gtm.add_to_cart', this.add_to_cart);
    // this.$eventBus.$on('gtm.remove_from_cart', this.remove_from_cart);
    this.$eventBus.$on('gtm.search', this.searchResult);
    this.$eventBus.$on('gtm.searchNoResult', this.searchNoResult);

    // this.$eventBus.$on('gtm.search_pageview', this.search_pageview);
    this.$eventBus.$on('gtm.openedCart', this.openedCart);
    this.$eventBus.$on('gtm.reviewedCart', this.reviewedCart);
    this.$eventBus.$on('gtm.startedPayment', this.startedPayment);
    this.$eventBus.$on('gtm.seenConfirmation', this.seenConfirmation);

    this.$eventBus.$on('gtm.uploadSucceeded', this.uploadSucceeded);
    this.$eventBus.$on('gtm.uploadFailed', this.uploadFailed);

    this.$eventBus.$on('gtm.templateDownloaded', this.templateDownloaded);
  },
  methods: {
    login() {
      this.$gtm.trackEvent({
        event: 'login',
        category: 'engagement',
      });
    },
    openPriceMatrix() {
      this.$gtm.trackEvent({
        event: 'open-price-matrix',
        category: 'engagement',
      });
    },
    openedCart(orderId) {
      this.$gtm.trackEvent({
        event: 'opened-cart',
        category: 'engagement',
        value: orderId,
        'custom-order-id': orderId,
      });
    },
    reviewedCart(data) {
      this.$gtm.trackEvent({
        event: 'reviewed-cart',
        category: 'engagement',
        value: data.orderId,
        'custom-order-id': data.orderId,
        'custom-total-price': data.totalPrice,
      });
    },
    startedPayment(data) {
      this.$gtm.trackEvent({
        event: 'started-payment',
        category: 'engagement',
        value: data.orderId,
        'custom-order-id': data.orderId,
        'custom-total-price': data.totalPrice,
      });
    },
    seenConfirmation(orderId) {
      this.$gtm.trackEvent({
        event: 'seen-confirmation',
        category: 'engagement',
        value: orderId,
      });
    },
    uploadSucceeded() {
      this.$gtm.trackEvent({
        event: 'upload-succeeded',
        category: 'engagement',
      });
    },
    uploadFailed() {
      this.$gtm.trackEvent({
        event: 'upload-failed',
        category: 'engagement',
      });
    },
    accountCreated(accountIsBusiness) {
      let label = 'not business';
      if (accountIsBusiness) {
        label = 'business';
      }
      this.$gtm.trackEvent({
        event: 'account-created',
        category: 'engagement',
        // value: account_is_business,
        'custom-is-business': label,
      });
    },
    // sign_up() {
    //   this.$gtm.trackEvent({
    //     event: 'account-created',
    //     category: 'engagement',
    //   });
    // },
    searchNoResult(searchTerm) {
      this.$gtm.trackEvent({
        category: 'engagement',
        label: searchTerm,
      });
    },
    searchResult(searchTerm) {
      this.$gtm.trackEvent({
        category: 'search',
        label: searchTerm,
      });
    },
    templateDownloaded(data) {
      this.$gtm.trackEvent({
        event: 'template-downloaded',
        category: 'engagement-content',
        // value: data.orderId,
        'custom-template-id': data.templateId,
        'custom-template-name': data.templateName,
      });
    },
    brochureRequested() {
      this.$gtm.trackEvent({
        event: 'brochure-requested',
        category: 'engagement',
        // value: data.orderId,
        // 'custom-template-id': data.templateId,
        // 'custom-template-name': data.templateName,
      });
    },
    guideRequested() {
      this.$gtm.trackEvent({
        event: 'guide-requested',
        category: 'engagement',
        // value: data.orderId,
        // 'custom-template-id': data.templateId,
        // 'custom-template-name': data.templateName,
      });
    },
    quoteRequested() {
      this.$gtm.trackEvent({
        event: 'quote-requested',
        category: 'engagement',
      });
    },
    volumeQuoteRequested() {
      this.$gtm.trackEvent({
        event: 'volume-quote-requested',
        category: 'engagement',
      });
    },
    sampleKitRequested(setNumber) {
      this.$gtm.trackEvent({
        event: 'sample-kit-requested',
        category: 'engagement',
        'custom-set-number': setNumber,
      });
    },
    // add_to_cart(items) {
    //   // this.$gtm.event('add_to_cart', {
    //   //   items: items.map((item) => ({
    //   //     id: item.toString,
    //   //   })),
    //   // });
    // },
    // remove_from_cart(items) {
    //   // this.$gtm.event('remove_from_cart', {
    //   //   items: items.map((item) => ({
    //   //     id: item.toString,
    //   //   })),
    //   });
    // },
    // search(searchTerm) {
    //   // this.$gtm.event('search', {
    //   //   search_term: searchTerm,
    //   // });
    // },
    search_pageview(searchPath) {
      this.$gtm.event('pageView', {
        page_path: searchPath,
      });
    },
  },
};
</script>
