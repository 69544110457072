<template>
  <tr class="recent-upload-item pointer" @click="openProductEditor">
    <td class="p-1">{{createdDate}}</td>
    <td class="img-col">
      <img class="bg-light mb-1"
        :alt="drawingConfiguration.name"
        :src="pngFile">
    </td>
    <td>
      <div class="drawing-name font-weight-bold">{{drawingConfiguration.name}}</div>
      <div v-if="hasSize" class="drawing-size">{{width}} mm x {{height}} mm</div>
    </td>
    <td class="text-right">
      <div class="dc-list-item-actions">
        <div class="btn-group-vertical row-actions">
          <loading-button
            v-if="canAddToCart"
            :loading="addToOrderLoading"
            :variant="addToCartVariant"
            :disabled="disabled"
            size="xs"
            stroke-color="#fff"
            @click="addToOrder"><i class="material-icons compact">addshopping_cart</i>
          </loading-button>

          <button type="button"
            class="btn btn-error btn-xs btn-flat"
            @click="showSelectMaterial">
            <template v-if="canAddToCart"><i class="material-icons compact">edit</i> {{ $t('my_designs.edit') }}</template>
            <template v-else><i class="material-icons compact">visibility</i> {{ $t('my_designs.show') }}</template>
          </button>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import _has from 'lodash/has';
import LoadingButton from 'src/components/tools/LoadingButton';

export default {
  components: {
    LoadingButton,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      addToOrderLoading: false,
    };
  },
  computed: {
    isInCart() {
      return _has(this.drawingConfiguration.draftOrders, this.$store.state.cart.orderId);
    },
    canAddToCart() {
      return this.drawingConfiguration.approvable && !this.hasUndeliverableMaterial;
    },
    hasError() {
      return this.drawingConfiguration.feedback.some((f) => f.type === 'ERROR');
    },
    hasUndeliverableMaterial() {
      return this.drawingConfiguration.locked && (this.hasMaterial && !this.materialIsPublished);
    },
    materialIsPublished() {
      if (this.material) {
        return this.material.is_published;
      }
      return false;
    },
    hasMaterial() {
      return this.drawingConfiguration.material_id in this.materials && parseInt(this.drawingConfiguration.material_id, 10) !== 1;
    },
    materials() {
      return this.$store.state.materials;
    },
    material() {
      if (this.materials[this.drawingConfiguration.material_id]) {
        return this.materials[this.drawingConfiguration.material_id];
      }
      return null;
    },
    addToCartVariant() {
      if (this.canAddToCart && this.hasMaterial) {
        return 'primary';
      }
      return 'empty';
    },
    drawingConfiguration() {
      return this.$store.state.dc.drawingConfiguration[parseInt(this.id, 10)];
    },
    height() {
      if (!_has(this.drawingConfiguration, 'Drawing.dimensions.height')) {
        return '';
      }
      return parseFloat(this.drawingConfiguration.Drawing.dimensions.height)
        .toFixed(0);
    },
    width() {
      if (!_has(this.drawingConfiguration, 'Drawing.dimensions.width')) {
        return '';
      }
      return parseFloat(this.drawingConfiguration.Drawing.dimensions.width)
        .toFixed(0);
    },
    hasSize() {
      return this.width !== '' && this.width !== '0' && this.height !== '' && this.height !== '0';
    },
    pngFile() {
      return `${this.$config.apiUrl}/drawing/pngsmall/${this.drawingConfiguration.drawing_id}`;
    },
    createdDate() {
      return new Date(this.drawingConfiguration.created_at).toLocaleDateString(this.$i18n.locale, {
        year: 'numeric', month: '2-digit', day: '2-digit',
      });
    },
  },
  methods: {
    addToOrder() {
      this.addToOrderLoading = true;
      this.$store.dispatch('addDrawingConfiguration', {
        dcId: this.id,
        orderId: this.orderId,
      })
        .finally(() => {
          // this.$eventBus.$emit('gtm.add_to_cart', [this.id]);
          this.addToOrderLoading = false;
        });
    },
    showSelectMaterial() {
      this.$emit('showSelectMaterial', this);
      return false;
    },
    openProductEditor() {
      this.$router.push({
        name: 'products-list-me-editor',
        params: { productId: this.id },
      });
    },
  },
};
</script>

<style lang="scss">
  .recent-upload-item {
    .img-col {
      width: 100px;
    }
  }
</style>
