<template>
  <div>
    <router-view v-if="ready"/>
    <loading-widget-full-width :loading="!ready" />
    <cart-loader :order-id="orderId"/>
  </div>
</template>
<script>
import CartLoader from 'src/components/loader/CartLoader';
import LoadingWidgetFullWidth from 'src/components/tools/LoadingWidgetFullWidth';

export default {
  components: { LoadingWidgetFullWidth, CartLoader },
  computed: {
    orderId() {
      // todo: redirect?
      const currentId = parseInt(this.$route.params.orderId, 10);
      if (!currentId && 'order_id' in this.$store.state.settings) {
        return parseInt(this.$store.state.settings.order_id, 10);
      }
      if (!currentId) {
        return -1;
      }
      return currentId;
    },
    ready() {
      return this.$store.getters.loaded([
        'drawingProducts',
        'materials',
        'translations',
        'user',
        'drawingConfigurations',
        'errors',
        'popups',
      ]);
    },
  },
  mounted() {
    this.$store.dispatch('loadSettings');
    this.$store.dispatch('loadMaterials');
    this.$store.dispatch('loadTemplates');
    this.$store.dispatch('loadDrawingConfigurations');
    if (this.orderId > 0) {
      this.$store.dispatch('loadOrder', this.orderId);
    }
  },
  watch: {
    $route() {
      this.loadNewOrderIfRequired();
    },
    orderId() {
      if (this.orderId > 0) {
        this.$store.dispatch('loadOrder', this.orderId);
      }
    },
  },
  methods: {
    loadNewOrderIfRequired() {
      // if the route has no orderId, do nothing
      if (this.$route.params.orderId === undefined) {
        return;
      }
      // check if the route has a different order than the currenly loaded order.
      // if so: load a new order
      // this is needed if a new order is loaded through the order list.
      if (this.$store.getters.loaded(['cart'])
        && 'orderId' in this.$route.params
        && this.$route.params.orderId !== this.$store.state.cart.orderId) {
        this.$store.dispatch('loadOrder', this.$route.params.orderId);
      }
    },
  },
};
</script>
