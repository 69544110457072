<template>
  <b-modal v-if="help"
    class="help-modal"
    v-model="visible"
    :title="help.title"
    size="s"
    cancel-title-html="">
    <div class="help-modal-content">
      <div v-html="help.body"></div>
    </div>
    <template v-slot:modal-footer="{ ok }">
      <b-button size="sm" class="btn btn-primary" @click="ok()">OK</b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      type: 'HELP_TYPE',
    };
  },
  computed: {
    help() {
      if (this.type.toLowerCase() in this.$store.state.errors) {
        return this.$store.state.errors[this.type.toLowerCase()];
      }
      return this.$store.state.popups[this.type.toLowerCase()];
    },
  },
  watch: {
    $route() {
      // don't persist when the route changes...
      this.visible = false;
    },
  },
  mounted() {
    this.$eventBus.$off('snlb.showHelp', this.showHelp);
    this.$eventBus.$on('snlb.showHelp', this.showHelp);
  },
  methods: {
    showHelp(type) {
      this.visible = true;
      this.type = type;
    },
  },
};
</script>

<style lang="scss">
// .help-modal {
  .modal-header .close {
    z-index: 1;
  }

  .help-modal-content a.hreflink i.material-icons {
    display: none;
  }

  .help-modal-content {
    img {
      width: 100%;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 100%;
      font-size: 1rem;

      .fix-link::before {
        display: none;
      }
    }

    h1 {
      font-size: 1.2rem;
    }

    ul {
      margin-top: 1rem;
      padding-left: 1.25rem;
    }
  }
// }
</style>
