/* eslint-disable func-names */
import Vue from 'vue';

const EventBus = new Vue();
EventBus.success = function (message) {
  this.$emit('snlb.success', message);
};
EventBus.error = function (message) {
  if (message.result && message.result.status === 401) {
    this.$emit('snlb.signedOut', message);
  } else {
    this.$emit('snlb.error', message);
  }
};

export default EventBus;
