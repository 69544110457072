<template>
  <tr v-if="item"
    class="cart-review-table-order-custom-item product-row align-top">
    <td>
      <!-- <strong>X{{ dc.id }}</strong> -->
    </td>
    <td>
      {{ name }}
    </td>
    <td class="text-right">
        {{ pricePerPiece }}
    </td>
    <td class="text-right">
      {{ item.quantity }} &times;
    </td>
    <td class="text-right">
        {{ priceTotal }}
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    showVat: {
      type: Boolean,
      required: true,
    },
    showPrices: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    pricePerPiece() {
      return this.$config.formatPrice(this.item.costs.perPiece, false, true);
    },
    priceTotal() {
      return this.$config.formatPrice(this.item.costs.total, false, true);
    },
    item() {
      return this.$store.state.cart.orderCustomItems[this.id];
    },
    name() {
      return this.item.name;
    },
  },
};
</script>

<style>
</style>
