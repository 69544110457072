<template>
  <div class="cart-list-item-quantity-widget border border-success">
    <b-button
      :disabled="this.quantityPublic <= 1"
      size="xs"
      variant="flat"
      class="qty-button px-2 mr-1"
      @click.capture.stop="decrement">
      -
    </b-button>

    <div style="position: relative; bottom: 8px"
      class="input-group qty-input-group">
      <async-queue
        :watch-value="quantityPublic"
        :delay="400"
         @update="(val) => { this.loading = val; }"
         @process="updateValue"><span/>
      </async-queue>

      <loading-widget :loading="loading" style="position: absolute; left: -4px; top: 12px" />

      <input
        :tabindex="tabstop"
        v-model.number="quantityPublic"
        class="form-control text-right w-100 border-0 input-field"
        type="number"
        min="1"
        step="1"
        max="100000"
        @click.capture.stop=""
        @blur="zeroToOne">
      </div>

      <b-button
        :disabled="this.quantityPublic >= 100000"
        size="xs"
        variant="flat"
        class="qty-button px-2"
        @click.capture.stop="increment">
        +
      </b-button>
      <br />
  </div>
</template>

<script>
import AsyncQueue from 'src/components/tools/AsyncQueue';
import VolatileField from 'src/lib/Volatile';
import LoadingWidget from 'src/components/tools/LoadingWidget';

export default {
  mixins: [VolatileField('quantity')],
  components: { LoadingWidget, AsyncQueue },
  props: {
    id: {
      type: Number,
    },
    quantity: {
      type: Number,
    },
    tabstop: {
      type: Number,
      default: 0,
    },
    productType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    quantityValidator(value) {
      const intValue = parseInt(value, 10);
      if (intValue > 99999) {
        this.$nextTick(() => this.$set(this, 'quantityTaintedValue', 99999));
      }
      return value;
    },
    async updateValue(promise, value, valid) {
      if (parseInt(value, 10) === 0 || Number.isNaN(value) || !Number.isFinite(value) || !this.quantityIsTainted) {
        promise.resolve();
        return;
      }

      this.$store.dispatch('updateQty', {
        id: this.id,
        value,
        productType: this.productType,
        valid,
      })
        .finally(() => {
          promise.resolve();
        });
    },
    setQuantity(value) {
      this.quantityPublic = value;
    },
    increment() {
      this.quantityPublic += 1;
    },
    decrement() {
      this.quantityPublic -= 1;
      if (this.quantityPublic < 1) { this.quantityPublic = 1; }
    },
    zeroToOne() {
      if (this.quantityPublic === 0) {
        this.quantityPublic = 1;
      }
    },
  },
};
</script>

<style lang="scss">
  .cart-list-item-quantity-widget {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 3;

    .qty-button {
      min-width: 0;
      font-size: 0.9rem;
    }

    .qty-input-group {
      display: inline-block;
      margin-bottom: -15px;
      border-color: green !important;
      border-width: 10px !important;

      input.form-control {
        box-shadow: none !important;
        min-width: 35px;
      }
    }
  }
</style>
