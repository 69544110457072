<template>
  <div v-if="loading"
    class="loading-widget-full-width">
    <indeterminate-progress
      :offset="radius"
      :radius="radius"
      :stroke-width="8" />
  </div>
</template>

<script>
import IndeterminateProgress from 'src/components/tools/IndeterminateProgress';

export default {
  components: { IndeterminateProgress },
  props: {
    radius: {
      type: Number,
      default: 100,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
};
</script>

<style lang="scss">
  .dummy {
    color: red;
    // dummy class to fix a problem where the first class is ignored after loading daemonite, or something like that...
  }

  .loading-widget-full-width {
    display: flex;
    align-content: center;
    justify-content: center;
    min-height: 75vh;
    width: 100%;
  }
</style>
