<template>
  <div class="drawing-configuration-price-matrix p-1">
    <b-button v-for="qap in quantitiesAndPrices" :key="qap.quantity"
      variant="flat"
      @click.prevent="setOrderDrawingQuantity(qap.quantity)"
      class="bulk-discount-line shadow-1 d-flex justify-content-around p-2 bg-light mb-1">
      <span class="bulk-discount-quantity">{{qap.quantity}}</span>
      <span>×</span>
      <span class="bulk-discount-price-piece">{{qap.priceSingle}}</span>
      <span>=</span>
      <span class="bulk-discount-price-total">{{qap.priceTotal}}</span>
    </b-button>

    <b-button
      variant="flat"
      class="bulk-discount-line shadow-1 d-flex justify-content-around p-2 bg-light mb-1"
      @click.prevent="setOrderDrawingQuantity(largestQuantity.quantity + 1)">
      <span class="bulk-discount-quantity">{{largestQuantity.quantity}}+</span>
      <span></span>
      <span class="request-quote-text text-left">{{$t('editor.request_a_custom_quote')}}</span>
    </b-button>

  </div>
</template>
<script>

export default {
  props: {
    showVat: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    priorityId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showAll: false,
    };
  },
  computed: {
    quantitiesAndPrices() {
      return this.$store.getters.nestingQty(this.id).slice().map((item, idx) => ({
        quantity: item,
        priceSingle: this.getPrice(this.priority.id, idx, false, 1),
        priceTotal: this.getPrice(this.priority.id, idx, false, item),
      })).filter((item, idx, arrayRef) => idx < 3 || idx > (arrayRef.length - 4));
    },
    largestQuantity() {
      return this.quantitiesAndPrices[this.quantitiesAndPrices.length - 1];
    },
    priorities() {
      return this.$store.getters.prioritiesOrdered.slice();
    },
    nestingQty() {
      return this.$store.getters.nestingQty(this.id).slice();
      // nestingQuantities = nestingQuantities.map((item) => item.priceIndex);
      // eslint-disable-next-line no-return-assign
      // nestingQuantities = nestingQuantities.map((item, idx) => {
      //   // eslint-disable-next-line no-param-reassign
      //   item.priceIndex = idx;
      //   return item;
      // });
      // return nestingQuantities;
      // return nestingQuantities.filter((item, index) => index <= 2 || index > (nestingQuantities.length - 3));
    },
    priority() {
      return this.$store.state.priorities[this.priorityId];
    },
  },
  methods: {
    getPrice(priorityId, qtyIdx, useVat, qty) {
      return this.$config.currencyFormat.format(qty * this.$store.getters.priceByQty(this.id, priorityId, qtyIdx, useVat));
    },
    getPriceNoCurrency(priorityId, qtyIdx, useVat) {
      return this.$store.getters.priceByQty(this.id, priorityId, qtyIdx, useVat);
    },
    setOrderDrawingQuantity(quantity) {
      this.$emit('setOrderDrawingQuantity', quantity);
    },

  },
};
</script>
<style lang="scss">
  .drawing-configuration-price-matrix {
    .bulk-discount-line {
      font-weight: 100;
      width: 100%;
      span {
        flex: 3;
        text-align: center;
      }
      span:nth-of-type(2),
      span:nth-of-type(4) {
        flex: 1;
      }
      .request-quote-text {
        flex: 7 !important;
      }
    }
    // .bulk-discount-quantity,
    // .bulk-discount-price-piece,
    // .bulk-discount-price-total {
    //   text-align: center;
    //   // width: 30%;
    // }

    // .bulk-discount-price-piece {
    //   &::before {
    //     padding-right: 2rem;
    //     content: "×";
    //   }
    //   &::after {
    //     padding-left: 2rem;
    //     content: "=";
    //   }
    // }
  }
</style>
