<template>
  <div :class="rowClasses">
    <div class="input-row-label" style="margin-bottom: 10px">
      <form-label-widget
        :label="label"
        :id="id"
        :help-link="helpLink" />

      <div class="queue">
        <indeterminate-progress v-if="loading"
          :offset="10"
          :radius="10"
          :stroke-width="2" />
      </div>
    </div>
    <slot/>
    <form-help-widget
      :error="error"
      :warning="warning"
      :help="help" />
  </div>
</template>

<script>
import FormWidgetBase from 'src/components/form/FormWidgetBase';
import IndeterminateProgress from 'src/components/tools/IndeterminateProgress';

export default {
  components: { IndeterminateProgress },
  extends: FormWidgetBase,
  props: {
    type: {
      type: String,
      default: 'text',
      validator(value) {
        return ['text', 'password', 'number', 'url', 'email', 'search'].includes(value);
      },
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onChange(event) {
      this.$emit('change', event);
    },
    onInput(event) {
      this.$emit('input', event);
    },
  },
};
</script>

<style>
</style>
