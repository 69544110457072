<template>
  <div class="row">
    <loading-widget-full-width v-if="!ready" :loading="!ready" />
    <div v-else
      class="col-md-6 offset-md-3">
      <forgot-password-save-new v-if="showFields"
        :code="code"
        :confirmation-code="confirmationCode"
        :use-dark="false" />

      <div v-if="errorIncorrectCode"
        class="mt-4 alert alert-danger">
        <div class="material-icons">warning</div>
        {{$t('loginpanel.the_entered_security_code_is_invalid')}}
      </div>

      <div v-if="errorTooManyAttempts"
        class="mt-4 alert alert-danger">
        <div class="material-icons">warning</div>
        {{$t('loginpanel.your_security_code_has_expired')}}
      </div>
    </div>

  </div>
</template>

<script>

import LoadingWidgetFullWidth from 'src/components/tools/LoadingWidgetFullWidth';
import ForgotPasswordSaveNew from 'src/components/user/ForgotPasswordSaveNew';
import axios from 'axios';

export default {
  components: {
    LoadingWidgetFullWidth,
    ForgotPasswordSaveNew,
  },
  mounted() {
    this.checkCodes();
  },
  data() {
    return {
      ready: false,
      showFields: false,
      errorIncorrectCode: false,
      errorTooManyAttempts: false,
    };
  },
  computed: {
    code() {
      return this.$route.params.code;
    },
    confirmationCode() {
      return this.$route.params.confirmationCode;
    },
  },
  methods: {
    checkCodes() {
      const formData = new FormData();
      formData.append('code', this.code);
      formData.append('confirmation', this.confirmationCode);
      this.loading = true;
      axios.post(`${this.$config.apiUrl}/user/forgotPasswordTokenIsValid`,
        formData, { withCredentials: true })
        .then(() => {
          this.showFields = true;
        })
        .catch((error) => {
          if (error.response.data.error === 'INCORRECT_FORGOT_PASSWORD_CODE') {
            this.errorIncorrectCode = true;
          } else if (error.response.data.error === 'TOO_MANY_FORGOT_PASSWORD_ATTEMPTS') {
            this.errorTooManyAttempts = true;
          }
        })
        .finally(() => {
          this.ready = true;
          this.loading = false;
        });
    },
  },
};
</script>

<style>

</style>
