<template>
  <div class="priority-item list-group-item list-group-item-action p-1 pointer"
    @click="updatePriority">
    <div class="selected-icon">
      <i v-if="selected" class="material-icons compact radio-icon">radio_button_checked</i>
      <i v-else class="material-icons compact radio-icon">radio_button_unchecked</i>
    </div>

    <div class="priority-info small pl-1">

      <div class="priority-time-and-name">
        <div class="priority-time">
          <span class="text-capitalize pr-1">
            <strong v-show="shippingMethod === 'SHIPPING'">
              {{ $tools.date.getRelativeDayShort(priority.estimatedDeliveryShipping, $i18n.locale) }}</strong>
            <strong v-show="shippingMethod === 'PICKUP'">
              {{ $tools.date.getRelativeDayShort(priority.estimatedDeliveryPickup, $i18n.locale) }}</strong>
          </span>
        <!-- <span v-show="shippingMethod === 'SHIPPING' && priority.id === 5">{{$t('cart.delivery.before')}}xx</span> -->

        </div>
        <div class="priority-name text-right pr-2" v-html="name_formatted"></div>
      </div>

      <div class="priority-latest-order-time">
        <span class="text-muted small">
          {{$t('cart.delivery.order_before')}} {{deadlineTime}}
        </span>
        <span class="text-muted small" v-if="shippingMethod === 'PICKUP'">
          <br>{{$t('cart.delivery.from')}}
        </span>
      </div>
    </div>
    <div class="priority-price border-left pr-1">
      <div class="text-right">{{ showPriceExclVat }}</div>
      <div class="text-right text-muted small">{{ showPrice }} {{$t('cart.delivery.incl')}}</div>
    </div>
  </div>
</template>

<script>
import TweenTools from 'src/lib/TweenTools';

export default {
  props: {
    id: {
      type: Number,
      default: null,
    },
    shippingMethod: {
      type: String,
      required: true,
    },
    priority: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    price: {
      type: String,
      required: true,
    },
    priceExclVat: {
      type: String,
      required: true,
    },
    priceNum: {
      type: Number,
      required: true,
    },
    priceNumExclVat: {
      type: Number,
      required: true,
    },
    selected: {
      type: Boolean,
      required: true,
    },
    deadlineTime: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      animatedPrice: null,
      animatedPriceExclVat: null,
    };
  },
  computed: {
    showPrice() {
      return this.$config.currencyFormat.format(this.priceNum / 100);
    },
    showPriceExclVat() {
      return this.$config.currencyFormat.format(this.priceNumExclVat / 100);
    },
    name_formatted() {
      return this.name.replace(' ', '<br>');
    },
  },
  watch: {
    priceNum(value, oldValue) {
      TweenTools.tweenNumber(value, oldValue, this, 'animatedPrice');
    },
    priceNumExclVat(value, oldValue) {
      TweenTools.tweenNumber(value, oldValue, this, 'animatedPriceExclVat');
    },
  },
  methods: {
    updatePriority(event) {
      event.preventDefault();
      if (this.selected) {
        return false;
      }
      this.$emit('updatePriority', this.priority.id);
      return false;
    },
  },
};
</script>

<style lang="scss">
  .priority-item {
    min-height: auto;
    display: grid;

    grid-template-columns: 30px auto 110px;

    .selected-icon {
      grid-column: 1/2;
      grid-row: 1/1;
      display: flex;
      align-items: center;
    }

    .priority-time-and-name {
      grid-column: 2/3;
      grid-row: 1/2;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      line-height: normal;
      max-height: 1rem;
      overflow-y: visible;

      .priority-time {
        flex-shrink: 0;
      }

      .priority-name {
        @media(max-width: 1200px) {
          display: none;
        }
      }
    }

    .priority-latest-order-time {
      grid-column: 2/3;
      grid-row: 2/3;
      line-height: normal;
    }

    .priority-price {
      grid-column: 3/4;
      grid-row: 1/2;
      font-size: 0.9em;
      line-height: normal;
    }
  }
</style>
