<template>
  <div class="indeterminate-progress spin-clockwise"
    :style="`width: ${(radius+offset)*2}px; height: ${(radius+offset)*2}px;`">
    <svg x="0" y="0" :width="(radius+offset)*2" :height="(radius+offset)*2"
      :viewBox="`0, 0, ${(radius+offset)*2}, ${(radius+offset)*2}`">
      <circle :r="radius" :cx="radius+offset" :cy="radius+offset"
        class="backgroundCircle"
        :style="{'strokeWidth': `${strokeWidth}px`, 'stroke': 'none'}"></circle>
      <circle :r="radius" :cx="radius+offset" :cy="radius+offset"
        :transform="`rotate(-90 ${radius+offset} ${radius+offset})`"
        class="progressCircle"
        :style="{'strokeWidth': `${strokeWidth}px`, 'stroke': strokeColor}"
        :stroke-dasharray="`${(length/this.dashCount)}px`"></circle>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    offset: {
      type: Number,
      default: 2,
    },
    strokeWidth: {
      type: Number,
      default: 3,
    },
    strokeColor: {
      type: String,
      default: '#000000',
    },
    radius: {
      type: Number,
      default: 7,
    },
    dashCount: {
      type: Number,
      default: 4,
    },
  },
  computed: {
    length() {
      return Math.PI * 2 * this.radius;
    },
  },
};
</script>

<style lang="scss">
.indeterminate-progress {
  .progressCircle {
    stroke: #000000;
    fill: none;
  }

  .backgroundCircle {
    stroke: none;
    fill: none;
  }

  @keyframes spin-clockwise {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(359deg);
    }
  }

  &.spin-clockwise {
    animation: spin-clockwise 2s infinite linear;
  }
}
</style>
