<template>
  <div class="dc-editor-modal-header">
    <span class="title">{{$t('drawing_configuration.details_of_design_x')}}{{productId}}</span>
    <span class="close d-sm-none" @click="closeModal">×</span>
    <span class="close d-none d-sm-block">
      <b-button v-if="!dcHasMaterial"
        variant="light"
        size="xs"
        @click="closeModal">
        <i class="material-icons compact">close</i>{{$t('drawing_configuration.close')}}
      </b-button>
      <b-button v-else
        :class="{'btn-light' : !dcHasMaterial, 'btn-success': dcHasMaterial}"
        size="xs"
        @click="closeModal">
        <i class="material-icons compact">check</i>{{$t('drawing_configuration.close')}}
      </b-button>
    </span>
  </div>
</template>

<script>
export default {
  methods: {
    closeModal() {
      this.$vuedals.close();
    },
  },
  computed: {
    productId() {
      return this.$route.params.productId;
    },
    dc() {
      if (this.productId) {
        return this.$store.state.dc.drawingConfiguration[this.productId];
      }
      return false;
    },
    // dcLocked() {
    //   if (this.productId) {
    //     return this.dc.locked;
    //   }
    //   return false;
    // },
    dcHasMaterial() {
      if (this.productId) {
        return this.dc.material_id && parseInt(this.dc.material_id, 10) !== 1;
      }
      return false;
    },
  },
};
</script>

<style>
  .dc-editor-modal-header .btn {
    margin-top: -14px;
    padding: 0.4rem 1rem;
  }
</style>
