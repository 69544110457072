<template>
  <div class="order-payment-view">
    <div class="row">
      <div class="col-12">
        <div class="mt-3 card shadow-1 text-center">
          <div v-if="status == 'accepted' || status == 'in_progress'"
            class="card-block bg-success py-2 payment-status-title">
            {{$t('order_payment.your_order_is_being_processed')}}
          </div>

          <div v-else-if="status == 'shipped'"
            class="card-block bg-success py-2 payment-status-title">
            {{$t('order_payment.your_order_has_been_shipped')}}
          </div>

          <div v-else-if="status == 'cancelled'"
            class="card-block bg-danger py-2 payment-status-title">
            {{$t('order_payment.this_order_has_been_canceled')}}
          </div>

          <div v-else-if="status  == 'submitted'"
            class="card-block bg-warning py-2 payment-status-title">
            {{$t('order_payment.this_order_has_not_been_paid')}}
          </div>

        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-6">
        <div class="card shadow-1">
          <div class="card-block">
            <div class="card-title pl-2 bg-light">{{$t('order_payment.payment_method')}}</div>
          </div>
          <payment-list v-model="paymentMethod"/>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card summary-card" v-if="cart">
          <div class="card-body p-2">
            <div class="card-title">Order L{{ orderId }}</div>
              {{cart.yourReference}}
              <cart-review-table
                :show-prices="true"
                :show-vat="showVat"/>

              <ul class="p-0" style="list-style-type: none;">
                <template v-if="cart.shippingMethod=== 'PICKUP'">
                  <li><i class="material-icons">check</i> {{$t('order_payment.youve_selected_to_pickup_the_order')}}</li>
                  <li><i class="material-icons">check</i> {{$t('order_payment.your_order_will_be_ready_on')}}
                    <strong>{{ $tools.date.getRelativeDay(estimatedDelivery, $i18n.locale) }}</strong>
                     {{$t('order_payment.at_16_if_paid_before')}}
                    <strong>{{ $tools.date.getRelativeDay(orderBeforeTime, $i18n.locale) }}
                      {{ $tools.date.getTime(orderBeforeTime, $i18n.locale)}}</strong>.
                  </li>
                </template>
                <template v-else>
                  <li><i class="material-icons">check</i> {{$t('order_payment.your_order_will_be_delivered')}}
                    <strong>{{ $tools.date.getRelativeDay(estimatedDelivery, $i18n.locale) }}</strong>
                      {{$t('order_payment.if_paid_before')}}
                    <strong>{{ $tools.date.getRelativeDay(orderBeforeTime, $i18n.locale) }}
                      {{ $tools.date.getTime(orderBeforeTime, $i18n.locale)}}</strong>.
                  </li>
                </template>
              </ul>

              <loading-button
                variant="success"
                :loading="payButtonLoading"
                stroke-color="#fff"
                btn-text="Betalen"
                @click="payOrder">{{$t('order_payment.pay')}}
              </loading-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _get from 'lodash/get';
import axios from 'axios';
import Vue from 'vue';
import CartReviewTable from 'src/components/cart/review/CartReviewTable';
import PaymentList from 'src/components/cart/review/PaymentList';
import LoadingButton from 'src/components/tools/LoadingButton';

export default {
  components: {
    PaymentList,
    CartReviewTable,
    LoadingButton,
  },
  data() {
    return {
      loading: true,
      paymentMethod: 'mollie-ideal',
      payButtonLoading: false,
    };
  },
  computed: {
    status() {
      if ('status' in this.$store.state.cart) {
        return this.$store.state.cart.status.toLowerCase();
      }
      return '';
    },
    estimatedDelivery() {
      if (this.priority) {
        return this.priority.estimatedDelivery;
      }
      return '';
    },
    orderBeforeTime() {
      if (this.priority) {
        return this.priority.orderBeforeTime;
      }
      return '';
    },
    deliveryDays() {
      if (this.priority) {
        return this.priority.deliveryDays;
      }
      return '';
    },
    comment() {
      return this.$store.state.cart.userComment;
    },
    orderId() {
      const currentId = parseInt(this.$route.params.orderId, 10);
      if (!currentId) {
        return parseInt(this.$store.state.settings.order_id, 10);
      }
      return currentId;
    },
    cart() {
      return this.$store.state.cart;
    },
    config() {
      return this.$config;
    },
    orderDrawings() {
      return this.$store.state.cart.orderDrawings;
    },
    showDelivery() {
      return this.$store.state.cart.shippingMethod !== 'PICKUP';
    },
    alternativeDelivery: {
      get() {
        return !this.addressData.invoice_is_delivery_address;
      },
      set(value) {
        Vue.set(this.addressData, 'invoice_is_delivery_address', !value);
      },
    },
    hasAlternativeDelivery() {
      return this.hasDelivery && this.alternativeDelivery;
    },
    showVat() {
      return this.$store.state.settings.showVat;
    },
    showVatInformation() {
      return this.euVatCountries.some((code) => code === this.cart.address.invoice_country);
    },
    hasShippingToEu() {
      return this.euVatCountries.some((code) => code === this.cart.address.delivery_country);
    },
    priority() {
      return _get(this.$store.state.cart.priorities, this.$store.state.cart.priorityId, '');
    },
    hasCorrectOrder() {
      if (!this.$store.getters.loaded(['cart'])) {
        return true;
      }
      return this.$store.state.cart.orderId === this.orderId;
    },
    ready() {
      // TODO: load multiple carts..
      return this.$store.getters.loaded([
        'drawingProducts',
        'materials',
        'translations',
        'user',
        'drawingConfigurations',
        'cart',
      ]) && this.hasCorrectOrder;
    },
  },
  watch: {
    ready(isReady) {
      if (isReady) {
        this.redirectIfNeeded();
      }
    },
  },
  mounted() {
    if (this.ready) {
      this.redirectIfNeeded();
    }
  },
  methods: {
    redirectIfNeeded() {
      if (this.$store.state.cart.status === 'DRAFT') {
        this.$router.push({
          name: 'order-review',
          params: {
            lang: this.$route.params.lang,
            orderId: this.$store.state.cart.orderId,
          },
        });
      } else if (this.$store.state.cart.status !== 'SUBMITTED') {
        this.$router.push({
          name: 'order-confirmation',
          params: {
            lang: this.$route.params.lang,
            orderId: this.$store.state.cart.orderId,
          },
        });
      }
    },
    // getSubtotalDotNotation(name, vat) {
    //   let total = this.costTable[name].amount;
    //   if (vat) {
    //     total += Object.values(this.costTable[name].vat).reduce((sum, value) => sum + value, 0);
    //   }
    //   return new Intl.NumberFormat('en', { minimumFractionDigits: 2 }).format(total / 100);
    // },
    payOrder() {
      this.payButtonLoading = true;
      const formData = new FormData();
      formData.append('order_id', this.$store.state.cart.orderId);
      formData.append('payment_method', this.paymentMethod);
      const ga = this.$tools.getCookie('_ga');
      if (ga !== '') {
        formData.append('ga', ga);
      }
      this.$eventBus.$emit('odoo.event', { event: 'payment_started', msg: 'Payment started from payment view' });
      // this.$eventBus.$emit('gtm.startedPayment', {
      //   orderId: this.$store.state.cart.orderId,
      //   totalPrice: this.getSubtotalDotNotation('total', false),
      // });
      return axios
        .post(
          `${this.$config.apiUrl}/order/pay`,
          formData,
          { withCredentials: true },
        )
        .then((response) => {
          window.location.href = response.data.result.paymentUrl;
        }).catch((err) => {
          this.payButtonLoading = false;
          this.$eventBus.error(err);
        });
    },
  },
};
</script>
<style lang="scss">
  .order-payment-view {

    .payment-status-title {
      font-size: 1.4rem;
    }

    .summary-card {

      table {
        width: 100%;
      }

      tr {
        border-bottom: 1px solid rgb(230, 230, 230);
      }

      td {
        padding-top: .1rem;
        padding-bottom: .1rem;
      }

      .total-row {
        border-bottom: none;
        td {
          padding-top: 0.2rem;
        }
      }
    }
  }
</style>
