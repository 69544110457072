/* eslint-disable object-shorthand */
import _get from 'lodash/get';

export default function VolatileField(name, storeName, sourceName) {
  const useSourceName = typeof sourceName === 'undefined' ? name : sourceName;
  const taintedValueName = `${name}TaintedValue`;
  const isTaintedName = `${name}IsTainted`;
  const validatorName = `${name}Validator`;
  const showName = `${name}Public`;
  const mixin = {
    // eslint-disable-next-line func-names
    data() {
      const obj = {};
      obj[taintedValueName] = null;
      return obj;
    },
    computed: {},
    watch: {},
  };
  // use function, so the scope remains intact.
  // eslint-disable-next-line func-names
  mixin.computed[showName] = {
    get() {
      if (this[isTaintedName]) {
        return this[taintedValueName];
      }
      return this[useSourceName];
    },
    set(value) {
      let useValue = value;
      if (Object.keys(this).includes(validatorName)) {
        useValue = this[validatorName](value);
      }
      this[taintedValueName] = useValue;
    },
  };

  if (storeName) {
    mixin.computed[name] = {
      get() {
        return _get(this.$store.state, storeName, '');
      },
    };
  }

  // use function, so the scope remains intact.
  // eslint-disable-next-line func-names
  mixin.watch[isTaintedName] = function (value) {
    // fdas
    if (!value) {
      this[taintedValueName] = null;
    }
  };

  // use function, so the scope remains intact.
  // eslint-disable-next-line func-names
  mixin.computed[isTaintedName] = function () {
    if (typeof this[taintedValueName] === 'string' && typeof this[useSourceName] === 'string') {
      return this[taintedValueName].trim() !== this[useSourceName].trim();
    }
    return this[taintedValueName] !== null && this[taintedValueName] !== this[useSourceName];
  };
  return mixin;
}
