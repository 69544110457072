<template>
  <div>
    <vue-recaptcha
      ref="recaptcha"
      :sitekey="sitekey"
      @verify="onVerify"
      @expired="onExpired"/>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import VueScript2 from 'vue-script2';

export default {
  components: {
    VueRecaptcha,
  },
  data() {
    return {
    };
  },
  computed: {
    sitekey() {
      return this.$config.recaptchaSiteKey;
    },
  },
  mounted() {
    this.$nextTick(() => VueScript2
      .load('https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit'));
  },
  methods: {
    onVerify(value) {
      this.$emit('input', value);
    },
    onExpired() {
      this.$emit('input', '');
    },
    reset() {
      this.$refs.recaptcha.reset();
    },
  },
};
</script>

<style>
</style>
