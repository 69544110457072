<template>
  <div>
    <div class="row">
      <div class="col-12">
        <order-status-warning
          v-if="ready"
          :can-be-paid="canBePaid"
          :is-locked="isLocked"
          @startNewOrder="createNewOrder"
          @goToPayment="goToPayment" />
      </div>
    </div>

    <router-view/>

    <div class="cart-list mt-2 row">
      <!-- exists for the ShowProductEditor component -->

      <loading-widget-full-width :loading="!ready" v-if="!ready" />

      <template v-else>

        <drawing-configuration-batch-editor
          ref="batchMaterialSelect"
          @hide="batchSelectMaterialVisible = false"
          :order-id="data.orderId"
          :visible="batchSelectMaterialVisible"
          :selected="Object.keys(selected).map((id) => orderDrawings[id].drawingConfigurationId)"
          :replace-in-cart="true"/>

        <div class="col-md-7 col-lg-8">
          <div class="cart-upload-block">
            <div class="p-2 text-center upload-hint" >
              <h3 class="mb-2 p-3 d-none d-md-block bg-light-3">
                <span><i class="material-icons compact">upload</i>{{$t('cart.drop_your_drawings_here')}}</span>
                <br>
                <small class="filetypes-msg text-muted">{{$t('cart.dropzone_file_extensions')}}</small>
              </h3>
              <div class="pb-1">
                <b-button
                  size="sm"
                  :class="{ 'btn-success' : cartEmpty, 'btn-light': !cartEmpty }"
                  class="mt-1 mr-2 shadow-1 mb-1"
                  @click="$eventBus.$emit('upload')">
                  <i class="material-icons">note_add</i>
                  {{$t('cart.upload_files')}}
                </b-button>
                <span class="upload-button-divider d-none d-md-inline-block mb-1">/</span>
                <b-button
                  variant="dark"
                  size="sm"
                  :class="{ 'btn-light':!cartEmpty }"
                  class="mt-1 ml-2 shadow-1 mb-1"
                  :to="{name:'products-list-me', params: routeParams}">
                  <i class="material-icons">folder_open</i>
                  {{$t('cart.select_previously_ordered_designs')}}
                </b-button>
              </div>
            </div>
          </div>

          <div class="mt-3">
            <!-- Start of cart -->
            <div class="card shadow-1 card-sticky mb-3">
              <div>
                <div class="card-title bg-light p-0 pl-2">
                  {{$t('cart.cart_title')}} L{{ data.orderId }}
                  <template v-if="$store.getters.isAdmin"> van {{this.$store.getters.profileFullName}}</template>
                </div>
              </div>

              <div class="card-body border-top">

                <div v-if="orderDrawingsCount > 0"
                  class="cart-list-header border-bottom py-2 pr-2">
                  <div class="cart-list-header-select-all pl-2">
                    <b-form-checkbox
                      ref="cbSelectAll"
                      label="Selecteer alles"
                      @change="toggleAllSelected">
                      <span class="text-muted">{{$t('cart.select_all_designs')}}</span>
                    </b-form-checkbox>
                  </div>

                  <div class="cart-list-header-actions">
                    <div class="btn-group shadow-1" role="group">
                      <loading-button
                        :loading="false"
                        size="xs"
                        variant="flat"
                        class="px-1"
                        :disabled="selectedCount === 0"
                        @click="batchSelectMaterial">
                        <i class="material-icons">edit</i>
                      </loading-button>
                      <loading-button
                        :loading="batchDeleteLoading"
                        size="xs"
                        variant="flat"
                        class="px-1"
                        :disabled="selectedCount === 0"
                        @click="showBatchDeleteModal">
                        <i class="material-icons">delete</i>
                      </loading-button>
                    </div>
                  </div>
                </div>

                <div class="cart-list-body">

                  <order-custom-item-widget v-if="$store.getters.isAdmin"
                    :order-id="orderId" />

                    <div class="list-group list-group-flush">

                      <!-- <template v-if="orderCustomItemsCount > 0"> -->
                      <template v-for="(item, key) in orderCustomItems">
                        <cart-custom-list-item
                          ref="cartListItem"
                          :key="key"
                          :id="parseInt(item.id)"
                          :quantity="parseInt(item.quantity)"
                          :tabstop="keys.indexOf(key)+1"
                          :is-draft="isDraft"
                          @delete="deleteOrderCustomItem" />
                      </template>
                      <!-- </template> -->

                      <template v-if="orderDrawingsCount > 0">
                          <cart-list-item
                            v-for="(item, key) in orderDrawings"
                            :key="key"
                            ref="cartListItem"
                            :id="parseInt(item.id)"
                            :quantity="parseInt(item.quantity)"
                            :show-vat="showVat"
                            :tabstop="keys.indexOf(key)+1"
                            :is-draft="isDraft"
                            @delete="deleteOrderDrawing"
                            @edit="editDrawingConfiguration"
                            @select="updateSelection" />
                    </template>

                    <cart-order-product-item v-for="(item, key) in orderProducts"
                      ref="cartListItem"
                      :key="key"
                      :id="parseInt(item.id)"
                      :quantity="parseInt(item.quantity)"
                      :tabstop="keys.indexOf(key)+1"
                      :is-draft="isDraft"
                      @delete="deleteOrderProduct" />

                  </div>

                  <div v-if="hasSubtotal('bulk_discount')"
                    class="list-group-item shadow-none cart-list-item cart-custom-list-item p-2 pb-2">
                    <div class="cart-list-item-title bulk-discount-title">{{$t('cart.bulk_discount')}}</div>
                    <div class="cart-list-item-subtotal bulk-discount-price ml-2">
                      <div class="cart-list-item-subtotal-total-price">&euro; {{ getSubtotal('bulk_discount', false) }}</div>
                      <div class="small text-muted">&euro; {{ getSubtotal('bulk_discount', true) }} incl.</div></div>
                  </div>
                  <h3 v-if="orderCustomItemsCount === 0 && orderDrawingsCount === 0 && orderProductsCount === 0"
                    class="cart-empty-msg text-center p-4">
                    {{$t('cart.your_cart_is_empty')}}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="cart-list-col-settings col-md-5 col-lg-4 mt-0 mt-md-0">
          <order-priority-list
            v-if="isDraft"
            :show-vat="showVat"
            :updating="updatingPriority"
            :order-id="orderId"
            @updatePriority="updatePriority"/>

          <shipping-list
            :shipping-method="shippingMethod"
            :order-id="orderId"
            :is-draft="isDraft" />

          <div v-if="isDraft || coupons.length > 0"
            class="card shadow-1 mt-3">
            <div class="cart-main-block">
              <div class="card-title bg-light p-0 pl-2">{{$t('cart.coupon.coupon_title')}}</div>
            </div>
            <div class="card-body p-1 border-top">
              <div v-for="(coupon, key) in coupons" :key="key"
                class="coupon-container cart-list-totals-row border-bottom pb-1">

                <div class="coupon-label pl-1">
                  {{ coupon.name }}<br>
                  <a v-if="isDraft" href="#" class="text-small" @click="deleteCoupon(coupon.id)">{{$t('cart.coupon.remove')}}</a>
                </div>

                <div class="coupon-price border-left pr-1">
                  <div class="medium-price text-right">
                    {{ config.formatPrice(coupon.amount, false, true) }}
                  </div>
                  <div class="text-muted text-right">
                    {{ config.formatPrice(coupon.amount, true, true) }} {{$t('cart.coupon.incl')}}
                  </div>
                </div>

              </div>

              <div v-if="isDraft"
                class="cart-list-totals-row coupon-add-field-container">
                <div class="input-container pl-1 pb-1">
                  <input ref="addCouponInput"
                      type="text"
                      class="form-control form-control-sm"
                      maxlength="50"
                      :placeholder="$t('cart.enter_coupon_code')">
                </div>
                <div class="text-right pr-1">
                  <a href="#" class="ml-5" @click="addCoupon">{{$t('cart.coupon.add')}}</a>
                </div>
              </div>
              <div v-show="couponError" class="text-danger pl-1">{{this.couponError}}</div>
            </div>
          </div>

          <div class="card shadow-1 mt-3 mb-3">
            <div class="cart-main-block">
              <div class="card-title bg-light p-0 pl-2">{{$t('cart.total')}}</div>
            </div>
            <div class="card-body border-top">

              <div class="alert-warning py-1" v-if="cartErrors && cartErrors.length > 0">
                <ul class="ul-snijlab">
                  <template v-for="(item, key) in cartErrors">
                    <li :key="key" v-if="item"
                      :class="{'no-bullet': cartErrors.length === 1}">
                      {{ getMessage(item) }}
                      <i  v-if="!(hideHelp.indexOf(item) !== -1)"
                        class="material-icons compact info-icon"
                        @click="showHelp(item)">info</i></li>
                  </template>
                </ul>
              </div>

              <div class="cart-list-totals p-2">

                <div class="cart-list-totals-row mt-1 pt-1">
                  <div v-if="orderCustomItemsCount === 0 && orderDrawingsCount === 0 && orderProductsCount === 0">
                    {{$t('cart.your_cart_is_empty')}}
                  </div>
                  <div v-else>
                    {{$t('cart.total_excl_vat')}}
                    <br>
                    <template v-if="vatType === 'VAT21'">{{$t('cart.vat_21')}}</template>
                    <template v-else-if="showVat && vatType === 'ICP'">{{$t('cart.vat_0_vat_reversed')}}</template>
                    <template v-else-if="vatType === 'NONEU'">{{$t('cart.vat_not_applicable')}}</template>
                    <br>
                    <div class="pt-2 font-weight-bold">{{$t('cart.to_pay')}}</div>
                  </div>
                  <div class="total-to-pay-container">
                    <div class="text-right">{{ showOrderTotalExclVat }}</div>
                    <div class="text-right">{{ showOrderTotalVatOnly }}</div>
                    <div class="large-price pt-1 text-right">{{ showOrderTotal }}</div>
                  </div>
                </div>
                <div class="cart-list-totals-row"></div>
              </div>

              <div class="text-right border-top pt-2">
                <b-button
                  :to="{name: 'order-review', params: routeParams}"
                  size="sm"
                  class="mb-2 mr-2 p-2"
                  v-bind:class="{'btn-success':isValidCart, 'btn-light':!isValidCart}">
                  {{$t('cart.complete_order')}} <i class="material-icons compact">arrow_forward_ios</i>
                </b-button>
              </div>
            </div>
          </div>

          <template v-if="orderDrawingsCount > 4">
            <div class="to-top-spacer d-none d-md-block" />
            <b-button
              class="to-top-btn d-none d-md-block px-1 mb-3"
              variant="light"
              size="xs"
              @click="toTop" >
              {{$t('cart.to_delivery_options_button')}} <i class="material-icons compact">arrow_upward</i>
            </b-button>
          </template>

        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import CartListItem from 'src/components/cart/edit/CartListItem';
import CartCustomListItem from 'src/components/cart/edit/CartCustomListItem';
import OrderPriorityList from 'src/components/cart/priority/OrderPriorityList';
import ShippingList from 'src/components/cart/shipment/ShippingList';
import OrderStatusWarning from 'src/components/cart/edit/OrderStatusWarning';
import CartReviewErrorComponent from 'src/components/cart/review/CartReviewErrorComponent';
import DrawingConfigurationBatchEditor from 'src/components/drawingConfiguration/DrawingConfigurationBatchEditor';
import LoadingButton from 'src/components/tools/LoadingButton';
import TweenTools from 'src/lib/TweenTools';
import OrderCustomItemWidget from 'src/components/admin/OrderCustomItemWidget';
import LoadingWidgetFullWidth from 'src/components/tools/LoadingWidgetFullWidth';
import CartOrderProductItem from 'src/components/cart/edit/CartOrderProductItem';

export default {
  components: {
    CartOrderProductItem,
    LoadingButton,
    OrderStatusWarning,
    DrawingConfigurationBatchEditor,
    ShippingList,
    OrderPriorityList,
    CartListItem,
    OrderCustomItemWidget,
    CartCustomListItem,
    LoadingWidgetFullWidth,
  },
  data() {
    return {
      hideHelp: [
        'ORDER_DRAWING_CONFIGURATION_INVALID',
        'ORDER_DRAWING_CONFIGURATION_NO_MATERIAL',
        'ORDER_DRAWING_CONFIGURATION_UNAVAILABLE_MATERIAL',
        'ORDER_DRAWING_CONFIGURATION_NOT_APPROVED',
        'ORDER_EMPTY',
        'ORDER_MINIMUM_AMOUNT_NOT_MET',
      ],
      loading: true,
      updating: false,
      updatingPriority: false,
      updatingReference: false,
      selected: {},
      orderTotalAnimated: null,
      orderTotalAnimatedExclVat: null,
      orderTotalAnimatedVatOnly: null,
      batchDeleteLoading: false,
      batchSelectMaterialVisible: false,
      couponError: null,
    };
  },
  computed: {
    routeParams() {
      return {
        lang: this.$route.params.lang,
        orderId: this.orderId,
      };
    },
    orderId() {
      const currentId = parseInt(this.$route.params.orderId, 10);
      if (!currentId) {
        return parseInt(this.$store.state.settings.order_id, 10);
      }
      return currentId;
    },
    config() {
      return this.$config;
    },
    showVat() {
      return this.$store.state.settings.showVat;
    },
    selectedCount() {
      return Object.values(this.selected).length;
    },
    keys() {
      return Object.keys(this.orderDrawings);
    },
    data() {
      return this.$store.state.cart;
    },
    isLocked() {
      return this.$store.state.cart.status !== 'DRAFT';
    },
    isDraft() {
      return this.$store.state.cart.status === 'DRAFT';
    },
    canBePaid() {
      return this.$store.state.cart.status === 'SUBMITTED';
    },
    showDelivery() {
      return this.shippingMethod === 'SHIPPING';
    },
    priorityId() {
      return this.$store.state.cart.priorityId;
    },
    errors() {
      return this.$store.state.cart.errors;
    },
    shippingMethod() {
      return this.$store.state.cart.shippingMethod;
    },
    orderCustomItems() {
      return Object.values(this.$store.state.cart.orderCustomItems).filter((item) => item.name !== 'Dropshipping');
    },
    orderProducts() {
      return Object.values(this.$store.state.cart.orderProducts);
    },
    orderProductsCount() {
      return Object.values(this.$store.state.cart.orderProducts).length;
    },
    orderCustomItemsCount() {
      return Object.keys(this.orderCustomItems).length;
    },
    orderDrawings() {
      return this.$store.state.cart.orderDrawings;
    },
    orderDrawingsCount() {
      return Object.keys(this.$store.state.cart.orderDrawings).length;
    },
    cartErrors() {
      return this.$store.state.cart.orderErrors.filter((err) => err.startsWith('ORDER_DRAWING_CONFIGURATION'));
    },
    costTable() {
      return this.$store.state.cart.costTable;
    },
    orderTotalRaw() {
      if (Object.keys(this.$store.state.cart.costTable).length > 0) {
        return this.$config.getPrice(this.$store.state.cart.costTable.total, true);
      }
      return 0;
    },
    orderTotalRawExclVat() {
      if (Object.keys(this.$store.state.cart.costTable).length > 0) {
        return this.$config.getPrice(this.$store.state.cart.costTable.total, false);
      }
      return 0;
    },
    orderTotalRawVatOnly() {
      if (Object.keys(this.$store.state.cart.costTable).length > 0) {
        return this.$config.getVat(this.$store.state.cart.costTable.total);
      }
      return 0;
    },
    showOrderTotal() {
      if (this.orderTotalAnimated !== null) {
        return this.$config.currencyFormat.format(this.orderTotalAnimated / 100);
      }
      return this.$config.currencyFormat.format(this.orderTotalRaw / 100);
    },
    showOrderTotalExclVat() {
      if (this.orderTotalAnimatedExclVat !== null) {
        return this.$config.currencyFormat.format(this.orderTotalAnimatedExclVat / 100);
      }
      return this.$config.currencyFormat.format(this.orderTotalRawExclVat / 100);
    },
    showOrderTotalVatOnly() {
      if (this.orderTotalAnimatedVatOnly !== null) {
        return this.$config.currencyFormat.format(this.orderTotalAnimatedVatOnly / 100);
      }
      return this.$config.currencyFormat.format(this.orderTotalRawVatOnly / 100);
    },
    coupons() {
      return this.$store.state.cart.coupons;
    },
    vatType() {
      return this.$store.state.cart.vat.vatType;
    },
    hasCorrectOrder() {
      if (!this.$store.getters.loaded(['cart'])) {
        return true;
      }
      return this.$store.state.cart.orderId === this.orderId;
    },
    ready() {
      return this.$store.getters.loaded([
        'drawingProducts',
        'materials',
        'translations',
        'user',
        'drawingConfigurations',
        'cart',
      ]) && this.hasCorrectOrder;
    },
    cartEmpty() {
      return Object.keys(this.$store.state.cart.orderDrawings).length === 0;
    },
    isValidOrder() {
      return !(this.data.orderErrors && Object.keys(this.data.orderErrors).length > 0)
          || (this.data.addressWarnings && Object.keys(this.data.addressWarnings).length > 0)
          || (this.data.addressErrors && Object.keys(this.data.addressErrors).length > 0);
    },
    isValidCart() {
      return this.isValidOrder && !this.cartEmpty;
    },
    isExpressPriority() {
      return this.$store.state.cart.priorityId === 5;
    },
  },
  watch: {
    orderDrawings() {
      this.syncSelection();
    },
    orderTotalRaw(value, oldValue) {
      TweenTools.tweenNumber(value, oldValue, this, 'orderTotalAnimated');
    },
    orderTotalRawExclVat(value, oldValue) {
      TweenTools.tweenNumber(value, oldValue, this, 'orderTotalAnimatedExclVat');
    },
    orderTotalRawVatOnly(value, oldValue) {
      TweenTools.tweenNumber(value, oldValue, this, 'orderTotalAnimatedVatOnly');
    },
  },
  mounted() {
    if (this.ready && this.$store.state.cart.status !== 'DRAFT'
      && this.$store.state.cart.status !== 'SUBMITTED'
      && !this.$store.getters.isAdmin) {
      this.createNewOrder();
    }
    if (this.$route.params.openUploadModal === true) {
      this.$eventBus.$emit('upload');
    }
    this.$eventBus.$emit('gtm.openedCart', this.orderId);
  },
  methods: {
    toTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    addProduct() {
      this.$store.dispatch('addOrderProduct', {
        productId: 340,
        orderId: this.orderId,
      });
    },
    toggleAllSelected(value) {
      this.$refs.cartListItem.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item.selected = value;
      });
    },
    batchSelectMaterial() {
      this.batchSelectMaterialVisible = true;
    },
    batchDuplicate() {
      const keys = Object.keys(this.selected)
        .map((id) => parseInt(id, 10));
      this.$store.dispatch('duplicateConfigurations', {
        ids: keys,
        replaceInOrders: false,
      });
    },
    deleteOrderDrawing(component) {
      this.$store.dispatch('deleteOrderDrawing', {
        orderDrawingIds: [component.id],
        orderId: this.orderId,
      });
    },
    deleteOrderCustomItem(component) {
      this.$store.dispatch('deleteOrderCustomItem', {
        orderCustomItemIds: [component.id],
        orderId: this.orderId,
      });
    },
    deleteOrderProduct(component) {
      this.$store.dispatch('deleteOrderProduct', {
        orderProductId: component.id,
        orderId: this.orderId,
      });
    },
    showBatchDeleteModal() {
      this.$bvModal.msgBoxConfirm(this.$t('cartlist.batch_remove.remove_selected_designs_from_your_cart'), {
        title: false,
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t('cartlist.batch_remove.remove'),
        cancelTitle: this.$t('cartlist.batch_remove.cancel'),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then((value) => {
          if (value) {
            this.batchDeleteOrderDrawing();
          }
        });
    },
    batchDeleteOrderDrawing() {
      if (this.batchDeleteLoading) {
        return;
      }
      this.batchDeleteLoading = true;
      this.$store.dispatch('deleteOrderDrawing', {
        orderDrawingIds: Object.keys(this.selected),
        orderId: this.orderId,
      })
        .then(() => {
          this.syncSelection();
        })
        .finally(() => {
          this.batchDeleteLoading = false;
        });
    },
    addCoupon() {
      const couponCode = this.$refs.addCouponInput.value;
      if (couponCode === '') {
        this.couponError = this.$i18n.t('cart.coupon.enter_a_coupon_code');
        return;
      }
      this.$store.dispatch('addCoupon', {
        couponCode,
        orderId: this.orderId,
      })
        .then(() => {
          this.$refs.addCouponInput.value = '';
          this.couponError = null;
        }, (err) => {
          this.couponError = err;
        });
    },
    deleteCoupon(id) {
      this.couponError = null;
      this.$store.dispatch(
        'deleteCoupon',
        {
          couponId: id,
          orderId: this.orderId,
        },
      );
    },
    updatePriority(id) {
      this.updatingPriority = true;
      this.$store.dispatch('updateOrderPriority', {
        priorityId: id,
        orderId: this.orderId,
      })
        .then(() => {
          this.updatingPriority = false;
        });
    },
    getSubtotal(name, vat) {
      let total = this.costTable[name].amount;
      if (vat) {
        total += Object.values(this.costTable[name].vat)
          .reduce((sum, value) => sum + value, 0);
      }
      return new Intl.NumberFormat(this.$i18n.locale, { minimumFractionDigits: 2 }).format(total / 100);
    },
    hasSubtotal(name) {
      return this.costTable[name].amount !== 0;
    },
    editDrawingConfiguration(event) {
      this.$router.push({
        name: `${this.$route.name}-editor`,
        params: {
          orderId: this.$route.params.orderId,
          lang: this.$route.params.lang,
          productId: event.id,
        },
      });
    },
    updateSelection(value) {
      // expects { id: 123, selected: true }
      if (value.selected) {
        Vue.set(this.selected, value.id, true);
      } else {
        Vue.delete(this.selected, value.id);
      }
    },
    syncSelection() {
      Object.keys(this.selected)
        .forEach((item) => Vue.delete(this.selected, item));
      this.$nextTick(() => {
        if (this.$refs.cartListItem) {
          this.$refs.cartListItem.forEach((value) => {
            if (value.is_drawing_product && value.selected) {
              Vue.set(this.selected, value.id, true);
            }
          });
        }
      });
    },
    downloadQuote() {
      if (this.isValidOrder) {
        window.open(`${this.$config.apiUrl}/document/quote/${this.$store.state.cart.orderId}?${Date.now()}`,
          '_blank');
      } else {
        this.$vuedals.open({
          title: this.$i18n.t('cart.cant_generate_invoice'),
          component: CartReviewErrorComponent,
          size: 'xl',
        });
      }
    },
    createNewOrder() {
      this.$store.dispatch('createNewOrder')
        .then(() => {
          this.$router.push({
            name: 'order-cart',
            params: {
              lang: this.$route.params.lang,
              orderId: this.$store.state.cart.orderId,
            },
          });
        });
    },
    goToPayment() {
      this.$router.push({
        name: 'order-payment',
        params: {
          lang: this.$route.params.lang,
          orderId: this.$store.state.cart.orderId,
        },
      });
    },
    getMessage(item) {
      return this.$store.state.popups[`cart.${item}`.toLowerCase()].title;
    },
    showHelp(item) {
      this.$eventBus.$emit('snlb.showHelp', `cart.${item}`);
    },
    updateVat({ value, promise }) {
      this.$store.dispatch('updateVat', value)
        .finally(() => {
          promise.resolve();
        });
    },
  },
};
</script>
<style lang="scss">

.cart-list {

  li.no-bullet {
    list-style: none;
    padding: 0;
  }

  .cart-empty-msg {
    font-size: 1rem;
  }

  .cart-upload-block .btn {
    white-space: normal;
  }

  .btn-container-center {
    align-content: center;
  }

  .cart-reference-widget {
    .input-row-full, .form-control {
      padding-top: 0;
    }
  }

  .card-sticky {
    position: sticky;
    top: 107px;
    z-index: 6;
  }

  .medium-price {
    font-size: 0.9rem;
  }

  .large-price {
      font-size: 1.5em;
  }

  .coupon-container {
    display: grid;
    grid-template-columns: 1fr 130px;
    justify-content: flex-start;

    .coupon-label {
      grid-column: 1/2;
    }
    .coupon-price {
      grid-column: 2/3;
    }
  }

  .coupon-add-field-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .input-container {
      width: 100%;
    }
  }

  .cart-list-header {
    display: grid;
    align-items: center;

    .cart-list-header-select-all {
      grid-row: 1;
      grid-column: 1;
      display: flex;
    }

    .cart-list-header-actions {
      grid-row: 1;
      grid-column: 2;
      display: flex;
      justify-content: flex-end;

      .btn {
        min-width: auto;
      }
    }
  }

  .cart-list-totals {
    .cart-list-totals-row {
      display: flex;
      justify-content: space-between;
    }
  }

  .upload-hint h3 {
    border: 2px dashed #777;
    border-radius: 0.5rem;
    display: inline-block;
    font-size: 1.8em;
    line-height: 100%;
    margin: auto;
    max-width: 400px;
    width: auto;
  }

  .upload-button-divider {
    font-size: 1.2rem;
  }

  .bulk-discount-title {
    grid-row: 1;
  }

  .bulk-discount-price {
    grid-row: 1;
  }

  @media (max-width: 992px) {
    .cart-upload-block .upload-hint h3 {
      font-size: 1.6em;

      .filetypes-msg {
        font-size: 0.8em;
      }
    }
  }

  .cart-list-col-settings {
    .to-top-spacer {
      height: 300px;
      background-color: #fff;
    }

    .to-top-btn {
      position: sticky;
      top: calc(100% - 60px);
      z-index: 0;
      float: right;
    }
  }
}

</style>
