<!--suppress HtmlUnknownTarget -->
<template>
  <div class="main-menu-wrapper" data-nosnippet>
    <nav class="main-menu" :class="{'show-mobile-menu': mobileMenuVisible}">

      <div class="d-md-none btn btn-outline-dark close-btn"
        @click="hideMobileMenu">
          <i class="material-icons">close</i></div>

      <!-- mobile logo -->
      <div class="menu-left-mobile d-md-none bg-light-1">
        <ul class="main-menu-items">
          <li class="menu-logo logo-snijlab-grey mr-2">
            <!-- <router-link to="/" aria-label="Home"></router-link> -->
          </li>
        </ul>
      </div>

      <div class="menu-right-mobile pr-3 d-md-none bg-light-1" :class="{'d-none': mobileMenuVisible}">
        <ul class="main-menu-items">
          <li class="menu-item pl-0 mr-2">
            <div class="btn btn btn-outline-dark p-2" @click="showMobileMenu">
              <i class="material-icons">menu</i>
            </div>
          </li>
          <!-- <li class="menu-item menu-search pl-0">
            <router-link class="btn btn-outline-light"
              :to="{ path: `/${this.$i18n.locale}/search?q=${searchString}` }" >
              <i class="material-icons">search</i></router-link>
          </li> -->
        </ul>
      </div>

      <div class="menu-left bg-light-1">
        <ul class="main-menu-items mx-auto mx-md-0"
          :class="{
            'align-items-center': mobileMenuVisible,
            'show-mobile-mat-menu': mobileMatMenuVisible,
            'show-mobile-quote-menu': mobileQuoteMenuVisible,
            'show-mobile-profile-menu': mobileProfileMenuVisible}">

          <!-- logo -->
          <li class="menu-logo logo-snijlab-grey mr-2">
            <!-- <router-link to="/" aria-label="Home"></router-link> -->
          </li>

          <!-- Home -->
          <li class="menu-item" v-show="noSubmenuOpen" v-if="this.$i18n.locale === 'nl'">
            <a href="https://snijlab.nl" target="_blank">
              <i class="material-icons">undo</i>
              {{$t('mainmenu.home')}}
            </a>
          </li>
          <li class="menu-item" v-show="noSubmenuOpen" v-else>
            <a href="https://snijlab.nl/en" target="_blank">
              <i class="material-icons">undo</i>
              {{$t('mainmenu.home')}}
            </a>
          </li>

          <!-- Dashboard -->
          <li v-if="isSignedIn" class="my-dashboard menu-item d-none d-xl-block">
            <router-link :to="{name:'dashboard', params: { lang: $i18n.locale} }">
              <i class="material-icons">dashboard</i>
              {{$t('mainmenu.popup.dashboard')}}</router-link>
          </li>

          <li v-if="isSignedIn" class="my-products menu-item d-none d-xl-block">
            <router-link :to="{name:'products-list-me', params: { lang: $i18n.locale} }">
              <i class="material-icons">folder_open</i>
              {{$t('mainmenu.popup.my_designs')}}</router-link>
          </li>

          <li v-if="isSignedIn" class="my-orders menu-item d-none d-xl-block">
            <router-link :to="{name: 'orders-list-me', params: { lang: $i18n.locale} }">
              <i class="material-icons">history</i>
              {{$t('mainmenu.popup.orders')}}</router-link>
          </li>

          <li v-if="isSignedIn" class="my-settings menu-item d-none d-xl-block">
            <router-link :to="{name: 'user-settings-me', params: { lang: $i18n.locale} }">
              <i class="material-icons">settings</i>
              {{$t('mainmenu.popup.my_details')}}</router-link>
          </li>

          <li v-if="isSignedIn" class="my-settings menu-item d-none d-xl-block">
            <trustpilot location="menu" />
          </li>

          <!-- contact -->
          <!-- <li class="menu-item" v-show="noSubmenuOpen" v-if="this.$i18n.locale === 'nl'">
            <a href="https://snijlab.nl/pages/contact">
              {{$t('mainmenu.contact')}}
            </a>
          </li>
          <li class="menu-item" v-show="noSubmenuOpen" v-else>
            <a href="https://snijlab.nl/en/pages/contact">
              {{$t('mainmenu.contact')}}
            </a>
          </li> -->
        </ul>
      </div>

      <div class="menu-right bg-light-1">
        <ul class="main-menu-items mx-auto mx-md-0 mt-0 mt-md-2">

          <!-- uploadbutton desktop -->
          <li v-if="isSignedIn" class="d-none d-lg-inline-block p-0">
            <a v-if="isCart" class="d-inline-block btn btn-secondary text-white"
              href="#" @click.prevent="orderBtnClicked">{{$t('mainmenu.upload')}}</a>
            <a v-else class="d-inline-block btn btn-secondary text-white"
              href="#" @click.prevent="orderBtnClicked">{{$t('mainmenu.order')}}</a>
          </li>

          <!-- uploadbutton mobile -->
          <li v-show="!mobileProfileMenuVisible && isSignedIn" class="d-md-none menu-item menu-item-upload w-100 p-0">
            <a v-if="isCart" href="#" class="btn btn-secondary text-white d-block"
              @click.prevent="orderBtnClicked">{{$t('mainmenu.upload')}}</a>
            <a v-else href="#" class="btn btn-secondary text-white d-block"
              @click.prevent="orderBtnClicked">{{$t('mainmenu.order')}}</a>
          </li>

          <!-- loginbutton desktop -->
          <li class="d-none d-md-inline-block" v-if="!isSignedIn">
            <a href="#signin" @click.prevent="showSignIn" class="btn btn-success text-white">{{$t('mainmenu.login')}}</a>
          </li>

          <!-- loginbutton mobile -->
          <li class="d-md-none w-100 p-0 mb-2" v-if="!isSignedIn">
            <a href="#signin" class="d-block mt-2 btn btn-success text-white"
              @click.prevent="showSignIn">{{$t('mainmenu.login')}}</a>
          </li>

          <!-- cart icon desktop -->
          <li class="d-none d-md-inline-block menu-item menu-cart" v-if="isSignedIn">
            <router-link
              :to="{ name: 'order-cart', params: { lang: $i18n.locale, orderId: orderId } }">
              <i class="material-icons">shopping_cart</i>
              <span class="badge badge-snijlab p-1">{{ cartCount }}</span>
            </router-link>
          </li>

          <!-- cart icon mobile -->
          <li v-if="isSignedIn && !mobileProfileMenuVisible"
            class="mobile-menu-cart-login d-md-none menu-item
            w-100 p-0 mt-2 mb-3 d-flex flex-row justify-content-between">
            <router-link class="btn btn-outline-dark d-block" @click.native="hideMobileMenu"
              :to="{ name: 'order-cart', params: { lang: $i18n.locale, orderId: orderId } }">
              <i class="material-icons compact">shopping_cart</i>
              <span class="badge-snijlab-inverted px-1">{{ cartCount }}</span>
            </router-link>

            <div class="btn btn-outline-dark d-block" @click="showMobileProfileMenu">
              <i class="material-icons compact">person</i>
            </div>
          </li>

          <li v-if="isSignedIn" class="menu-item menu-popup w-100 w-md-auto"
            v-click-outside="hideProfileMenuClickedOutside" @click="hideMobileMenu">
            <div class="d-none d-md-block" @click="toggleProfileMenu">
              <a class="hamburgerIcon px-0" href="#">
                <i class="material-icons pr-0">person</i>
                <i v-show="!profileMenuVisible" class="material-icons pl-0 d-none"
                :class="{'d-sm-block' : !profileMenuVisible}">keyboard_arrow_down</i>
                <i v-show="profileMenuVisible" class="material-icons pl-0 d-none"
                :class="{'d-sm-block' : profileMenuVisible}">keyboard_arrow_up</i></a>
            </div>

            <div v-show="profileMenuVisible || mobileProfileMenuVisible"
              class="popup-menu bg-light">
              <ul>
                <li class="menu-item menu-500" v-if="!mobileProfileMenuVisible">
                  <router-link :to="{ name: 'materials-view' }">{{$t('mainmenu.materials')}}</router-link>
                </li>

                <li class="menu-item menu-400" v-if="!mobileProfileMenuVisible">
                  <router-link :to="{ name: 'kb-page', params: { route: 'algemene-informatie' } }">
                    {{$t('mainmenu.help')}}</router-link>
                </li>

                <li class="menu-item menu-300" v-if="!mobileProfileMenuVisible">
                  <router-link :to="{ name: 'contact-page'}">{{$t('mainmenu.contact')}}</router-link>
                </li>

                <li class="menu-item menu-300" v-if="!mobileProfileMenuVisible">
                  <router-link :to="{ name: 'quote-page'}">{{$t('mainmenu.request_quote')}}</router-link>
                </li>

                <!-- <li class="menu-item menu-1200" v-if="!mobileProfileMenuVisible">
                  <form v-on:submit.prevent="submitSearch">
                    <input type="text" class="menu-search-input-el input" autocomplete="off"
                      :placeholder="$t('search.placeholder')" v-model="searchString" >
                  </form>
                  <router-link :to="{ path: `/${this.$i18n.locale}/search`, query: {q: searchString } }" class="searchIcon">
                    <i class="material-icons">search</i></router-link>
                </li> -->

                <li class="menu-1200"></li>

                <li v-if="userLoaded" v-show="!mobileProfileMenuVisible" class="username-item pl-2">
                    {{$t('mainmenu.popup.hello')}}, {{this.$store.state.profile.first_name}}
                </li>

                <template v-if="$store.getters.isAdmin">
                  <li class="my-cart menu-item menu-item-admin border-bottom" @click="hideProfileMenu">
                    <a href="/admin.php">
                      <i class="material-icons">build</i> Admin backend</a>
                  </li>

                  <li class="my-cart menu-item menu-item-admin border-bottom" @click="hideProfileMenu">
                    <a href="https://grav.snijlab.nl/admin/pages" target="_blank">
                      <i class="material-icons">description</i> Grav</a>
                  </li>

                  <li class="my-cart menu-item menu-item-admin border-bottom" @click="hideProfileMenu">
                    <router-link :to="{name: 'create-user-as-admin', params: { lang: $i18n.locale } }">
                      <i class="material-icons">add</i> {{$t('admin.create_new_client')}}</router-link>
                  </li>
                </template>

                <li class="my-dashboard menu-item" @click="hideProfileMenu">
                  <router-link :to="{name:'dashboard', params: { lang: $i18n.locale} }">
                    <i class="material-icons">dashboard</i> {{$t('mainmenu.popup.dashboard')}}</router-link>
                </li>

                <li class="my-products menu-item" @click="hideProfileMenu">
                  <router-link :to="{name:'products-list-me', params: { lang: $i18n.locale} }">
                    <i class="material-icons">folder_open</i> {{$t('mainmenu.popup.my_designs')}}</router-link>
                </li>

                <li class="my-orders menu-item" @click="hideProfileMenu">
                  <router-link :to="{name: 'orders-list-me', params: { lang: $i18n.locale} }">
                    <i class="material-icons">history</i> {{$t('mainmenu.popup.orders')}}</router-link>
                </li>

                <li v-if="$store.getters.isAdmin" class="my-drafts menu-item" @click="hideProfileMenu">
                  <router-link :to="{name: 'drafts-list-me', params: { lang: $i18n.locale} }">
                    <i class="material-icons">list_alt</i> {{$t('mainmenu.popup.concept_orders')}}</router-link>
                </li>

                <li class="my-settings menu-item" @click="hideProfileMenu">
                  <router-link :to="{name: 'user-settings-me', params: { lang: $i18n.locale} }">
                    <i class="material-icons">settings</i> {{$t('mainmenu.popup.my_details')}}</router-link>
                </li>

                <li class="logout menu-item mb-2" @click="hideProfileMenu">
                  <a href="#" @click.prevent="logout">
                    <i class="material-icons">exit_to_app</i> {{$t('mainmenu.popup.logout')}}
                    <span v-if="$store.getters.isAdmin" class="pl-1">Admin</span>
                  </a>
                </li>
              </ul>
            </div>
          </li>

          <!-- desktop -->
          <li class="menu-lang d-none d-md-flex">
            <a href="#" @click.prevent="updateLang('nl')"
            :class="{'selected-language': this.$i18n.locale === 'nl'}">NL</a>
            <!-- <span class=" px-1">/</span> -->
            <a href="#" @click.prevent="updateLang('en')"
            :class="{'selected-language': this.$i18n.locale === 'en'}">EN</a>
          </li>

          <!-- mobile -->
          <li class="menu-lang pt-1 d-md-none d-flex flex-row justify-content-between align-items-center">
            <a href="#" @click.prevent="updateLang('nl')" class="btn btn-flat w-40">{{ $t('mainmenu.nederlands') }}</a>
            <div class="spacer">&nbsp;</div>
            <!-- <span class=" px-1">/</span> -->
            <a href="#" @click.prevent="updateLang('en')" class="btn btn-flat w-40">{{ $t('mainmenu.english') }}</a>
          </li>

        </ul>
      </div>
    </nav>
  </div>
</template>
<script>
import vClickOutside from 'v-click-outside';
import Trustpilot from 'src/components/utils/Trustpilot';

export default {
  components: {
    Trustpilot,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    orderId: {
      type: Number,
      required: true,
    },
    isSignedIn: {
      type: Boolean,
      default: false,
    },
    cartCount: {
      type: Number,
      default: 0,
    },
    isDark: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // menuOpen: false,
      showSigninButton: false,
      profileMenuVisible: false,
      uploadAfterLogin: false,
      searchString: '',
      mobileMenuVisible: false,
      mobileWhatMenuVisible: false,
      mobileMatMenuVisible: false,
      mobileQuoteMenuVisible: false,
      mobileHelpMenuVisible: false,
      mobileAboutMenuVisible: false,
      mobileProfileMenuVisible: false,
    };
  },
  watch: {
    isSignedIn(value) {
      this.showSignedIn = value;
      if (value === true && this.npmmAfterLogin) {
        this.$router.push({
          name: 'order-cart',
          params: {
            lang: this.$i18n.locale,
            orderId: this.orderId,
            openUploadModal: true,
          },
        });
      }
    },
    // $route: {
    //   handler() {
    //     this.mobileMenuVisible = false;
    //   },
    //   deep: true,
    // },
  },
  computed: {
    // route() {
    //   return this.$route.params.route;
    // },
    userLoaded() {
      return this.$store.getters.loaded([
        'user',
      ]);
    },
    isCart() {
      return this.$route.name && this.$route.name.includes('order') && this.$route.name !== 'orders-list-me';
    },
    noSubmenuOpen() {
      return !this.mobileMatMenuVisible && !this.mobileProfileMenuVisible && !this.mobileHelpMenuVisible
        && !this.mobileQuoteMenuVisible && !this.mobileWhatMenuVisible && !this.mobileAboutMenuVisible;
    },
  },
  methods: {
    showMobileMenu() {
      this.mobileMenuVisible = true;
      this.mobileWhatMenuVisible = false;
      this.mobileMatMenuVisible = false;
      this.mobileHelpMenuVisible = false;
      this.mobileAboutMenuVisible = false;
      this.mobileProfileMenuVisible = false;
    },
    hideMobileMenu() {
      this.mobileMenuVisible = false;
      this.mobileWhatMenuVisible = false;
      this.mobileMatMenuVisible = false;
      this.mobileHelpMenuVisible = false;
      this.mobileAboutMenuVisible = false;
      this.mobileProfileMenuVisible = false;
    },
    showMobileProfileMenu() {
      if (this.mobileMenuVisible) {
        this.mobileProfileMenuVisible = true;
        this.mobileWhatMenuVisible = false;
        this.mobileMatMenuVisible = false;
        this.mobileHelpMenuVisible = false;
        this.mobileAboutMenuVisible = false;
      }
    },
    showMobileWhatMenu() {
      if (this.mobileMenuVisible) {
        this.mobileProfileMenuVisible = false;
        this.mobileWhatMenuVisible = true;
        this.mobileMatMenuVisible = false;
        this.mobileHelpMenuVisible = false;
        this.mobileAboutMenuVisible = false;
      }
    },
    showMobileMatMenu() {
      if (this.mobileMenuVisible) {
        this.mobileProfileMenuVisible = false;
        this.mobileWhatMenuVisible = false;
        this.mobileMatMenuVisible = true;
        this.mobileHelpMenuVisible = false;
        this.mobileAboutMenuVisible = false;
      }
    },
    showMobileHelpMenu() {
      if (this.mobileMenuVisible) {
        this.mobileProfileMenuVisible = false;
        this.mobileWhatMenuVisible = false;
        this.mobileMatMenuVisible = false;
        this.mobileHelpMenuVisible = true;
        this.mobileAboutMenuVisible = false;
      }
    },
    showMobileAboutMenu() {
      if (this.mobileMenuVisible) {
        this.mobileProfileMenuVisible = false;
        this.mobileWhatMenuVisible = false;
        this.mobileMatMenuVisible = false;
        this.mobileHelpMenuVisible = false;
        this.mobileAboutMenuVisible = true;
      }
    },
    submitSearch() {
      if (this.searchString !== this.$route.query.q) {
        this.profileMenuVisible = false;
        this.$router.push({
          name: 'search-view',
          query: {
            q: this.searchString,
          },
        });
      }
    },
    updateLang(value) {
      let q = '';
      if (this.$store.getters.isAdmin) {
        if ('userId' in this.$route.query) {
          q = `?userId=${this.$route.query.userId}`;
        }
      }
      window.location = `/${value}/${this.$route.path.substring(4)}${q}`;
    },
    hideProfileMenuClickedOutside() {
      this.profileMenuVisible = false;
    },
    hideProfileMenu() {
      this.profileMenuVisible = false;
      this.mobileProfileMenuVisible = false;
      this.mobileWhatMenuVisible = false;
      this.mobileMatMenuVisible = false;
      this.mobileHelpMenuVisible = false;
      this.mobileAboutMenuVisible = false;
    },
    toggleProfileMenu() {
      this.profileMenuVisible = !this.profileMenuVisible;
    },
    upload() {
      this.$eventBus.$emit('upload');
    },
    showSignIn() {
      this.uploadAfterLogin = false;
      this.$eventBus.$emit('showLogin');
    },
    logout() {
      this.$eventBus.$emit('logout');
    },
    orderBtnClicked() {
      this.hideMobileMenu();
      if (this.isSignedIn) {
        if (this.$route.name === 'order-cart') {
          this.$eventBus.$emit('upload');
        } else {
          this.$router.push({
            name: 'order-cart',
            params: {
              lang: this.$i18n.locale,
              orderId: this.orderId,
              openUploadModal: true,
            },
          });
        }
      } else {
        this.showSignIn();
        this.uploadAfterLogin = true;
      }
    },
  },
};
</script>
<style lang="scss">
  .main-menu-wrapper {
    width: 100%;
    position: fixed;
    z-index: 100;
    top: 0px;
    left: 0px;

    .selected-language {
      font-weight: 400;
    }

    .popup-menu {
      z-index: 200;
      .material-icons {
        padding-right: .6rem;
      }
      ul {
        list-style: none;
        text-indent: 0;
        padding: 0;
        margin: 0;
      }
    }

    .dropdown-main-item {
      .item-and-dropdown-container {
        position: relative;
        // overflow: visible;
        .no-pointer {
          cursor: default;
        }
      }
      @media(min-width: 768px) {
        &:hover {
          .dropdown-container {
            display: inline-block;
          }
        }
      }
      .dropdown-container {
        min-width: 220px;
        background-color: #f1f1f1;
        position: absolute;
        display: none;

        @media(min-width: 768px) {
          padding-top: 16px;
        }

        ul li {
          list-style-type: none;
        }

        ul {
          padding: 0;
        }
      }
    }

    .dropdown.no-icon .dropdown-toggle::after {
      display: none;
    }

    .btn-group.no-box {
      box-shadow: none;
    }

    .main-menu-logo {
      width: 10rem;
    }

    .logo-snijlab {
      background-image: url('~@/assets/logo/snijlabweb.svg');
      background-repeat: no-repeat no-repeat;
      background-position: center center;
    }

    .logo-snijlab-grey {
      background-image: url('~@/assets/logo/snijlab-logo-grey.svg');
      background-repeat: no-repeat no-repeat;
      background-position: center center;
    }

    .menu-left {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-items: flex-start;
    }

    .menu-right {
      width: 100%;
      display: flex;
      // align-items: center;
      // justify-items: flex-end;
      // justify-self: flex-end;
      justify-content: flex-end;
    }

    .menu-popup {
      position: relative;
    }

    .username-item {
      font-size: 1rem;
      font-weight: 500;
      padding: 0.5rem;
    }

    .main-menu-items .menu-popup {
      .menu-1200, .menu-600, .menu-500, .menu-400, .menu-300 {
        display: none;
      }
    }

    .main-menu-items {
      .menu-1200, .menu-600, .menu-500, .menu-400, .menu-300 {
        display: flex;
      }
      padding-left: 0;
    }

    .menu-separator {
      margin: 5px;
      border-bottom: 1px solid #aaaaaa;
    }

    .hamburgerIcon .material-icons {
      font-size: 1.5rem;
    }

    .menu-title{
      font-weight: 500;
    }

    // @media (max-width: 1200px) {
      .main-menu-items {
        .menu-logo.logo-snijlab {
          width: 115px;
          margin-left: 1.1rem;
        }

        .menu-item a {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          // display: block;
          &.hamburgerIcon {
            display: flex;
          }
        }
      }

      .main-menu {
        .popup-menu .menu-1200 {
          display: flex;
        }
      }
    // }

    @media (max-width: 992px) {
    //   .main-menu .main-menu-items {
    //     .menu-item a {
    //       font-size: 0.7rem;

    //     }
        .menu-logo.logo-snijlab {
          width: 115px;
          margin-left: .8rem;
        }
    //   }
    }

    .popup-menu {
      position: absolute;
      right: 0;
      top: 62px;
      width: 250px;
      z-index: 200;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);

      ul li {
        list-style-type: none;
      }

      ul {
        padding: 0;
      }

      li.search {
        display: none;
        /*display: flex;*/
        padding: 5px;
      }

      li.language {
        display: flex;
        span {
          padding: 10px;
        }
        a {
          width: 33%;
        }
      }
    }

    @media(max-width: 767px) {

      .menu-left,
      .menu-right,
      .close-btn {
        display: none;
      }

      .menu-right-mobile {
        display: flex;
        justify-content: flex-end;
        .btn {
          min-width: 1px;
        }
      }

      .main-menu.show-mobile-menu {
        z-index: 100;
        width: 100vw;
        height: 100vh;
        background-color: white;
        display: flex;
        flex-direction: column;

        .menu-logo {
          display: none;
        }

        .close-btn {
          position: fixed;
          right: 1rem;
          top: 1rem;
          min-width: 40px;
          max-width: 40px;
          width: 40px;
          min-height: 40px;
          max-height: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .menu-left,
        .menu-right {
          display: flex;
          flex-direction: column;

          .popup-menu {
            position: relative;
            top: 0;
            box-shadow: none;
            background-color: transparent !important;
            background-color: #ffdd00;

            .material-icons {
              margin-top: -3px;
            }

            .menu-item {
              display: block;
            }

            .menu-item-admin {
              background-color: #424242;
              a:link, a:hover, a:active, a:visited {
                color: white;
              }
            }
          }

        }

        .badge-snijlab-inverted {
          background-color: #363636;
          color: #ffdd00;
          height: 1.5rem;
          width: 1.5rem;
        }

        .menu-left .main-menu-items {
          // border-bottom: 1px solid #000;
          margin-top: 80px;

          &.show-mobile-profile-menu {
            border: none;
          }

          &.show-mobile-mat-menu,
          &.show-mobile-profile-menu {
            margin-top: 40px;
          }
        }

        .mobile-mat-menu-back-btn,
        .mobile-profile-menu-back-btn {
          height: 40px;
          background-color: #ffdd00;
        }
        .mobile-profile-menu-back-btn .hello-text {
          padding-top: 2px;
        }

        .main-menu-items {
          .mobile-menu-cart-login {
            .btn {
              font-size: 24px;
              width: 48%;
            }
          }

          flex-direction: column;
          width: 100%;
          max-width: 300px;

          .menu-item {
            display: inline-block;

            .badge {
              margin-top: 0;
              font-size: inherit;
            }

            a:link, a:hover, a:active, a:visited {
              color: #363636;
              font-size: 20px;
              font-weight: 400;
              line-height: 16px;
              text-align: center;
              padding: .6rem;
            }
          }
        }

        .dropdown-container {
          position: relative;
          background: transparent;
          text-align: center;

          .menu-item {
            margin-left: 1rem;
          }
        }
          .dropdown-main-item.show-mobile-profile-menu {
            width: 100%;
          }

        .popup-menu {
          width: 100%;
          ul {
            padding: 0;
          }
        }

        .menu-lang {
          margin-top: 2rem;
          border-top: 1px solid #000;
          width: 100%;
          min-height: 70px;
          margin: auto;

          a {
            color: #000;
            text-decoration: none;
         }

          .spacer {
            width: 1px;
            height: 40px;
            background-color: #000;
          }
        }
      }
    }

    .menu-lang {
      margin-left: 0.5rem;
      margin-right: 1.5rem;
      display: flex;
      flex-direction: column;

      a {
        color: #363636;
        // opacity: 0.8;
      }

      a:hover, a:focus, a:active {
        color: #363636;
      }
      // .divider {
      //   display: none;
      // }
    }

    .main-menu {
      z-index: 101;
      // background-color: #f1f1f1;
      color: #363636;
      height: 75px;
      width: 100vw;
      display: grid;
      grid-template-columns: 2fr 1fr;
      min-width: 280px;
    }

    .main-menu-items {
      margin-top: 15px;
      list-style-type: none;
      display: flex;
      align-items: center;

      .menu-item.menu-cart {
        position: relative;
        padding-right: 0.35rem;
        padding-left: 0.15rem;

        a {
          opacity: 1;
        }

        .badge {
          font-size: .6rem;
          height: 20px;
          margin-top: -10px;
          padding: 2px;
        }
      }

      .menu-logo {
        width: 150px;
        height: 35px;
      }

      .menu-logo a, .menu-logo button {
        display: flex;
        width: 100%;
        height: 100%;
      }

      .menu-search-input {
        align-items: center;
        display: flex;
        color: #363636;
        padding: 0;
        position: relative;
        @media (min-width: 1400px) {
          display: inline-block !important;
        }
      }

      .menu-search-input-el {
        max-width: 150px;
        padding: 10px;
        height: 2.1rem;
        background-color: #fff;

        border: none;
        color: #fff;

        &::placeholder {
          color: #ccc;
        }

        font-weight: lighter;

        &:focus {
          outline: rgba(255, 228, 0, 0.5) 1px solid;
        }
      }

      .menu-item {
        &.menu-sign-in {
          min-width: 120px;
          text-align: center;
          a {
            font-weight: 500;
            color: white !important;
            background: #00b900;
          }
          // padding: 0.2rem;
          border-radius: 2px;
        }

        &.menu-item-upload {
          border: 1px solid #363636;
          min-width: 120px;
          text-align: center;
          a {
            color: #363636;
            font-weight: 500;
            padding-left: 20px;
            padding-right: 20px;
            opacity: 1;
          }

          border-radius: 2px;
        }

        .material-icons {
          line-height: 1.1rem;
        }

        &.no-fade {
          a {
            opacity: 1;
          }
        }

        a {
          display: flex;
          padding: 0.5rem;
          color: rgb(54, 54, 54);
          // font-weight: lighter;
          font-size: 0.8rem;
          font-weight: 400;
        }

        &:hover {
          color: rgb(54, 54, 54);

          a {
            opacity: 0.8;
            text-decoration: none;
          }
        }

        a:focus, a:hover, a:active {
          text-decoration: none;
        }

        // a:active {
        //   outline: 1px dotted rgba(255, 228, 0, 0.5);
        // }

        // &:focus {
        //   outline: 1px dotted #ccc;
        // }

        &.active {
          a {
            opacity: 1;
            font-weight: bolder;
          }
        }

        &.menu-item-admin {
          background-color: #ffe400;
          a {
            color: #000;
          }
          &:hover {
            background-color: #e6cf00;
          }
        }
      }
    }
  }

</style>
