export default {
  date: {
    getTime(dateString, locale = 'nl') {
      // eslint-disable-next-line no-param-reassign
      locale = 'nl'; // ignore given locale and always use 24h clock
      return new Date(dateString).toLocaleTimeString(locale, {
        hour: '2-digit',
        minute: '2-digit',
      });
    },
    getRelativeDay(value, locale) {
      const date = new Date(value);
      const today = new Date();
      const tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);
      // always use nl time? / 24h time?
      if (date.toDateString() === today.toDateString()) {
        const values = {
          nl: 'vandaag',
          en: 'today',
          de: 'Heute',
        };
        return values[locale];
      }
      if (date.toDateString() === tomorrow.toDateString()) {
        const values = {
          nl: 'morgen',
          en: 'tomorrow',
          de: 'Morgen',
        };
        return values[locale];
      }
      const fulldate = date.toLocaleDateString(locale, {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
      });
      return `${fulldate}`;
    },
    getRelativeDayShort(value, locale) {
      const date = new Date(value);
      const today = new Date();
      const tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);
      const fulldate = date.toLocaleDateString(locale, {
        weekday: 'short',
        day: 'numeric',
        month: 'short',
      });
      return `${fulldate}`;
    },
  },
  getCookie(cname) {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  },
  wrap_around_iframe(html) {
    return html.replace(/(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/ig,
      '<div class="embed-responsive embed-responsive-16by9">$&:</div>');
  },
};
