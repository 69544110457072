<template>
  <div class="cart-reference-widget">
    <form-async-widget-input
      :value="referenceValue"
      v-bind="form"
      class="p-0"
      :show-label-when-empty="true"
      :max-length="50"
      @update="updateValue"/>
  </div>
</template>

<script>
import FormAsyncWidgetInput from 'src/components/form-async/FormAsyncWidgetInput';

export default {
  components: {
    FormAsyncWidgetInput,
  },
  props: {
    order_id: {
      type: Number,
      required: true,
    },
    use_label: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    referenceValue() {
      return this.$store.state.cart.yourReference;
    },
    placeholderText() {
      if (this.$store.state.cart.hasDropshipping) {
        return this.$i18n.t('order_review.visible_to_your_client');
      }
      return this.$i18n.t('order_review.your_reference_or_purchase_number_optional');
    },
    form() {
      return {
        label: this.use_label === true ? this.$i18n.t('order_review.order_reference') : ' ',
        placeholder: this.placeholderText,
        help: '',
        error: '',
        required: false,
      };
    },
  },
  methods: {
    updateValue({ promise, value }) {
      this.$store.dispatch(
        'updateCartField',
        { orderId: this.order_id, field: 'your_reference', value },
      ).then(() => promise.resolve());
    },
  },
};
</script>

<style lang="scss">
  .cart-reference-widget {
    .input-icon-front {
      top: -0.4rem;
    }
    input {
      font-size: 0.65rem;
    }
  }
</style>
