<template>
  <div class="drawing-product-batch-list form-group products" v-if="material">
    <div v-for="(item) in availableGroups"
         :key="item.keyword">
      <drawing-product-batch-group v-if="typeof selectedProducts[item.keyword] !== 'undefined'"
        :keyword="item.keyword"
        :selected-id="selectedProducts[item.keyword]"
        @update="updateProduct" >
      </drawing-product-batch-group>
    </div>
  </div>
</template>

<script>
import DrawingProductBatchGroup from 'src/components/drawingConfiguration/DrawingProductBatchGroup';

export default {
  components: {
    DrawingProductBatchGroup,
  },
  data() {
    return {
      selectedProducts: {},
    };
  },
  props: {
    locked: {
      type: Boolean,
      default: false,
    },
    materialId: {
      type: Number,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    material() {
      return this.$store.state.materials[this.materialId];
    },
    availableGroups() {
      return this.material.product_groups.map((groupName) => this.$store.state.drawingProductGroups[groupName]);
    },
  },
  watch: {
    materialId() {
      this.updateDefaults();
      this.$emit('change', this.selectedProducts);
    },
  },
  methods: {
    updateDefaults() {
      this.availableGroups
        .forEach((group) => {
          this.$set(this.selectedProducts, group.keyword, parseInt(group.DrawingProducts.find((p) => p.is_group_default).id, 10));
        });
    },
    updateProduct(obj) {
      this.$set(this.selectedProducts, obj.group, parseInt(obj.value, 10));
      this.$emit('change', this.selectedProducts);
    },
  },
  mounted() {
    this.updateDefaults();
    this.$emit('change', this.selectedProducts);
  },
};
</script>

<style lang="scss">
  .drawing-product-batch-list.form-group label {
    width: 100%
  }
</style>
