<template>
  <div class="user-settings">
    <div v-if="$store.getters.isAdmin"
      class="card shadow-1">
      <div class="card-title pl-2 py-1 mb-0 bg-snijlab">Admin opties</div>
      <div class="card-body p-2">
        <email-template-widget /><br>
        <form-async-widget-single-checkbox
          row-class=""
          :value="allowAdvancedProducts"
          @update="updateAllowAdvancedProducts"
          :label="this.$i18n.t('admin.client_can_see_unordered_designs')" />
      </div>
    </div>

    <div class="card shadow-1">
      <div class="card-title pl-2 py-1 mb-0 bg-light">{{$t('user_settings.registered_email')}}</div>
      <div class="card-body p-2">
        <a v-if="$store.getters.isAdmin" :href="`mailto:${userEmail}`" target="_blank">{{userEmail}}</a>
        <template v-else>{{userEmail}}</template>
      </div>
    </div>

    <div class="card shadow-1">
      <div class="card-title pl-2 py-1 mb-0 bg-light">{{$t('user_settings.change_password')}}</div>
      <div class="card-body">
        <change-password-form />
      </div>
    </div>

    <div class="card shadow-1">
      <div class="card-title pl-2 py-1 bg-light mb-0">{{$t('user_settings.contact_details')}}</div>
      <div class="card-body pb-2">
        <client-details-form @updateFields="updateFormFields" />
      </div>
    </div>

    <address-form shipping-method="SHIPPING"
      :has-dropshipping="false"
      store-error-property="profileErrors"
      store-property="profile"
      @updateFields="updateFormFields" />
  </div>
</template>

<script>
import AddressForm from 'src/components/cart/form/AddressForm';
import ChangePasswordForm from 'src/components/user/ChangePasswordForm';
import ClientDetailsForm from 'src/components/user/ClientDetailsForm';
import EmailTemplateWidget from 'src/components/admin/EmailTemplateWidget';
import FormAsyncWidgetSingleCheckbox from 'src/components/form-async/FormAsyncWidgetSingleCheckbox';

export default {
  components: {
    ChangePasswordForm,
    FormAsyncWidgetSingleCheckbox,
    AddressForm,
    ClientDetailsForm,
    EmailTemplateWidget,
  },
  data() {
    return {
      vatLoading: false,
    };
  },
  methods: {
    updateFormFields(values, resolve, reject) {
      const outValues = { ...values };
      outValues.orderId = this.orderId;
      this.$store.dispatch('updateProfileField', outValues)
        .then((value) => {
          resolve(value);
        })
        .catch((value) => {
          reject(value);
        });
    },
    updateAllowAdvancedProducts({ value, promise }) {
      this.$store.dispatch('updateAllowAdvancedProducts', value)
        .finally(() => {
          promise.resolve();
        });
    },
  },
  computed: {
    allowAdvancedProducts() {
      return this.$store.state.user.allow_advanced_products;
    },
    userEmail() {
      return this.$store.state.user.email_address;
    },
  },
};
</script>

<style lang="scss">
  .user-settings .card {
    max-width: 50%;
    margin: 20px auto 0;

    .language-radios-container {
      display: flex;
      flex-direction: column;

      .custom-control {
        display: flex;
        align-items: center;
      }
    }
  }

  @media (max-width: 992px) {
    .user-settings .card {
      max-width: 80%;
    }
  }

  @media (max-width: 578px) {
    .user-settings .card {
      max-width: 100%;
    }
  }

</style>
