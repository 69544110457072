<template>
  <form-async-widget-textarea
    class="cart-comment-widget mx-2"
    :value="commentValue"
    v-bind="form"
    size="sm"
    :rows="2"
    :max-rows="10"
    :max-length="500"
    :show-available-length="true"
    :placeholder="placeholderText"
    @update="updateValue"/>
</template>

<script>

import FormAsyncWidgetTextarea from 'src/components/form-async/FormAsyncWidgetTextarea';

export default {
  components: {
    FormAsyncWidgetTextarea,
  },
  props: {
    order_id: {
      type: Number,
      required: true,
    },
  },
  computed: {
    placeholderText() {
      if (this.$store.state.cart.hasDropshipping) {
        return this.$t('order_review.only_visible_to_snijlab');
      }
      return '';
    },
    commentValue() {
      return this.$store.state.cart.userComment;
    },
    form() {
      return {
        label: this.$i18n.t('order_review.comments'),
        placeholder: '',
        help: '',
        error: '',
        required: false,
        'row-class': '', // overwrites default input-row-full
      };
    },
  },
  methods: {
    updateValue({ promise, value }) {
      this.$store.dispatch(
        'updateCartField',
        { field: 'user_comment', value, orderId: this.order_id },
      ).then(() => promise.resolve());
    },
  },
};
</script>
<style lang="scss">
  .cart-comment-widget {
    textarea {
      padding: .2rem .3rem !important;
    }
  }
</style>
