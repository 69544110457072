<template>
  <b-modal v-if="loaded" v-model="visible">
    <div slot="modal-title">
      {{$t('request_quote_modal.request_quote')}}
    </div>

    <div v-if="!formSubmitted" class="request-quote-modal-content">
      <p>{{$t('drawingConfiguration.priceMatrix.for_this_quantity_we_like_to_create_a_custom_quote')}}</p>

      <div class="form-container">

        <div class="mb-1">{{ $t('request_quote_modal.drawing_reference') }}</div>
        <div class="font-weight-bold mb-3">{{dcReference}}</div>

        <div class="form-group" :class="{'form-group--error': $v.quantity.$error}">
          <label class="mb-1" for="quantity">
            {{ $t('request_quote_modal.how_many_pieces_do_you_need') }} <span class="asterisk">*</span></label>
          <div class="input-group mb-3">
            <input id="quantity" class="form-control border p-1"
              v-model="$v.quantity.$model" autocomplete="family-name" />
          </div>
        </div>

        <div class="form-group" :class="{'form-group--error': $v.whenNeeded.$error}">
          <label class="mb-1" for="when-needed">
            {{ $t('request_quote_modal.when_do_you_need_this') }} <span class="asterisk">*</span></label>
          <div class="input-group mb-3">
            <input id="when-needed" class="form-control border p-1"
              v-model="$v.whenNeeded.$model" />
          </div>
        </div>

        <div class="form-group" :class="{'form-group--error': $v.extraInfo.$error}">
          <label class="mb-1" for="extra-info">
            {{ $t('request_quote_modal.are_there_any_other_specifics') }}</label>
          <div class="input-group mb-3">
            <textarea id="extra-info" class="form-control border p-1"
              v-model="$v.extraInfo.$model" />
          </div>
        </div>

        <div class="form-group" :class="{'form-group--error': $v.material.$error}">
          <label class="mb-1" for="material">{{ $t('request_quote_modal.material') }} <span class="asterisk">*</span></label>
          <div class="input-group mb-3">
            <input id="material" class="form-control border p-1"
              v-model="$v.material.$model" />
          </div>
        </div>

        <div class="form-group" :class="{'form-group--error': $v.organization.$error}">
          <label class="mb-1" for="company_name">{{ $t('request_form.company_name') }} <span class="asterisk">*</span></label>
          <div class="input-group mb-3">
            <input id="company_name" class="form-control border p-1"
              v-model="$v.organization.$model" autocomplete="organization" />
          </div>
        </div>

        <div class="form-group" :class="{'form-group--error': $v.firstname.$error}">
          <label class="mb-1" for="first_name">{{ $t('request_form.first_name') }} <span class="asterisk">*</span></label>
          <div class="input-group mb-3">
            <input id="first_name" class="form-control border p-1"
              v-model="$v.firstname.$model" autocomplete="given-name" />
          </div>
        </div>

        <div class="form-group" :class="{'form-group--error': $v.lastname.$error}">
          <label class="mb-1" for="last_name">{{ $t('request_form.last_name') }} <span class="asterisk">*</span></label>
          <div class="input-group mb-3">
            <input id="last_name" class="form-control border p-1"
              v-model="$v.lastname.$model" autocomplete="family-name" />
          </div>
        </div>

        <div class="form-group" :class="{'form-group--error': $v.emailaddress.$error}">
          <label class="mb-1" for="email">{{ $t('request_form.email_address') }} <span class="asterisk">*</span></label>
          <div class="input-group mb-3">
            <input id="email" class="form-control border p-1"
              v-model="$v.emailaddress.$model" autocomplete="email" />
          </div>
        </div>

        <div class="form-group" :class="{'form-group--error': $v.phonenumber.$error}">
        <label class="mb-1" for="phonenumber">{{ $t('request_form.phone_number') }} <span class="asterisk">*</span></label>
          <div class="input-group mb-3">
            <input id="phonenumber" class="form-control border p-1"
              v-model="$v.phonenumber.$model" autocomplete="tel" />
          </div>
        </div>
      </div>
      <div v-if="requestFailed" class="mt-2 text-danger form-error-text  p-1">
        {{ $t('request_form.something_went_wrong') }} <a href="mailto:info@snijlab.nl">info@snijlab.nl</a>
        {{ $t('request_form.if_this_problem_persists') }}
      </div>
    </div>

    <div v-else>
      <p>{{$t('request_quote_modal.thank_you_for_your_request')}}</p>
    </div>

    <template v-slot:modal-footer="{ok, cancel}">
      <b-button v-if="!formSubmitted" variant="secondary" size="xs" @click="cancel()">
        {{ $t('request_quote_modal.cancel') }}
      </b-button>
      <loading-button v-if="!formSubmitted"
        :loading="waitingForForm"
        size="xs"
        stroke-color="#fff"
        variant="success"
        class="btn btn-success submit-request-quote-modal-btn"
        @click="submit">
        {{ $t('request_form.submit') }}
      </loading-button>

      <b-button v-if="formSubmitted" variant="success" size="xs" @click="ok()">
        {{ $t('request_quote_modal.close') }}
      </b-button>

    </template>

  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate';
import {
  required, email, minLength,
} from 'vuelidate/lib/validators';
import axios from 'axios';
import LoadingButton from 'src/components/tools/LoadingButton';
import { load } from 'recaptcha-v3';

export default {
  components: {
    LoadingButton,
  },
  data() {
    return {
      visible: false,
      dcId: -1,
      waitingForForm: false,
      requestFailed: false,
      formError: false,
      recaptchaError: '',
      recaptcha: null,
      formSubmitted: false,

      dcReference: '',
      firstname: '',
      lastname: '',
      emailaddress: '',
      phonenumber: '',
      organization: '',
      material: '',
      whenNeeded: '',
      quantity: 0,

    };
  },
  computed: {
    storeData() {
      return this.$store.state.dc.drawingConfiguration[this.dcId];
    },
    loaded() {
      return this.priority && this.storeData;
    },
    priority() {
      return this.$store.getters.selectedPriority;
    },
    showVat() {
      return this.$store.state.settings.showVat;
    },
  },
  mixins: [validationMixin],
  validations() {
    return {
      material: {
        required,
      },
      firstname: {
        required,
      },
      lastname: {
        required,
      },
      emailaddress: {
        required,
        email,
      },
      phonenumber: {
        required,
        minLength: minLength(5),
      },
      organization: {
        required,
      },
      quantity: {
        required,
      },
      whenNeeded: {
        required,
      },
      extraInfo: {
      },
    };
  },
  watch: {
    $route() {
      // don't persist when the route changes...
      this.visible = false;
    },
  },
  mounted() {
    this.$eventBus.$off('snlb.showRequestQuoteModal', this.show);
    this.$eventBus.$on('snlb.showRequestQuoteModal', this.show);
  },
  methods: {
    show(data) {
      this.formSubmitted = false;

      this.recaptcha = load('6LfFY94hAAAAAE2dkRmiF-4zJS1P9U41YM1En6VN', {
        renderParameters: {
          badge: 'bottomright',
        }, //   // autoHideBadge: true,
      }).then((recaptcha) => {
        this.recaptcha = recaptcha;
      });

      this.visible = true;
      this.dcId = data.dcId;
      this.dcReference = `${this.$config.drawingConfigurationPrefix}${this.dcId}`;
      this.material = data.materialName;
      this.quantity = data.quantity;

      if (this.$store.state.profile.is_business) {
        this.organization = this.$store.state.profile.organisation;
      }
      this.emailaddress = this.$store.state.user.email_address;
      this.firstname = this.$store.state.profile.first_name;
      this.lastname = this.$store.state.profile.last_name;
      this.phonenumber = this.$store.state.profile.phone_number;
    },
    async submit() {
      this.requestFailed = false;
      if (this.waitingForForm) {
        return;
      }

      // eslint-disable-next-line no-unreachable
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.formError = true;
        return;
      }

      // eslint-disable-next-line no-unreachable
      this.waitingForForm = true;
      await this.recaptcha;
      this.recaptcha.execute('lead_form').then((token) => {
        const formData = new FormData();
        formData.append('contact_name', `${this.firstname} ${this.lastname}`);
        formData.append('phone', this.phonenumber);
        formData.append('email_from', this.emailaddress);
        formData.append('partner_name', this.organization);
        formData.append('name', 'Quote request via product editor');

        const combinedDescription = `Contact name: ${this.firstname} ${this.lastname}<br>
          Phone: ${this.phonenumber}<br>
          Emailaddress: ${this.emailaddress}<br>
          Drawing reference: ${this.dcReference}<br>
          Material: ${this.material}<br>
          Quantity: ${this.quantity}<br>
          When needed: ${this.whenNeeded}<br>
          Extra info: ${this.extraInfo ? this.extraInfo : 'None'}`;

        formData.append('description', combinedDescription);
        formData.append('recaptcha_token_response', token);

        // eslint-disable-next-line no-unreachable
        axios.post(this.$config.odooLeadFormUrl,
          formData,
          { withCredentials: false })
          .then((response) => {
            if ('error' in response.data) {
              this.requestFailed = true;
              this.waitingForForm = false;
            } else {
              this.$eventBus.$emit('gtm.volumeQuoteRequested');
              this.requested = true;
              this.$v.$reset();
              this.formSubmitted = true;
            }
          }).catch((error) => {
            this.waitingForForm = false;
            this.$eventBus.$emit('snlb.error', `UNEXPECTED ERROR: ${error}`);
          }).finally(() => {
            // this.loading = false;
          });
      });
    },
  },
};
</script>
<style lang="scss">
  .submit-request-quote-modal-btn {
    color: white !important;
    background-color: #00b900 !important;
  }

  .request-quote-modal-content {
    .b-modal-target.vue-portal-target > div {
      position: fixed;
      z-index: 2000 !important;
    }
    .quote-incentive{
      margin-top: 20px;
    }

    .form-container {

      .asterisk {
        color: red;
      }

      .form-error {
        font-size: 0.8rem;
      }

      .form-group--error {
        label {
          color: red;
        }
        input,
        textarea,
        select {
          border: 1px solid red !important;
        }
      }
    }
  }
</style>
