<template>
  <form method="post" class="forgot-password-form form-dark signin-form"
    name="signin" @submit.prevent="login">
    <div class="input-row-full">
      <h2>{{ $t('forgot_password.forgot_password') }}</h2>
      <p>{{ $t('forgot_password.enter_your_email_address_to_request_a_new_password') }}</p>
    </div>
    <form-widget-input
      v-model.trim="$v.email.$model"
      :error="error('email')"
      :placeholder="$t('forgot_password.email_address')"
      autocomplete="email username"
      size="sm"
      row-class="input-row-full"
    />
    <div class="input-row-full">
      <button class="btn btn-light btn-block mt-3 p-0 login-btn" type="submit">
        <loading-widget :loading="loading" :radius="10"/>
        <div v-if="!loading" class="login-text">
          {{ $t('forgot_password.request_new_password') }}
        </div>
      </button>
    </div>
  </form>
</template>
<script>
import axios from 'axios';
import FormWidgetInput from 'src/components/form/FormWidgetInput';
import { validationMixin } from 'vuelidate';
import {
  required, email,
} from 'vuelidate/lib/validators';
import LoadingWidget from 'src/components/tools/LoadingWidget';

export default {
  mixins: [validationMixin],
  name: 'ForgotPasswordForm',
  components: {
    LoadingWidget,
    FormWidgetInput,
  },
  data() {
    return {
      showForgotPassword: false,
      loading: false,
      email: this.initialEmail,
      serverErrors: {
        email: null,
      },
    };
  },
  props: {
    initialEmail: {
      type: String,
      default: '',
    },
  },
  computed: {
    errors() {
      return {
        email: this.$t('forgot_password.enter_a_valid_email_address'),
      };
    },
  },
  validations() {
    return {
      email: {
        required,
        email,
      },
    };
  },
  methods: {
    error(name) {
      if (this.$v[name].$error) {
        return this.errors[name];
      }
      if (name in this.serverErrors && this.serverErrors[name] != null && this.serverErrors[name].length > 0) {
        return this.serverErrors[name];
      }
      return '';
    },
    login() {
      const formData = new FormData();
      formData.set('email', this.email);
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      axios.post(`${this.$config.apiUrl}/user/forgotPasswordRequest`, formData, { withCredentials: true })
        .then((response) => {
          this.$emit('showConfirmPassword', response.data.result.code);
        })
        .catch((error) => {
          this.$eventBus.$emit('snlb.error', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss">
.forgot-password-form {
  .signin-form {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;

    .login-btn {
      max-height: 50px;
      .login-text {
        padding: 13px;
      }
    }
  }
  a.btn-google {
    color: rgba(0, 0, 0, 0.87)
  }
  .invalid-feedback {
    background-color: #fff;
    font-weight: bold;
    padding: 4px;
  }

}
</style>
