<template>
  <div v-if="hasFeedback"
    class="drawing-check">
    <b-form-checkbox
      v-if="approvable"
      ref="input"
      v-model="isChecked"
      @change="confirmDrawing">
      {{$tc('editor.i_accept_above_warnings', feedbackQuantity)}}
      <loading-widget :loading="isLoading" />
    </b-form-checkbox>
  </div>
</template>

<script>
import LoadingWidget from 'src/components/tools/LoadingWidget';

export default {
  components: { LoadingWidget },
  props: {
    dcId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isChecked: false,
      isLoading: false,
    };
  },
  computed: {
    approvable() {
      return this.storeData.approvable;
    },
    approved() {
      return this.storeData.approved;
    },
    hasFeedback() {
      return this.storeData.feedback.length !== 0;
    },
    feedbackQuantity() {
      if (!this.hasFeedback) {
        return 0;
      }
      return this.storeData.feedback.length;
    },
    hasMultipleFeedback() {
      return this.storeData.feedback.length > 1;
    },
    storeData() {
      return this.$store.state.dc.drawingConfiguration[this.dcId];
    },
    drawingCheck() {
      if (typeof this.storeData === 'undefined') {
        return false;
      }
      return this.storeData.feedback.length === 0 || this.storeData.feedback.every((v) => v.is_checked === true);
    },
  },
  watch: {
    dcId() {
      this.$nextTick(() => {
        this.isChecked = this.drawingCheck;
        this.isLoading = false;
      });
    },
  },
  mounted() {
    this.isChecked = this.drawingCheck;
    this.isLoading = false;
  },
  methods: {
    confirmDrawing() {
      this.$nextTick(() => {
        if (!this.isLoading && !this.drawingCheck) {
          const { orderId } = this.$store.state.cart;
          const result = this.$store.dispatch('approveDrawingConfiguration', { id: this.dcId, orderId });
          this.isLoading = true;
          result.finally(() => {
            this.isLoading = false;
          });
        }
        this.isChecked = true;
      });
    },
  },
};
</script>

<style>
</style>
