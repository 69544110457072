<template>
  <div class="message-bar-container" data-nosnippet>

    <div class="message-bar p-1 pr-3 shadow-1">
      <div class="message" v-html="messageBarContent" />
      <i class="material-icons compact close p-1"
        @click="toggleMessageBar()">close</i>
    </div>

    <div class="message-bar message-bar-relative p-1 pr-3">
      <div data-nosnippet class="message" v-html="messageBarContent" />
    </div>

  </div>
</template>

<script>
export default {
  methods: {
    toggleMessageBar() {
      this.$store.dispatch('setShowMessageBar', false)
        .finally(() => {
        });
    },
  },
  computed: {
    messageBarContent() {
      return this.$store.state.messageBar;
    },
  },
};
</script>

<style lang="scss">
  .message-bar {
    background-color: #ffe400;
    display: flex;
    font-size: 0.75rem;
    justify-items: space-between;
    position: fixed;
    width: 100%;
    z-index: 10;

    &.message-bar-relative {
      position: relative;
      z-index: 1;
    }

    @media (min-width: 768px) {
      height: 50px;
      &.message-bar-relative {
        display: none;
      }
    }

    .message {
      align-self: center;
    }

    .material-icons {
      line-height: inherit;
    }

    .close {
      color: #000;
      position: absolute;
      right: 0;
      top: 0;
    }

    p {
      margin: 0;
      padding: 0;
    }
  }
</style>
