/* eslint-disable no-underscore-dangle */
import Vue from 'vue';
// import debounce from 'es6-promise-debounce';

export default class AsyncEventQueue {
  /**
   * Create a new async event queue for ajax requests.
   * This makes sure that
   *
   * @param delay
   * @param makeRequest
   */
  constructor(delay, makeRequest) {
    this.debounce = AsyncEventQueue.debounce(() => new Promise((resolve) => resolve()), delay);
    this.makeRequest = makeRequest;
    Vue.util.defineReactive(this, 'queue', {});
  }

  async processEvent(obj) {
    // we generate a unique id.
    const id = performance.now();
    try {
      // this may fail.
      await this.debounce();
      Vue.set(this.queue, id, true);
      await this.makeRequest(obj, id);
    } catch (e) {
      //
    } finally {
      Vue.delete(this.queue, id, true);
    }
  }

  static debounce(func, wait, immediate) {
    let timeout;
    let lastReject;
    // eslint-disable-next-line func-names
    return function () {
      const context = this;
      // eslint-disable-next-line prefer-rest-params
      const args = arguments;
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line func-names
        const later = function () {
          timeout = null;
          if (!immediate) resolve(func.apply(context, args));
        };

        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        if (lastReject != null) {
          lastReject();
        }
        lastReject = reject;
        timeout = setTimeout(later, wait);

        if (callNow) {
          resolve(func.apply(context, args));
        }
      });
    };
  }
}
