<template>
  <div :class="classes" class="queue">
    <slot>
      <indeterminate-progress v-if="loading"
        :offset="10"
        :radius="10"
        :stroke-width="2" />
    </slot>
  </div>
</template>

<script>
import Deferred from 'es6-deferred';
import AsyncEventQueue from 'src/lib/AsyncEventQueue';
import IndeterminateProgress from 'src/components/tools/IndeterminateProgress';

export default {
  components: { IndeterminateProgress },
  props: {
    classes: {
      type: Object,
      default() {
        return {};
      },
    },
    // this line should be dynamic.
    // eslint-disable-next-line
    watchValue: {
      default: null,
    },
    delay: {
      type: Number,
      default: 750,
    },
  },
  data() {
    return {
      updateQueue: null,
      lastId: null,
    };
  },
  computed: {
    loading() {
      if (!this.updateQueue) {
        return false;
      }
      return Object.keys(this.updateQueue.queue).length > 0;
    },
  },
  watch: {
    watchValue(value) {
      this.updateQueue.processEvent(value);
    },
    loading() {
      this.$emit('update', this.loading);
    },
  },
  mounted() {
    this.updateQueue = new AsyncEventQueue(this.delay, this.updateValue);
  },
  methods: {
    async updateValue(value, id) {
      this.lastId = id;
      const promise = new Deferred();
      this.$eventBus.$emit('snlb.process', promise);
      this.$emit('process', promise, value, () => this.lastId === id, id);
      await promise;
    },
  },
};
</script>
