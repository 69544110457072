<template>
  <div>
    <div v-show="isDragging"
      class="fullscreen">
      <div class="upload-message p-3">
        <h2><i class="material-icons">upload</i>{{$t('uploader.drop_your_drawings_here')}}</h2>
        <p>{{$t('uploader.dropzone_file_extensions')}}</p>
      </div>
    </div>

    <b-modal
      id="fileUploader"
      ref="modal"
      v-model="visibleStatus"
      no-auto-focus
      @hidden="hidden"
      size="m">

      <form
        enctype="multipart/form-data"
        novalidate
        target="uploadTarget">
        <div class="dropbox">
          <span v-if="false">
            <div class="notify-uploading">
              <loading-widget loading="true"/>
            </div>
             {{$t('file_uploader.uploading_files')}}
          </span>

          <h2 class="dropbox-title py-3 mb-4" style="border: 2px dashed gray; border-radius: 10px;">
            <i class="material-icons compact">upload</i>{{$t('file_uploader.drop_your_drawings_here')}}
            <br><span class="filetypes-msg text-muted">{{$t('file_uploader.file_extensions')}}</span>
          </h2>

          <div class="stepper-horiz mt-2">
            <div class="stepper p-0 pr-4 ">
              <div class="stepper-icon">
                <i class="material-icons compact">note_add</i>
              </div>
              <span class="stepper-text">{{$t('file_uploader.upload_drawing')}}</span>
            </div>

            <div class="stepper py-0 px-4">
              <div class="stepper-icon">
                <i class="material-icons compact">style</i>
              </div>
              <span class="stepper-text">{{$t('file_uploader.select_material')}}</span>
            </div>

            <div class="stepper p-0 pl-4">
              <div class="stepper-icon">
                <i class="material-icons compact">shopping_cart</i>
              </div>
              <span class="stepper-text">{{$t('file_uploader.order')}}</span>
            </div>
          </div>

          <div class="upload-comment px-1 mt-4">
            <div>
              <label
                class="btn btn-primary btn-sm mr-2"
                :class="{'btn-success': !hasDrawingConfiguration && !isUploading}">
                <i class="upload-icon material-icons">note_add</i> {{$t('file_uploader.select_files')}}
                <input
                  ref="fileInput"
                  :multiple="isMultiple"
                  hidden
                  type="file"
                  data-tap-disabled="true"
                  class="input-file"
                  @change="processFile($event); showUploadHelp=false">
              </label>

              <button class="btn btn-light btn-sm" @click.prevent="showUploadHelp = !showUploadHelp">
                <div>
                  {{$t('file_uploader.short_manual')}}
                  <i v-show="!showUploadHelp" class="material-icons">keyboard_arrow_down</i>
                  <i v-show="showUploadHelp" class="material-icons">keyboard_arrow_up</i>
                </div>
              </button>
            </div>
            <div v-show="showUploadHelp" class="card shadow-1 mt-3 pt-2">
              <ul class="upload-comment-list">
                <li>{{$t('order_review.short_manual.allow_filetypes')}}: <strong>
                  {{$t('order_review.short_manual.extensions')}}</strong></li>
                <li>{{$t('order_review.short_manual.scale')}}:
                  <strong>{{$t('order_review.short_manual.1_to_1_in_millimeters')}}</strong></li>
                <li>{{$t('order_review.short_manual.maximum_dimensions')}}:
                  <strong>{{$t('order_review.short_manual.dimensions')}}</strong></li>
                <li>{{$t('order_review.short_manual.remove_all_but_lines')}}</li>
                <li>{{$t('order_review.short_manual.you_can_place_multiple_parts_in_single_drawing')}}</li>
                <li>{{$t('order_review.short_manual.use_the_right')}}
                  <strong>{{$t('order_review.short_manual.colors_and_layers')}}</strong>
                   {{$t('order_review.short_manual.to_select_between_cutting_and_engraving')}}
                  <i class="material-icons compact pointer" @click.prevent.stop="showHelp('uploader.layers')">info</i>
                </li>
              </ul>

              <router-link v-if="$i18n.locale === 'nl'"
                class="btn btn-xs btn-light" :to="{ path: '/nl/help/tekenregels' }" target="_blank">
                {{$t('order_review.short_manual.read_extended_manual')}} <i class="material-icons">open_in_new</i>
              </router-link>
              <router-link v-else
                class="btn btn-xs btn-light" :to="{ path: '/en/help/drawing-rules' }" target="_blank">
                {{$t('order_review.short_manual.read_extended_manual')}} <i class="material-icons">open_in_new</i>
              </router-link>

            </div>
          </div>
        </div>

        <ul class="selected-files list-group mt-3">
          <li class="uploaded-file list-group-item pl-2 pt-2 pb-0"
              v-for="(file, key) in fileQueue"
              :key="key">
            <file-uploader-file :index="key"
              :file="file"
              @hide="(value) => fileQueue.splice(value,1)" />
          </li>
        </ul>
      </form>

      <div v-if="hasDrawingConfiguration" class="uploader-button-bar mt-2">
        <div v-if="!isMyProducts" class="multiple-material-tip">
          <template v-if="fileQueueLength > 4">
            <strong>{{$t('file_uploader.hint')}}:</strong>
            {{$t('file_uploader.did_you_know_you_can_set_the_material_for_multiple_designs_at_the_same_time')}}
            <i class="material-icons compact info-icon" @click.prevent.stop="showHelp('uploader.batch-editor-hint')">info</i>
          </template>
        </div>
        <b-button v-if="!isMyProducts"
          class="mt-2"
          variant="success"
          size="sm"
          @click="close()">
          {{$t('file_uploader.done')}}
        </b-button>
      </div>

      <b-button v-if="hasDrawingConfiguration && isMyProducts"
        class="mt-2"
        variant="light"
        size="sm"
        @click="close()">
        {{$t('file_uploader.close')}} <i class="material-icons">close</i>
      </b-button>

      <div
        slot="modal-footer"
        class="w-100">
        <p class="float-left"/>
      </div>
    </b-modal>

    <dn-d-watcher
      @drop="handleDrop"
      @dragstart="handleDragStart"
      @dragstop="handleDragStop"/>
    <div class="queue"/>
  </div>
</template>

<script>
import DnDWatcher from 'src/components/tools/DragAndDropGlobalWatcher';
import LoadingWidget from 'src/components/tools/LoadingWidget';
import FileUploaderFile from 'src/components/upload/FileUploaderFile';

export default {
  components: {
    FileUploaderFile,
    LoadingWidget,
    DnDWatcher,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    useClick: {
      type: Boolean,
      default: false,
    },
    addToCart: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visibleStatus: false,
      fileQueue: [],
      isMultiple: true,
      isUploading: false,
      isDragging: false,
      showUploadHelp: false,
    };
  },
  computed: {
    isCart() {
      return this.$route.name === 'order-cart';
    },
    orderId() {
      return this.$store.state.cart.orderId;
    },
    isMyProducts() {
      return this.$route.name === 'products-list-me';
    },
    fileQueueTodoCount() {
      return this.fileQueue.filter((i) => i.status !== 'UPLOAD_FINISHED' && i.status !== 'PROCESSED').length;
    },
    fileQueueLength() {
      return this.fileQueue.length;
    },
    firstDrawingConfigurationId() {
      for (let i = 0; i < this.fileQueue.length; i += 1) {
        if (this.fileQueue[i].status === 'PROCESSED' && this.fileQueue[i].dcId !== null) {
          return this.fileQueue[i].dcId;
        }
      }
      return null;
    },
    hasDrawingConfiguration() {
      return this.firstDrawingConfigurationId !== null;
    },
    manyUploaded() {
      if (this.fileQueue.length > 5) {
        return true;
      }
      return false;
    },
    lastDrawingConfigurationId() {
      for (let i = this.fileQueue.length - 1; i >= 0; i -= 1) {
        if (this.fileQueue[i].status === 'PROCESSED' && this.fileQueue[i].dcId !== null) {
          return this.fileQueue[i].dcId;
        }
      }
      return null;
    },
  },
  watch: {
    visible(value) {
      this.visibleStatus = value;
      if (value) {
        this.uploadNextFile(false);
      }
    },
  },
  mounted() {
    this.$eventBus.$off('fileResultFromGenerator');
    this.$eventBus.$on('fileResultFromGenerator', this.fileFromGenerator);
  },
  methods: {
    fileFromGenerator(fileData) {
      const file = {
        data: fileData,
        progress: 0,
        status: 'TODO',
        error: null,
        dcId: null,
        fromGenerator: true,
      };
      if (file.data.size > this.$config.maxFileSize) {
        file.status = 'PROCESSED';
        file.error = 'UPLOAD_TOO_LARGE';
      }
      this.fileQueue.push(file);
      this.uploadNextFile(true);
    },
    navigateToEditor(isMyProducts) {
      if (!this.hasDrawingConfiguration) {
        return;
      }
      if (isMyProducts) {
        this.$router.push({
          name: 'products-list-me-editor',
          params: { productId: this.lastDrawingConfigurationId },
        });
      } else {
        this.$router.push({
          name: 'order-cart-editor',
          params: {
            orderId: this.orderId,
            productId: this.firstDrawingConfigurationId,
          },
        });
      }
      this.close();
    },
    close() {
      this.visibleStatus = false;
    },
    hidden() {
      for (let i = this.fileQueue.length - 1; i >= 0; i -= 1) {
        if ((this.fileQueue[i].status === 'UPLOAD_FINISHED' || this.fileQueue[i].status === 'PROCESSED')
            && this.fileQueue[i].error === null) {
          this.fileQueue.splice(i, 1);
        }
      }
    },
    showHelp(name) {
      this.$eventBus.$emit('snlb.showHelp', name);
    },
    selectFile() {
      this.$refs.fileInput.click();
    },
    variant(status) {
      if (status === 'TODO') {
        return 'warning';
      }
      if (status === 'UPLOAD_FINISHED' || status === 'PROCESSED') {
        return 'success';
      }
      return 'warning';
    },
    processFile(event) {
      for (let i = 0; i < event.target.files.length; i += 1) {
        const file = {
          data: event.target.files[i],
          progress: 0,
          status: 'TODO',
          error: null,
          dcId: null,
        };
        if (file.data.size > this.$config.maxFileSize) {
          file.status = 'PROCESSED';
          file.error = 'UPLOAD_TOO_LARGE';
          this.$eventBus.$emit('odoo.event', { event: 'upload_error', msg: `UPLOAD_TOO_LARGE: ${file.data.name}` });
          this.$eventBus.$emit('gtm.uploadFailed');
        }
        this.fileQueue.push(file);
      }
      this.uploadNextFile(false);
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    },
    handleDrop(event) {
      for (let i = 0; i < event.dataTransfer.files.length; i += 1) {
        const file = {
          data: event.dataTransfer.files[i],
          progress: 0,
          status: 'TODO',
          error: null,
          dcId: null,
        };
        if (file.data.size > this.$config.maxFileSize) {
          file.status = 'PROCESSED';
          file.error = 'UPLOAD_TOO_LARGE';
        }
        this.fileQueue.push(file);
      }
      if (this.isCart) {
        this.visibleStatus = true;
        this.uploadNextFile(false);
      } else {
        this.$router.push({
          name: 'order-cart',
          params: {
            lang: this.$i18n.locale,
            orderId: this.orderId,
            openUploadModal: true,
          },
        });
      }
    },
    uploadNextFile(notify) {
      const file = this.fileQueue.find((element) => element.status === 'TODO');
      if (file === null || typeof file === 'undefined' || this.isUploading) {
        return;
      }
      this.isUploading = true;

      // const vm = this;
      const axiosConfig = {
        onUploadProgress(progressEvent) {
          file.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        },
        withCredentials: true,
      };
      const vm = this;
      file.feedback = [];
      this.$store.dispatch('uploadDrawing', {
        axiosConfig,
        file,
      }).then((res) => {
        const errors = res.feedback.filter((f) => f.type === 'ERROR');
        file.dcId = res.id;
        if (errors.length === 0) {
          this.$store.dispatch('addDrawingConfiguration', {
            dcId: res.id,
            orderId: this.orderId,
          }).finally(() => {
            file.status = 'PROCESSED';
          });
        } else {
          file.feedback = res.feedback;
          file.drawingSize = this.getDrawingSize(res.id);
          file.status = 'PROCESSED';
        }
      }).catch((err) => {
        file.error = err;
      }).finally(() => {
        if (notify) {
          this.$eventBus.$emit('uploadFinishedNotify', file);
        }
        this.isUploading = false;
        vm.uploadNextFile(false);
      });
    },

    handleDragStart() {
      this.isDragging = true;
    },

    handleDragStop() {
      this.isDragging = false;
    },
    getDrawingSize(dcId) {
      const dc = this.$store.state.dc.drawingConfiguration[dcId];
      return `${parseFloat(dc.Drawing.dimensions.width).toFixed(0)} x ${parseFloat(dc.Drawing.dimensions.height).toFixed(0)} mm`;
    },
  },
};
</script>

<style lang="scss">
  @import '../../assets/scss/_variables.scss';

  #fileUploader {
    .modal-header {
      padding-top: 0;
      margin-top: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    .modal-footer {
      display: none
    }

    .stepper-horiz .stepper {
      .stepper-icon {
        font-size: .7rem;
      }

      .stepper-text {
        font-size: .8rem;
      }
    }

    .upload-icon {
      font-size: 1.2rem;
    }

    .dropbox {
      position: relative;
      text-align: center;

      .dropbox-title {
        font-size: 1.5rem;

        .material-icons {
          font-size: 2.2rem;
        }

        .filetypes-msg {
          font-size: 0.9rem;
        }
      }
    }
  }

  .notify-uploading {
    font-size: 1.2rem;
  }

  .upload-comment-list {
    list-style: outside;
    text-align: left;
  }

  .selected-files {
    .uploaded-file {
      font-size: .75rem;

      p {
        margin-bottom: 8px;
      }

      ul {
        margin-top: 0;
        line-height: .8rem;
      }

      .material-icons {
        line-height: .8rem;
      }
    }
  }

  .uploader-button-bar {
    display: flex;
    align-items: top;
    justify-content: space-between;
    .multiple-material-tip {
      width: 60%;
    }
  }

  .fullscreen {
    font-size: 2em;
    color: #fff;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(51, 51, 51, 0.7);
    z-index: 10000000;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fullscreen .upload-message {
    text-align: center;
    border: 2px dashed $color-info-border;
  }
</style>
