<template>
  <b-form-select
    :value="value"
    @change="emitUpdate">
    <option :value="item.id" :key="item.id" v-for="(item) in countriesOptions">{{ item.text }}</option>
  </b-form-select>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    priorityCountries: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    countriesOptions() {
      // eslint-disable-next-line arrow-body-style
      const allkeys = Object.keys(this.$store.state.countries)
        .filter((iso) => !this.priorityCountries.includes(iso));
        // eslint-disable-next-line arrow-body-style
      return this.priorityCountries.concat(allkeys).map((key) => {
        return {
          id: key,
          text: this.$store.state.countries[key],
        };
      });
    },
  },
  methods: {
    emitUpdate(value) {
      this.$emit('update', value);
    },
  },
};
</script>
