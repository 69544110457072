<template>
  <div class="shipping-cards mt-3">
    <div class="shipping-list card shadow-1">
      <div class="card-block card-block-title p-0 pl-2 bg-light">
        <div class="card-title">{{$t('cart.sidebar.delivery_method')}}</div>
        <loading-widget :loading="updatingDelivery" :radius="4"/>
      </div>
      <div class="card-body border-top">
        <shipping-list-delivery
          v-if="isDraft"
          :shipping-method="shippingMethod"
          v-model="updatingDelivery"
          :order-id="orderId" />

        <div v-else class="p-2">
          <template v-if="shippingMethod === 'SHIPPING'">
            {{$t('cart.shipping')}}
          </template>

          <template v-else-if="shippingMethod === 'PICKUP'">
            {{$t('cart.pickup')}}
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShippingListDelivery from 'src/components/cart/shipment/ShippingListDelivery';
import LoadingWidget from 'src/components/tools/LoadingWidget';

export default {
  components: {
    LoadingWidget,
    ShippingListDelivery,
  },
  props: {
    shippingMethod: {
      type: String,
      required: true,
    },
    orderId: {
      type: Number,
      required: true,
    },
    isDraft: {
      type: Boolean,
    },
  },
  data() {
    return {
      updatingCountry: false,
      updatingDelivery: false,
    };
  },
  computed: {
    countriesOptions() {
      // eslint-disable-next-line arrow-body-style
      return Object.keys(this.$store.state.countries).map((key) => {
        return {
          id: key,
          text: this.$store.state.countries[key],
        };
      });
    },
    countries() {
      return this.$store.state.countries;
    },
  },
  methods: {
    isSelected(shipping) {
      return shipping === this.shippingMethod;
    },
    isSelectedDeliveryCountry(code) {
      return this.$store.state.cart.address.delivery_country === code;
    },
  },
};
</script>

<style>
</style>
