<template>
  <div class="dashboard-contentful-block bg-light-4 border border-light rounded p-2">
    <h2>{{replacePlaceholders(block.title)}}</h2>
    <div v-if="block.mainText" v-html="htmlString(block.mainText)" />

    <template v-if="block.ctaPrimaryTitle && block.ctaPrimaryLink">
      <a v-if="block.ctaPrimaryLink.toLowerCase() === 'upload'" href="#"
        class="btn btn-success mr-2 mb-2" @click.prevent="uploadBtnClicked">
        {{block.ctaPrimaryTitle}}
      </a>
      <a v-else-if="block.ctaPrimaryLink.endsWith('/dashboard')"
        class="btn btn-success mr-2 shadow-none mb-2"
        :href="$router.resolve({ 'name': 'dashboard' }).href">{{block.ctaPrimaryTitle}}</a>
      <router-link v-else class="btn btn-primary mr-2 mb-2"
        :to="{path: strippedLink(block.ctaPrimaryLink)}">{{block.ctaPrimaryTitle}}</router-link>
    </template>

    <template v-if="block.ctaSecondaryTitle">
      <loading-button
        v-if="block.ctaSecondaryLink.toLowerCase() === 'examplefile' && block.exampleFile"
        :loading="loadingAddingExampleToCart"
        variant="outline-secondary"
        strokeColor="#424242"
        class="mb-2"
        :btnText="block.ctaSecondaryTitle"
        @click="exampleFileBtnClicked(block.exampleFile.fields.file)">
        {{block.ctaSecondaryTitle}}
      </loading-button>

      <router-link v-else class="btn btn-secondary mr-2 mb-2"
        :to="{path: strippedLink(block.ctaSecondaryLink)}">{{block.ctaSecondaryTitle}}</router-link>
    </template>

  </div>
</template>

<script>
// import _has from 'lodash/has';
import LoadingButton from 'src/components/tools/LoadingButton';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

import axios from 'axios';

// eslint-disable-next-line no-unused-vars
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
// import axios from 'axios';
// import RichTextRenderer from 'contentful-rich-text-vue-renderer';

export default {
  components: {
    LoadingButton,
    // RichTextRenderer,
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loadingAddingExampleToCart: false,
    };
  },
  methods: {
    htmlString(text) {
      return documentToHtmlString(text, {
        renderMark: {},
        renderNode: {
          [INLINES.HYPERLINK]: (node, next) => {
            if (node.data.uri.indexOf('youtube.com') !== -1) {
              let videoUrl = node.data.uri;
              if (videoUrl.indexOf('rel=0') === -1) {
                videoUrl += '?rel=0';
              }
              return `<div class="video-holder text-center">
                  <iframe
                    src=${videoUrl}
                    type="text/html"
                    width="640"
                    height="360"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture ; fullscreen"
                  />
                </div>`;
            }
            // return h('a', { attrs: { href: node.data.uri } }, node.content[0].value);
            return `<a href=${node.data.uri}>${next(node.content)}</a>`;
          },
        },
      });
    },
    strippedLink(url) {
      const strippedUrl = url.split('snijlab.nl');
      if (strippedUrl.length > 1) {
        return strippedUrl[1];
      }
      return url;
    },
    exampleFileUploaded() {
      this.loadingAddingExampleToCart = false;
    },
    uploadBtnClicked() {
      this.$router.push({
        name: 'order-cart',
        params: {
          lang: this.$i18n.locale,
          orderId: this.orderId,
          openUploadModal: true,
        },
      });
    },
    exampleFileBtnClicked(exampleFile) {
      if (this.loadingAddingExampleToCart) {
        return;
      }
      this.loadingAddingExampleToCart = true;
      axios.get(exampleFile.url,
        { responseType: 'blob' })
        .then((response) => {
          const file = new File([response.data], exampleFile.fileName, { type: 'application/pdf' });

          this.$eventBus.$on('uploadFinishedNotify', this.fileUploadedCallback);
          this.$eventBus.$emit('fileResultFromGenerator', file);
        });
    },
    fileUploadedCallback(file) {
      this.loadingAddingExampleToCart = false;
      this.$eventBus.$off('uploadFinishedNotify');
      this.$router.push({
        name: 'products-list-me-editor',
        params: { productId: file.dcId },
      });
    },
    replacePlaceholders(text) {
      let nameReplaced = '';
      if (this.$store.state.profile.first_name) {
        nameReplaced = `, ${this.$store.state.profile.first_name}`;
      }
      return text.replace('{first_name}', nameReplaced);
    },
    getNodeContentWithNewline({ node, key }) {
      const nodeContentWithNewlineBr = node.content.map((childNode) => {
        if (childNode.nodeType === 'text') {
          const splittedValue = childNode.value.split('\n');
          return splittedValue
            .reduce(
              (aggregate, v, i) => [
                ...aggregate,
                { ...childNode, value: v },
                { nodeType: 'break', key: `${key}-br-${i}` },
              ],
              [],
            )
            .slice(0, -1);
        }

        return childNode;
      });
      // eslint-disable-next-line prefer-spread
      return [].concat.apply([], nodeContentWithNewlineBr);
    },
    formatAcceptedDate(date) {
      const options = {
        // weekday: 'short',
        // year: 'numeric',
        // month: 'short',
        // day: 'numeric',
      };
      const event = new Date(date);
      return event.toLocaleDateString(this.$i18n.locale, options);
    },
    invoiceLink(order) {
      return `${this.$config.apiUrl}/document/invoice/${order.invoiceId}`;
    },
  },
};
</script>

<style lang="scss">
  .dashboard-contentful-block {
    iframe {
      width: 100%;
    }
    ul {
      list-style: none;
      padding: 0 0 0 1rem;

      li:before {
        font-weight: bold;
        content: '✓';
        text-indent: 0;
        float: left;
        padding: 0 .5rem 0 0;
      }

      li p {
        padding: 0;
        margin: 0;
      }
    }
  }
</style>
