<template>
  <b-button
    class="loading-button"
    :variant="variant"
    :block="block"
    :size="size"
    :disabled="disabled"
    :type="type"
    @click="$emit('click')">
    <div v-if="loading"
      class="loading-center">
      <indeterminate-progress
        :stroke-width="2"
        :stroke-color="strokeColor" />
      <span v-if="btnText"
        class="ml-1">{{btnText}}</span>
    </div>
    <slot v-if="!loading" />
  </b-button>
</template>

<script>
import IndeterminateProgress from 'src/components/tools/IndeterminateProgress';

export default {
  components: {
    IndeterminateProgress,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    strokeColor: {
      type: String,
      default: '#000',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'button',
    },
    block: {
      type: Boolean,
      default: false,
    },
    blockLoadingClick: {
      type: Boolean,
      default: true,
    },
    btnText: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
  .loading-button .loading-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
