<template>
    <div class="contentful-text-with-gallery pt-5 mb-5"
      :style="cssBackgroundImage()">
      <div class="content-container">
        <h2 v-if="data.title" class="text-center mb-5">{{data.title}}</h2>
        <div v-if="data.images" class="images-container pt-2">
          <img class="shadow" v-for="(image, index) in data.images"
            loading="lazy"
            :key="`${image.fields.file.title}${index}`"
            :src="`${image.fields.file.url}?w=350&h=350`" width="350" height="350"
            :alt="image.fields.description" />
        </div>
      </div>
    </div>
</template>

<script>

export default {
  props: {
    data: {
      type: Object,
      require: true,
    },
    removeBeforeDestroy: {
      type: Boolean,
      default: false,
    },
    bgColor: {
      type: String,
      default: '#ffffff',
    },
  },
  data() {
    return {
      imgWidth: 350,
      imgMargin: 50,
      moveSpeed: 0.6,
      imgCount: 0,
    };
  },
  methods: {
    cssBackgroundImage() {
      if (this.bgImage) {
        return `background-image: url("${this.bgImage.url}"?w=1800);`;
      }
      return `background-color: ${this.bgColor}`;
    },
    strippedLink(url) {
      const strippedUrl = url.split('snijlab.nl');
      if (strippedUrl.length > 1) {
        return strippedUrl[1];
      }
      return url;
    },
  },
  beforeDestroy() {
    if (this.removeBeforeDestroy) {
      this.$root.$el.parentNode.removeChild(this.$root.$el);
    }
  },
  mounted() {
    const data = this;
    function slideshowStep() {
      if (data.data) {
        document.querySelectorAll('.images-container img').forEach((el) => {
          let curPos = new DOMMatrix(window.getComputedStyle(el).transform).e;
          curPos -= data.moveSpeed;
          // eslint-disable-next-line no-param-reassign
          el.style.transform = `translateX(${curPos}px)`;
          if (curPos > -1 * (data.imgWidth + data.imgMargin)) {
            // eslint-disable-next-line no-param-reassign
            // el.style.transform = `translateX(${curPos}px)`;
          } else {
            const diff = (-1 * (data.imgWidth + data.imgMargin)) - curPos;
            if (diff < 100 && !el.classList.contains('fadeout')) {
              // eslint-disable-next-line no-param-reassign
              el.style.animation = 'fadeout 2s forwards';
            } else if (diff > 100 && !el.classList.contains('fadein')) {
              // eslint-disable-next-line no-param-reassign
              el.style.transform = `translateX(${(data.data.images.length - 1)
                * (data.imgWidth + data.imgMargin) + -100}px)`;
              // eslint-disable-next-line no-param-reassign
              el.style.animation = 'fadein 2s forwards';
            }
          }
        });
      }
    }
    this.$nextTick(() => {
      const sliderImages = document.querySelectorAll('.images-container img');
      this.imgCount = sliderImages.length;
      sliderImages.forEach((el, i) => {
        // eslint-disable-next-line no-param-reassign
        el.style.transform = `translateX(${i * (data.imgWidth + data.imgMargin)}px)`;
      });
      setInterval(slideshowStep, 10);
    });
  },
};
</script>

<style lang="scss">
  .contentful-text-with-gallery {

    h2 {
      font-size: 42px;
      font-weight: 300;
      line-height: 54px;
      text-align: center;
    }

    .content-container {
      padding-bottom: 3rem;
      max-width: 1000px;
      margin: auto;

      .text-container {
        padding-right: 0;
        padding-left: 1rem;

        z-index: 2;
        grid-column: 1;
        grid-row: 1;
        h2 {
          font-weight: 700;
        }
      }

      .images-container {
        grid-column: 1/3;
        grid-row: 1;
        width: 100%;
        min-height: 300px;
        overflow: hidden;
        z-index: 1;

        img {
          position: absolute;
          padding: 0;
          margin: 0;
          width: 350px;
          animation-fill-mode: forwards;
        }
      }

      @keyframes fadein {
          from { opacity: 0; }
          to   { opacity: 1; }
      }

      @keyframes fadeout {
          from { opacity: 1; }
          to   { opacity: 0; }
      }

      @media(max-width: 767px) {
        grid-template-columns: auto;
        grid-template-rows: 1fr auto;
        padding-bottom: 0;

        .text-container {
          grid-column: 1;
          grid-row: 1;
          font-size: .8rem;
          padding: 0 1rem 1rem 1rem;

          h2 {
            margin-bottom: 1.5rem;
            font-size: 1.8rem;
          }
        }

        .images-container {
          grid-column: 1;
          grid-row: 2;
          width: 100%;
          position: relative;
          min-height: 380px;
        }
      }
    }
  }
</style>
