<template>
  <div>
    <footer>
      <cookie-law
        ref="cookie"
        class="p-2 border-top"
        theme="mytheme"
        :buttonDecline="true"
        buttonDeclineClass="btn btn-light-flat text-white btn-xs"
        buttonClass="btn btn-primary btn-xs"
        position="bottom"
        :buttonText="$t('cookiebanner.accept')"
        :buttonDeclineText="$t('cookiebanner.decline')"
        storageType="cookies"
        storageName="snijlabCookiesAccepted"
        :cookieOptions="{
          'SameSite': 'None',
          'Secure': true,
          'max-age': '604800',
        }"
        @accept="accept()"
        @decline="decline()" >
        <div slot="message">
          {{$t('cookiebanner.message')}}
          <router-link class="text-underline" :to="{ name: 'privacy-page', hash: '#cookies'}">
            {{$t('cookiebanner.read_more')}}</router-link>
        </div>
      </cookie-law>
    </footer>
  </div>
</template>

<script>

import CookieLaw from 'vue-cookie-law';

export default {
  components: {
    CookieLaw,
  },
  data() {
    return {
      status: null,
    };
  },
  methods: {
    decline() {
      // this.$eventBus.$emit('gtm.disable');
      this.$gtm.enabled(false);
    },
    accept() {
      // this.$eventBus.$emit('gtm.disable');
      this.$gtm.enabled(true);
    },
  },
  computed: {
  },
};
</script>

<style lang="scss">
  .Cookie {
    .text-underline {
      text-decoration: underline;
    }
    &.Cookie--mytheme {
      background-color: #333;
      color: #fff;
    }
    .Cookie__buttons {
      flex-direction: row;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
</style>
