<template>
  <div class="list-group list-group-flush payment-widget">
    <label
      v-for="(name, key) in paymentOptions"
      :key="key"
      class="payment-label list-group-item list-group-item-action p-2">
      <input
        :id="'orderconfirm_method_'+key"
        :checked="computedValue === key"
        :value="key"
        class="payment-choice"
        :disabled="!withinSpendingLimit(key)"
        type="radio"
        @change="updateSelected">
      <i v-if="computedValue === key" class="material-icons compact radio-icon">radio_button_checked</i>
      <i v-else class="material-icons compact radio-icon">radio_button_unchecked</i>
      <div class="payment-label-name small pl-2">
        {{ name }}
        <div v-if="!withinSpendingLimit(key)"
          class="mt-1 text-danger small">
          {{ spendingLimitMessage(key) }}
        </div>
      </div>
      <div :class="'payment-method-'+key" class="payment-logo" />
    </label>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
      default: 'invalid',
    },
  },
  data() {
    return {
      selected: this.value,
      limitsPerMethod: {
        invoice: { min: 0, max: Infinity },
        'mollie-ideal': { min: 1, max: 5000000 },
        'mollie-creditcard': { min: 0, max: 500000 },
        'mollie-mistercash': { min: 2, max: 5000000 },
        'mollie-sofort': { min: 10, max: 5000000 },
        'mollie-banktransfer': { min: 1, max: 100000000 },
        'mollie-directdebit': { min: 1, max: 100000 },
        'mollie-belfius': { min: 1, max: 5000000 },
        'mollie-paypal': { min: 1, max: 800000 },
        'mollie-paysafecard': { min: 100, max: 99900 },
      },
    };
  },
  computed: {
    paymentOptions() {
      return this.$store.state.settings.paymentOptions;
    },
    computedValue() {
      return this.withinSpendingLimit(this.selected) ? this.selected : 'invalid';
    },
    orderTotalRaw() {
      if (Object.keys(this.$store.state.cart.costTable).length > 0) {
        return this.$config.getPrice(this.$store.state.cart.costTable.total, true);
      }
      return 0;
    },
  },
  watch: {
    computedValue(value) {
      this.$emit('input', value);
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.computedValue !== this.selected) {
        this.$emit('input', this.computedValue);
      }
    });
  },
  methods: {
    updateSelected(event) {
      this.selected = event.target.value;
    },
    withinSpendingLimit(paymentMethod) {
      if (paymentMethod in this.limitsPerMethod) {
        const orderTotal = this.orderTotalRaw;
        return orderTotal <= this.limitsPerMethod[paymentMethod].max;
      }
      return true;
    },
    spendingLimitMessage(paymentMethod) {
      if (paymentMethod in this.limitsPerMethod) {
        return `${this.$t('cart.available_for_orders_up_to')}
        ${this.$config.currencyFormat.format(this.limitsPerMethod[paymentMethod].max / 100)}`;
      }
      return '';
    },
  },
};
</script>

<style lang="scss">
  .payment-widget {

    padding: 0;
    list-style-type: none;

    .list-group-item-action {
      min-height: 0;
    }

    input {
      display: none;
    }

    label {
      position:  relative;
    }

    label.selected {
      background-color: #eeeeee;
    }

    label .radio-icon {
      display: inline-block;
      grid-column: 1;
    }

     .payment-label {
      display: grid;
      grid-template-columns: 30px 1fr 30px;
      align-items: center;
    }
    label .payment-label-name {
      display: inline-block;
      grid-column: 2;
    }

    label .payment-logo {
      float: right;
      width: 30px;
      height: 30px;
      background-repeat: no-repeat;
      background-size: cover;
      grid-column: 3;
    }

    .payment-method-mollie-ideal {
      background-image: url('~@/assets/mollie/ideal.png');
    }

    .payment-method-mollie-creditcard {
      background-image: url('~@/assets/mollie/creditcard.png');
    }

    .payment-method-mollie-mistercash {
      background-image: url('~@/assets/mollie/mistercash.png');
    }

    .payment-method-mollie-sofort {
      background-image: url('~@/assets/mollie/sofort.png');
    }

    .payment-method-mollie-banktransfer {
      background-image: url('~@/assets/mollie/banktransfer.png');
    }

    .payment-method-mollie-directdebit {
      background-image: url('~@/assets/mollie/directdebit.png');
    }

    .payment-method-mollie-belfius {
      background-image: url('~@/assets/mollie/belfius.png');
    }

    .payment-method-mollie-paypal {
      background-image: url('~@/assets/mollie/paypal.png');
    }

    .payment-method-mollie-bitcoin {
      background-image: url('~@/assets/mollie/bitcoin.png');
    }

    .payment-method-mollie-podiumcadeaukaart {
      background-image: url('~@/assets/mollie/podiumcadeaukaart.png');
    }

    .payment-method-mollie-paysafecard {
      background-image: url('~@/assets/mollie/paysafecard.png');
    }

    .payment-method-invoice {
      background-image: url('~@/assets/mollie/invoice.png');
    }
  }
</style>
