<template>
  <div class="drawing-product-group">
    <label class="mt-1" :class="`option-group-${group.keyword}`">
      {{ group.name }} <i class="material-icons compact info-icon" @click.prevent.stop="showHelp(group.keyword)">info</i>
    </label>
    <div v-if="drawingProductOptions.length === 1">{{ drawingProductOptions[0].text }} </div>
    <b-form-select v-else
      :value="selectedId"
      :options="drawingProductOptions"
      :disabled="disabled"
      @change="updateProducts" />
  </div>
</template>
<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    keyword: {
      type: String,
      required: true,
    },
    dcId: {
      type: Number,
      required: true,
    },
    materialMaxHeight: {
      type: Number,
      required: true,
    },
  },
  computed: {
    helpMap() {
      return {
        bottom_default: 'editor.bottom_film',
        bottom_not_available: 'editor.bottom_film',
        bottom_optional: 'editor.bottom_film',
        direction_corrugated: 'editor.direction_corrugated',
        direction_grain: 'editor.direction_grain',
        selection: 'editor.selection',
        top_default: 'editor.top_film',
        top_not_available: 'editor.top_film',
        top_optional: 'editor.top_film',
      };
    },
    group() {
      return this.$store.state.drawingProductGroups[this.keyword];
    },
    drawingConfiguration() {
      return this.$store.state.dc.drawingConfiguration[this.dcId];
    },
    drawingProducts() {
      return this.group.DrawingProducts.map(
        (prod) => ({
          product: this.$store.state.drawingProducts[prod.id],
          isDefault: prod.is_group_default,
        }),
      );
    },
    selectedId() {
      let id = parseInt(this.drawingConfiguration.productsPerGroup[this.keyword], 10);
      if (this.drawingProductOptions.filter((obj) => parseInt(obj.value, 10) === id).length < 1) {
        id = parseInt(this.drawingProductOptions[0].value, 10);
        this.updateProducts(id);
      }
      return id;
    },
    drawingHeight() {
      return Math.round(this.$store.state.dc.drawingConfiguration[this.dcId].Drawing.dimensions.height);
    },
    drawingProductOptions() {
      if ((parseInt(this.group.id, 10) !== 7 && parseInt(this.group.id, 10) !== 8)
        || this.drawingHeight <= this.materialMaxHeight) {
        return this.drawingProducts.map((prod) => ({
          value: prod.product.id,
          text: this.getProductName(prod.product.name, prod.isDefault),
        }));
      }

      // corrugated / grain direction
      let options = {};
      options = this.drawingProducts
        .filter((prod) => parseInt(prod.product.id, 10) === 23 || parseInt(prod.product.id, 10) === 25)
        .map((prod) => ({
          value: prod.product.id,
          text: this.getProductName(prod.product.name, prod.isDefault),
          selected: true,
        }));
      options.push({
        value: null,
        html: this.$i18n.t('drawing_configuration.no_other_options_available_because_of_drawing_height'),
        disabled: true,
      });

      return options;
    },
    selectedProduct() {
      return this.$store.state.drawingProducts[this.selectedId];
    },
  },
  methods: {
    showHelp(type) {
      this.$eventBus.$emit('snlb.showHelp', this.helpMap[type]);
    },
    getProductName(name, isDefault) {
      if (isDefault) {
        return `${name} (${this.$i18n.t('drawing_configuration.standard')})`;
      }
      return `${name}`;
    },
    updateProducts(val) {
      this.$emit('update', { group: this.keyword, value: val });
    },
  },
};
</script>

<style lang="scss">
  .drawing-product-group {
    label {
      cursor: default;
    }
   }
</style>
