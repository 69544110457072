<template>
  <form @submit.prevent="submit">
    <div class="create-account-form">
      <div class="input-row-full">
        <h2>{{$t('create_account.new_customer_title')}}</h2>
        <p v-if="!isAdmin">{{$t('create_account.new_customer_intro')}}</p>
      </div>

      <form-widget-single-checkbox
        :label="$t('create_account.business')"
        v-model="isBusiness"
        size="sm" row-class="input-row-full" />

      <form-widget-input
        v-if="isBusiness"
        v-model.trim="$v.businessName.$model"
        :error="error('businessName')"
        :placeholder="$t('create_account.company_name')"
        autocomplete="organization"
        size="sm" row-class="input-row-full" />

      <form-widget-input
        v-model.trim="$v.firstName.$model"
        :error="error('firstName')"
        :placeholder="$t('create_account.first_name')"
        autocomplete="given-name"
        size="sm" row-class="input-row-full" />

      <form-widget-input
        v-model.trim="$v.lastName.$model"
        :error="error('lastName')"
        :placeholder="$t('create_account.last_name')"
        autocomplete="family-name"
        size="sm" row-class="input-row-full" />

      <form-widget-input
        v-model.trim="$v.email.$model"
        :error="error('email')"
        type="email"
        :placeholder="$t('create_account.email')"
        autocomplete="email username"
        size="sm" row-class="input-row-full" />

      <form-widget-input
        v-model.trim="$v.phone.$model"
        :error="error('phone')"
        :placeholder="$t('create_account.phone_number')"
        autocomplete="tel"
        size="sm" row-class="input-row-full" />

      <template v-if="!isAdmin">
        <form-widget-input
          v-model.trim="$v.password.$model"
          :error="error('password')"
          type="password"
          :placeholder="$t('create_account.enter_a_password')"
          autocomplete="new-password"
          size="sm" row-class="input-row-full" />

          <div class="input-row-full" v-html="$t('create_account.read_our_tos_pp')" />

        <template v-if="showRecaptcha">
          <form-widget-single-checkbox
            :label="$t('create_account.accept_tos_pp')"
            v-model="$v.agreedToTc.$model"
            :error="error('agreedToTc')"
            size="sm" row-class="input-row-full" />

          <div class="input-row-full">
            <recaptcha-component ref="recaptcha" v-model="recaptchaValue" @input="recaptchaError = ''"/>
            <form-help-widget :error="recaptchaError" />
          </div>
        </template>
      </template>

      <div class="row-stick-bottom">
        <loading-button
          :block="true"
          :loading="createLoading"
          variant="primary"
          class="btn btn-primary btn-block"
          stroke-color="#fff"
          @click="submit">{{$t('create_account.create_new_account_button')}}
        </loading-button>
      </div>
    </div>
  </form>
</template>
<script>
import axios from 'axios';
import { validationMixin } from 'vuelidate';
import {
  required, requiredIf, minLength, email,
} from 'vuelidate/lib/validators';
import RecaptchaComponent from 'src/components/user/RecaptchaComponent';
import FormWidgetInput from 'src/components/form/FormWidgetInput';
import FormWidgetSingleCheckbox from 'src/components/form/FormWidgetSingleCheckbox';
import FormHelpWidget from 'src/components/form/FormHelpWidget';
import LoadingButton from 'src/components/tools/LoadingButton';

export default {
  components: {
    LoadingButton,
    FormHelpWidget,
    FormWidgetSingleCheckbox,
    FormWidgetInput,
    RecaptchaComponent,
  },
  mixins: [validationMixin],
  validations() {
    return {
      firstName: {
        required,
      },
      businessName: {
        required: requiredIf((opt) => opt.isBusiness),
      },
      lastName: {
        required,
      },
      email: {
        required,
        email,
      },
      phone: {
      },
      password: {
        required: requiredIf(() => !this.isAdmin),
        minLength: minLength(8),
      },
      recaptchaValue: {
      },
      agreedToTc: {
        mustBeTrue: (val) => val === true || this.isAdmin,
      },
    };
  },
  data() {
    return {
      createLoading: false,
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      password: '',
      isBusiness: false,
      businessName: '',
      agreedToTc: false,
      recaptchaError: '',
      recaptchaValue: null,
      forceShowRecaptcha: false,
      serverErrors: {
        email: null,
      },
    };
  },
  watch: {
    // eslint-disable-next-line
    '$v.email.$model': function (value) {
      this.serverErrors.email = null;
      this.$emit('updateEmail', value);
    },
    showRecaptcha(value) {
      if (!value) {
        this.recaptcha_value = null;
      }
    },
  },
  computed: {
    showRecaptcha() {
      return (!this.$v.businessName.$invalid
        && !this.$v.firstName.$invalid
        && !this.$v.lastName.$invalid
        && !this.$v.email.$invalid)
        || this.forceShowRecaptcha;
    },
    errors() {
      return {
        businessName: this.$i18n.t('create_account.error.required_field'),
        firstName: this.$i18n.t('create_account.error.required_field'),
        lastName: this.$i18n.t('create_account.error.required_field'),
        password: this.$i18n.t('create_account.error.password_needs_to_be_at_least_8_characters_long'),
        email: this.$i18n.t('create_account.error.enter_a_valid_email_address'),
        agreedToTc: this.$i18n.t('create_account.error.required_field'),
        recaptchaValue: this.$i18n.t('create_account.error.captcha_required'),
      };
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
  methods: {
    error(name) {
      if (this.$v[name].$error) {
        return this.errors[name];
      }
      if (name in this.serverErrors && this.serverErrors[name] != null && this.serverErrors[name].length > 0) {
        return this.serverErrors[name];
      }
      return '';
    },
    submit() {
      this.forceShowRecaptcha = true;
      if (this.createLoading) {
        return;
      }
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      const formData = new FormData();
      formData.append('user[email]', this.email);
      formData.append('user[password]', this.password);
      formData.append('user[firstname]', this.firstName);
      formData.append('user[lastname]', this.lastName);
      formData.append('user[is_business]', this.isBusiness);
      formData.append('user[business_name]', this.businessName);
      formData.append('user[captcha]', this.recaptchaValue);
      this.createLoading = true;

      let createAccountUrl = `${this.$config.baseApiUrl}/${this.$i18n.locale}/user/createAccount`;
      if (this.isAdmin) {
        createAccountUrl = `${this.$config.baseApiUrl}/${this.$i18n.locale}/user/createAccountAsAdmin`;
      }

      axios
        .post(createAccountUrl,
          formData,
          { withCredentials: true })
        .then((response) => {
          this.hasInternalError = false;
          this.hasError = false;
          if (response.data.error !== null) {
            this.hasError = true;
            this.recaptchaValue = '';
            // EventBus.error(response.data.error);
          } else {
            this.valid = true;
            this.$eventBus.$emit('gtm.accountCreated', this.isBusiness);

            if (this.isAdmin) {
              window.location = `/${this.$i18n.locale}/app/orders/cart?userId=${response.data.result.user.id}`;
            } else {
              const userdata = response.data.result;
              userdata.new_account = true;
              userdata.tmp_phone_number = this.phone;
              this.$eventBus.$emit('newLogin', userdata);
            }
          }
        }).catch((error) => {
          if (this.$refs.recaptcha !== undefined) {
            this.$refs.recaptcha.reset();
          }
          if (!('response' in error)) {
            this.$eventBus.$emit('snlb.error', `UNEXPECTED ERROR: ${error}`);
          }
          if (error.response.status === 417) {
            if ('user_email' in error.response.data.result) {
              this.serverErrors.email = this.$i18n.t('create_account.error.email_address_already_exists');
            }
            if ('user_captcha' in error.response.data.result) {
              this.recaptchaError = this.$i18n.t('create_account.error.captcha_not_entered_or_invalid');
            }
            return;
          }
          this.$eventBus.$emit('snlb.error', error);
        }).finally(() => {
          this.createLoading = false;
        });
    },
  },
};
</script>
<style lang="scss">
  .create-account-form {
    display: flex;
    flex-flow: wrap;
  }
</style>
