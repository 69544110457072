<template>
  <div v-if="isLocked" class="order-status-warning text-center">
    <div class="alert alert-danger text-white my-2">
      <h3>{{$t('cart.this_order_cant_be_edited_anymore')}}</h3>
      {{$t('cart.order_status')}}: {{this.$store.state.cart.status}}
      <div class="mt-2">
        <b-button
          size="xs"
          class="mr-2 p-2"
          :class="{ 'btn-success': !canBePaid }"
          @click="startNewOrder">{{$t('cart.create_a_new_order')}}
        </b-button>
        <b-button
          v-if="canBePaid"
          variant="success"
          class="p-2"
          size="xs"
          @click="goToPayment">{{$t('cart.pay_order')}} <i class='material-icons'>keyboard_arrow_right</i>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    canBePaid: {
      type: Boolean,
      default: false,
    },
    isLocked: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goToPayment() {
      this.$emit('goToPayment');
    },
    startNewOrder() {
      this.$emit('startNewOrder');
    },
  },
};
</script>
<style lang="scss">
  .order-status-warning {
    table.cart-table td {
        border: none;
    }
  }
</style>
