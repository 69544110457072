<template>
  <div class="form-widget-input" :class="rowClasses">
    <form-label-widget
      :label="label"
      :id="id"
      :help-link="helpLink"/>

    <div style="position: relative">
      <i v-if="iconFront"
        class="material-icons input-icon-front">
        {{iconFront}}
      </i>
      <b-form-input
        ref="input"
        :id="id"
        :value="value"
        :class="{inputClass, 'pl-4' : iconFront !== null}"
        :disabled="disabled"
        :readonly="readonly"
        size="sm"
        :state="state"
        :aria-invalid="hasError"
        :placeholder="placeholder"
        :autofocus="autofocus"
        :autocomplete="autocomplete"
        :type="type"
        :name="name"
        @input="onInput"
        @change="onChange" />
      <i v-if="icon !== null"
        class="material-icons input-icon">{{ icon }}</i>
    </div>
    <form-help-widget
      :error="error"
      :warning="warning"
      :help="help" />
  </div>
</template>

<script>
import FormWidgetBase from 'src/components/form/FormWidgetBase';

export default {
  extends: FormWidgetBase,
  props: {
    type: {
      type: String,
      default: 'text',
    },
    iconFront: {
      type: String,
      default: null,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
  },
  methods: {
    onChange(event) {
      this.$emit('change', event);
    },
    onInput(event) {
      this.$emit('input', event);
    },
  },
};
</script>

<style lang="scss">
  .form-widget-input {
    .input-icon-front {
      top: .12rem;
    }
  }
</style>
