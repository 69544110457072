<template>
  <div :class="rowClasses">
    <form-label-widget
      :label="label"
      :id="id"
      :help-link="helpLink" />

    <autocomplete-places
      :id="id"
      :country="country"
      :value="value"
      :size="size"
      classname="form-control"
      placeholder="Start typing"
      types=""
      @placechanged="onPlaceChanged"
      @input="onInput"
      @change="onChange" />

    <form-help-widget
      :error="error"
      :warning="warning"
      :help="help" />

  </div>
</template>

<script>
import FormWidgetBase from 'src/components/form/FormWidgetBase';
import AutocompletePlaces from 'src/components/tools/AutocompletePlaces';

export default {
  components: {
    AutocompletePlaces,
  },
  extends: FormWidgetBase,
  props: {
    type: {
      type: String,
      default: 'text',
      validator(value) {
        return ['text', 'password', 'number', 'url', 'email', 'search'].includes(value);
      },
      required: false,
    },
    country: {
      type: String,
    },
  },
  methods: {
    onPlaceChanged(event) {
      this.$emit('placechanged', event);
    },
    onChange(event) {
      this.$emit('change', event);
    },
    onInput(event) {
      this.$emit('input', event);
    },
  },
};
</script>

<style>
</style>
